import PortalIdParser from 'PortalIdParser';
import memoize from 'react-utils/memoize';
const BLOCK_LIST_PORTALS = [40193627];
const portalId = PortalIdParser.get();

/**
 * Some portals appear to have a workflow that is causing a large amount of
 * graphql errors for permissions-related reasons. The errors are causing
 * us to be paged. While we track down whether the portal is using an abnormal
 * workflow as well as a more proper solution, we'll ignore this portal.
 */
export const isSearchErrorBlocklistPortal = memoize(() => {
  return Boolean(portalId && BLOCK_LIST_PORTALS.includes(portalId));
});