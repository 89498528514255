'use es6';

import { fromJS, List } from 'immutable';
import get from 'transmute/get';
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
const getContact = get('contact');
const getError = get('error');
const getEmailAddress = get('emailAddress');
const formatReferencedObject = item => fromJS(getError(item) ? null : getContact(item));
export default (contactResponses => List(contactResponses.map(item => new ReferenceRecord({
  id: getEmailAddress(item),
  label: getEmailAddress(item),
  referencedObject: formatReferencedObject(item)
}))));