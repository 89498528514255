'use es6';

import http from 'hub-http/clients/apiClient';
import { createGetAllTicketPipelinesWithPermissions } from '../api/PipelinesWithPermissionsAPI';
import { createTicketPipelinesReferenceResolver } from './TicketPipelinesReferenceResolver';
const getAllTicketPipelines = createGetAllTicketPipelinesWithPermissions({
  httpClient: http
});
export const createTicketPipelinesWithPermissionsReferenceResolver = options => createTicketPipelinesReferenceResolver(Object.assign({
  createFetchData: createGetAllTicketPipelinesWithPermissions,
  fetchData: getAllTicketPipelines
}, options));
export default createTicketPipelinesWithPermissionsReferenceResolver();