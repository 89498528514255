import registerAllData from 'I18n/init/register/sync/registerAllData';
import 'I18n/init/data/currency';
import 'I18n/init/data/baseLocales';
import 'I18n/init/data/allTimezones';
import langs from 'i2l?mode=very-lazy&query=sporks!../../lang/en.lyaml';
import { getI18nProvider } from './getI18nProvider';
export function i18nInit() {
  const provider = getI18nProvider();
  return Promise.all([provider.register(langs), registerAllData(provider)]);
}
export function setI18nLocale({
  portal,
  user
} = {}) {
  const provider = getI18nProvider();
  provider.setLocale({
    locale: user.locale,
    langEnabled: user.lang_enabled,
    timezone: portal.timezone
  });
}