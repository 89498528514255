import { setPreferredCurrency } from '../api/preferredCurrencyApi';
import { registerMutation, useMutation } from 'data-fetching-client';
import { fetchPreferredCurrencyQuery } from './useFetchPreferredCurrency';
import { useCallback } from 'react';
const preferredCurrencyMutationFieldName = 'changePreferredCurrency';
export const ChangePreferredCurrencyCodeMutation = registerMutation({
  fieldName: preferredCurrencyMutationFieldName,
  args: ['preferredCurrency'],
  fetcher: ({
    preferredCurrency
  }) => setPreferredCurrency(preferredCurrency)
});
export const useSetPreferredCurrency = () => {
  const [set] = useMutation(ChangePreferredCurrencyCodeMutation, {
    refetchQueries: [{
      query: fetchPreferredCurrencyQuery
    }],
    //From apollo docs: "If true, makes sure all queries included in refetchQueries are completed before the mutation is considered complete."
    //This is important because the backend will return stale data unless we wait for the put request to complete
    awaitRefetchQueries: true
  });
  return useCallback(({
    preferredDisplayCurrency
  }) => {
    return set({
      variables: {
        preferredCurrency: preferredDisplayCurrency
      }
    });
  }, [set]);
};