import { PRODUCT_LIMIT_FREE, PRODUCT_LIMIT_STARTER } from '../constants/limits';
import { FREE, PROFESSIONAL, STARTER } from '../constants/tiers';
export function getTierByProductLimit(limit) {
  if (limit === PRODUCT_LIMIT_FREE) {
    return FREE;
  }
  if (limit === PRODUCT_LIMIT_STARTER) {
    return STARTER;
  }
  return PROFESSIONAL;
}