import { useMemo } from 'react';
import { useQueryObjectDefinitions } from './objectTypeDefinition/useLoadObjectTypes';
import { useFetchRecordCardProperties } from './properties/hooks/useLoadRecordCardProperties';
const useObjectTypeDefinition = objectTypeId => {
  const {
    data
  } = useQueryObjectDefinitions();
  return useMemo(() => {
    if (data) {
      const found = data.find(item => item.objectTypeId === objectTypeId);
      if (found) {
        return found;
      }
    }
    return null;
  }, [data, objectTypeId]);
};
export const useCorePrefetch = options => {
  const objectTypeDefinition = useObjectTypeDefinition(options.objectTypeId);
  const {
    data: properties
  } = useFetchRecordCardProperties(options.objectTypeId, options.pipelineId);
  return {
    objectTypeDefinition,
    properties
  };
};