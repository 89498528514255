'use es6';

import Raven from 'raven-js';
const longTasksByInput = {};
const inputsUsedThisSession = [];
const lastEventTimeStampByInput = {};
let lastTextInputUsed = null;
let perfObs;
const sendData = isProsemirror => {
  Object.keys(longTasksByInput).forEach(inputName => {
    Raven.capturePageEvent('longInputTasks', {
      extra: {
        inputName,
        longTaskCount: longTasksByInput[inputName],
        didReceiveEvents: inputsUsedThisSession.includes(inputName),
        isProsemirror
      }
    });
  });
};
export const setupTextInputPerformanceMeasuring = (isProsemirror = false) => {
  if (window.PerformanceObserver) {
    try {
      if (!perfObs) {
        // only create perfObs and add beforeunload listener once/session
        perfObs = new window.PerformanceObserver(list => {
          const entries = list.getEntries();
          entries.forEach(entry => {
            const lastInputTimeStamp = lastEventTimeStampByInput[lastTextInputUsed];
            if (lastInputTimeStamp && entry.startTime < lastInputTimeStamp && entry.startTime + entry.duration > lastInputTimeStamp) {
              longTasksByInput[lastTextInputUsed]++;
            }
          });
        });
        window.addEventListener('beforeunload', () => {
          sendData(isProsemirror);
        });
      }
      perfObs.observe({
        entryTypes: ['longtask']
      });
    } catch (e) {
      // not all browsers suport longtask or PerformanceObserver.prototype.observe so catch if any of the above fails
      // for more information see https://git.hubteam.com/HubSpot/customer-data-rte/pull/120
    }
  }
};
export const registerNewInput = inputName => {
  longTasksByInput[inputName] = 0;
  let didRecordUsage = false;
  return inputEvent => {
    if (!didRecordUsage) {
      inputsUsedThisSession.push(inputName);
      didRecordUsage = true;
    }
    lastTextInputUsed = inputName;
    lastEventTimeStampByInput[inputName] = inputEvent.timeStamp;
  };
};