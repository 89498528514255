import { useEffect } from 'react';
import { useViews } from '../../views/hooks/useViews';
import { useCurrentViewId } from '../../views/hooks/useCurrentViewId';
import { getPluralForm } from '../../crmObjects/methods/getPluralForm';
import { useSelectedObjectTypeDef } from '../../crmObjects/hooks/useSelectedObjectTypeDef';

/**
 * @function useDocumentTitle
 * @description sets document title with current page object and current page view
 */
export const useDocumentTitle = () => {
  const typeDef = useSelectedObjectTypeDef();
  const allViewObjects = useViews();
  const currentViewId = useCurrentViewId();
  const currentPageObject = typeDef ? getPluralForm(typeDef) || 'Index' : 'Index';
  const currentViewName = currentViewId ? allViewObjects[currentViewId].name || 'Index' : 'Index';
  const documentTitle = `${currentPageObject} | ${currentViewName}`;
  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);
};