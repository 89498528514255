'use es6';

import I18n from 'I18n';
import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import map from 'transmute/map';
import pipe from 'transmute/pipe';
import userInfo from 'hub-http/userInfo';
import { DEAL, TICKET } from 'customer-data-objects/constants/ObjectTypes';
import DealStageStore from 'crm_data/deals/DealStageStore';
import TicketsPipelinesStagesStore from 'crm_data/tickets/TicketsPipelinesStagesStore';
import { CLOSED } from 'customer-data-objects/ticket/TicketStageStatusOptions';
import devLogger from 'react-utils/devLogger';
import { connectPromiseSingle } from 'crm_data/flux/connectPromiseSingle';
import { MONTHS } from '../constants/Months';
import always from 'transmute/always';
// Do not remove! Required to make the pipelines stores function in a code split
// See also https://git.hubteam.com/HubSpot/CRM-Issues/issues/1874
import 'crm_data/tickets/TicketsPipelinesStore';
import 'crm_data/deals/DealPipelineStore';

// these are set in setupFilterPlaceholderResolver
let currentOwnerId;
let currentUser;
const currentOwnerIdWarningInfo = {
  message: 'FilterPlaceholderResolver should not be used with calling setupFilterPlaceholderResolver to initialize the current owner',
  url: 'https://git.hubteam.com/HubSpot/CRM/pull/19205',
  key: 'FilterPlaceholderResolver:Init'
};
const currentUserWarningInfo = {
  message: 'FilterPlaceholderResolver should not be used with calling setupFilterPlaceholderResolver to initialize the current user',
  url: 'https://git.hubteam.com/HubSpot/CRM/pull/19205',
  key: 'FilterPlaceholderResolver:Init'
};
export const PLACEHOLDER_ME = '__hs__ME';
export const PLACEHOLDER_ID = '__hs__ID';
export const PLACEHOLDER_NOW = '__hs__NOW';
export const PLACEHOLDER_CLOSED_WON = '__hs__CLOSEDWON';
export const PLACEHOLDER_TICKET_CLOSED = '__hs__TICKET_CLOSED';
export const PLACEHOLDER_FISCAL_YEAR_OFFSET = '__hs__FISCAL_YEAR_OFFSET';
const resolveValues = (filter, valueMap) => {
  if (filter.values) {
    filter.values = map(value => valueMap.has(value) ? valueMap.get(value) : value, filter.values);
  }
  if (valueMap.has(filter.value)) {
    filter.value = valueMap.get(filter.value);
  }
  return filter;
};
const getClosedWonDealStages = () => {
  const dealstages = DealStageStore.get();
  if (!dealstages) {
    return false;
  }
  return dealstages.filter(stage => stage.get('probability') === 1).map(stage => stage.get('value'));
};
const fiscalYearDefaultMonthOffset = 0;
export const getFiscalYearMonthOffset = month => {
  const offset = MONTHS.indexOf(month);
  return offset > -1 ? offset : fiscalYearDefaultMonthOffset;
};
const getClosedTicketStages = () => {
  const ticketStages = TicketsPipelinesStagesStore.get();
  if (!ticketStages) return false;
  return ticketStages.filter(stage => stage.getIn(['metadata', 'ticketState']) === CLOSED).map(stage => stage.get('value'));
};
const resolveIdentityPlaceholders = filter => {
  if (!currentOwnerId) {
    devLogger.warn(currentOwnerIdWarningInfo);
  }
  if (!currentUser) {
    devLogger.warn(currentUserWarningInfo);
  }
  return resolveValues(filter, ImmutableMap({
    [PLACEHOLDER_ME]: `${currentOwnerId}`,
    [PLACEHOLDER_ID]: `${currentUser.user_id}`,
    [PLACEHOLDER_NOW]: I18n.moment.userTz().valueOf()
  }));
};
const resolveClosedWonStages = filter => {
  let values = ImmutableSet(filter.values);
  let stages;
  if (values.contains(PLACEHOLDER_CLOSED_WON)) {
    values = values.remove(PLACEHOLDER_CLOSED_WON);
    stages = getClosedWonDealStages();
  } else if (values.contains(PLACEHOLDER_TICKET_CLOSED)) {
    values = values.remove(PLACEHOLDER_TICKET_CLOSED);
    stages = getClosedTicketStages();
  }
  if (stages) {
    filter.values = values.union(stages.toSet()).toArray();
  }
  return filter;
};
const removeLabel = filter => {
  delete filter.label;
  return filter;
};
const resolveFilters = map(pipe(resolveIdentityPlaceholders, resolveClosedWonStages, removeLabel));
export const setupFilterPlaceholderResolver = ownerId => {
  currentOwnerId = ownerId;
  return userInfo().then(({
    user
  }) => {
    currentUser = user;
  });
};
export function fixGeoProperties(filter) {
  if (filter.operator === 'DISTANCE_LT' && filter.distanceUnit == null) {
    filter.distanceUnit = 'MILES';
    filter.distanceUnitCount = 25;
  }
  return filter;
}
export const fetchDealStages = connectPromiseSingle({
  stores: [DealStageStore],
  deref: () => DealStageStore.get()
});
export const fetchTicketStages = connectPromiseSingle({
  stores: [TicketsPipelinesStagesStore],
  deref: () => TicketsPipelinesStagesStore.get()
});
export const fetchNoRequiredData = always(Promise.resolve(undefined));
export const getFetchRequiredFilterPlaceholderResolverData = objectType => {
  if (objectType === DEAL) {
    return fetchDealStages;
  } else if (objectType === TICKET) {
    return fetchTicketStages;
  }
  return fetchNoRequiredData;
};
export { resolveFilters as replaceSpecialTypes };