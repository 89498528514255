module.exports = {
  "tourInteraction": {
    "name": "tour interaction",
    "class": "interaction",
    "properties": {
      "appName": {
        "type": "string"
      },
      "tourId": {
        "type": "string"
      },
      "stepId": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string"
      },
      "value": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ui-shepherd-react"
  },
  "tourStart": {
    "name": "shepherd tour start",
    "class": "view",
    "properties": {
      "appName": {
        "type": "string"
      },
      "tourId": {
        "type": "string"
      }
    },
    "namespace": "ui-shepherd-react"
  },
  "tourClose": {
    "name": "shepherd tour close",
    "class": "interaction",
    "properties": {
      "appName": {
        "type": "string"
      },
      "tourId": {
        "type": "string"
      },
      "stepId": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ui-shepherd-react"
  },
  "tourFinish": {
    "name": "shepherd tour finish",
    "class": "interaction",
    "properties": {
      "appName": {
        "type": "string"
      },
      "tourId": {
        "type": "string"
      }
    },
    "namespace": "ui-shepherd-react"
  },
  "tourViewStep": {
    "name": "shepherd tour view step",
    "class": "view",
    "properties": {
      "appName": {
        "type": "string"
      },
      "tourId": {
        "type": "string"
      },
      "stepId": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ui-shepherd-react"
  }
};