import * as PropertyTypes from 'customer-data-objects/property/PropertyTypes';
import { checkIfDatetimeOperatorSupported, checkIfEnumOperatorSupported, checkIfNumberDurationOperatorSupported, checkIfStringOperatorSupported, checkIfBoolOperatorSupported } from './checkFiltersSupport';
const isQuickFilterOperatorUnmodified = (quickFilter, propertyRecord) => {
  const {
    operator
  } = quickFilter;
  const {
    type,
    numberDisplayHint
  } = propertyRecord;
  if (numberDisplayHint === 'duration') {
    return checkIfNumberDurationOperatorSupported(operator);
  }
  switch (type) {
    case PropertyTypes.DATE_TIME:
      return checkIfDatetimeOperatorSupported(operator, 'rollingOffset' in quickFilter ? quickFilter.rollingOffset : undefined);
    case PropertyTypes.ENUMERATION:
      return checkIfEnumOperatorSupported(operator);
    case PropertyTypes.BOOLEAN:
      return checkIfBoolOperatorSupported(operator);
    case PropertyTypes.NUMBER:
      return checkIfNumberDurationOperatorSupported(operator);
    case PropertyTypes.STRING:
      return checkIfStringOperatorSupported(operator);
    default:
      return false;
  }
};
export const separateFilterGroupsAndQuickFilters = (unparsedFilters = [], quickFilterProperties) => {
  const quickFilters = [];
  quickFilterProperties.forEach(quickFilterProperty => {
    const quickFilter = unparsedFilters.find(maybeQuickFilter => maybeQuickFilter.property === quickFilterProperty.name && isQuickFilterOperatorUnmodified(maybeQuickFilter, quickFilterProperty));
    if (quickFilter) {
      quickFilters.push(quickFilter);
    }
  });
  const parsedAdvancedFilters = unparsedFilters.filter(advancedFilter => !quickFilters.includes(advancedFilter));
  const filterGroups = parsedAdvancedFilters.length ? [{
    filters: parsedAdvancedFilters
  }] : [];
  return {
    filterGroups,
    quickFilters
  };
};