import { fetchFrameworkTypes } from './frameworkTypesApi';
import { makeFrameworkTypesQuickFetchKey } from 'framework-data-schema-quick-fetch';
import { Metrics } from '../metrics';
import { wrapPromise } from 'persist-promise/wrapPromise';
import { isEarlyCacheReturnEnabled } from '../earlyCacheReturn';
const persistedFetchFrameworkTypes = wrapPromise({
  namespace: 'FDSR',
  entityName: 'frameworkTypes',
  deepFreeze: true,
  toCacheKey: makeFrameworkTypesQuickFetchKey,
  fetch: fetchFrameworkTypes,
  metricsConfig: {
    enablePatchDiffing: true,
    convertKeyToMetricsDimension: key => {
      const match = key.match(makeFrameworkTypesQuickFetchKey({
        portalId: '.*',
        family: '(.*)'
      }));
      return match && match[1] || null;
    },
    normalizeForPatchDiffing: (data, env) => env.keyBy(data, ({
      fullyQualifiedName
    }) => fullyQualifiedName, type => {
      var _type$scopeMappings;
      if (!((_type$scopeMappings = type.scopeMappings) !== null && _type$scopeMappings !== void 0 && _type$scopeMappings.length)) {
        return type;
      }
      return Object.assign({}, type, {
        scopeMappings: env.keyBy(type.scopeMappings, ({
          requestAction,
          accessLevel,
          scopeName
        }) => `${requestAction}-${accessLevel}-${scopeName}`)
      });
    })
  }
});
export const makeFrameworkTypesClient = ({
  httpClient,
  persistedPromise = persistedFetchFrameworkTypes
}) => {
  const getForFamily = ({
    family
  }) => persistedPromise.makeFetchWithOpts({
    allowEagerCacheReturn: isEarlyCacheReturnEnabled()
  })({
    family,
    httpClient
  });
  return Promise.resolve({
    /**
     * Prints debug info to the console.
     */
    debug: () => {
      persistedPromise.debug();
    },
    /**
     * Clears internal cache state
     *
     * @returns A promise which resolves when state is clear.
     */
    clearCache: () => {
      persistedPromise.clearCache();
      return Promise.resolve();
    },
    /**
     * Gets all event type definitions in this portal.
     *
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to all event types, or null if the data could not be found.
     */
    getEvents: ({
      __isComposed = false
    } = {}) => {
      if (!__isComposed) {
        Metrics.counter('frameworkTypes.getEvents').increment();
      }
      return getForFamily({
        family: 'events'
      });
    },
    /**
     * Gets all object type definitions in this portal.
     *
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to all object types, or null if the data could not be found.
     */
    getObjects: ({
      __isComposed = false
    } = {}) => {
      if (!__isComposed) {
        Metrics.counter('frameworkTypes.getObjects').increment();
      }
      return getForFamily({
        family: 'objects'
      });
    }
  });
};