import { getRecordCard } from '../api/recordCardApi';
import { withCrmObjectBoardNamespace } from '../../utils';
import { registerQuery, useQuery } from 'data-fetching-client';
import { useGetHasScope } from '../../user/useGetHasScope';
import { useMemo } from 'react';
import { useBoardProps } from '../../boardProps/useBoardProps';
const fetcher = ({
  objectTypeId,
  pipelineId
}) => getRecordCard({
  objectTypeId,
  pipelineId
});
export const GET_RECORD_CARD_QUERY = withCrmObjectBoardNamespace('GET_RECORD_CARD');
const getRecordCardQuery = registerQuery({
  fieldName: GET_RECORD_CARD_QUERY,
  args: ['objectTypeId', 'pipelineId'],
  fetcher
});
export const useFetchRecordCardProperties = (objectTypeId, pipelineId) => {
  const {
    hasScope,
    loading: scopesLoading
  } = useGetHasScope();
  const {
    loading: recordCardLoading,
    error,
    data
  } = useQuery(getRecordCardQuery, {
    variables: {
      objectTypeId,
      pipelineId: hasScope('crm-board-card-customization-per-pipeline') ? pipelineId : undefined
    },
    skip: scopesLoading
  });
  const loading = useMemo(() => {
    return recordCardLoading || scopesLoading;
  }, [recordCardLoading, scopesLoading]);
  return useMemo(() => {
    if (!loading && data && !error) {
      return {
        data: data[GET_RECORD_CARD_QUERY],
        loading,
        error
      };
    }
    return {
      data: [],
      loading,
      error
    };
  }, [data, error, loading]);
};
export const useLoadRecordCardProperties = () => {
  const {
    objectTypeId,
    pipelineId
  } = useBoardProps();
  const {
    loading,
    data,
    error
  } = useFetchRecordCardProperties(objectTypeId, pipelineId);
  return useMemo(() => {
    if (!loading && data && !error) {
      return {
        data,
        loading
      };
    }
    return {
      data: [],
      loading
    };
  }, [data, error, loading]);
};