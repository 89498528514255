'use es6';

import { makeReferenceResolver } from 'reference-resolvers/ReferenceResolver';
import ResolverLoading from 'reference-resolvers/schema/ResolverLoading';
import ResolverError from 'reference-resolvers/schema/ResolverError';
import { reset, swap, watch, unwatch, deref } from 'atom';
import { Map as ImmutableMap } from 'immutable';
import identity from 'transmute/identity';
import invariant from 'react-utils/invariant';
const initialCacheState = ImmutableMap({
  loading: false,
  loaded: false
});
const createCache = atom => {
  swap(atom, cache => cache != null ? cache : initialCacheState);
  return {
    setLoading() {
      reset(atom, ImmutableMap({
        loading: Date.now(),
        loaded: false
      }));
    },
    setError(error) {
      reset(atom, ImmutableMap({
        loading: false,
        loaded: false,
        error
      }));
    },
    setLoadedData(data) {
      reset(atom, ImmutableMap({
        loading: false,
        loaded: true,
        data
      }));
    },
    listen(onUpdate) {
      watch(atom, onUpdate);
      onUpdate(deref(atom));
      return () => {
        unwatch(atom, onUpdate);
      };
    },
    evict() {
      if (!deref(atom).get('loading')) {
        reset(atom, initialCacheState);
      }
    }
  };
};
const createSimpleCachedReferenceResolver = ({
  cacheKey,
  createFetchData,
  fetchData,
  httpClient,
  selectReferences = identity,
  selectReferencesById = selectReferences,
  selectAllReferences = selectReferences
}) => {
  invariant(cacheKey && typeof cacheKey === 'string', 'expected `cacheKey` to be a non-empty string');
  if (httpClient) {
    invariant(!!createFetchData, 'createFetchData factory function not provided for supplied httpClient for simple resolver');
    fetchData = createFetchData({
      httpClient
    });
  }
  return getCacheAtom => {
    const cache = createCache(getCacheAtom(cacheKey));
    const resolveReferences = (next, selector, resolve) => {
      return cache.listen(state => {
        if (state.get('loading')) {
          next(ResolverLoading({
            startDate: state.get('loading')
          }));
        } else if (state.get('error')) {
          next(ResolverError({
            reason: state.get('error')
          }));
        } else if (state.get('loaded')) {
          const refs = selector(state.get('data'));
          resolve(refs);
        } else {
          cache.setLoading();
          fetchData().then(cache.setLoadedData).catch(cache.setError);
        }
      });
    };
    return makeReferenceResolver({
      byId(id, next) {
        return resolveReferences(next, selectReferencesById, refs => next(refs.get(id)));
      },
      all(next) {
        return resolveReferences(next, selectAllReferences, refs => next(refs.valueSeq()));
      },
      refreshCache() {
        cache.evict();
      }
    }, `SimpleCachedReferenceResolver_${cacheKey}`);
  };
};
export default createSimpleCachedReferenceResolver;