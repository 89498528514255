import { PENDING, SUCCEEDED, FAILED } from '../../rewrite/constants/RequestStatus';
import { FETCH_AUTOMATION_FLOW_STARTED, FETCH_AUTOMATION_FLOW_SUCCEEDED, FETCH_AUTOMATION_FLOW_FAILED } from '../actions/automationActionTypes';
import { produce } from 'immer';
const initialState = {
  status: {},
  data: {}
};
export const automationReducer = produce((draft, action) => {
  switch (action.type) {
    case FETCH_AUTOMATION_FLOW_STARTED:
      {
        const {
          flowIds
        } = action.payload;
        flowIds.forEach(flowId => draft.status[flowId] = PENDING);
        return draft;
      }
    case FETCH_AUTOMATION_FLOW_SUCCEEDED:
      {
        const {
          flowIds,
          response
        } = action.payload;
        flowIds.forEach(flowId => {
          draft.status[flowId] = SUCCEEDED;
          draft.data[flowId] = response[flowId];
        });
        return draft;
      }
    case FETCH_AUTOMATION_FLOW_FAILED:
      {
        const {
          flowIds
        } = action.payload;
        flowIds.forEach(flowId => draft.status[flowId] = FAILED);
        return draft;
      }
    default:
      {
        return draft;
      }
  }
}, initialState);