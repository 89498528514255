import { isSearchErrorBlocklistPortal } from '../isSearchErrorBlocklistPortal';
export class BaseCrmSearchError extends Error {
  /**
   * There are a very small number of portals (at the time of writing this comment, there is a single one)
   * that are deemed "problematic" and cause a large large amount of errors
   * Consumers can optionally read this property when logging to help alleviate their alerting noise for this problematic portals
   */

  constructor({
    message,
    status,
    metadata,
    objectTypeId
  }) {
    super(message);
    this.name = 'BaseCrmSearchError';
    this.status = status;
    this.metadata = metadata;
    this.objectTypeId = objectTypeId;
    this.isBlocklistPortal = isSearchErrorBlocklistPortal();
  }

  /**
   * Takes in literally any error, and will tell you true/false if the error passed in
   * is a class that extends BaseCrmSearchError,
   *
   * Generally this is helpful for when your app/library handles errors, but only a subset of them come from crm search,
   * and what you do/show varies based upon the error.
   *
   * EX:
   * ```tsx
   *   const MyErrorState = (error:Error) => {
   *        if(BaseCrmSearchError.isCrmSearchError(error)){ return <CrmSearchErrorEmptyState error={error}/> }
   *        return <MyCustomErrorState error={error} />
   *   }
   * ```
   */
  static isCrmSearchError(error) {
    return error instanceof BaseCrmSearchError;
  }
}