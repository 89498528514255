import { useGenerateCrmObjectsSearchQueryObject } from 'crm-object-search-query-utilities/crmObjectSearch';
import { useMemo } from 'react';
import { getPropertiesForsearch } from './data/usePropertiesForSearch';
import { CrmObjectBoardSearchQuery } from './data/useCrmSearch';
import { DEFAULT_BOARD_PAGE_SIZE } from './data/constants';
import { useQuery } from '@apollo/client';
import { useObjectTypeAppSettings } from 'framework-app-settings-features-lib';
import { useCorePrefetch } from 'crm-object-board-core/prefetch';
export const usePrefetch = options => {
  const {
    properties,
    objectTypeDefinition
  } = useCorePrefetch({
    objectTypeId: options.objectTypeId,
    pipelineId: options.pipelineId
  });
  const {
    data: tagSettingsMetadata
  } = useObjectTypeAppSettings(options.objectTypeId, 'crmTagsSettings');
  const properitesForSearch = useMemo(() => {
    return getPropertiesForsearch(objectTypeDefinition, [...(properties || []), tagSettingsMetadata ? tagSettingsMetadata.propertyName.value : null]);
  }, [properties, tagSettingsMetadata, objectTypeDefinition]);
  const {
    data: searchQuery,
    loading: searchQueryLoading
  } = useGenerateCrmObjectsSearchQueryObject({
    inputQuery: Object.assign({}, options.searchQuery, {
      filterGroups: options.searchQuery.filterGroups,
      properties: properitesForSearch,
      objectTypeId: options.objectTypeId
    })
  });
  const skip = searchQueryLoading || !properties || !tagSettingsMetadata || !objectTypeDefinition;
  useQuery(CrmObjectBoardSearchQuery, {
    variables: Object.assign({}, searchQuery, {
      count: DEFAULT_BOARD_PAGE_SIZE,
      offset: 0,
      stageIds: options.stageIds
    }),
    context: {
      noBatch: true
    },
    skip
  });
};