'use es6';

import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
import indexBy from 'transmute/indexBy';
import get from 'transmute/get';
import { List, fromJS } from 'immutable';
import formatName from 'I18n/utils/formatName';
const formatUserId = user => {
  return String(user.id);
};
const formatLabel = user => {
  const {
    firstName,
    lastName,
    email
  } = user;
  const fullName = formatName({
    firstName,
    lastName
  });
  if (fullName !== null && fullName.length > 0) {
    return fullName;
  }
  return email;
};
const formatUserReference = user => new ReferenceRecord({
  description: user.email,
  id: formatUserId(user),
  label: formatLabel(user),
  referencedObject: fromJS(user)
});
export const formatUsersV2 = response => {
  return List(response.map(formatUserReference));
};
export const formatUsersV2Map = response => {
  return indexBy(get('id'), formatUsersV2(response));
};