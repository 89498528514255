import { COMPANY, CONTACT, DEAL } from 'customer-data-objects/constants/ObjectTypes';
import { useSearchQuery } from '../../rewrite/searchQuery/hooks/useSearchQuery';
import { getCompanyEmptyState } from 'onboarding-empty-states-lib/utils/getCompanyEmptyState';
import { useTableQueryCache } from '../../rewrite/table/hooks/useTableQueryCache';
const SAMPLE_CONTACT_SOURCE_ID = 'sample-contact';
export const useIsEmptyListOrBoard = objectType => {
  // Can derive total number of data in board from useTableQuery as well
  const {
    data,
    loading
  } = useTableQueryCache();
  const searchQuery = useSearchQuery();
  if (objectType === COMPANY) {
    return getCompanyEmptyState(data, loading);
  }
  if (objectType === DEAL) {
    return {
      isEmptyPageLoading: loading,
      isEmptyPage: !!(data && data.total === 0)
    };
  }
  if (objectType === CONTACT || objectType === 'IMPORT') {
    const numberOfNonSampleContacts = data && data.results.filter(result => {
      var _result$properties$hs;
      return ((_result$properties$hs = result.properties.hs_object_source_id) === null || _result$properties$hs === void 0 || (_result$properties$hs = _result$properties$hs.value) === null || _result$properties$hs === void 0 ? void 0 : _result$properties$hs.toString()) !== SAMPLE_CONTACT_SOURCE_ID;
    }).length;
    const isEmptyState = !!(data && data.total === 0);
    const isOneToTwoContacts = data && data.total <= 2;
    const isEmptyStateOrSampleContacts = isEmptyState || isOneToTwoContacts && numberOfNonSampleContacts === 0;

    // We don't consider "I searched something that returned no results"
    // to be an empty state, so we exclude situations where the user has
    // entered a search term

    const isActiveSearchQuery = searchQuery && searchQuery.query && searchQuery.query.length > 0;
    const isActiveFilter = searchQuery && searchQuery.filterGroups && searchQuery.filterGroups[0].filters && searchQuery.filterGroups[0].filters.length > 0;
    const isDataFiltered = isActiveSearchQuery || isActiveFilter;
    if (objectType === CONTACT) {
      // should show for empty state (or sample contacts only) when not filtered
      const noFilterAndNoResults = isEmptyStateOrSampleContacts && !isDataFiltered;
      return {
        isEmptyPageLoading: loading,
        isEmptyPage: !!noFilterAndNoResults
      };
    }
    if (objectType === 'IMPORT') {
      // should show for less than 2 user-created contacts when not filtered
      const lessThanTwoNonSampleContacts = numberOfNonSampleContacts < 2;
      const lessThanTwoWithNoFilter = lessThanTwoNonSampleContacts && !isDataFiltered;
      return {
        isEmptyPageLoading: loading,
        isEmptyPage: lessThanTwoWithNoFilter
      };
    }
  }
  return {
    isEmptyPageLoading: false,
    isEmptyPage: false
  };
};