import { useCallback, useMemo } from 'react';
import { useUserInfo } from './useUserInfo';
import memoizeOne from 'react-utils/memoizeOne';
const EMPTY_SCOPES = new Set();
const memoizedToScopesSet = memoizeOne(scopes => new Set(scopes));
export const useScopes = () => {
  const userInfo = useUserInfo();
  const userInfoScopes = useMemo(() => {
    if (!userInfo) {
      return EMPTY_SCOPES;
    }
    const {
      user
    } = userInfo;
    if (!user) {
      return EMPTY_SCOPES;
    }
    const {
      scopes
    } = user;
    return memoizedToScopesSet(scopes);
  }, [userInfo]);
  return {
    scopes: userInfoScopes,
    loading: !userInfo
  };
};
export const useGetHasScope = () => {
  const {
    scopes: userInfoScopes,
    loading
  } = useScopes();
  const hasScope = useCallback(scope => {
    return userInfoScopes.has(scope);
  }, [userInfoScopes]);
  return {
    hasScope,
    loading
  };
};