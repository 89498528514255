import { useMemo } from 'react';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import { registerQuery, useQuery } from 'data-fetching-client';
import { makeApolloLikeResultType } from '../typeUtils';
const ALL_OBJECT_TYPE_DEFINITIONS_QUERY_NAME = 'crmObjectSearchQueryUtilitiesUseObjectTypeDefinitions';
const AllObjectTypeDefinitionsQuery = registerQuery({
  fieldName: ALL_OBJECT_TYPE_DEFINITIONS_QUERY_NAME,
  args: [],
  fetcher: () => getFrameworkDataSchemasClient().then(client => client.frameworkTypes.getObjects()).then(objectTypes => {
    var _objectTypes$map;
    return Object.fromEntries((_objectTypes$map = objectTypes.map(objectType => [objectType.objectTypeId, objectType])) !== null && _objectTypes$map !== void 0 ? _objectTypes$map : []);
  })
});
export const useObjectTypeDefinitions = ({
  skip = false
} = {}) => {
  const {
    data,
    loading,
    error
  } = useQuery(AllObjectTypeDefinitionsQuery, {
    skip
  });
  const maybeObjectTypeDefs = data === null || data === void 0 ? void 0 : data[ALL_OBJECT_TYPE_DEFINITIONS_QUERY_NAME];
  return useMemo(() => makeApolloLikeResultType({
    data: maybeObjectTypeDefs,
    loading,
    error
  }), [maybeObjectTypeDefs, loading, error]);
};
export const useObjectTypeDefinition = ({
  objectTypeId
}) => {
  const {
    data,
    loading,
    error
  } = useObjectTypeDefinitions();
  const maybeObjectTypeDef = data === null || data === void 0 ? void 0 : data[objectTypeId];
  return useMemo(() => makeApolloLikeResultType({
    data: maybeObjectTypeDef,
    loading,
    error
  }), [maybeObjectTypeDef, loading, error]);
};