import { DEFAULT, STANDARD } from 'customer-data-objects/view/ViewTypes';
import get from 'transmute/get';
import omit from 'transmute/omit';
export const parseAttribute = function parseAttribute(value) {
  if (value == null || value === undefined) {
    return;
  }
  try {
    if (typeof value === 'string') {
      return JSON.parse(value);
    } else if (typeof value === 'object' && 'toJSON' in value) {
      return value.toJSON();
    }
    return value;
  } catch (e) {
    // Nothing to do here, will return defaultValue
  }
  return;
};

/**
 * @temporary A FE read-time migration to fix broken ilsListMemberships.listId properties until the BE can backfill remove all usages of it
 * @expiration 2025-03-01
 * @deprecated
 * ref: https://hubspot.slack.com/archives/C016VNJ0B8E/p1711980963074899
 *
 * There exists a "magic" property "ilsListMemberships.listId" that we are retiring in favor of 'hs_crm_search.ilsListIds'
 * This code will migrate at read time / parse time any instanc of the legacy property name with the new property name
 *
 * This code can/should be used as temporary bandaid. This will be removed once the backend has migrated all views to not use the ilsListMemberships.listId property
 */
const migrateFilters = filterGroups => {
  const FE_FILTER_MIGRATION = {
    'ilsListMemberships.listId': 'hs_crm_search.ilsListIds'
  };
  const getFilterProperty = filter => {
    const propertyName = filter.property;
    const maybeOverride = FE_FILTER_MIGRATION[propertyName];
    return maybeOverride || propertyName;
  };
  return filterGroups.map(group => {
    const newGroups = group.filters.map(filter => {
      const property = getFilterProperty(filter);
      return Object.assign({}, filter, {
        property
      });
    });
    return {
      filters: newGroups
    };
  });
};

/**
 * This converts the BE's representation of the view to the one we use in our state system.
 */
export const parseViewFromBEModel = view => {
  // Only default views have an idLabel property, but we want to track all viewIds as strings.
  const isDefaultView = Boolean(view.idLabel);
  const stringId = view.idLabel || String(view.id);
  const filterGroups = parseAttribute(view.filterGroups) || [];
  const migratedFilterGroups = migrateFilters(filterGroups);
  return omit(['collectionType', 'portalId'], Object.assign({}, view, {
    id: stringId,
    type: isDefaultView ? DEFAULT : STANDARD,
    ownerId: isDefaultView ? -1 : view.ownerId,
    columns: parseAttribute(view.columns) || [],
    filters: parseAttribute(view.filters) || [],
    filterGroups: migratedFilterGroups,
    quickFilters: parseAttribute(view.quickFilters) || [],
    quickFilterProperties: parseAttribute(view.quickFilterProperties) || [],
    // FIXME: View should have a correct default type for property 'state' rather than empty object
    state: parseAttribute(view.state) || {}
  }));
};

/* This converts from the representation used in our state system to that used in the backend.
 * It is the inverse of parseViewFromBEModel
 */
export const convertViewToBEModel = view =>
// @ts-expect-error: FIXME - Conversion missing property 'collectionType' and 'portalId'
omit(['filters'], Object.assign({}, view, {
  // FIXME: Backend type expect id to be a number, but we use string here for special type of views like 'all' or 'my'
  id: view.id,
  columns: JSON.stringify(view.columns.map(col => ({
    name: get('name', col)
  }))),
  state: JSON.stringify(view.state),
  filterGroups: JSON.stringify(view.filterGroups),
  quickFilters: JSON.stringify(view.quickFilters),
  quickFilterProperties: JSON.stringify(view.quickFilterProperties)
}));