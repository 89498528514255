import { CREATED_CRM_OBJECT, DELETE_CRM_OBJECTS_FAILED, DELETE_CRM_OBJECTS_STARTED, DELETE_CRM_OBJECTS_SUCCEEDED, FETCH_CRM_OBJECTS_FAILED, FETCH_CRM_OBJECTS_STARTED, FETCH_CRM_OBJECTS_SUCCEEDED, UPDATE_CRM_OBJECTS_FAILED, UPDATE_CRM_OBJECTS_STARTED, UPDATE_CRM_OBJECTS_SUCCEEDED, BATCH_DELETE_CRM_OBJECTS_FAILED, BATCH_DELETE_CRM_OBJECTS_STARTED, BATCH_DELETE_CRM_OBJECTS_SUCCEEDED, BATCH_UPDATE_CRM_OBJECTS_FAILED, BATCH_UPDATE_CRM_OBJECTS_STARTED, BATCH_UPDATE_CRM_OBJECTS_SUCCEEDED } from './crmObjectsActionTypes';
import { deleteCrmObjectsByIds, deleteCrmObjectsByFilters, writeCrmObjectPropertiesByIds, writeCrmObjectPropertiesByFilters, writeMixedCrmObjectPropertiesByObjectIds } from '../api/crmObjectsAPI';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
export const createdCrmObjectAction = createAction(CREATED_CRM_OBJECT);
export const getCrmObjectsStartedAction = createAction(FETCH_CRM_OBJECTS_STARTED);
export const getCrmObjectsSucceededAction = createAction(FETCH_CRM_OBJECTS_SUCCEEDED);
export const getCrmObjectsFailedAction = createAction(FETCH_CRM_OBJECTS_FAILED);
export const deleteCrmObjectsSucceededAction = createAction(DELETE_CRM_OBJECTS_SUCCEEDED);
export const deleteCrmObjectsAction = payload => dispatch => {
  dispatch({
    type: DELETE_CRM_OBJECTS_STARTED,
    payload
  });
  return deleteCrmObjectsByIds(payload).then(() => dispatch(deleteCrmObjectsSucceededAction(payload))).catch(error => {
    dispatch({
      type: DELETE_CRM_OBJECTS_FAILED,
      payload
    });
    throw error;
  });
};
export const batchDeleteCrmObjectsSucceededAction = createAction(BATCH_DELETE_CRM_OBJECTS_SUCCEEDED);
export const batchDeleteCrmObjectsAction = payload => dispatch => {
  dispatch({
    type: BATCH_DELETE_CRM_OBJECTS_STARTED,
    payload: {
      objectTypeId: payload.objectTypeId,
      objectIds: payload.objectIds
    }
  });
  return deleteCrmObjectsByFilters(payload).then(() => dispatch(deleteCrmObjectsSucceededAction(payload))).catch(error => {
    dispatch({
      type: BATCH_DELETE_CRM_OBJECTS_FAILED,
      payload: {
        objectTypeId: payload.objectTypeId,
        objectIds: payload.objectIds
      }
    });
    throw error;
  });
};
export const updateCrmObjectsSucceededAction = createAction(UPDATE_CRM_OBJECTS_SUCCEEDED);
export const updateCrmObjectsAction = payload => dispatch => {
  dispatch({
    type: UPDATE_CRM_OBJECTS_STARTED,
    payload
  });
  return writeCrmObjectPropertiesByIds(payload).then(updatedObjectsResponse => dispatch(updateCrmObjectsSucceededAction(Object.assign({}, payload, {
    updatedObjectsResponse
  })))).catch(error => {
    dispatch({
      type: UPDATE_CRM_OBJECTS_FAILED,
      payload
    });
    throw error;
  });
};
export const batchUpdateCrmObjectsSucceededAction = createAction(BATCH_UPDATE_CRM_OBJECTS_SUCCEEDED);
export const batchUpdateCrmObjectsAction = payload => dispatch => {
  dispatch({
    type: BATCH_UPDATE_CRM_OBJECTS_STARTED,
    payload: {
      objectTypeId: payload.objectTypeId,
      propertyValues: payload.propertyValues
    }
  });
  return writeCrmObjectPropertiesByFilters({
    objectTypeId: payload.objectTypeId,
    propertyValues: payload.propertyValues,
    filterGroups: payload.filterGroups,
    query: payload.query
  }).then(() => dispatch(batchUpdateCrmObjectsSucceededAction({
    objectTypeId: payload.objectTypeId,
    propertyValues: payload.propertyValues,
    objectIds: payload.objectIds
  }))).catch(error => {
    dispatch({
      type: BATCH_UPDATE_CRM_OBJECTS_FAILED,
      payload: {
        objectTypeId: payload.objectTypeId,
        propertyValues: payload.propertyValues
      }
    });
    throw error;
  });
};

// Having only writeMixedCrmObjectPropertiesByObjectIds with createAsyncThunk
// returns a generic error instead of the specific one the backend sends.
// This way we return the part of the error we need when the request is rejected.
const thunkHandler = asyncFn => (arg, thunkAPI) => new Promise((resolve, reject) => {
  asyncFn(arg, thunkAPI).then(response => {
    resolve(response);
  }).catch(error => {
    reject(thunkAPI.rejectWithValue(error.responseJSON));
  });
});
export const updateMixedCrmObjectsByIdsAction = createAsyncThunk('updateMixedCrmObjectsByIdsAction', thunkHandler(writeMixedCrmObjectPropertiesByObjectIds));