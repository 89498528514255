'use es6';

import http from 'hub-http/clients/apiClient';
import { createGetAllTicketPipelinesWithPermissions } from '../api/PipelinesWithPermissionsAPI';
import { createTicketStagesReferenceResolver } from './TicketStagesReferenceResolver';
const getAllTicketPipelines = createGetAllTicketPipelinesWithPermissions({
  httpClient: http
});
export const createTicketPipelineStagesWithPermissionsReferenceResolver = options => createTicketStagesReferenceResolver(Object.assign({
  createFetchData: createGetAllTicketPipelinesWithPermissions,
  fetchData: getAllTicketPipelines
}, options));
export default createTicketPipelineStagesWithPermissionsReferenceResolver();