/* eslint-disable */
/*! fast-json-patch, version: 3.1.1 */
// We repackage this, but it's unusable in a worker due to usage of closures.
// I duped the code and hacked it to get just the compare function, as that's all
// we actually need.
export const buildFastJsonPatchInstance = () => {
  function _deepClone(obj) {
    switch (typeof obj) {
      case 'object':
        return JSON.parse(JSON.stringify(obj)); //Faster than ES5 clone - http://jsperf.com/deep-cloning-of-objects/5
      case 'undefined':
        return null; //this is how JSON.stringify behaves for array items
      default:
        return obj; //no need to clone primitives
    }
  }

  function escapePathComponent(path) {
    if (path.indexOf('/') === -1 && path.indexOf('~') === -1) return path;
    return path.replace(/~/g, '~0').replace(/\//g, '~1');
  }

  // Dirty check if obj is different from mirror, generate patches and update mirror
  function _generate(mirror, obj, patches, path) {
    if (obj === mirror) {
      return;
    }
    if (typeof obj.toJSON === 'function') {
      obj = obj.toJSON();
    }
    var newKeys = Object.keys(obj);
    var oldKeys = Object.keys(mirror);
    var changed = false;
    var deleted = false;
    //if ever "move" operation is implemented here, make sure this test runs OK: "should not generate the same patch twice (move)"
    for (var t = oldKeys.length - 1; t >= 0; t--) {
      var key = oldKeys[t];
      var oldVal = mirror[key];
      if (
        Object.prototype.hasOwnProperty.call(obj, key) &&
        !(
          obj[key] === undefined &&
          oldVal !== undefined &&
          Array.isArray(obj) === false
        )
      ) {
        var newVal = obj[key];
        if (
          typeof oldVal == 'object' &&
          oldVal != null &&
          typeof newVal == 'object' &&
          newVal != null &&
          Array.isArray(oldVal) === Array.isArray(newVal)
        ) {
          _generate(
            oldVal,
            newVal,
            patches,
            path + '/' + escapePathComponent(key)
          );
        } else {
          if (oldVal !== newVal) {
            changed = true;
            patches.push({
              op: 'replace',
              path: path + '/' + escapePathComponent(key),
              value: _deepClone(newVal),
            });
          }
        }
      } else if (Array.isArray(mirror) === Array.isArray(obj)) {
        patches.push({
          op: 'remove',
          path: path + '/' + escapePathComponent(key),
        });
        deleted = true; // property has been deleted
      } else {
        patches.push({ op: 'replace', path: path, value: obj });
        changed = true;
      }
    }
    if (!deleted && newKeys.length == oldKeys.length) {
      return;
    }
    for (var t = 0; t < newKeys.length; t++) {
      var key = newKeys[t];
      if (
        !Object.prototype.hasOwnProperty.call(mirror, key) &&
        obj[key] !== undefined
      ) {
        patches.push({
          op: 'add',
          path: path + '/' + escapePathComponent(key),
          value: _deepClone(obj[key]),
        });
      }
    }
  }

  return function compare(tree1, tree2) {
    var patches = [];
    _generate(tree1, tree2, patches, '', false);
    return patches;
  };
};
