'use es6';

import formatName from 'I18n/utils/formatName';
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
import { List, Map as ImmutableMap, fromJS } from 'immutable';
import indexBy from 'transmute/indexBy';
import get from 'transmute/get';
const formatOwnerId = owner => {
  return String(owner.ownerId);
};
export const formatOwnerLabel = owner => {
  const firstName = owner.firstName;
  const lastName = owner.lastName;
  const fullName = formatName({
    firstName,
    lastName
  });
  if (fullName !== null && fullName.length > 0) {
    return fullName;
  } else {
    return owner.email;
  }
};
const formatOwnerReference = owner => new ReferenceRecord({
  id: formatOwnerId(owner),
  label: formatOwnerLabel(owner),
  description: owner.email,
  referencedObject: fromJS(owner)
});
const formatDisabledOwnerReference = (owner, disabled) => new ReferenceRecord({
  id: formatOwnerId(owner),
  label: formatOwnerLabel(owner),
  description: owner.email,
  referencedObject: fromJS(owner),
  disabled
});
export const formatOwners = response => {
  return indexBy(get('id'), List(response).map(formatOwnerReference));
};
export const formatOwnersPaged = (response, allowList) => {
  const {
    results,
    hasMore,
    offset
  } = response;
  const formattedResults = allowList ? List(results).map(owner => formatDisabledOwnerReference(owner, !allowList.includes(owner.activeUserId))) : List(results).map(formatOwnerReference);
  return ImmutableMap({
    hasMore,
    offset: offset || 0,
    count: results.length,
    results: formattedResults
  });
};