import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { UNINITIALIZED } from '../../constants/RequestStatus';
import { useCurrentViewId } from '../../../views/hooks/useCurrentViewId';
import { loadViewColumnWidths } from '../slice/columnWidthsSlice';
import { useColumnWidthsStatus } from './useColumnWidthsStatus';
import Raven from 'raven-js';
export const useLoadColumnWidths = () => {
  const objectTypeId = useSelectedObjectTypeId();
  const viewId = useCurrentViewId();
  const status = useColumnWidthsStatus();
  const dispatch = useDispatch();
  useEffect(() => {
    if (status === UNINITIALIZED) {
      dispatch(loadViewColumnWidths({
        objectTypeId,
        viewId
      })).catch(e => {
        Raven.captureException(e, {
          extra: {
            description: 'useLoadColumnWidths failed on loadViewColumnWidths'
          }
        });
      });
    }
  }, [dispatch, objectTypeId, status, viewId]);
  return status;
};