import { useAllHighlySensitivePropertyValues } from 'customer-data-properties/dataSensitivity/useAllHighlySensitivePropertyValues';
import Raven from 'raven-js';

// exported for use in test cases
export const DEFAULT_HIGHLY_SENSITIVE_PROPERTY_VALUES = {};
export const useDecryptedValues = ({
  objectTypeId
}) => {
  const highlySensitivePropertyValues = useAllHighlySensitivePropertyValues({
    objectTypeId,
    defaultValues: DEFAULT_HIGHLY_SENSITIVE_PROPERTY_VALUES
  });
  if (
  // When the default values are returned, it means we were unable to
  // find a context to read from. We allow this as a pseudo "skip" option,
  // but we probably want to know where it occurs and ensure it is intended.
  highlySensitivePropertyValues === DEFAULT_HIGHLY_SENSITIVE_PROPERTY_VALUES) {
    Raven.captureMessage('useSensibleCrmObjectsSearchQuery was called outside of a PropertiesProvider', {
      stacktrace: true
    });
  }
  return highlySensitivePropertyValues;
};