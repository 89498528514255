import { useMemo } from 'react';
import { useObjectTypeDefinition } from './useObjectTypeDefinition';
export const useSelectedTypeDefSingularForm = () => {
  const selected = useObjectTypeDefinition();
  return selected ? selected.singularForm || selected.name : '';
};
export const useSelectedTypeDefPluralForm = () => {
  const selected = useObjectTypeDefinition();
  return selected ? selected.pluralForm || selected.name : '';
};
export const useObjectTypeDefinitionTranslationOptions = () => {
  const singularForm = useSelectedTypeDefSingularForm();
  const pluralForm = useSelectedTypeDefPluralForm();
  return useMemo(() => {
    return {
      objectName: singularForm,
      singularForm,
      singularFormLowercase: singularForm.toLocaleLowerCase(),
      pluralForm,
      pluralFormLowercase: pluralForm.toLocaleLowerCase()
    };
  }, [singularForm, pluralForm]);
};