import { useEffect } from 'react';
import { useFastDispatch } from '../context/FastContext';
import { errorSlice } from './errorStore';
export const useSyncError = error => {
  const dispatch = useFastDispatch();
  useEffect(() => {
    if (error) {
      dispatch(errorSlice.actions.setError(error));
    }
  }, [dispatch, error]);
};