/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["language", "locale", "portalCreationUtc", "portalId", "portalIsPartner", "userCreationUtc", "userEmail", "userFirstName", "userId", "userLastName", "userIsPartner"];
import enviro from 'enviro';
import { configureCsat } from './csat-config';
import * as isEligible from '../eligibility';
import { combineAll as combineAllFilters } from '../filters';
import { initializeAll as initializeDataSources } from '../sources';
import { defer, fetchScript, log, shortenTimestamp, getBeaconUrl } from '../utils';
import { apiClient } from '../apiClient';
const setupParams = (_ref, surveyKey, customWootricSettings = {}) => {
  let {
      language,
      locale,
      portalCreationUtc,
      portalId,
      portalIsPartner,
      userCreationUtc,
      userEmail,
      userFirstName,
      userId,
      userLastName,
      userIsPartner
    } = _ref,
    extraProperties = _objectWithoutPropertiesLoose(_ref, _excluded);
  const properties = Object.assign({
    language: locale,
    portalCreation_date: shortenTimestamp(portalCreationUtc),
    portalId,
    portalIsPartner,
    userCreation_date: shortenTimestamp(userCreationUtc),
    userEmail,
    userFirstName,
    userId,
    userIsPartner,
    userLastName
  }, extraProperties);
  configureCsat(Object.assign({}, customWootricSettings, {
    created_at: shortenTimestamp(userCreationUtc),
    email: userEmail,
    language,
    properties
  }), surveyKey);
  window.wootric('run');
};
export const setupCsat = (surveyKey, customWootricSettings) => {
  if (!isEligible.earlyCheck()) {
    return;
  }
  Promise.all([fetchScript(getBeaconUrl()), new Promise((resolve, reject) => {
    defer(() => {
      initializeDataSources({
        apiClient,
        isQa: enviro.getShort() === 'qa',
        type: 'csat',
        surveyKey
      }).then(combineAllFilters).then(resolve, reject);
    });
  })]).then(([__, properties]) => {
    if (isEligible.fullCheck(properties)) {
      setupParams(properties, surveyKey, customWootricSettings);
    }
  }).catch(e => {
    log(e);
  });
};