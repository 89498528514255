import { useEffect, useSyncExternalStore, useCallback, useRef } from 'react';
import Raven from 'raven-js';
function usePageVisibility() {
  return useSyncExternalStore(callback => {
    document.addEventListener('visibilitychange', callback);
    return () => {
      document.removeEventListener('visibilitychange', callback);
    };
  }, () => document.visibilityState);
}
export const usePollWhenVisible = ({
  pollInterval = 0,
  startPolling,
  stopPolling,
  refetch,
  skipPollAttempt
}) => {
  const timeHiddenRef = useRef();
  const visibilityState = usePageVisibility();
  const isVisible = visibilityState === 'visible';
  const handlePageHidden = useCallback(() => {
    timeHiddenRef.current = Date.now();
    stopPolling();
  }, [stopPolling]);
  const handlePageVisible = useCallback(() => {
    if (skipPollAttempt) {
      return;
    }

    // Don't trigger a should refetch check if we've never been hidden
    if (timeHiddenRef.current) {
      const timeSinceHidden = Date.now() - timeHiddenRef.current;
      const shouldRefetch = pollInterval > 0 && timeSinceHidden > pollInterval;
      if (shouldRefetch) {
        refetch().catch(e => Raven.captureException(e));
      }
    }
    startPolling(pollInterval);
  }, [pollInterval, refetch, startPolling, skipPollAttempt]);

  // We don't want to re-run handlePageVisible when skipPollAttempt changes
  // so we've separated the logic into two useEffects. handlePageVisible will
  // check the time since hidden and decide whether to refetch or not, so it
  // should only run on true visibility changes.
  useEffect(() => {
    if (isVisible) {
      handlePageVisible();
    } else {
      handlePageHidden();
    }
  }, [isVisible, handlePageVisible, handlePageHidden]);
  useEffect(() => {
    if (skipPollAttempt) {
      stopPolling();
    }
  }, [skipPollAttempt, stopPolling]);
};