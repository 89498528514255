import { ALL_PIPELINES_VALUE } from '../constants/AllPipelinesValue';
import { BOARD } from '../../../views/constants/PageType';
import { useCurrentPageType } from '../../../views/hooks/useCurrentPageType';
import { useSelector } from 'react-redux';
import { getCurrentPipelineIdForCurrentType } from '../selectors/currentPipelineIdSelectors';
export const useCurrentPipelineId = () => {
  const currentPipelineId = useSelector(getCurrentPipelineIdForCurrentType);
  const pageType = useCurrentPageType();
  if (pageType === BOARD && currentPipelineId === ALL_PIPELINES_VALUE) {
    // This needs to be null rather than undefined to match what would come
    // out of localStorage if the key was missing for the comparison in
    // useSyncPipelineId
    return null;
  }
  return currentPipelineId;
};