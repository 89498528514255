function identity(i) {
  return i;
}
/**
 * Creates an object from an array using the key for each item returned by `keyMapper`.
 * Keys should be unique; items sharing the same key will overwrite (last write wins).
 *
 * @example
 * const items = [{ id: '1' }, { id: '2' }]
 * const index = keyBy(items, ({ id }) => id))
 * // => {
 * //   '1': { id: '1' },
 * //   '2': { id: '2' },
 * // }
 */
export function keyBy(collection, keyMapper, valueMapper = identity) {
  return collection.reduce((map, item, index) => {
    const key = keyMapper(item, index);
    map[key] = valueMapper(item, index, map[key]);
    return map;
  }, {});
}
export function get(key, subject) {
  if (!subject) {
    return undefined;
  }
  return subject[key];
}