import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { replaceViewsAction, storeViewsAction } from '../actions/viewsActions';
import { usePojoProperties } from '../../rewrite/properties/hooks/useProperties';
import { useBehavior } from '../../extensions/hooks/useBehavior';
import { QuickFilterProperties } from '../../extensions/constants/BehaviorTypes';
import { useSelectedObjectTypeId } from '../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { separateFilterGroupsAndQuickFilters } from '../utils/separateFilterGroupsAndQuickFilters';
import omit from 'transmute/omit';
import isEmpty from 'transmute/isEmpty';
const withoutFilters = omit(['filters']);
const mapValuesToProcessedViews = (views, mapper) => Object.entries(views).reduce((mappedViews, [viewId, view]) => {
  mappedViews[viewId] = mapper(view);
  return mappedViews;
}, {});
const migrateViewFilters = quickFilterPropertyDefs => view => {
  // this is the filter format migration, from this point on the view
  // should use only .filterGroups and .quickFilters, never .filters
  if (isEmpty(view.filters)) {
    return withoutFilters(view);
  }
  const {
    filterGroups,
    quickFilters
  } = separateFilterGroupsAndQuickFilters(view.filters, quickFilterPropertyDefs);
  return withoutFilters(Object.assign({}, view, {
    filterGroups,
    quickFilters
  }));
};
const isValidPropertyDef = propertyDef => Boolean(propertyDef);
export const useStoreViewsActions = () => {
  const dispatch = useDispatch();
  const objectTypeId = useSelectedObjectTypeId();
  const properties = usePojoProperties();
  const behaviorResult = useBehavior(QuickFilterProperties);
  const quickFilterPropertyNames = useMemo(() => behaviorResult !== null && behaviorResult !== void 0 ? behaviorResult : [], [behaviorResult]);
  const quickFilterPropertyDefs = useMemo(() => quickFilterPropertyNames.map(name => properties[name]).filter(isValidPropertyDef), [properties, quickFilterPropertyNames]);
  const mapViews = useCallback(views => {
    return mapValuesToProcessedViews(views, migrateViewFilters(quickFilterPropertyDefs));
  }, [quickFilterPropertyDefs]);
  const replaceViews = useCallback(views => {
    dispatch(replaceViewsAction({
      objectTypeId,
      views: mapViews(views)
    }));
  }, [dispatch, mapViews, objectTypeId]);
  const storeViews = useCallback(views => {
    dispatch(storeViewsAction({
      objectTypeId,
      views: mapViews(views)
    }));
  }, [dispatch, mapViews, objectTypeId]);
  return {
    storeViews,
    replaceViews
  };
};