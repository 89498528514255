import I18n from 'I18n';
export const getPluralForm = ({
  objectTypeId,
  metaTypeId,
  pluralForm
}) => {
  const pluralKey = `index.objectTypeNames.${objectTypeId}.plural`;
  const isDefault = metaTypeId === 0;
  // FIXME: lookup does not exist on I18n
  // @ts-expect-error type error
  const shouldTranslate = isDefault && I18n.lookup(pluralKey);
  return shouldTranslate ? I18n.text(pluralKey) : pluralForm;
};