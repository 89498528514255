import { Set as ImmutableSet } from 'immutable';
import debounce from 'transmute/debounce';
const classBlocklist = ['public-DraftEditor-content', 'form-control'];
const nodeBlockList = ['INPUT', 'TEXTAREA'];
function getIsBlocklisted(target) {
  if (!nodeBlockList.includes(target.nodeName)) {
    const isBlocklistedClass = [...target.classList].some(el => classBlocklist.includes(el));
    if (!isBlocklistedClass) {
      return false;
    }
  }
  return true;
}
export function createShortcutHandler(...passedInShortcuts) {
  let pressedKeys = ImmutableSet();
  let shortcuts = ImmutableSet(passedInShortcuts);
  function checkAndRunShortcuts() {
    shortcuts.forEach(shortcut => shortcut(pressedKeys));
  }
  function handleProfileKeydown({
    keyCode,
    target
  }) {
    if (getIsBlocklisted(target)) {
      pressedKeys = pressedKeys.clear();
      return false;
    }
    pressedKeys = pressedKeys.add(keyCode);
    checkAndRunShortcuts();
    return true;
  }
  function handleProfileKeyup({
    keyCode,
    target
  }) {
    if (getIsBlocklisted(target)) {
      pressedKeys = pressedKeys.clear();
      return false;
    }
    pressedKeys = pressedKeys.delete(keyCode);
    return true;
  }
  function clearPressedKeys() {
    pressedKeys = pressedKeys.clear();
  }
  function registerShortcut(shortcut) {
    shortcuts = shortcuts.add(shortcut);
  }
  function getShortcuts() {
    return shortcuts;
  }
  const handleKeyUp = debounce(100, handleProfileKeyup);
  const handleKeyDown = debounce(100, handleProfileKeydown);
  function addShortcutListener(useDebounce) {
    document.addEventListener('keyup', useDebounce ? handleKeyUp : handleProfileKeyup);
    document.addEventListener('keydown', useDebounce ? handleKeyDown : handleProfileKeydown);
    window.addEventListener('blur', clearPressedKeys);
  }
  function removeShortcutListener(useDebounce) {
    document.removeEventListener('keyup', useDebounce ? handleKeyUp : handleProfileKeyup);
    document.removeEventListener('keydown', useDebounce ? handleKeyDown : handleProfileKeydown);
    window.removeEventListener('blur', clearPressedKeys);
  }
  function initializeShortcutListener(useDebounce = true) {
    addShortcutListener(useDebounce);
    return () => removeShortcutListener(useDebounce);
  }
  return {
    initializeShortcutListener,
    registerShortcut,
    // used for unit tests
    getShortcuts
  };
}