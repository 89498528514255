'use es6';

import { FETCH } from '../actions/ActionVerbs';
import { dispatchImmediate, dispatchQueue } from '../dispatch/Dispatch';
import { defineFactory } from 'general-store';
import identity from 'transmute/identity';
import { Record } from 'immutable';
import { makeAsyncActionTypes } from '../actions/MakeActions';
import { EMPTY, LOADING } from '../constants/LoadingStatus';
export const LazyValueState = Record({
  promise: null,
  value: LOADING
}, 'LazyValueState');
export function defineLazyValueStore({
  fetch,
  namespace,
  responseTransform = identity,
  getterTransform = ({
    value
  }) => value
}) {
  const {
    QUEUED,
    STARTED,
    FAILED,
    SUCCEEDED
  } = makeAsyncActionTypes(namespace, FETCH);
  return defineFactory().defineName(`${namespace}_LazyValueStore`).defineGetInitialState(LazyValueState).defineGet((state, options) => {
    if (options && options.forceRefresh) {
      dispatchQueue(QUEUED, options);
    } else if (!state.promise) {
      dispatchQueue(QUEUED);
    }
    return getterTransform({
      value: state.value,
      options
    });
  }).defineResponseTo(QUEUED, (state, options = {}) => {
    if (state.promise && !options.forceRefresh) {
      return state;
    }
    const promise = fetch();
    dispatchQueue(STARTED);
    promise.then(value => dispatchImmediate(SUCCEEDED, value), () => dispatchImmediate(FAILED)).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
    return state.set('promise', promise);
  }).defineResponseTo(SUCCEEDED, (state, value) => {
    return state.set('value', responseTransform(value));
  }).defineResponseTo(FAILED, state => {
    return state.set('value', EMPTY);
  });
}