// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'disp... Remove this comment to see the full error message
import dispatcher from 'dispatcher/dispatcher';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'gene... Remove this comment to see the full error message
import { DispatcherInstance } from 'general-store';
const GeneralStoreSetup = () => {
  // DIRTY ROTTEN HACK
  // the payload dispatched by handleViewAction v1 doesn't validate
  // in GeneralStore because it doesn't have an actionType
  // until the format gets sorted out, this should protect us
  // TODO colby: remove this
  dispatcher.handleViewAction = function (action) {
    return this.dispatch({
      source: 'VIEW_ACTION',
      action,
      actionType: action.actionType || ''
    });
  };

  // set the global dispatcher instance so we don't pass a
  // dispatcher to every register
  DispatcherInstance.set(dispatcher);
};
export default GeneralStoreSetup;