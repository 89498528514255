import { useMemo } from 'react';
import { useObjectTypes } from './useObjectTypes';
import { useBoardProps } from '../boardProps/useBoardProps';
export const useObjectTypeDefinition = () => {
  const {
    objectTypeId
  } = useBoardProps();
  const data = useObjectTypes();
  return useMemo(() => {
    if (data) {
      const found = data.find(item => item.objectTypeId === objectTypeId);
      if (found) {
        return found;
      }
    }
    return null;
  }, [data, objectTypeId]);
};