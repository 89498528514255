import http from 'hub-http/clients/apiClient';
import Raven from 'raven-js';
import { BOTTOM_PANEL, INACTIVE_LIMIT_UNITS, STYLES, DEFAULT_CARD_PREFERENCES as defaults } from '../../../constants';
const toServer = data => {
  return {
    cardStyle: data.cozyCardPreferences.isCompact ? STYLES.COZY : STYLES.COMFORTABLE,
    cardBottomPanel: data.cozyCardPreferences.isBottomPanelHidden ? BOTTOM_PANEL.WITHOUT_PANEL : BOTTOM_PANEL.WITH_PANEL,
    cardHideTags: data.cozyCardPreferences.areTagsHidden,
    boardCurrencyIsFullLength: !data.currencyFormatPreferences.isAbbreviated,
    boardInactiveIsTurnedOn: !data.inactiveCardPreferences.isTurnedOff,
    boardInactiveLimit: data.inactiveCardPreferences.value,
    boardInactiveLimitUnit: data.inactiveCardPreferences.unit === INACTIVE_LIMIT_UNITS.DAYS ? 'DAY' : 'WEEK',
    boardNextLastActivityIsTurnedOn: !data.nextLastActivityPreferences.isTurnedOff
  };
};
function getValue(value, parse, defaultValue) {
  if (value === null || value === undefined) {
    return defaultValue;
  }
  return parse(value);
}
const toClient = data => {
  return {
    cozyCardPreferences: {
      isCompact: getValue(data.cardStyle, x => x === STYLES.COZY, defaults.cozyCardPreferences.isCompact),
      isBottomPanelHidden: getValue(data.cardBottomPanel, x => x === BOTTOM_PANEL.WITHOUT_PANEL, defaults.cozyCardPreferences.isBottomPanelHidden),
      areTagsHidden: getValue(data.cardHideTags, x => x, defaults.cozyCardPreferences.areTagsHidden)
    },
    inactiveCardPreferences: {
      isTurnedOff: getValue(data.boardInactiveIsTurnedOn, x => !x, defaults.inactiveCardPreferences.isTurnedOff),
      unit: getValue(data.boardInactiveLimitUnit, x => x === 'WEEK' ? INACTIVE_LIMIT_UNITS.WEEKS : INACTIVE_LIMIT_UNITS.DAYS, defaults.inactiveCardPreferences.unit),
      value: getValue(data.boardInactiveLimit, x => x, defaults.inactiveCardPreferences.value)
    },
    nextLastActivityPreferences: {
      isTurnedOff: getValue(data.boardNextLastActivityIsTurnedOn, x => !x, defaults.nextLastActivityPreferences.isTurnedOff)
    },
    preferredDisplayCurrencyPreferences: {
      preferredDisplayCurrency: 'UNINITIALIZED'
    },
    currencyFormatPreferences: {
      isAbbreviated: getValue(data.boardCurrencyIsFullLength, x => !x, defaults.currencyFormatPreferences.isAbbreviated)
    }
  };
};
export const getUserBoardSettings = objectTypeId => {
  return http.get(`pipelines/v2/pipelines/user-board-settings/${encodeURIComponent(objectTypeId)}/getSettings`).then(toClient).catch(err => {
    Raven.captureException(err);
    throw err;
  });
};
export const setUserBoardSettings = ({
  objectTypeId,
  data
}) => {
  return http.patch(`pipelines/v2/pipelines/user-board-settings/${encodeURIComponent(objectTypeId)}/upsertSettings`, {
    data: toServer(data)
  }).then(() => data).catch(err => {
    Raven.captureException(err);
    throw err;
  });
};