import { useEffect, useCallback } from 'react';
import { useMessageBus } from './useMessageBus';
import { useCrmMessagingContext } from './CrmMessagingContext';
function getMessageSource(envelope = {}) {
  return envelope.sourceId;
}
function getMessageTarget(envelope = {}) {
  return envelope.targetId;
}
function getCurrentMessagingId(messageContext) {
  if (!messageContext) {
    return undefined;
  }
  return messageContext.messagingId;
}
function messageCameFromCurrentMessenger(messageContext, envelope = {}) {
  const currentMessagingId = getCurrentMessagingId(messageContext);
  const messageSource = getMessageSource(envelope);
  return !!messageSource && messageSource === currentMessagingId;
}
export function useOnCrmMessageTopic(topic, onTopic, config) {
  const messageBus = useMessageBus();
  const messagingContext = useCrmMessagingContext();
  const skip = config && config.skip;
  const cbWrapper = useCallback(envelopeData => {
    const {
      data,
      envelope
    } = envelopeData;
    if (config && config.ignoreLocal && messageCameFromCurrentMessenger(messagingContext, envelope)) {
      return;
    }
    const target = getMessageTarget(envelope);
    if (target && target !== getCurrentMessagingId(messagingContext)) {
      return;
    }
    onTopic(data);
  }, [onTopic, messagingContext, config]);
  useEffect(() => {
    if (!messageBus || skip) {
      return () => {};
    }
    const unsubscribe = messageBus.subscribe(topic, cbWrapper);
    return () => {
      unsubscribe();
    };
  }, [messageBus, topic, cbWrapper, skip]);
}