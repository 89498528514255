import { useEffect, useMemo } from 'react';
import { useQueryParams } from '../../../router/useQueryParams';
import { useNavigate } from '../../navigation/hooks/useNavigate';
import { useCurrentView } from '../../../views/hooks/useCurrentView';
import { useViewActions } from '../../../views/hooks/useViewActions';
import { getJsonColumns } from '../utils/getJsonColumns';
import { FiltersQueryParam, ColumnsQueryParam, SpecificPropertyFilterQueryParams, DenormalizedPropertyFilterQueryParamMapping } from '../constants/QueryParamsThatRequireParsing';
import { parseQueryParamFilters } from '../utils/parseQueryParamFilters';
import { normalizeQueryParams } from '../utils/normalizeQueryParams';
// Setting params to undefined will remove them from the url
const queryWithoutParsedParams = [FiltersQueryParam, ColumnsQueryParam, ...SpecificPropertyFilterQueryParams, ...Object.keys(DenormalizedPropertyFilterQueryParamMapping)].reduce((query, param) => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  query[param] = undefined;
  return query;
}, {});
export const hasQueryParamsThatMakeFilters = params => FiltersQueryParam in params || SpecificPropertyFilterQueryParams.some(param => param in params);
export const useHandleSpecialQueryParams = () => {
  const rawParams = useQueryParams();
  const params = useMemo(() => normalizeQueryParams(rawParams), [rawParams]);
  const {
    columns: viewColumns
  } = useCurrentView();
  const hasParamsThatMakeFilters = useMemo(() => hasQueryParamsThatMakeFilters(params), [params]);
  const hasParamsThatMakeColumns = (ColumnsQueryParam in params);
  const {
    onFiltersChanged,
    onColumnsChanged
  } = useViewActions();
  const navigate = useNavigate();
  useEffect(() => {
    if (hasParamsThatMakeFilters) {
      const nextFilters = parseQueryParamFilters(params);
      onFiltersChanged({
        nextFilters,
        isFromFiltersQueryParam: true
      });
    }
    if (hasParamsThatMakeColumns) {
      const parsedColumns = getJsonColumns(params[ColumnsQueryParam]);
      if (parsedColumns) {
        const mergedColumns = parsedColumns.map(name => ({
          name
        })).concat(viewColumns);
        onColumnsChanged({
          columns: mergedColumns,
          isFromQueryParam: true
        });
      }
    }
    if (hasParamsThatMakeFilters || hasParamsThatMakeColumns) {
      navigate({
        query: queryWithoutParsedParams,
        replace: true
      });
    }
  }, [viewColumns, hasParamsThatMakeColumns, hasParamsThatMakeFilters, navigate, onColumnsChanged, onFiltersChanged, params]);
  return !hasParamsThatMakeFilters && !hasParamsThatMakeColumns;
};