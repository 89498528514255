import { retryerClient } from './retryerClient';
import { rethrowError } from 'UIComponents/core/PromiseHandlers';
export const BASE_URI = '/sync-bridge/v3';
export function fetchAccounts(appId) {
  return retryerClient.get(`${BASE_URI}/accounts`, {
    query: {
      appId
    }
  }).then(response => response).catch(rethrowError);
}
export function fetchConnections(appId, accountId) {
  return retryerClient.get(`${BASE_URI}/accounts/${accountId}/connections`, {
    query: {
      appId
    }
  }).then(response => response.connections).catch(rethrowError);
}