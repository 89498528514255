'use es6';

import formatPipelines from 'reference-resolvers/formatters/formatPipelines';
import { OrderedMap } from 'immutable';
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
import partial from 'transmute/partial';
const getDisplayOrder = stage => {
  if (typeof stage.get('displayOrder') === 'number') {
    return stage.get('displayOrder');
  } else {
    return -1;
  }
};
const formatStageId = stage => {
  return String(stage.get('stageId'));
};
const formatStageReference = (pipelineCount, pipelineRef, stage) => {
  return new ReferenceRecord({
    id: formatStageId(stage),
    label: pipelineCount > 1 ? `${stage.get('label')} (${pipelineRef.label})` : stage.get('label'),
    referencedObject: stage.merge({
      pipeline: pipelineRef.referencedObject
    })
  });
};
const getPipelineRefStages = (pipelineCount, pipelineRef) => {
  const sortedStages = pipelineRef.getIn(['referencedObject', 'stages']).sortBy(getDisplayOrder);
  return OrderedMap(sortedStages.map(stage => [formatStageId(stage), formatStageReference(pipelineCount, pipelineRef, stage)]));
};
const formatPipelineStages = response => {
  const pipelineRefs = formatPipelines(response);
  const pipelineCount = pipelineRefs.size;
  return pipelineRefs.valueSeq().map(partial(getPipelineRefStages, pipelineCount)).reduce((a, b) => a.merge(b), OrderedMap());
};
export default formatPipelineStages;