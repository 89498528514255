import { UNINITIALIZED } from '../../constants/RequestStatus';
import { getCurrentObjectTypeId } from '../../init/selectors/routerStateSelectors';
import { createFrozenSelector } from '../../utils/createFrozenSelector';
export const getPropertiesSlice = state => state.properties;
export const getAllPropertiesFetchStatusByObjectType = createFrozenSelector([getPropertiesSlice], ({
  allPropertiesStatus
}) => allPropertiesStatus);
export const getPropertiesData = createFrozenSelector([getPropertiesSlice], ({
  data
}) => data);
export const getPropertiesFetchStatus = createFrozenSelector([getAllPropertiesFetchStatusByObjectType, getCurrentObjectTypeId], (status, objectTypeId) => status[objectTypeId] || UNINITIALIZED);
export const getProperties = createFrozenSelector([getPropertiesData, getCurrentObjectTypeId], (data, objectTypeId) => data[objectTypeId]);