import { createSlice } from '@reduxjs/toolkit';
const initialState = {};
const currentPipelineIdSlice = createSlice({
  name: 'currentPipelineId',
  initialState,
  reducers: {
    pipelineChanged: (state, {
      payload: {
        objectTypeId,
        pipelineId
      }
    }) => {
      state[objectTypeId] = pipelineId;
    }
  }
});
export const {
  pipelineChanged
} = currentPipelineIdSlice.actions;
export const currentPipelineIdReducer = currentPipelineIdSlice.reducer;