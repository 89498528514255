'use es6';

import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { COMPANY } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { createGetObjectsByIds, createSearchObjects, getObjectsByIds, searchObjects } from 'reference-resolvers/api/ContactSearchAPI';
export const createCompanyReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.COMPANIES,
  createFetchByIds: opts => createGetObjectsByIds(opts)(COMPANY),
  createFetchSearchPage: opts => createSearchObjects(opts)(COMPANY),
  fetchByIds: getObjectsByIds(COMPANY),
  fetchSearchPage: searchObjects(COMPANY)
}, options));
export default createCompanyReferenceResolver();