import http from 'hub-http/clients/apiClient';
import { generatePath } from 'react-router-dom';
import { makeQuickFetchedRequest } from '../../../utils/makeQuickFetchedRequest';
export const getDefaultView = makeQuickFetchedRequest({
  getRequestName: ({
    viewId
  }) => viewId ? `defaultView-${viewId}` : 'defaultView',
  request: ({
    objectTypeId,
    viewId
  }) => {
    return http.get(generatePath('sales/v4/views/default-view/:objectTypeId', {
      objectTypeId
    }), {
      query: Object.assign({}, viewId && {
        viewId
      }, {
        namespace: 'NONE'
      })
    });
  }
});