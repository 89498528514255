import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { ALL_PIPELINES_VALUE } from '../constants/AllPipelinesValue';
import { pipelineChanged } from '../slice/currentPipelineIdSlice';
import { clearDefaultPipelineIdInLocalStorage, setDefaultPipelineIdInLocalStorage } from '../utils/pipelineIdLocalStorage';
import { useViewActions } from '../../../views/hooks/useViewActions';
export const useCurrentPipelineIdActions = () => {
  const objectTypeId = useSelectedObjectTypeId();
  const dispatch = useDispatch();
  const {
    onViewPipelineChanged
  } = useViewActions();
  const changePipeline = useCallback(pipelineId => {
    if (pipelineId === ALL_PIPELINES_VALUE) {
      clearDefaultPipelineIdInLocalStorage(objectTypeId);
      //we want to prevent storing ALL_PIPELINES_VALUE as view.state.pipelineId, and instead set it to undefined
      onViewPipelineChanged({
        pipelineId: undefined
      });
    } else {
      setDefaultPipelineIdInLocalStorage(objectTypeId, pipelineId);
      onViewPipelineChanged({
        pipelineId
      });
    }
    return dispatch(pipelineChanged({
      objectTypeId,
      pipelineId
    }));
  }, [dispatch, objectTypeId, onViewPipelineChanged]);
  return {
    changePipeline
  };
};