'use es6';

import { CONTACT, COMPANY, DEAL } from 'customer-data-objects/constants/ObjectTypes';
import { List, Set as ImmutableSet } from 'immutable';
import set from 'transmute/set';

/**
 * Properties that should not be visible in Filters.
 */
export const HiddenFilterProperties = ImmutableSet.of('dealstage.probability');
export const VisibleFilterProperties = ImmutableSet.of('associatedcompanyid', 'associations.company', 'associations.contact');

/**
 * Returns `true` if the PropertyRecord should be visible to users in the filter panel.
 *
 * @param  {any}
 * @param  {PropertyRecord}
 * @return {bool}
 */
export function isVisibleFilterProperty(scopes, {
  hidden,
  name
}, objectType) {
  // the objects that support ils list creation
  const hasILSLists = [CONTACT, COMPANY, DEAL].includes(objectType);
  const hasCLSLists = objectType === CONTACT;
  if (name === 'hs_crm_search.ilsListIds') {
    return Boolean(scopes && !!scopes['ils-lists-read']);
  }
  if (name === 'ilsListMemberships.listId') {
    return hasILSLists && scopes && !!scopes['ils-lists-read'];
  }

  // this should field should never be used anymore
  if (name === 'listMemberships.listId') {
    return hasCLSLists && scopes && !!scopes['contacts-lists-read'];
  }
  switch (name) {
    case '_inbounddbio.importid_':
      return scopes && !!scopes['crm-import-read'];
    case 'formSubmissions.formId':
      return scopes && !!scopes['crm-forms-filtering'];
    default:
      return VisibleFilterProperties.contains(name) || !hidden && !HiddenFilterProperties.contains(name);
  }
}

/**
 * Properties that should not be visible in the Grid.
 */
export const HiddenGridProperties = ImmutableSet.of('_inbounddbio.importid_', 'associations.company', 'associations.contact', 'dealstage.probability', 'formSubmissions.formId', 'listMemberships.listId', 'ilsListMemberships.listId', 'engagement.createdBy', 'engagement.type', 'hs_crm_search.ilsListIds');
export const VisibleGridProperties = ImmutableSet.of('associatedcompanyid', 'metadata.reminders');

/**
 * Returns `true` if the PropertyRecord should be visible to users in the grid.
 *
 * @param  {any}
 * @param  {PropertyRecord}
 * @return {bool}
 */
export function isVisibleGridProperty(scopes, {
  hidden,
  name
}) {
  return VisibleGridProperties.contains(name) || !hidden && !HiddenGridProperties.contains(name);
}
export const addHubspotDefinedToPropertyOptions = property => {
  const {
    hubspotDefined,
    options = List()
  } = property;
  const parsedOptions = hubspotDefined ? options.map(option => set('hubspotDefined', true, option)) : options;
  return set('options', parsedOptions, property);
};