import { useMemo } from 'react';
import { useObjectTypeAppSettings } from 'framework-app-settings-features-lib';
import { useBoardProps } from '../boardProps/useBoardProps';
export const useTagSettings = () => {
  const {
    objectTypeId
  } = useBoardProps();
  const {
    data: tagSettingsMetadata
  } = useObjectTypeAppSettings(objectTypeId, 'crmTagsSettings');
  return useMemo(() => {
    if (tagSettingsMetadata) {
      return tagSettingsMetadata;
    }
    return null;
  }, [tagSettingsMetadata]);
};