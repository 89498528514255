import { useMemo } from 'react';
import { useObjectTypeDefinition } from './useObjectTypeDefinitions';
import { makeApolloLikeResultType } from '../typeUtils';
import { usePipelines } from './usePipelines';
export const useProcessInputQueryFilters = ({
  inputFilters,
  objectTypeId
}) => {
  const {
    data: objectTypeDef,
    loading: objectTypeDefLoading,
    error: objectTypeDefError
  } = useObjectTypeDefinition({
    objectTypeId
  });
  const pipelinePropertyName = objectTypeDef === null || objectTypeDef === void 0 ? void 0 : objectTypeDef.pipelinePropertyName;
  const hasPipelines = objectTypeDef === null || objectTypeDef === void 0 ? void 0 : objectTypeDef.hasPipelines;
  const {
    data: pipelines,
    loading: pipelinesLoading,
    error: pipelinesError
  } = usePipelines({
    objectTypeId
  });
  const hiddenPipelineFilter = useMemo(() => {
    if (!hasPipelines) {
      return undefined;
    }
    const hiddenPipelineIds = (pipelines === null || pipelines === void 0 ? void 0 : pipelines.filter(pipeline => {
      var _pipeline$permission;
      return ((_pipeline$permission = pipeline.permission) === null || _pipeline$permission === void 0 ? void 0 : _pipeline$permission.accessLevel) === 'HIDDEN';
    }).map(pipeline => pipeline.pipelineId)) || [];
    if (hiddenPipelineIds.length === 0) {
      return undefined;
    }
    return {
      operator: 'NOT_IN',
      property: pipelinePropertyName,
      values: hiddenPipelineIds
    };
  }, [hasPipelines, pipelinePropertyName, pipelines]);
  const data = useMemo(() => {
    if (hiddenPipelineFilter === undefined) {
      return inputFilters;
    }

    //if there are no inputFilters, such as [], we need to make a new filter group
    if (inputFilters.length === 0) {
      return [{
        filters: [hiddenPipelineFilter]
      }];
    }
    return inputFilters.map(filterGroup => Object.assign({}, filterGroup, {
      filters: [...filterGroup.filters, hiddenPipelineFilter]
    }));
  }, [hiddenPipelineFilter, inputFilters]);
  const loading = objectTypeDefLoading || pipelinesLoading;
  const error = objectTypeDefError || pipelinesError;
  return useMemo(() => makeApolloLikeResultType({
    data,
    loading,
    error
  }), [data, error, loading]);
};