'use es6';

import ReferenceRecord from '../schema/ReferenceRecord';
import { bridgeAtom, enforceRemoveFunction } from './Strategy';
import { createEnforceResolverValue } from '../lib/enforce';
const enforceResolverValue = createEnforceResolverValue('Immutable.Iterable of ReferenceRecords', ReferenceRecord.isReferenceIterable);
export function makeAllResolver(getSubscription) {
  let all = null;
  return () => {
    if (!all) {
      all = bridgeAtom(next => {
        const remove = enforceRemoveFunction(getSubscription(records => next(enforceResolverValue(records))));
        return () => {
          remove();
          all = null;
        };
      });
    }
    return all;
  };
}