import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModalAction, closeModalAction } from '../actions/overlayActions';
import { getModalType } from '../selectors/overlaySelectors';
import { CREATE_VIEW_MODAL, EDIT_COLUMNS_MODAL, EXPORT_VIEW_MODAL, BOARD_SORT_MODAL, INVALID_STAGE_TRANSITION_MODAL, EMPTY_STATE_WELCOME_MODAL, PRODUCT_PAYMENT_LINK_MODAL, CONTACT_DATA_IMPORT_MODAL, CONFIRM_BYPASS_STAGE_VALIDATION_MODAL, CREATE_OBJECT_FROM_PRODUCT_MODAL } from '../constants/modalTypes';
import { trackOpenEditColumnsModal } from '../../tracking/indexPageTracking';
import { useSelectedObjectTypeDef } from '../../crmObjects/hooks/useSelectedObjectTypeDef';
export const useModalActions = () => {
  const dispatch = useDispatch();
  const modalType = useSelector(getModalType);
  const objectTypeDef = useSelectedObjectTypeDef();
  const openModal = useCallback((type, data) => dispatch(openModalAction(type, data)), [dispatch]);
  const closeModal = useCallback(() => dispatch(closeModalAction()), [dispatch]);
  const openEditColumnsModal = useCallback(() => {
    if (modalType !== EDIT_COLUMNS_MODAL) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      trackOpenEditColumnsModal(objectTypeDef);
    }
    openModal(EDIT_COLUMNS_MODAL);
  }, [objectTypeDef, openModal, modalType]);
  const openViewExportModal = useCallback(({
    viewId,
    exportPageType
  } = {}) => openModal(EXPORT_VIEW_MODAL, {
    viewId,
    exportPageType
  }), [openModal]);
  const openCreateViewModal = useCallback(viewIdToClone => openModal(CREATE_VIEW_MODAL, {
    viewIdToClone
  }), [openModal]);
  const openBoardSortModal = useCallback(() => openModal(BOARD_SORT_MODAL), [openModal]);
  const openInvalidStageMoveModal = useCallback(({
    errorCode,
    objectTypeId,
    objectId
  }) => {
    openModal(INVALID_STAGE_TRANSITION_MODAL, {
      errorCode,
      objectTypeId,
      objectId
    });
  }, [openModal]);
  const openConfirmBypassStageValidationModal = useCallback(({
    errorCode,
    onConfirm,
    onCancel
  }) => {
    openModal(CONFIRM_BYPASS_STAGE_VALIDATION_MODAL, {
      errorCode,
      onConfirm,
      onCancel
    });
  }, [openModal]);
  const openEmptyStateWelcomeModal = useCallback(({
    modalTextType,
    onCloseModal,
    onGuidedSetupClick,
    onManualSetupClick
  }) => openModal(EMPTY_STATE_WELCOME_MODAL, {
    modalTextType,
    onCloseModal,
    onGuidedSetupClick,
    onManualSetupClick
  }), [openModal]);
  const openProductPaymentLinkModal = useCallback(({
    productId
  }) => openModal(PRODUCT_PAYMENT_LINK_MODAL, {
    productId
  }), [openModal]);
  const openCreateObjectFromProductModal = useCallback(({
    productId
  }) => openModal(CREATE_OBJECT_FROM_PRODUCT_MODAL, {
    productId
  }), [openModal]);
  const openContactDataImportModal = useCallback(() => openModal(CONTACT_DATA_IMPORT_MODAL, {}), [openModal]);
  return {
    openEditColumnsModal,
    openViewExportModal,
    openCreateViewModal,
    openBoardSortModal,
    openEmptyStateWelcomeModal,
    openContactDataImportModal,
    openInvalidStageMoveModal,
    openConfirmBypassStageValidationModal,
    openProductPaymentLinkModal,
    openCreateObjectFromProductModal,
    closeModal
  };
};