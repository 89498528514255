import enviro from 'enviro';
import { IntegrationAppIds } from '../constants/IntegrationAppIds';
export function getQuickBooksAppId() {
  if (enviro.isQa()) {
    return IntegrationAppIds.QUICKBOOKS_QA;
  }
  return IntegrationAppIds.QUICKBOOKS_PROD;
}
export function getXeroAppId() {
  if (enviro.isQa()) {
    return IntegrationAppIds.XERO_QA;
  }
  return IntegrationAppIds.XERO_PROD;
}