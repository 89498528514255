import { useMemo } from 'react';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import { registerQuery, useQuery } from 'data-fetching-client';
import { makeApolloLikeResultType } from '../typeUtils';
const ALL_PROPERTIES_QUERY_NAME = 'crmObjectSearchQueryUtilitiesUsePropertyDefintions';
const allPropertiesQueryArgsArgument = ['objectTypeId'];
const AllPropertiesQuery = registerQuery({
  fieldName: ALL_PROPERTIES_QUERY_NAME,
  args: allPropertiesQueryArgsArgument,
  fetcher: args => getFrameworkDataSchemasClient().then(client => client.properties.get({
    frameworkTypeIdentifier: args.objectTypeId,
    query: {
      showHighlySensitiveProperties: true
    }
  })).then(allPropertyDefinitions => {
    var _allPropertyDefinitio;
    return Object.fromEntries((_allPropertyDefinitio = allPropertyDefinitions.map(propertyDefinition => [propertyDefinition.property.name, propertyDefinition.property])) !== null && _allPropertyDefinitio !== void 0 ? _allPropertyDefinitio : []);
  })
});
export const usePropertyDefinitions = ({
  objectTypeId,
  skip = false
}) => {
  const {
    data,
    loading,
    error
  } = useQuery(AllPropertiesQuery, {
    skip,
    variables: {
      objectTypeId
    }
  });
  const propertiesByName = data && data[ALL_PROPERTIES_QUERY_NAME];
  return useMemo(() => {
    return makeApolloLikeResultType({
      data: propertiesByName,
      loading,
      error
    });
  }, [propertiesByName, loading, error]);
};