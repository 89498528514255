export { AppContainerMounted, AppRenderApplicationStart, AppStart, BeginningOfTime, Now } from '../standard/performanceMarks';

/**
 * {
 *  name:  Required. Mark that will be placed as a performance marker
 * }
 */

export const IndexRouteMounted = {
  name: 'mark-index-route-mounted'
};
export const IndexDataLoaderMounted = {
  name: 'mark-index-data-loader-mounted'
};
export const IndexPageMounted = {
  name: 'mark-index-page-mounted'
};
export const TableMounted = {
  name: 'mark-table-mounted'
};
export const TableQueryMounted = {
  name: 'mark-table-query-mounted'
};
export const AsyncTableMounted = {
  name: 'mark-async-table-mounted'
};
export const TableLoaded = {
  name: 'mark-TableLoaded'
};
export const BoardQueryMounted = {
  name: 'mark-BoardQueryMounted'
};
export const BoardLoaded = {
  name: 'mark-BoardLoaded'
};
export const BoardColumnLoaded = {
  name: 'mark-BoardColumnLoaded'
};
export const BoardItemLoaded = {
  name: 'mark-BoardItemLoaded'
};
export const SetupFetched = {
  name: 'mark_setup_fetched'
};
export const SetupLoaded = {
  name: 'mark_setup_loaded'
};
export const StartJS = {
  name: 'mark-startjs'
};