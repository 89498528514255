import I18n from 'I18n';
export const getSingularForm = ({
  objectTypeId,
  metaTypeId,
  singularForm,
  name
}) => {
  const singularKey = `index.objectTypeNames.${objectTypeId}.singular`;
  const isDefault = metaTypeId === 0;
  // FIXME: lookup does not exist on I18n
  // @ts-expect-error type error
  const shouldTranslate = isDefault && I18n.lookup(singularKey);
  return shouldTranslate ? I18n.text(singularKey) : singularForm || name;
};