import Raven from 'raven-js';
import { QUERY_DELETED } from '../LocalMutationsStore';
import { produce } from 'immer';
export const deleteQuery = ({
  queryHash,
  currentState
}) => {
  const nextState = produce(currentState, draft => {
    if (draft.queryMutations[queryHash] === QUERY_DELETED) {
      const error = new Error('LocalMutationsStore - trying to delete an already deleted query');
      Raven.captureException(error);
      return;
    }
    draft.queryMutations[queryHash] = QUERY_DELETED;
    return;
  });
  return nextState;
};