import { useMemo } from 'react';
import { useRecordCardProperties } from 'crm-object-board-core/properties';
import { useObjectTypeDefinition } from 'crm-object-board-core/objectTypeDefinition';
import { useTagPropertyName } from 'crm-object-board-core/tags';
export const getPropertiesForsearch = (typeDef, incoming) => {
  const unique = new Set(['hs_object_id', typeDef === null || typeDef === void 0 ? void 0 : typeDef.pipelinePropertyName, typeDef === null || typeDef === void 0 ? void 0 : typeDef.pipelineStagePropertyName, typeDef === null || typeDef === void 0 ? void 0 : typeDef.primaryDisplayLabelPropertyName, ...incoming].filter(Boolean) //remove null/undefined/empty
  );
  return Array.from(unique);
};
export const usePropertiesForSearch = () => {
  const typeDef = useObjectTypeDefinition();
  const tagPropertyName = useTagPropertyName();
  const recordCardProperties = useRecordCardProperties();
  return useMemo(() => {
    if (!typeDef || !recordCardProperties) {
      return null;
    }
    return getPropertiesForsearch(typeDef, [...recordCardProperties, tagPropertyName || null]);
  }, [typeDef, tagPropertyName, recordCardProperties]);
};