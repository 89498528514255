// Modals
export const EDIT_COLUMNS_MODAL = 'EDIT_COLUMNS_MODAL';
export const EXPORT_VIEW_MODAL = 'EXPORT_VIEW_MODAL';
export const RENAME_VIEW_MODAL = 'RENAME_VIEW_MODAL';
export const BULK_DELETE_MODAL = 'BULK_DELETE_MODAL';
export const MANAGE_VIEW_SHARING_MODAL = 'MANAGE_VIEW_SHARING_MODAL';
export const DELETE_VIEW_MODAL = 'DELETE_VIEW_MODAL';
export const CLONE_VIEW_MODAL = 'CLONE_VIEW_MODAL';
export const CREATE_VIEW_MODAL = 'CREATE_VIEW_MODAL';
export const BOARD_SORT_MODAL = 'BOARD_SORT_MODAL';
export const INVALID_STAGE_TRANSITION_MODAL = 'INVALID_STAGE_TRANSITION_MODAL';
export const CONFIRM_BYPASS_STAGE_VALIDATION_MODAL = 'CONFIRM_BYPASS_STAGE_VALIDATION_MODAL';
export const EMPTY_STATE_WELCOME_MODAL = 'EMPTY_STATE_WELCOME_MODAL';
export const PRODUCT_PAYMENT_LINK_MODAL = 'PRODUCT_PAYMENT_LINK_MODAL';
export const CREATE_OBJECT_FROM_PRODUCT_MODAL = 'CREATE_OBJECT_FROM_PRODUCT_MODAL';
export const CONTACT_DATA_IMPORT_MODAL = 'CONTACT_DATA_IMPORT_MODAL';