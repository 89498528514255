export let ConnectionStatus;
(function (ConnectionStatus) {
  ConnectionStatus["CREATED"] = "CREATED";
  ConnectionStatus["STARTED"] = "STARTED";
  ConnectionStatus["PAUSED"] = "PAUSED";
})(ConnectionStatus || (ConnectionStatus = {}));
export let SyncDirection;
(function (SyncDirection) {
  SyncDirection["BOTH"] = "BOTH";
  SyncDirection["INBOUND"] = "INBOUND";
  SyncDirection["OUTBOUND"] = "OUTBOUND";
})(SyncDirection || (SyncDirection = {}));