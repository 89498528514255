import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import Raven from 'raven-js';
export const fetchAppSettings = ({
  appSettings
}) => {
  return getFrameworkDataSchemasClient().then(client => client.typeMetadata.get({
    appSettingNames: appSettings
  })).catch(error => {
    Raven.captureException(error);
    return undefined;
  });
};