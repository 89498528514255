import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addPendingEdit as addPendingEditAction, clearPendingEdits as clearPendingEditsAction, addPendingEditValidation as addPendingEditValidationAction } from './inlineEditingSlice';
import { mapPropertyUpdatesToBackendType, mapPropertyValuesFromBackendType } from './mapPendingEditsToObjectUpdates';
export const useInlineEditingActions = ({
  objectTypeId: currentObjectTypeId
}) => {
  const dispatch = useDispatch();
  const addPendingEditValidation = useCallback(({
    objectTypeId = currentObjectTypeId,
    objectId,
    validation,
    propertyName
  }) => {
    dispatch(addPendingEditValidationAction({
      objectTypeId,
      objectId,
      propertyName,
      validation
    }));
  }, [currentObjectTypeId, dispatch]);
  const addPendingEdit = useCallback(({
    objectTypeId = currentObjectTypeId,
    objectId,
    updates,
    originalValues
  }) => {
    const decoratedUpdates = mapPropertyValuesFromBackendType(mapPropertyUpdatesToBackendType(updates));
    dispatch(addPendingEditAction({
      objectTypeId,
      objectId,
      updates: decoratedUpdates,
      originalValues
    }));
    Object.entries(decoratedUpdates).forEach(([propertyName, propertyValue]) => {
      dispatch(addPendingEditValidationAction({
        objectTypeId,
        objectId,
        propertyName,
        validation: {
          errors: [],
          invalid: false,
          loading: false,
          saveable: true,
          validationStatus: 'UNKNOWN',
          value: propertyValue,
          propertyName
        }
      }));
    });
  }, [currentObjectTypeId, dispatch]);
  const clearPendingEdits = useCallback(payload => dispatch(clearPendingEditsAction(payload)), [dispatch]);
  return {
    addPendingEdit,
    addPendingEditValidation,
    clearPendingEdits
  };
};