import Superstore, { IndexedDB } from 'superstore';
import Raven from 'raven-js';
import { Metrics } from '../../lib/metrics/metrics';
let storeInstance;
export const getSuperStoreInstance = () => {
  if (!storeInstance) {
    try {
      storeInstance = new Superstore({
        namespace: 'crm-index-ui',
        backend: IndexedDB
      }).open().catch(() => {
        Metrics.counter('superstore-open-failed').increment();
      });
    } catch (e) {
      Metrics.counter('superstore-init-failed').increment();
      Raven.captureException(e, {
        tags: {
          superstoreInit: 'true'
        }
      });
      storeInstance = Promise.reject(e);
    }
  }
  return storeInstance;
};

/**
 * @deprecated
 * Only exported for testing purposes! Please do not use in real code.
 */
export const _clearInstance = () => {
  if (!storeInstance) {
    return Promise.resolve();
  }
  return storeInstance.then(store => {
    // e.g: Promise.reject
    if (!store || typeof store.clear !== 'function') {
      return undefined;
    }
    return store.clear();
  }).finally(() => {
    storeInstance = undefined;
  });
};