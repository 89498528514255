'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["resolverOptions"];
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { createFetchByIds, fetchByIds } from 'reference-resolvers/api/CrmObjectsAPI';
import { createFetchSearchPage, fetchSearchPage } from 'reference-resolvers/api/CrmSearchAPI';
import { CUSTOM_OBJECT } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { getDefaultGettersByObjectTypeId } from 'reference-resolvers/formatters/formatCustomObjects';
import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import memoize from 'transmute/memoize';
const createCustomObjectKey = memoize((key, objectTypeId) => `${key}_${objectTypeId}`);
export const toCustomReferenceObjectType = objectTypeId => createCustomObjectKey(CUSTOM_OBJECT, objectTypeId);
export const getFormatterOptions = (objectTypeId, formatterOptions = {}) => Object.assign({}, formatterOptions, {
  getters: Object.assign({}, getDefaultGettersByObjectTypeId(objectTypeId), formatterOptions.getters)
});
export const createCustomObjectReferenceResolver = (objectTypeId, {
  resolverOptions,
  fetchQueryParams,
  searchOptions,
  searchQueryParams,
  formatterOptions: options
} = {}) => {
  const formatterOptions = getFormatterOptions(objectTypeId, options);
  return createBatchedReferenceResolver(Object.assign({
    cacheKey: createCustomObjectKey(CacheKeys.CUSTOM_OBJECT, objectTypeId),
    maxBatchSize: 200,
    createFetchByIds: opts => createFetchByIds(Object.assign({}, opts))(objectTypeId, {
      fetchQueryParams,
      formatterOptions
    }),
    createFetchSearchPage: opts => createFetchSearchPage(opts)(objectTypeId, {
      searchOptions,
      searchQueryParams,
      formatterOptions
    }),
    fetchByIds: fetchByIds(objectTypeId, {
      fetchQueryParams,
      formatterOptions
    }),
    fetchSearchPage: fetchSearchPage(objectTypeId, {
      searchOptions,
      searchQueryParams,
      formatterOptions
    })
  }, resolverOptions));
};
export default ((objectTypeId, options) => {
  const opts = _objectWithoutPropertiesLoose(options, _excluded);
  return createCustomObjectReferenceResolver(objectTypeId, opts);
});