import { getPerformanceAPI } from './fromWindow';
import getMarkerName from './getMarkerName';
const markExists = marker => {
  const perfAPI = getPerformanceAPI();
  if (!perfAPI) {
    return undefined;
  }
  const markerName = getMarkerName(marker);
  return markerName ? perfAPI.getEntriesByName(markerName).length > 0 : false;
};
export default markExists;