import { useGetHasScope } from '../../../hooks/useGetHasScope';
import { useHasMulticurrencyActive } from './useFetchPortalCurrencies';
const PreferredDisplayCurrencyScope = 'crm-pipelines-preferred-currency-access';
export const useHasPreferredDisplayCurrencyScope = () => {
  const {
    hasScope
  } = useGetHasScope();
  return hasScope(PreferredDisplayCurrencyScope);
};
export const useHasPreferredDisplayCurrency = objectTypeId => {
  const hasMulticurrencyActive = useHasMulticurrencyActive(objectTypeId);
  const hasScope = useHasPreferredDisplayCurrencyScope();
  return hasMulticurrencyActive && hasScope;
};