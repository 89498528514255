// FIXME: Use me instead of the one in js/quick-fetch
import quickFetch from 'quick-fetch';

/**
 * This function consumes a request name and a promise and returns a function that will
 * check for that requestName in quickFetch before calling the promise.
 * It implements faas's standard guidelines such as retrying on failure across all our
 * quickFetched requests.
 *
 * Note that regardless of whether or not it succeeds or fails, the request state is cleared.
 * This is to prevent the quickFetch-enhanced api files from returning stale data.
 *
 * @param {string} requestName - Whatever name you have given your request in your early requester
 * @param {(...params: any[]) => Promise<unknown>} request - The promise to be used when a quickFetched request is not available.
 * @returns {(...params: any[]) => Promise<unknown>} The enhanced function
 */
export const makeQuickFetchedRequest = _ref => {
  let {
    requestName,
    getRequestName,
    request
  } = _ref;
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...params) => {
      const name = requestName || getRequestName && getRequestName(...params) || '';
      const quickFetchedRequest = quickFetch.getRequestStateByName(name);
      return quickFetchedRequest ? new Promise((resolve, reject) => {
        quickFetchedRequest.whenFinished(result => {
          quickFetch.removeEarlyRequest(name);
          resolve(result);
        });
        quickFetchedRequest.onError(() => {
          quickFetch.removeEarlyRequest(name);
          request(...params).then(resolve).catch(reject);
        });
      }) : request(...params);
    }
  );
};