import { useCallback } from 'react';
import { usePropertyDefinitions } from './usePropertyDefinitions';
export const useGetPropertyDefinition = () => {
  const data = usePropertyDefinitions();
  return useCallback(propertyName => {
    if (data.has(propertyName)) {
      return data.get(propertyName);
    }
    return null;
  }, [data]);
};