'use es6';

import http from 'hub-http/clients/apiClient';
import { formatOwners, formatOwnersPaged } from 'reference-resolvers/formatters/formatOwners';
import formatUsers from 'reference-resolvers/formatters/formatUsers';
import { Map as ImmutableMap } from 'immutable';
const BASE_URL = 'owners/v2/owners';
const formatResponse = response => {
  return ImmutableMap({
    owners: formatOwners(response),
    users: formatUsers(response)
  });
};
export const createGetAllOwners = ({
  httpClient
}) => () => httpClient.get(BASE_URL, {
  query: {
    contactsOnly: true
  }
}).then(formatResponse);
export const getAllOwners = createGetAllOwners({
  httpClient: http
});
export const createGetOwnersById = ({
  httpClient
}) => ids => httpClient.get(`${BASE_URL}/batch-v2`, {
  query: {
    ownerId: ids
  }
}).then(response => formatOwners(ImmutableMap(response).toList()));
export const getOwnersById = createGetOwnersById({
  httpClient: http
});
export const createFetchOwnersSearchPage = ({
  httpClient
}) => props => {
  const {
    count,
    includeInactive,
    includeSignature,
    offset,
    query
  } = props.toJS();
  return httpClient.get(`${BASE_URL}/search`, {
    query: {
      includeInactive: includeInactive || false,
      limit: count,
      offset: offset || 0,
      search: query,
      includeSignature: includeSignature || false
    }
  });
};
export const fetchOwnersSearchPage = createFetchOwnersSearchPage({
  httpClient: http
});
export const createGetOwnersSearchPage = options => props => {
  const fetchFn = createFetchOwnersSearchPage(options);
  return fetchFn(props).then(formatOwnersPaged);
};
export const getOwnersSearchPage = props => fetchOwnersSearchPage(props).then(formatOwnersPaged);