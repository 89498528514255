import { OBJECT_DELETED } from '../LocalMutationsStore';
import { produce } from 'immer';
import Raven from 'raven-js';
export const addIndividualObjectMutation = ({
  input: {
    objectTypeId,
    objectId,
    mutation: currMutation
  },
  currentState
}) => {
  const nextState = produce(currentState, draft => {
    var _draft$individualObje, _draft$individualObje2, _ref, _ref$objectId, _draft$individualObje3;
    (_draft$individualObje2 = (_draft$individualObje = draft.individualObjects)[objectTypeId]) !== null && _draft$individualObje2 !== void 0 ? _draft$individualObje2 : _draft$individualObje[objectTypeId] = {};
    (_ref$objectId = (_ref = draft.individualObjects[objectTypeId])[objectId]) !== null && _ref$objectId !== void 0 ? _ref$objectId : _ref[objectId] = [];
    const prevMutations = (_draft$individualObje3 = draft.individualObjects[objectTypeId]) === null || _draft$individualObje3 === void 0 ? void 0 : _draft$individualObje3[objectId];
    const isEdit = currMutation !== OBJECT_DELETED;
    const objectDeleted = prevMutations === null || prevMutations === void 0 ? void 0 : prevMutations.includes(OBJECT_DELETED);

    // Allow deleting an already deleted object, but not editing an
    // already deleted object.
    if (isEdit && objectDeleted) {
      Raven.captureException(new Error('LocalMutationsStore - trying to edit an already deleted object'));
    } else {
      prevMutations === null || prevMutations === void 0 || prevMutations.push(currMutation);
    }
    return;
  });
  return nextState;
};