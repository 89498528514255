export const TASK_LAST_TOUCH = 'TASK_LAST_TOUCH';
export const TASK_LAST_ENGAGEMENT = 'TASK_LAST_ENGAGEMENT';
export const ACTIVITY_TYPES = 'ACTIVITY_TYPES';
export const ADS = 'ADS';
export const ADS_ACCOUNTS = 'ADS_ACCOUNTS';
export const ADS_ADGROUPS = 'ADS_ADGROUPS';
export const ADS_CAMPAIGNS = 'ADS_CAMPAIGNS';
export const ADS_KEYWORDS = 'ADS_KEYWORDS';
export const ADS_FACEBOOK_ADGROUPS = 'ADS_FACEBOOK_ADGROUPS';
export const ADS_FACEBOOK_ADS = 'ADS_FACEBOOK_ADS';
export const ADS_FACEBOOK_CAMPAIGNS = 'ADS_FACEBOOK_CAMPAIGNS';
export const ADS_GOOGLE_ADGROUPS = 'ADS_GOOGLE_ADGROUPS';
export const ADS_GOOGLE_ADS = 'ADS_GOOGLE_ADS';
export const ADS_GOOGLE_CAMPAIGNS = 'ADS_GOOGLE_CAMPAIGNS';
export const ADS_GOOGLE_KEYWORDS = 'ADS_GOOGLE_KEYWORDS';
export const ADS_LINKEDIN_ADGROUPS = 'ADS_LINKEDIN_ADGROUPS';
export const ADS_LINKEDIN_ADS = 'ADS_LINKEDIN_ADS';
export const ADS_LINKEDIN_CAMPAIGNS = 'ADS_LINKEDIN_CAMPAIGNS';
export const ADS_PROPERTIES = 'ADS_PROPERTIES';
export const ALL_OBJECT_PROPERTIES = 'ALL_OBJECT_PROPERTIES';
export const ALL_WORKFLOWS = 'ALL_WORKFLOWS';
export const ANALYTICS_VIEWS = 'ANALYTICS_VIEWS';
export const APP_USERS = 'APP_USERS';
export const AUTOMATED_EMAILS = 'AUTOMATED_EMAILS';
export const AUTOMATION_PLATFORM_WORKFLOWS = 'AUTOMATION_PLATFORM_WORKFLOWS';
export const BLOG_AUTHOR = 'BLOG_AUTHOR';
export const BLOG_POSTS = 'BLOG_POSTS';
export const BLOG_TAG = 'BLOG_TAG';
export const BLOGS = 'BLOGS';
export const BROADCASTS = 'BROADCASTS';
export const BUSINESS_UNITS = 'BUSINESS_UNITS';
export const BUSINESS_UNIT_DROPDOWN = 'BUSINESS_UNIT_DROPDOWN';
export const CALL_DISPOSITIONS = 'CALL_DISPOSITIONS';
export const CAMPAIGNS = 'CAMPAIGNS';
export const CHATFLOW = 'CHATFLOW';
export const CHATFLOWS_BY_UNIQUE_ID = 'CHATFLOWS_BY_UNIQUE_ID';
export const COMMENTS = 'COMMENTS';
export const COMPANIES = 'COMPANIES';
export const COMPANIES_SAVED_FILTERS = 'COMPANIES_SAVED_FILTERS';
export const COMPANY_PROPERTIES = 'COMPANY_PROPERTIES';
export const CONTACT_LISTS = 'CONTACT_LISTS';
export const CONTACT_PROPERTIES = 'CONTACT_PROPERTIES';
export const CONTACT_STATIC_LISTS = 'CONTACT_STATIC_LISTS';
export const CONTACTS = 'CONTACTS';
export const CONTACTS_BY_EMAIL = 'CONTACTS_BY_EMAIL';
export const CONTENTS = 'CONTENTS';
export const CONTACTS_SAVED_FILTERS = 'CONTACTS_SAVED_FILTERS';
export const CONVERSATION_INBOX = 'CONVERSATION_INBOX';
export const CONVERSATION_PROPERTIES = 'CONVERSATION_PROPERTIES';
export const COS_INDEX_CONNECTIONS_PARENTS = 'COS_INDEX_CONNECTIONS_PARENTS';
export const CTA_TO_PLACEMENT = 'CTA_TO_PLACEMENT';
export const CTAS = 'CTAS';
export const CTA_GUID = 'CTA_GUID';
export const CUSTOM_OBJECT = 'CUSTOM_OBJECT';
export const CUSTOM_OBJECT_TYPES = 'CUSTOM_OBJECT_TYPES';
export const DEAL_PIPELINE_STAGES = 'DEAL_PIPELINE_STAGES';
export const DEAL_PIPELINES = 'DEAL_PIPELINES';
export const DEAL_PROPERTIES = 'DEAL_PROPERTIES';
export const DEALS = 'DEALS';
export const REVENUE_DEAL_MERGES = 'REVENUE_DEAL_MERGES';
export const DEALS_SAVED_FILTERS = 'DEALS_SAVED_FILTERS';
export const ECOMMERCE_APPS = 'ECOMMERCE_APPS';
export const ECOMMERCE_STORES = 'ECOMMERCE_STORES';
export const EDITABLE_DEALS = 'EDITABLE_DEALS';
export const EMAIL_CAMPAIGN_URLS = 'EMAIL_CAMPAIGN_URLS';
export const EMAIL_CAMPAIGNS = 'EMAIL_CAMPAIGNS';
export const EMAIL_SUBSCRIPTION_GROUPS = 'EMAIL_SUBSCRIPTION_GROUPS';
export const EMAIL_SUBSCRIPTIONS = 'EMAIL_SUBSCRIPTIONS';
export const SMS_SUBSCRIPTIONS = 'SMS_SUBSCRIPTIONS';
export const WHATSAPP_SUBSCRIPTIONS = 'WHATSAPP_SUBSCRIPTIONS';
export const EMAIL_VALIDATION = 'EMAIL_VALIDATION';
export const EMAILS = 'EMAILS';
export const ENGAGEMENT_PROPERTIES = 'ENGAGEMENT_PROPERTIES';
export const ENGAGEMENTS = 'ENGAGEMENTS';
export const EVENTS = 'EVENTS';
export const FEEDBACK = 'FEEDBACK';
export const FEEDBACK_FORMS = 'FEEDBACK_FORMS';
export const FORMS = 'FORMS';
export const HIERARCHICAL_TEAMS = 'HIERARCHICAL_TEAMS';
export const HIERARCHICAL_TEAMS_BY_USER = 'HIERARCHICAL_TEAMS_BY_USER';
export const IMPORT_NAMES = 'IMPORT_NAMES';
export const INBOUND_DB_COMPANY_IMPORT = 'INBOUND_DB_COMPANY_IMPORT';
export const INBOUND_DB_COMPANY_LIST = 'INBOUND_DB_COMPANY_LIST';
export const INBOUND_DB_CONTACT_IMPORT = 'INBOUND_DB_CONTACT_IMPORT';
export const INBOUND_DB_CONTACT_LIST = 'INBOUND_DB_CONTACT_LIST';
export const INBOUND_DB_DEAL_IMPORT = 'INBOUND_DB_DEAL_IMPORT';
export const INBOUND_DB_DEAL_LIST = 'INBOUND_DB_DEAL_LIST';
export const INBOUND_DB_IMPORT = 'INBOUND_DB_IMPORT';
export const INBOUND_DB_LISTS = 'INBOUND_DB_LISTS';
export const INBOUND_DB_TASK_IMPORT = 'INBOUND_DB_TASK_IMPORT';
export const INBOUND_DB_TICKET_LIST = 'INBOUND_DB_TICKET_LIST';
export const INTEGRATION_NAMES = 'INTEGRATION_NAMES';
export const INTEGRATION_PROPERTIES = 'INTEGRATION_PROPERTIES';
export const INTEGRATIONS = 'INTEGRATIONS';
export const INVOICE_PROPERTIES = 'INVOICE_PROPERTIES';
export const KNOWLEDGE_ARTICLES = 'KNOWLEDGE_ARTICLES';
export const LANDING_PAGES = 'LANDING_PAGES';
export const LEAD_FLOWS = 'LEAD_FLOWS';
export const LINE_ITEM_PROPERTIES = 'LINE_ITEM_PROPERTIES';
export const LINE_ITEMS = 'LINE_ITEMS';
export const MARKETING_EVENT = 'MARKETING_EVENT';
export const MEETING_OUTCOME = 'MEETING_OUTCOME';
export const MARKETING_REASONS = 'MARKETING_REASONS';
export const MARKETING_EVENT_APP_NAMES = 'MARKETING_EVENT_APP_NAMES';
export const MULTI_CURRENCY_CURRENCY_CODES = 'MULTI_CURRENCY_CURRENCY_CODES';
export const MULTI_CURRENCY_INFORMATION = 'MULTI_CURRENCY_INFORMATION';
export const OBJECT_PROPERTIES = 'OBJECT_PROPERTIES';
export const OBJECT_COORDINATES = 'OBJECT_COORDINATES';
export const OWNERS = 'OWNERS';
export const PERSONAS = 'PERSONAS';
export const PIPELINES = 'PIPELINES';
export const PRODUCT_FOLDERS = 'PRODUCT_FOLDERS';
export const PRODUCT_PROPERTIES = 'PRODUCT_PROPERTIES';
export const PRODUCTS = 'PRODUCTS';
export const PROPERTY_GROUPS = 'PROPERTY_GROUPS';
export const QUOTE_PROPERTIES = 'QUOTE_PROPERTIES';
export const QUOTES = 'QUOTES';
export const QUOTE_TEMPLATES = 'QUOTE_TEMPLATES';
export const SALESFORCE_CAMPAIGNS = 'SALESFORCE_CAMPAIGNS';
export const SEQUENCES = 'SEQUENCES';
export const SEQUENCES_WITH_PERMISSIONS = 'SEQUENCES_WITH_PERMISSIONS';
export const SEQUENCE_STEP_ORDER = 'SEQUENCE_STEP_ORDER';
export const SHARED_TASK_QUEUES = 'SHARED_TASK_QUEUES';
export const SITE_CONTENT = 'SITE_CONTENT';
export const SITE_PAGES = 'SITE_PAGES';
export const SLACK_CHANNEL = 'SLACK_CHANNEL';
export const SLACK_USER = 'SLACK_USER';
export const SUBDOMAIN_IDS = 'SUBDOMAIN_IDS';
export const SOCIAL_CHANNEL = 'SOCIAL_CHANNEL';
export const SUBDOMAINS = 'SUBDOMAINS';
export const SUBSCRIPTIONS = 'SUBSCRIPTIONS';
export const SUBMISSION_TAG = 'SUBMISSION_TAG';
export const SURVEY_QUESTIONS = 'SURVEY_QUESTIONS';
export const SURVEY_SETTINGS = 'SURVEY_SETTINGS';
export const SURVEYS = 'SURVEYS';
export const EXTERNAL_ACCOUNTS = 'EXTERNAL_ACCOUNTS';
export const TASK_QUEUES = 'TASK_QUEUES';
export const TASKS = 'TASKS';
export const TEAMS = 'TEAMS';
export const TEMPLATES = 'TEMPLATES';
export const TICKET_PIPELINES = 'TICKET_PIPELINES';
export const TICKET_PROPERTIES = 'TICKET_PROPERTIES';
export const TICKETS = 'TICKETS';
export const USERS = 'USERS';
export const WEBINAR_SETTINGS = 'WEBINAR_SETTINGS';
export const WEBINARS = 'WEBINARS';
export const WEB_INTERACTIVES = 'WEB_INTERACTIVES';
export const WORKFLOWS = 'WORKFLOWS';
export const WORKFLOW_FOLDERS = 'WORKFLOW_FOLDERS';
export const WORKFLOWS_SALESFORCE = 'WORKFLOWS_SALESFORCE';