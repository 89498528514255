import { createReducer } from '@reduxjs/toolkit';
import { batchDeleteCrmObjectsSucceededAction, batchUpdateCrmObjectsSucceededAction, createdCrmObjectAction, deleteCrmObjectsSucceededAction, updateCrmObjectsSucceededAction, updateMixedCrmObjectsByIdsAction } from '../../crmObjects/actions/crmObjectsActions';
import { setReconciledObjectsAction } from '../actions/localMutationsActions';
import { FILTERS_CHANGED, OPTIMISTICALLY_UPDATE_CURRENT_VIEW_ID, SORT_CHANGED } from '../../../views/actions/viewsActionTypes';
import { SYNC_ROUTER_VALUES } from '../../init/actions/initActionTypes';
import { pipelineChanged } from '../../pipelines/slice/currentPipelineIdSlice';
import { SYNC_SEARCH_TERM } from '../../search/actions/searchActionTypes';
function findPropertyUpdateValue(objectUpdates, objectId, propertyName) {
  var _requestedUpdate$prop;
  const requestedUpdate = objectUpdates.find(update => update.objectId === objectId);
  const requestedValue = requestedUpdate === null || requestedUpdate === void 0 || (_requestedUpdate$prop = requestedUpdate.propertyValues.find(propertyValue => propertyValue.name === propertyName)) === null || _requestedUpdate$prop === void 0 ? void 0 : _requestedUpdate$prop.value;
  return requestedValue;
}
export const initialState = {
  createdObjectIds: {},
  deletedObjectIds: {},
  updatedObjectIdsAndDeltas: {},
  filterQueryMutations: {},
  // Map of objectTypeId > objectId > reconciled stageId
  reconciledPipelineableObjects: {}
};
const addValuesWithoutDuplicates = (arr, ...values) => {
  const set = new Set([...(arr !== null && arr !== void 0 ? arr : []), ...values]);
  return Array.from(set);
};
export const localCrmObjectMutationsReducer = createReducer(initialState, builder => {
  builder.addCase(createdCrmObjectAction, (state, action) => {
    const {
      objectTypeId,
      objectId
    } = action.payload;
    const createdObjectIds = addValuesWithoutDuplicates(state.createdObjectIds[objectTypeId], objectId);
    state.createdObjectIds[objectTypeId] = createdObjectIds;
  }).addCase(batchDeleteCrmObjectsSucceededAction, (state, action) => {
    var _state$filterQueryMut;
    const {
      objectTypeId,
      objectIds
    } = action.payload;
    state.deletedObjectIds[objectTypeId] = addValuesWithoutDuplicates(state.deletedObjectIds[objectTypeId], ...objectIds);
    const filterQueryMutations = (_state$filterQueryMut = state.filterQueryMutations[objectTypeId]) !== null && _state$filterQueryMut !== void 0 ? _state$filterQueryMut : {};
    filterQueryMutations.entireFilterQueryIsDeletedMutation = true;
    state.filterQueryMutations[objectTypeId] = filterQueryMutations;
  }).addCase(deleteCrmObjectsSucceededAction, (state, action) => {
    const {
      objectTypeId,
      objectIds
    } = action.payload;
    state.deletedObjectIds[objectTypeId] = addValuesWithoutDuplicates(state.deletedObjectIds[objectTypeId], ...objectIds);
  }).addCase(updateCrmObjectsSucceededAction, (state, action) => {
    const {
      objectTypeId,
      objectIds,
      propertyValues,
      updatedObjectsResponse
    } = action.payload;
    if (updatedObjectsResponse) {
      updatedObjectsResponse.forEach(updatedCrmObject => {
        const properties = updatedCrmObject.properties;
        Object.entries(properties).forEach(([propertyName, property]) => {
          var _state$updatedObjectI, _state$updatedObjectI2;
          state.updatedObjectIdsAndDeltas[objectTypeId] = (_state$updatedObjectI = state.updatedObjectIdsAndDeltas[objectTypeId]) !== null && _state$updatedObjectI !== void 0 ? _state$updatedObjectI : {};
          state.updatedObjectIdsAndDeltas[objectTypeId][updatedCrmObject.objectId] = (_state$updatedObjectI2 = state.updatedObjectIdsAndDeltas[objectTypeId][updatedCrmObject.objectId]) !== null && _state$updatedObjectI2 !== void 0 ? _state$updatedObjectI2 : {};
          state.updatedObjectIdsAndDeltas[objectTypeId][updatedCrmObject.objectId][propertyName] = property.value;
        });
      });
    } else {
      objectIds.forEach(objectId => {
        propertyValues.forEach(({
          name,
          value
        }) => {
          var _state$updatedObjectI3, _state$updatedObjectI4;
          state.updatedObjectIdsAndDeltas[objectTypeId] = (_state$updatedObjectI3 = state.updatedObjectIdsAndDeltas[objectTypeId]) !== null && _state$updatedObjectI3 !== void 0 ? _state$updatedObjectI3 : {};
          state.updatedObjectIdsAndDeltas[objectTypeId][objectId] = (_state$updatedObjectI4 = state.updatedObjectIdsAndDeltas[objectTypeId][objectId]) !== null && _state$updatedObjectI4 !== void 0 ? _state$updatedObjectI4 : {};
          state.updatedObjectIdsAndDeltas[objectTypeId][objectId][name] = value;
        });
      });
    }
  }).addCase(batchUpdateCrmObjectsSucceededAction, (state, action) => {
    var _state$filterQueryMut2, _state$filterQueryMut3;
    const {
      objectTypeId,
      propertyValues
    } = action.payload;
    state.filterQueryMutations[objectTypeId] = (_state$filterQueryMut2 = state.filterQueryMutations[objectTypeId]) !== null && _state$filterQueryMut2 !== void 0 ? _state$filterQueryMut2 : {};
    const propertyUpdates = (_state$filterQueryMut3 = state.filterQueryMutations[objectTypeId].propertyUpdates) !== null && _state$filterQueryMut3 !== void 0 ? _state$filterQueryMut3 : {};
    propertyValues.forEach(({
      name,
      value
    }) => {
      propertyUpdates[name] = value;
    });
    state.filterQueryMutations[objectTypeId].propertyUpdates = propertyUpdates;
  }).addCase(setReconciledObjectsAction, (state, action) => {
    const {
      objectTypeId,
      objectIdsToStageIds,
      pipelineStagePropertyName
    } = action.payload;

    // We use this to track in which order the objects were reconciled. This allows us to always
    // put the most recently reconciled object at the top of the list. This could be replaced by
    // a simple increasing id, but a timestamp seems to fit the problem better.
    const now = Date.now();
    Object.entries(objectIdsToStageIds).forEach(([objectId, toStageId]) => {
      var _state$reconciledPipe, _state$updatedObjectI5, _state$updatedObjectI6;
      state.reconciledPipelineableObjects[objectTypeId] = (_state$reconciledPipe = state.reconciledPipelineableObjects[objectTypeId]) !== null && _state$reconciledPipe !== void 0 ? _state$reconciledPipe : {};
      state.reconciledPipelineableObjects[objectTypeId][objectId] = {
        toStageId,
        timestamp: now
      };
      state.updatedObjectIdsAndDeltas[objectTypeId] = (_state$updatedObjectI5 = state.updatedObjectIdsAndDeltas[objectTypeId]) !== null && _state$updatedObjectI5 !== void 0 ? _state$updatedObjectI5 : {};
      state.updatedObjectIdsAndDeltas[objectTypeId][objectId] = (_state$updatedObjectI6 = state.updatedObjectIdsAndDeltas[objectTypeId][objectId]) !== null && _state$updatedObjectI6 !== void 0 ? _state$updatedObjectI6 : {};
      state.updatedObjectIdsAndDeltas[objectTypeId][objectId][pipelineStagePropertyName] = toStageId;
    });
  }).addMatcher(action => action.type === updateMixedCrmObjectsByIdsAction.fulfilled.toString(), (state, action) => {
    const {
      objectTypeId,
      objectUpdates
    } = action.meta.arg;
    const objectUpdatesResponse = action.payload;
    if (objectUpdatesResponse) {
      objectUpdatesResponse.forEach(objectUpdate => {
        const properties = objectUpdate.properties;
        const objectId = String(objectUpdate.objectId);
        Object.entries(properties).forEach(([propertyName, _property]) => {
          var _state$updatedObjectI7, _state$updatedObjectI8;
          // Assign type, because client-types return this as `unknown`
          const property = _property;
          let value = property.value;

          // As of July 24, 2024:
          // Encrypted property updates do not return the value directly, so we need
          // to look up the requested update and use that instead. If the requested value
          // didn't update correctly, it shouldn't show up in this response.
          if (property.isEncrypted) {
            const updateValue = findPropertyUpdateValue(objectUpdates, objectId, propertyName);
            if (typeof updateValue === 'string') {
              value = updateValue;
            }
          }
          state.updatedObjectIdsAndDeltas[objectTypeId] = (_state$updatedObjectI7 = state.updatedObjectIdsAndDeltas[objectTypeId]) !== null && _state$updatedObjectI7 !== void 0 ? _state$updatedObjectI7 : {};
          state.updatedObjectIdsAndDeltas[objectTypeId][objectUpdate.objectId] = (_state$updatedObjectI8 = state.updatedObjectIdsAndDeltas[objectTypeId][objectUpdate.objectId]) !== null && _state$updatedObjectI8 !== void 0 ? _state$updatedObjectI8 : {};
          state.updatedObjectIdsAndDeltas[objectTypeId][objectUpdate.objectId][propertyName] = value;
        });
      });
    } else {
      // We get the last modified property for free as it's one of the properties the BE returns on a property update.
      objectUpdates.forEach(({
        objectId,
        propertyValues
      }) => propertyValues.forEach(({
        name,
        value
      }) => {
        var _state$updatedObjectI9, _state$updatedObjectI10;
        state.updatedObjectIdsAndDeltas[objectTypeId] = (_state$updatedObjectI9 = state.updatedObjectIdsAndDeltas[objectTypeId]) !== null && _state$updatedObjectI9 !== void 0 ? _state$updatedObjectI9 : {};
        state.updatedObjectIdsAndDeltas[objectTypeId][objectId] = (_state$updatedObjectI10 = state.updatedObjectIdsAndDeltas[objectTypeId][objectId]) !== null && _state$updatedObjectI10 !== void 0 ? _state$updatedObjectI10 : {};
        state.updatedObjectIdsAndDeltas[objectTypeId][objectId][name] = value;
      }));
    }
  }).addMatcher(action => {
    return [pipelineChanged.toString(), SORT_CHANGED, FILTERS_CHANGED, SYNC_SEARCH_TERM, OPTIMISTICALLY_UPDATE_CURRENT_VIEW_ID, SYNC_ROUTER_VALUES].includes(action.type);
  }, state => {
    state.createdObjectIds = {};
    state.deletedObjectIds = {};
    state.updatedObjectIdsAndDeltas = {};
    state.reconciledPipelineableObjects = {};
    state.filterQueryMutations = {};
  }).addDefaultCase(state => state);
});