'use es6';

import { Map as ImmutableMap } from 'immutable';
import { defineFactory } from 'general-store';
import dispatcher from 'dispatcher/dispatcher';
import { DEAL_PIPELINES_FETCH_SUCCEEDED, PIPELINE_UPDATED, PIPELINE_DELETED } from 'crm_data/actions/ActionTypes';
import DealPipelineStore from 'crm_data/deals/DealPipelineStore';
import { propertyLabelTranslator } from 'property-translator/propertyTranslator';
const parseStage = (stage, pipeline) => stage.merge({
  pipelineDisplayOrder: pipeline.get('displayOrder'),
  pipelineId: pipeline.get('pipelineId'),
  pipelineLabel: propertyLabelTranslator(pipeline.get('label')),
  // unfortunately there is no way to distinguish hubspot-defined and custom pipelines, so translate all
  text: propertyLabelTranslator(stage.get('label')),
  // unfortunately there is no way to distinguish hubspot-defined and custom stages, so translate all
  value: stage.get('stageId')
});
const DealStageStore = defineFactory().defineName('DealsStageStore').defineGetInitialState(() => ImmutableMap()).defineGet((state, dealStageId) => {
  if (state.size === 0) {
    DealPipelineStore.get();
  }
  if (dealStageId) {
    return state.get(dealStageId);
  }
  return state;
}).defineResponseTo(DEAL_PIPELINES_FETCH_SUCCEEDED, (state, pipelines) => {
  pipelines.forEach(pipeline => pipeline.get('stages').forEach(stage => {
    state = state.set(stage.get('stageId'), parseStage(stage, pipeline));
  }));
  return state;
}).defineResponseTo(PIPELINE_UPDATED, (state, pipeline) => {
  const {
    value
  } = pipeline;
  if (!value) {
    return state;
  }
  const stages = value.get('stages').sortBy(stage => stage.get('displayOrder'));
  stages.forEach(stage => {
    state = state.set(stage.get('stageId'), parseStage(stage, value));
  });
  return state;
}).defineResponseTo(PIPELINE_DELETED, (state, pipelineId) => state.filter(stage => stage.get('pipelineId') !== pipelineId)).register(dispatcher);
export default DealStageStore;