import get from 'transmute/get';
import getIn from 'transmute/getIn';
import { CRM_OBJECT } from '../constants/CrmObjectType';
export function getIdKey(record) {
  return record && record.constructor._idKey;
}
export function getId(record) {
  return record && getIn(getIdKey(record) || [], record);
}
export function getObjectType(record) {
  const objectType = record && record.constructor._objectType;
  return objectType === CRM_OBJECT ?
  // We know that this will return an objectTypeId
  get('objectTypeId', record) : objectType;
}
export function getProperty(record, property) {
  if (property === undefined) return undefined;
  if (!record) return undefined;
  const propertyValue = getIn(['properties', property, 'value'], record);
  if (propertyValue === undefined) return undefined;
  return propertyValue;
}
export function getPropertyValue(record, property) {
  if (property === undefined) return undefined;
  return record && getIn(['properties', property], record);
}