'use es6';

import http from 'hub-http/clients/apiClient';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import indexBy from 'transmute/indexBy';
import pipe from 'transmute/pipe';
const BASE_URL = 'inbounddb-io/imports';
const GET_NAMES_URL = `${BASE_URL}/get-names`;
export const createFetchImportNamesById = ({
  httpClient
}) => ids => httpClient.put(GET_NAMES_URL, {
  data: ids
}).then(response => ids.map(id => ({
  id,
  importName: response.hits[id] || null
}))).then(formatToReferencesList({
  getId: get('id'),
  getLabel: get('importName')
}));
export const fetchImportNamesById = createFetchImportNamesById({
  httpClient: http
});
export const createFetchImportNamesByObjectType = ({
  httpClient
}) => objectType => httpClient.get(`${BASE_URL}/names/${objectType}?limit=5000`, {
  timeout: 60000
}).then(pipe(getIn([0, 'options']), formatToReferencesList({
  getId: get('value'),
  getLabel: get('label')
}), indexBy(get('id'))));
export const fetchImportNamesByObjectType = createFetchImportNamesByObjectType({
  httpClient: http
});