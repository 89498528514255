/* eslint-disable hubspot-dev/no-unsafe-storage */

import enviro from 'enviro';
import { NAMESPACE_SUFFIX } from './constants';
import getStoreName from './utils/getStoreName';
import { getRecordSizes } from './utils/getRecordSizes';
import getRecordSizeDebugString from './utils/getRecordSizeDebugString';
import getNaiveObjectSize from './utils/getNaiveObjectSize';
export const storageAvailable = () => {
  let storage;
  try {
    storage = window.localStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.getItem(x);
    storage.removeItem(x);
  } catch (e) {
    throw new Error('Backend error: LocalStorage is unavailable.');
  }
};
const accessor = operation => {
  try {
    return operation();
  } catch (e) {
    const err = e;
    if (err.message && /Usage error/.exec(err.message)) {
      throw e;
    } else {
      throw new Error(`Backend error: LocalStorage failed with the following error: ${err.message}`);
    }
  }
};
export const isStorable = value => {
  if (typeof value === 'string') return true;
  if (typeof value === 'object' && Object.prototype.hasOwnProperty.call(value, 'toString')) return true;
  if (typeof value === 'boolean' || typeof value === 'number' || value === null || value === undefined) {
    if (enviro.getShort() !== 'prod') {
      console.warn(`value \`${value}\` will be cast to a string when stored in localStorage but won't be cast back when retrieved. Watch out for type safety.`);
    }
    return true;
  }
  return false;
};
/**
 * Superstore backend which uses the browser's LocalStorage API. This backend prioritizes simplicity over durability or performance.
 *
 * This backend's API supports two interfaces: synchronous and async. The synchronous API will be more familiar to users
 * of the SafeStorage utility or the underlying LocalStorage API, but the async API offers better error handling and an
 * easier migration path to more complex data storage scenarios (for which you will need to implement a backend that
 * requires async).
 *
 * To create a Superstore instance using this backend:
 *
 * ```js
 * import Superstore, { LocalStorage } from 'superstore';
 *
 * // create an async superstore (default)
 * const store = new Superstore({
 *   backend: LocalStorage,
 *   namespace: 'example',
 * });
 *
 * // create a synchronous superstore
 * const storeSync = new Superstore({
 *   backend: LocalStorage,
 *   async: false,
 *   namespace: 'example',
 * });
 * ```
 */
export class SuperstoreLocal {
  /** @hidden */

  /** @hidden */

  constructor(opts) {
    this.getItem = SuperstoreLocal.prototype.get;
    this.setItem = SuperstoreLocal.prototype.set;
    this.removeItem = SuperstoreLocal.prototype.delete;
    this.accessor = accessor;
    this.storeName = getStoreName(opts);
    storageAvailable();
    if (opts && opts.async) {
      this.accessor = operation => new Promise((resolve, reject) => {
        try {
          resolve(operation());
        } catch (e) {
          const err = e;
          if (err.message && /Usage error/.exec(err.message)) {
            throw e;
          } else {
            reject(new Error(`Backend error: LocalStorage failed with the following error: ${err.message}`));
          }
        }
      });
    }
  }

  /**
   * Supplement the key with the database and namespace path components.
   * This doesn't actually do anything in localstorage but it keeps parity with IDB.
   */
  fullKey(key) {
    return `${this.storeName}.${NAMESPACE_SUFFIX}.${key}`;
  }

  /**
   * Check if key exists in LocalStorage.
   *
   * ```js
   * // async
   * store.has('foo')
   *   .then(fooExists => {
   *     // fooExists === true, if you previously called `store.set('foo', something)`
   *   })
   *   .catch(dispatchError);
   * ```
   *
   * ```js
   * // sync
   * try {
   *   const fooExists = store.has('foo');
   *     // fooExists === true, if you previously called `store.set('foo', something)`
   * } catch (e) {
   *   // handle the errors this function throws
   * }
   * ```
   *
   * @param key - Key in localstorage to check existence of
   */
  has(key) {
    if (!key) {
      return this.accessor(() => {
        throw new Error('Usage error: `has` requires `key` argument to be defined');
      });
    }
    if (typeof key !== 'string') {
      return this.accessor(() => {
        throw new Error("Usage error: LocalStorage doesn't support non-string keys. Consider implementing the IndexedDB backend.");
      });
    }
    return this.accessor(() => {
      return window.localStorage.getItem(this.fullKey(key)) !== null;
    });
  }

  /**
   * Get value from LocalStorage.
   *
   * ```js
   * // async
   * store.get('foo')
   *   .then(val => {
   *     // `val` will be whatever you used as `val` in `store.set('foo', val)`
   *   })
   *   .catch(dispatchError);
   * ```
   *
   * ```js
   * // sync
   * let storedFoo
   * try {
   *   storedFoo = store.get('foo');
   *   assert(storedFoo === 'bar');
   * } catch (e) {
   *   // handle the errors this function throws
   * }
   * ```
   *
   * @param key - Key in localstorage to get
   */
  get(key) {
    if (!key) {
      return this.accessor(() => {
        throw new Error('Usage error: `get` requires `key` argument to be defined');
      });
    }
    if (typeof key !== 'string') {
      return this.accessor(() => {
        throw new Error("Usage error: LocalStorage doesn't support non-string keys. Consider implementing the IndexedDB backend.");
      });
    }
    return this.accessor(() => {
      return window.localStorage.getItem(this.fullKey(key));
    });
  }

  /**
   * Set a value in LocalStorage.
   *
   * ```js
   * // async
   * store.set('foo', 'bar')
   *   .then(dispatchSuccess)
   *   .catch(dispatchError);
   * ```
   *
   * ```js
   * // sync
   * try {
   *   store.set('foo', 'bar');
   * } catch (e) {
   *   // handle the errors this function throws
   * }
   * ```
   *
   * @param key - Key in localstorage to set
   * @param value - Value to store under given key
   */
  set(key, value) {
    if (!key || value === undefined) {
      return this.accessor(() => {
        throw new Error('Usage error: `set` requires `key` and `value` arguments both to be defined');
      });
    }
    if (typeof key !== 'string') {
      return this.accessor(() => {
        throw new Error("Usage error: LocalStorage doesn't support non-string keys. Consider implementing the IndexedDB backend.");
      });
    }
    if (!isStorable(value)) {
      return this.accessor(() => {
        throw new Error(`Usage error: LocalStorage doesn't support values of type ${typeof value}. Consider implementing the IndexedDB backend.`);
      });
    }
    return this.accessor(() => {
      try {
        const fqKey = this.fullKey(key);
        window.localStorage.setItem(fqKey, value);
        const storedVal = window.localStorage.getItem(fqKey);
        return storedVal;
      } catch (err) {
        const recordSizes = getRecordSizes(SuperstoreLocal.getAllRecords);
        const recordSizesLog = getRecordSizeDebugString(recordSizes);
        throw new Error(`Encountered error: ${err} while setting ${key}:(${getNaiveObjectSize(value)}b), ${recordSizesLog}`);
      }
    });
  }

  /**
   * Delete value from LocalStorage.
   *
   * ```js
   * // async
   * store.delete('foo')
   *   .then(foo => {
   *     // `foo` is the value set under key foo, if you need it
   *     return store.has('foo');
   *   })
   *   .then(fooExists => {
   *     // fooExists === false
   *   })
   *   .catch(dispatchError);
   * ```
   *
   * ```js
   * // sync
   * try {
   *   store.delete('foo'); // returns 'bar'
   *   const fooExists = store.has('foo');
   *   // fooExists === false
   * } catch (e) {
   *   // handle the errors this function throws
   * }
   * ```
   *
   * @param key - Key in localstorage to delete
   *
   */
  delete(key) {
    if (!key) {
      return this.accessor(() => {
        throw new Error('Usage error: `get` requires `key` argument to be defined');
      });
    }
    if (typeof key !== 'string') {
      return this.accessor(() => {
        throw new Error("Usage error: LocalStorage doesn't support non-string keys. Consider implementing the IndexedDB backend.");
      });
    }
    return this.accessor(() => {
      const fqKey = this.fullKey(key);
      const res = window.localStorage.getItem(fqKey);
      window.localStorage.removeItem(fqKey);
      return res;
    });
  }

  /**
   * Remove all keys and values from this namespace in LocalStorage.
   *
   *
   * ```js
   * // async
   * store.clear()
   *   .then(() => store.has('foo'))
   *   .then(fooExists => {
   *     // fooExists === false
   *   })
   *   .catch(dispatchError);
   * ```
   *
   * ```js
   * // sync
   * try {
   *   store.clear();
   *   const fooExists = store.has('foo');
   *   // fooExists === false
   * } catch (e) {
   *   // handle the errors this function throws
   * }
   * ```
   *
   */
  clear() {
    return this.accessor(() => {
      Object.keys(Object.assign({}, window.localStorage)).forEach(fqKey => {
        if (!fqKey.startsWith(this.storeName)) {
          return;
        }
        window.localStorage.removeItem(fqKey);
      });
    });
  }

  /** Alias for `get` to aid in migration from SafeStorage. */

  /** Alias for `set` to aid in migration from SafeStorage. */

  /** Alias for `delete` to aid in migration from SafeStorage. */

  /**
   * Runs `callback()` on every record in window.localstorage
   *
   * @param callback callback to run on every record in window.localstorage
   */
  static getAllRecords(callback) {
    const recordCount = window.localStorage.length;
    for (let recordIx = 0; recordIx < recordCount; recordIx++) {
      const key = window.localStorage.key(recordIx);
      if (key) {
        const value = window.localStorage.getItem(key);
        callback(key, value);
      }
    }
  }

  /* eslint-enable @typescript-eslint/unbound-method */
}
export default function localSuperstore(opts) {
  return new SuperstoreLocal(opts);
}