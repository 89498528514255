export class PageDataQueryError extends Error {
  constructor(error, objectTypeId) {
    super(error.message);
    this.name = 'PageDataQueryError';
    this.objectTypeId = objectTypeId;
    this.details = [];
    this.paths = [];
    this.addGraphQLErrors(error);
    this.addNetworkError(error);
  }
  getExtra() {
    return {
      objectTypeId: this.objectTypeId,
      details: this.details.join('\n'),
      paths: this.paths.join('\n')
    };
  }
  addGraphQLErrors(error) {
    if (!error.graphQLErrors) {
      return;
    }
    error.graphQLErrors.forEach(graphQLError => {
      this.details.push(graphQLError.message);
      if (graphQLError.path) {
        this.paths.push(graphQLError.path.join(','));
      }
    });
  }
  addNetworkError(error) {
    if (!error.networkError) {
      return;
    }
    this.details.push(error.networkError.message);
  }
}