export const DEFAULT = 'DEFAULT';
export const FORECAST = 'FORECAST';
export const HIDDEN = 'HIDDEN';
export const QUEUE = 'QUEUE';
export const STANDARD = 'STANDARD';
export const TEMP = 'TEMP';
export const SHARING = {
  PUBLIC: 'PUBLIC',
  TEAM: 'TEAM',
  PRIVATE: 'PRIVATE'
};