import enviro from 'enviro';
import crmTracker from '../tracking/usageTracker';
import { BIZOPS_INTERACTION, CRM_ONBOARDING, INDEX_INTERACTION } from '../constants/eventNames';
import { includeScreen } from '../utils/includeScreen';
import { CRM, LISTS, PRODUCTS } from '../constants/namespaces';
const validNamespaces = [CRM, LISTS, PRODUCTS];
const CrmLogger = {
  externalData: {},
  init: data => {
    CrmLogger.externalData = data;
    crmTracker.init(data);
  },
  logImmediate: (eventName, eventProps = {}, trackerOptions = {
    namespace: CRM
  }) => {
    CrmLogger.log(eventName, eventProps, Object.assign({}, trackerOptions, {
      sendImmediate: true
    }));
  },
  log: (eventName, eventProps = {}, trackerOptions = {
    sendImmediate: false,
    namespace: CRM
  }) => {
    if (trackerOptions.namespace && validNamespaces.indexOf(trackerOptions.namespace) === -1) {
      if (!enviro.isProd() && !enviro.deployed()) {
        console.warn(`[CrmLogger]: ${trackerOptions.namespace} is not a valid namespace. Event will not be logged`);
      }
    } else {
      CrmLogger._log(eventName, eventProps, trackerOptions);
    }
  },
  _log(eventName = '', eventProps = {}, trackerOptions = {
    sendImmediate: false,
    namespace: CRM
  }) {
    // some dynamic call sites are hard to migrate off of where_app_override
    // right now so this hydrates the namespace to make sure events go to the
    // right place
    if (eventProps.where_app_override && trackerOptions.namespace === CRM) {
      trackerOptions.namespace = eventProps.where_app_override;
    }
    crmTracker.track(eventName, eventProps, trackerOptions);
  },
  logEventAndProps(properties) {
    const newProps = includeScreen(properties);
    if (newProps) {
      const {
        event,
        eventProps
      } = newProps;
      CrmLogger._log(event, eventProps, properties.options);
    }
  },
  logIndexInteraction(objectType, eventProps, options) {
    return CrmLogger.logEventAndProps({
      event: INDEX_INTERACTION,
      objectType,
      eventProps,
      options
    });
  },
  logCRMOnboarding(objectType, eventProps) {
    return CrmLogger.logEventAndProps({
      event: CRM_ONBOARDING,
      objectType,
      eventProps
    });
  },
  logForBizOps(objectType, eventProps) {
    return CrmLogger.logEventAndProps({
      event: BIZOPS_INTERACTION,
      objectType,
      eventProps
    });
  }
};
export default CrmLogger;