import { useCallback } from 'react';
import { useUserInfo } from './useUserInfo';
import memoizeOne from 'react-utils/memoizeOne';
const EMPTY_SCOPES = new Set();
const memoizedToScopesSet = memoizeOne(scopes => new Set(scopes));
export const useGetHasScope = () => {
  const userInfo = useUserInfo();
  const userInfoScopes = userInfo ? memoizedToScopesSet(userInfo.user.scopes) : EMPTY_SCOPES;
  return useCallback(scope => {
    return userInfoScopes.has(scope);
  }, [userInfoScopes]);
};