import { useViews } from './useViews';
import { useGenerateView } from './useGenerateView';

/**
 * Use this hook instead of useCachedViews()[viewId] or useGenerateCachedViewById when
 * - you want the view as the user currently sees it with draft changes
 * - as opposed to useCachedViews or useGenerateCachedViewById that does not include draft changes
 * this hook will also have applyColumnRules called on it, ensuring that all columns are valid (removing association columns that are invalid, and removing columns for properties that dont exist)
 *
 * @data-source redux
 */
export const useViewById = viewId => {
  const views = useViews();
  // defensive check as typescript migration is not complete. Remove when migration is solid
  const view = views[String(viewId)];
  return useGenerateView(view);
};