import http from 'hub-http/clients/apiClient';
import Raven from 'raven-js';
import { generatePath } from 'react-router-dom';
const BASE_URL = 'crm-record-cards/v1/pipeline-customization';
export const getRecordCard = ({
  objectTypeId,
  pipelineId
}) => {
  if (pipelineId) {
    return http.get(generatePath(`${BASE_URL}/:objectTypeId/pipeline/:pipelineId`, {
      objectTypeId,
      pipelineId
    })).catch(err => {
      Raven.captureException(err);
      throw err;
    });
  }
  return http.get(generatePath(`${BASE_URL}/:objectTypeId/`, {
    objectTypeId
  })).catch(err => {
    Raven.captureException(err);
    throw err;
  });
};