import { useCallback, useMemo } from 'react';
import { useFetchPortalCurrencies } from '../multiCurrency/hooks/useFetchPortalCurrencies';
import { useHasPreferredDisplayCurrencyScope } from '../multiCurrency/hooks/useHasPreferredDisplayCurrency';
import { useIsPortalCurrency } from '../multiCurrency/hooks/useIsPortalCurrency';
import { usePreferredDisplayCurrency } from '../multiCurrency/hooks/usePreferredDisplayCurrency';
import { getCurrencySettingsUrl } from '../multiCurrency/utils/getCurrencySettingsUrl';
export const useCurrencyOptions = (objectTypeId, settings) => {
  const {
    homeCurrencyCode
  } = useFetchPortalCurrencies(objectTypeId);
  const preferredDisplayCurrency = usePreferredDisplayCurrency(objectTypeId, settings);
  const {
    isPortalCurrency
  } = useIsPortalCurrency(objectTypeId);
  const filterCurrencies = useCallback(({
    value
  }) => isPortalCurrency(value), [isPortalCurrency]);
  const settingsUrl = getCurrencySettingsUrl();
  const active = useHasPreferredDisplayCurrencyScope();
  return useMemo(() => ({
    preferredDisplayCurrency,
    homeCurrencyCode,
    settingsUrl,
    active,
    filterCurrencies
  }), [preferredDisplayCurrency, homeCurrencyCode, settingsUrl, active, filterCurrencies]);
};