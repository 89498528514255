'use es6';

import { Map as ImmutableMap } from 'immutable';
import formatPipelinesWithPermissions from '../formatters/formatPipelinesWithPermissions';
import formatPipelineStagesWithPermissions from '../formatters/formatPipelineStagesWithPermissions';
const formatResponseWithPermissions = response => ImmutableMap({
  pipelines: formatPipelinesWithPermissions(response),
  stages: formatPipelineStagesWithPermissions(response)
});
export const createGetAllPipelinesForTypeWithPermissions = objectTypeId => ({
  httpClient
}) => () => httpClient.get(`pipelines/v2/pipelines/${encodeURIComponent(objectTypeId)}`, {
  query: {
    includePermissions: true
  }
}).then(formatResponseWithPermissions);
export const createGetAllDealPipelinesWithPermissions = createGetAllPipelinesForTypeWithPermissions('0-3');
export const createGetAllTicketPipelinesWithPermissions = createGetAllPipelinesForTypeWithPermissions('0-5');