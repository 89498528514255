import { createSlice } from '@reduxjs/toolkit';
export const handlersSlice = createSlice({
  name: 'handlers',
  initialState: {
    onColumnCollapseChange: [],
    onLoadMore: [],
    onOpenOverlay: [],
    onCardClick: [],
    onColumnChange: [],
    onDataReceived: [],
    onCardMounted: []
  },
  reducers: {
    addHandler: (state, action) => {
      state[action.payload.key].push(action.payload.handler);
    },
    removeHandler: (state, action) => {
      const handlers = state[action.payload.key];
      const index = handlers.indexOf(action.payload.handler);
      if (index > -1) {
        handlers.splice(index, 1);
      }
    }
  }
});