import userInfo from 'hub-http/userInfo';
export const defaultResolvePortalAndUserId = () => userInfo({
  ignoreRedirect: true
}).then(({
  portal: {
    portal_id
  },
  user: {
    user_id
  }
}) => ({
  portalId: portal_id,
  userId: user_id
}));