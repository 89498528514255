'use es6';

import { INBOUND_DB_LISTS } from 'reference-resolvers/constants/CacheKeys';
import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import { createGetInboundDbListsByIds, createGetInboundDbListsSearchPage, getInboundDbListsByIds, getInboundDbListsSearchPage } from 'reference-resolvers/api/InboundDbListsAPI';
export const createInboundDbListsReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: INBOUND_DB_LISTS,
  createFetchByIds: createGetInboundDbListsByIds,
  createFetchSearchPage: createGetInboundDbListsSearchPage,
  fetchByIds: getInboundDbListsByIds,
  fetchSearchPage: getInboundDbListsSearchPage,
  maxBatchSize: 100
}, options));
export default createInboundDbListsReferenceResolver();