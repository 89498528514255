// The following disable directive for no-restricted-imports is intentional. We need to mport it for setup.
// eslint-disable-next-line no-restricted-imports
import GlobalMyTeamContainer from 'crm-legacy-global-containers/GlobalMyTeamContainer';
import MyTeamContainer from '../containers/MyTeamContainer';
export const setupMyTeamContainer = auth => {
  const {
    user
  } = auth;
  const myTeam = user.teams ? user.teams.find(team => team.my_team) : undefined;
  const teamId = myTeam && myTeam.id;
  MyTeamContainer.set(teamId);
  GlobalMyTeamContainer.setContainer(MyTeamContainer);
};