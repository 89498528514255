import { useEffect, useState } from 'react';
import { useIsPaidPortal } from 'onboarding-scopes';
import { useHasPurchasedStarterSinceGivenDate } from 'growth-onboarding-shared-api/hooks/starterHubs';
import { CONTACT } from 'customer-data-objects/constants/ObjectTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import IsUngatedStore from 'crm_data/gates/IsUngatedStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import withGateOverride from 'crm_data/gates/withGateOverride';
import { useIsMounted } from '../../../crm_ui/hooks/useIsMounted';
import { delayUntilIdle } from '../../../crm_ui/utils/delayUntilIdle';
import { useQueryParams } from '../../../router/useQueryParams';
import { STARTER_COACHING_TIPS_RELEASE_DATE } from '../constants';
const CONTACTS_COACHING_TIPS_GATE = 'CRM:crm-index-ui:coaching-tips';
export const useShouldShowCoachingTips = objectType => {
  const [isIdle, setIsIdle] = useState(false);
  const {
    showCoachingTips
  } = useQueryParams();
  const isMounted = useIsMounted();
  const isPaidPortal = useIsPaidPortal();
  const isUngatedForCoachingTips = withGateOverride(CONTACTS_COACHING_TIPS_GATE, IsUngatedStore.get(CONTACTS_COACHING_TIPS_GATE));
  const isContactIndexPage = objectType === CONTACT;
  const shouldSeeStarterCoachingTips = useHasPurchasedStarterSinceGivenDate(STARTER_COACHING_TIPS_RELEASE_DATE);
  useEffect(() => {
    delayUntilIdle(() => {
      if (isMounted.current) {
        setIsIdle(true);
      }
    });
  });

  // if we aren't on the CONTACT page we don't render coaching tips
  if (!isContactIndexPage) {
    return false;
  }

  // if the query param override is present
  // show the coaching tips regardless of other conditions
  if (showCoachingTips) {
    return true;
  }

  // Free users
  if (isUngatedForCoachingTips && isIdle && !isPaidPortal) {
    return true;
  }

  // Starter users
  if (isIdle && shouldSeeStarterCoachingTips) {
    return true;
  }
  return false;
};