import { useCurrentView } from './useCurrentView';
export const useCurrentViewFilters = () => {
  const {
    filterGroups: viewFilterGroups,
    quickFilters: viewQuickFilters
  } = useCurrentView();
  return {
    filters: viewFilterGroups,
    quickFilters: viewQuickFilters
  };
};