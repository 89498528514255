import Raven from 'raven-js';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useAsyncEffect } from 'react-utils/hooks/useAsyncEffect';
import { QF_SEARCH_QF_CACHE_READ_HIT_METRIC, QF_SEARCH_QF_CACHE_READ_MISS_METRIC, QF_SEARCH_QF_CACHE_ERROR_METRIC } from '../quickFetch';
import { Metrics } from '../Metrics';
import stableStringify from 'fast-json-stable-stringify';
import { closeIDB, getFromIDB, setInIDB } from './indexdb';
export const QF_SEARCH_CACHE_WRITE_METRIC = 'crm-object-search-query-utilities-qf-cache-write';
export const QF_SEARCH_APOLLO_CACHE_HIT_METRIC = 'crm-object-search-query-utilities-qf-apollo-cache-hit';
export const QF_SEARCH_APOLLO_CACHE_MISS_METRIC = 'crm-object-search-query-utilities-qf-apollo-cache-miss';

/**
 * Hook to cache CRM objects search query variables in IndexedDB. This hooks is designed to enhance
 * the performance of host applications by storing frequently accessed search query data locally.
 * The cache is keyed by a unique identifier, which should be stable and context-specific to ensure
 * accurate retrieval.
 *
 * In the context of crm-index-ui, the view id typically serves as the cache key because the search
 * query variables are derived from data specific to a particular view.
 *
 * @param {Object} searchQueryVariables - The crm objects search query variables that are to be cached.
 * @param {string} [cacheKey] - Unique identifier for caching the query variables. In crm-index-ui this
 * is usually the view id associated with a specific view from which query variables are derived. Omitting
 * this optional param skips caching altogether.
 * @param {boolean} [skip=false] - Optional flag to skip the caching process.
 */
export function useCacheCrmObjectsSearchQueryVariables({
  searchQueryVariables: queryVars,
  cacheKey,
  skip = false
}) {
  const [varsFromCache, setVarsFromCache] = useState();
  const mountedAt = useMemo(() => performance.now(), []);
  const logWriteVars = useCallback(() => {
    Metrics.counter(QF_SEARCH_CACHE_WRITE_METRIC).increment();
  }, []);
  const writeVars = useCallback(async () => {
    if (cacheKey == null || skip) {
      return;
    }
    await setInIDB(cacheKey, queryVars).then(() => {
      logWriteVars();
    }).catch(error => {
      if (error instanceof Error) {
        Raven.captureException(error, {
          level: 'warning'
        });
      }
    });
  }, [cacheKey, logWriteVars, queryVars, skip]);
  const logQuickFetchCacheRead = useCallback(() => {
    var _performance$getEntri, _performance, _performance$getEntri2, _performance2;
    const [qfCacheHitMark] = (_performance$getEntri = (_performance = performance) === null || _performance === void 0 ? void 0 : _performance.getEntriesByName(QF_SEARCH_QF_CACHE_READ_HIT_METRIC)) !== null && _performance$getEntri !== void 0 ? _performance$getEntri : [];
    const [qfCacheMissMark] = (_performance$getEntri2 = (_performance2 = performance) === null || _performance2 === void 0 ? void 0 : _performance2.getEntriesByName(QF_SEARCH_QF_CACHE_READ_MISS_METRIC)) !== null && _performance$getEntri2 !== void 0 ? _performance$getEntri2 : [];
    const entry = qfCacheHitMark !== null && qfCacheHitMark !== void 0 ? qfCacheHitMark : qfCacheMissMark;
    if (entry) {
      Metrics.timer(entry.name).update(performance.now() - entry.startTime);
    }
  }, []);
  const readVars = useCallback(async () => {
    if (cacheKey == null || skip || varsFromCache != null) {
      return;
    }
    await getFromIDB(cacheKey).then(vars => {
      setVarsFromCache(vars);
    }).catch(error => {
      if (error instanceof Error) {
        Raven.captureException(error, {
          level: 'warning'
        });
      }
    });
  }, [cacheKey, skip, varsFromCache]);
  const logProxyToApolloQueryCacheRead = useCallback(() => {
    if (varsFromCache == null) return;
    const cachedVarsHash = stableStringify(varsFromCache);
    const queryVarsHash = stableStringify(queryVars);
    const isHit = cachedVarsHash === queryVarsHash;
    const metricName = isHit ? QF_SEARCH_APOLLO_CACHE_HIT_METRIC : QF_SEARCH_APOLLO_CACHE_MISS_METRIC;
    Metrics.timer(metricName).update(mountedAt);
  }, [mountedAt, queryVars, varsFromCache]);
  const logQuickFetchCacheReadError = useCallback(() => {
    const qfCacheReadErrorMarks = performance.getEntriesByName(QF_SEARCH_QF_CACHE_ERROR_METRIC);
    if (qfCacheReadErrorMarks.length > 0) {
      Metrics.counter(QF_SEARCH_QF_CACHE_ERROR_METRIC).increment();
    }
  }, []);
  const logNoDependenciesAtLowPriority = useCallback(() => {
    logQuickFetchCacheRead();
    logQuickFetchCacheReadError();
  }, [logQuickFetchCacheRead, logQuickFetchCacheReadError]);
  useAsyncEffect(async () => {
    await readVars();
  }, [readVars]);
  useAsyncEffect(async () => {
    await writeVars();
  }, [writeVars]);
  useEffect(() => {
    executeAtLowerPriority(logProxyToApolloQueryCacheRead);
  }, [logProxyToApolloQueryCacheRead]);

  // We only want these to run once and not be dependent
  // on logProxyToApolloQueryCacheRead, which can be
  // run twice, once before cache read completes, which is a no-op
  // and again after cache read completes if vars were found.
  useEffect(() => {
    executeAtLowerPriority(logNoDependenciesAtLowPriority);
  }, [logNoDependenciesAtLowPriority]);
  useEffect(() => {
    return function closeStoreConnection() {
      closeIDB().catch(() => {
        // noop
      });
    };
  }, []);
}
const IDLE_CALLBACK_FALLBACK_TIMEOUT = 2000;
function executeAtLowerPriority(callback) {
  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(callback);
  } else {
    setTimeout(callback, IDLE_CALLBACK_FALLBACK_TIMEOUT);
  }
}