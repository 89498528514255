import { useCallback } from 'react';
export const getPendingEditForProperty = (pendingEdits, property) => {
  return pendingEdits && pendingEdits[property];
};
export const useSecondaryPropertyValueFactory = ({
  pendingInlineEditsForObject,
  row
}) => {
  return useCallback(name => {
    var _row$properties;
    const pendingvalue = pendingInlineEditsForObject ? getPendingEditForProperty(pendingInlineEditsForObject, name) : null;
    const savedValue = row === null || row === void 0 || (_row$properties = row.properties) === null || _row$properties === void 0 || (_row$properties = _row$properties[name]) === null || _row$properties === void 0 ? void 0 : _row$properties.value;
    return pendingvalue ? pendingvalue.value : savedValue;
  }, [pendingInlineEditsForObject, row]);
};