import { useObjectTypeDefinitions } from './crmObjectSearch/useObjectTypeDefinitions';
import { usePropertyDefinitions } from './crmObjectSearch/usePropertyDefinitions';
import { usePipelines } from './crmObjectSearch/usePipelines';
export const usePrefetch = ({
  objectTypeId,
  skip = false
}) => {
  useObjectTypeDefinitions({
    skip
  });
  usePropertyDefinitions({
    objectTypeId,
    skip
  });
  usePipelines({
    objectTypeId,
    skip
  });
};