import { closest, matches } from 'UIComponents/utils/Dom';
import { FINISH } from '../constants/TourActions';
import { getAttachToElementQuery } from './attachToUtil';
import { debug } from './debugUtil';
import { getElement, listenToEventOnElement } from './elementUtil';
import { waitForAttachToElementToAppearOrFireSentry, waitForElementToAppear } from './mutationUtil';
const attachedElements = [];
export const performActionAndRemoveListener = (tour, eventHandler, element, onEvent, interactionEvent) => {
  // Tours can pass in a keyCode via the event value
  const [eventType, keyCode] = eventHandler.event.split(':');
  // If the keyCode exists then only trigger the action if the correct key is pressed
  if (keyCode && interactionEvent && interactionEvent.keyCode !== parseInt(keyCode, 10)) {
    return;
  }
  if (eventHandler.action && tour.getHandler().isActive) {
    tour[eventHandler.action]();
    if (eventHandler.action === FINISH) {
      tour.getConfig().openTaskCompletionModal();
    }
  }

  // Stop listening to future events
  element.removeEventListener(eventType, onEvent);
};

// Exported for testing purposes only
export const handleEventListeners = (tour, step, registerUnlistener, listenerType, attachToElement) => {
  const {
    eventHandlers
  } = step;
  if (!eventHandlers) {
    return;
  }

  // Listen out for events and progress the tour if they occur
  eventHandlers.forEach(eventHandler => {
    const {
      capture,
      contains,
      elementGetter,
      elementQuery
    } = eventHandler;
    const element = getElement({
      contains,
      elementGetter,
      elementQuery
    }) || attachToElement;
    if (!element) {
      debug('No element for listening found');
      return;
    }

    // Certain elements in the CRM (ie communicator) cause multiple event attachments due to rendering/timing issues
    // In those cases, we prevent event handlers from being attached multiple times to a single element
    if (eventHandler.preventDuplicateListeners) {
      if (attachedElements.includes(element)) {
        return;
      }
      attachedElements.push(element);
    }

    // Tours can pass in a keyCode via the event value
    const [eventType] = eventHandler.event.split(':');

    // Let the thread complete before listening. This prevents a parent node
    // click from triggering if its child is clicked and it is the next step.
    setTimeout(() => {
      // If the element is dynamic, we listen to events on the document that match the
      // element we're listening for. Once we get it, we stop listening on the document.
      if (eventHandler.isDynamic) {
        const onEvent = ({
          target
        }) => {
          const currentElement = getElement({
            contains,
            elementGetter,
            elementQuery
          });
          if (currentElement === target ||
          // Check if the target would be selected by the provided elementQuery
          // https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
          elementQuery && matches(target, elementQuery) ||
          // Check if the target would be child element of the provided elementQuery
          // https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
          // For example: UIButton will be rendered as:
          // <button>
          //   <i18n-string>...</i18n-string>
          // </button>
          // Event target of clicking on button may be i18n-string, closest will check if i18n-string is child of button and trigger tour action.
          elementQuery && closest(target, elementQuery)) {
            performActionAndRemoveListener(tour, eventHandler, document, onEvent);
          }
        };
        document.addEventListener(eventType, onEvent, {
          capture
        });
        registerUnlistener({
          element: document,
          eventType: eventType,
          listener: onEvent,
          listenerType
        });
      } else {
        const onEvent = interactionEvent => {
          performActionAndRemoveListener(tour, eventHandler, element, onEvent, interactionEvent);
        };
        element.addEventListener(eventType, onEvent, {
          capture
        });
        registerUnlistener({
          element,
          eventType: eventType,
          listener: onEvent,
          listenerType
        });
      }
    });
  });
};
const handleInteractions = (tour, step, registerUnlistener, listenerType, attachToElement) => {
  handleEventListeners(tour, step, registerUnlistener, listenerType, attachToElement);
};
export const beforeStepStartListener = (tour, step, registerUnlistener, maybeEnableLinkedTour) => {
  // Set the tour id that should be shown on the next page if we hit the last step in the tour
  const {
    steps
  } = tour.getConfig();
  const stepIndex = steps.indexOf(tour.getStep());
  const isOnLastStep = stepIndex === steps.length - 1;
  const tourId = tour.getTour();
  const stepId = step.id;
  const attachToElementQuery = getAttachToElementQuery(step.attachTo);
  let attachToElement = getElement({
    elementGetter: step.attachTo && step.attachTo.elementGetter,
    elementQuery: attachToElementQuery
  });
  debug('Attaching to element: ', attachToElement);

  // This handles the case that attachedTo element disappear on user interaction
  // The target element can be specified using observerTargetQuery in the tour config
  const handleDetachedElement = () => {
    const targetElement = step.attachTo && step.attachTo.observerTargetQuery;
    if (targetElement) {
      const observedStepKey = tour.getStep();
      const observer = new MutationObserver(() => {
        if (tour.getStep() === observedStepKey) {
          tour.next();
          observer.disconnect();
        }
      });
      const mutationConfig = {
        subtree: true,
        childList: true
      };
      const el = getElement({
        elementQuery: targetElement
      });
      observer.observe(el, mutationConfig);

      // If tour moved to next step in some other ways, disconnect the observer
      const unsubscribes = tour.subscribe(stepKey => {
        if (stepKey !== observedStepKey) {
          observer.disconnect();
          unsubscribes.forEach(unsubscribe => {
            unsubscribe();
          });
        }
      });
    }
  };
  const handleStepShown = () => {
    if (isOnLastStep) {
      const hasFinishAction = Boolean([...(step.buttons || []), ...(step.eventHandlers || [])].find(({
        action
      }) => action === FINISH));

      // Maybe mark action complete
      tour.getConfig().markTaskComplete({
        autoOpenSidePanel: false,
        showTaskCompletionModal: !hasFinishAction
      });
      maybeEnableLinkedTour();
    }
  };
  if (step.isModal && !step.waitForElement) {
    handleStepShown();
  }
  if (!step.isModal && step.skippable && !attachToElement) {
    debug('Skipping tour step: ', stepId);
    tour.next();
    return Promise.resolve();
  }
  if (step.waitForElement) {
    const waitForElementPromise = typeof step.waitForElement === 'function' ? step.waitForElement() : waitForElementToAppear({
      elementQuery: step.waitForElement,
      requiresModalAnimation: step.attachTo && step.attachTo.requiresModalAnimation
    });
    return waitForElementPromise.then(() => {
      attachToElement = getElement({
        elementGetter: step.attachTo && step.attachTo.elementGetter,
        elementQuery: attachToElementQuery
      });
      handleStepShown();
      handleDetachedElement();
      handleInteractions(tour, step, registerUnlistener, 'tourStep', attachToElement);
    });
  } else if (!step.isModal) {
    return waitForAttachToElementToAppearOrFireSentry(tourId, stepId, {
      elementGetter: step.attachTo && step.attachTo.elementGetter,
      elementQuery: attachToElementQuery,
      requiresModalAnimation: step.attachTo && step.attachTo.requiresModalAnimation
    }).then(elementToAttachTo => {
      handleStepShown();
      handleInteractions(tour, step, registerUnlistener, 'tourStep', elementToAttachTo || undefined);
    });
  }
  return Promise.resolve();
};

// Exported for testing purposes only
export function attachEscapeHatchListener(escapeHatch, addEscapeHatchUnlistener, removeTourListeners, tour, maybeEnableNextLinkedTour) {
  escapeHatch.elementQueries.forEach(elementQuery => {
    listenToEventOnElement(elementQuery, escapeHatch.event, () => {
      removeTourListeners();
      if (escapeHatch.markComplete) {
        const {
          steps
        } = tour.getConfig();
        const lastStepIdentifier = steps[steps.length - 1];
        // If we hit a markComplete escape hatch, and there is a nextTourAlias, assume that the page is about
        // to immediately refresh. We don't want to display the next step, just allow the page do its thing.
        if (maybeEnableNextLinkedTour() === false) {
          tour.next(lastStepIdentifier);
        }
      } else {
        tour.deactivate();
      }
    }, addEscapeHatchUnlistener, 'escapeHatch').catch(() => {});
  });
}
export function attachEscapeHatchListeners(tour, addEscapeHatchUnlistener, removeTourListeners, maybeEnableNextLinkedTour) {
  const {
    escapeHatches
  } = tour.getConfig();
  if (escapeHatches) {
    escapeHatches.map(escapeHatch => attachEscapeHatchListener(escapeHatch, addEscapeHatchUnlistener, removeTourListeners, tour, maybeEnableNextLinkedTour));
  }
}