import { AppRenderApplicationStart, BeginningOfTime, TableLoaded, BoardColumnLoaded, BoardItemLoaded, StartJS, IndexRouteMounted, IndexDataLoaderMounted, IndexPageMounted, TableMounted, TableQueryMounted, AsyncTableMounted, BoardLoaded, BoardQueryMounted } from '../../marks/crm-index-ui/performanceMarks';
export { AppStartToRenderApplication, LoadBaseJS, LoadHTML, UntilAppStart } from '../standard/performanceMeasurements';
export { EvaluateJS } from '../standard/performanceMeasurements';

/**
{
  name: REQUIRED: String - Measurement name,
  start: REQUIRED: PerformanceMark,
  end: REQUIRED: PerformanceMark,
}
 */

export const RenderApplicationToIndexRoute = {
  name: 'RenderApplicationToIndexRoute',
  start: AppRenderApplicationStart,
  end: IndexRouteMounted
};
export const IndexRouteToIndexDataLoaderMounted = {
  name: 'IndexRouteToIndexDataLoaderMounted',
  start: IndexRouteMounted,
  end: IndexDataLoaderMounted
};
export const IndexDataLoaderToIndexPageMounted = {
  name: 'IndexDataLoaderToIndexPageMounted',
  start: IndexDataLoaderMounted,
  end: IndexPageMounted
};

// Table measurements
export const TableQueryToAsyncTableMounted = {
  name: 'TableQueryToAsyncTableMounted',
  start: TableQueryMounted,
  end: AsyncTableMounted
};
export const TableQueryToTableMounted = {
  name: 'TableQueryToTableMounted',
  start: TableQueryMounted,
  end: TableMounted
};
export const RenderApplicationToTableLoaded = {
  name: 'RenderApplicationToTableLoaded',
  start: AppRenderApplicationStart,
  end: TableLoaded
};

// Board measurements
export const RenderApplicationToBoardLoaded = {
  name: 'RenderApplicationToBoardLoaded',
  start: AppRenderApplicationStart,
  end: BoardLoaded
};
export const BoardQueryMountedToBoardLoaded = {
  name: 'BoardQueryMountedToBoardLoaded',
  start: BoardQueryMounted,
  end: BoardLoaded
};
export const IndexPageToBoardColumnMounted = {
  name: 'IndexPageToBoardColumnMounted',
  start: IndexPageMounted,
  end: BoardColumnLoaded
};
export const IndexPageToBoardItemMounted = {
  name: 'IndexPageToBoardItemMounted',
  start: IndexPageMounted,
  end: BoardItemLoaded
};

// General measurements
export const TimeToStartJS = {
  name: 'TimeUntilStartJS',
  start: BeginningOfTime,
  end: StartJS
};