import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FAILED, PENDING, SUCCEEDED } from '../../constants/RequestStatus';
import { getSuperStoreValue } from '../../superstore/getSuperStoreValue';
import { setSuperStoreValue } from '../../superstore/setSuperStoreValue';
import { getRecentlyUsedPropertiesSuperStoreKey } from '../utils/getRecentlyUsedPropertiesSuperStoreKey';
const initialState = {
  status: {},
  data: {}
};
export const loadRecentlyUsedProperties = createAsyncThunk('recentlyUsedProperties/load', ({
  objectTypeId
}) => getSuperStoreValue(getRecentlyUsedPropertiesSuperStoreKey(objectTypeId)));
export const setRecentlyUsedProperties = createAsyncThunk('recentlyUsedProperties/set', ({
  objectTypeId,
  value
}) => setSuperStoreValue(getRecentlyUsedPropertiesSuperStoreKey(objectTypeId), value));
export const recentlyUsedPropertiesSlice = createSlice({
  name: 'recentlyUsedProperties',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadRecentlyUsedProperties.pending, (state, {
      meta
    }) => {
      state.status[meta.arg.objectTypeId] = PENDING;
    }).addCase(loadRecentlyUsedProperties.fulfilled, (state, {
      meta,
      payload
    }) => {
      const {
        objectTypeId
      } = meta.arg;
      state.status[objectTypeId] = SUCCEEDED;
      state.data[objectTypeId] = payload;
    }).addCase(loadRecentlyUsedProperties.rejected, (state, {
      meta
    }) => {
      state.status[meta.arg.objectTypeId] = FAILED;
    }).addCase(setRecentlyUsedProperties.fulfilled, (state, {
      meta
    }) => {
      const {
        objectTypeId,
        value
      } = meta.arg;
      state.status[objectTypeId] = SUCCEEDED;
      state.data[objectTypeId] = value;
    });
  }
});
export const recentlyUsedPropertiesReducer = recentlyUsedPropertiesSlice.reducer;