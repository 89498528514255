export const SAVE_VIEW_STARTED = 'SAVE_VIEW_STARTED';
export const SAVE_VIEW_SUCCEEDED = 'SAVE_VIEW_SUCCEEDED';
export const SAVE_VIEW_FAILED = 'SAVE_VIEW_FAILED';
export const CREATE_VIEW_STARTED = 'CREATE_VIEW_STARTED';
export const CREATE_VIEW_SUCCEEDED = 'CREATE_VIEW_SUCCEEDED';
export const CREATE_VIEW_FAILED = 'CREATE_VIEW_FAILED';
export const FILTERS_CHANGED = 'FILTERS_CHANGED';
export const FILTER_GROUPS_CHANGED = 'FILTER_GROUPS_CHANGED';
export const QUICK_FILTERS_CHANGED = 'QUICK_FILTERS_CHANGED';
export const QUICK_FILTER_PROPERTIES_CHANGED = 'QUICK_FILTER_PROPERTIES_CHANGED';
export const RESET_VIEW = 'RESET_VIEW';
export const COLUMNS_CHANGED = 'COLUMNS_CHANGED';
export const SORT_CHANGED = 'SORT_CHANGED';
export const STORE_VIEWS = 'STORE_VIEWS';
export const REPLACE_VIEWS = 'REPLACE_VIEWS';
export const OPTIMISTICALLY_UPDATE_CURRENT_VIEW_ID = 'OPTIMISTICALLY_UPDATE_CURRENT_VIEW_ID';
export const VIEW_PIPELINE_CHANGED = 'VIEW_PIPELINE_CHANGED';