import { createSlice } from '@reduxjs/toolkit';
import { createFrozenSelector } from '../utils/createFrozenSelector';
const pendingEdits = 'pendingEdits';
const initialState = {
  pendingEdits: {}
};
const isValueConsideredChanged = (oldValue, newValue) => {
  //`null` represents "A value for this property has never ever been set in the entire history of this object"
  //Whereas undefined/emptystrings can represent "this property has been set to something previously, but has since been reset to nothing"
  //
  //When inline editing from null => "some new value" => nothing, we want it to appear as "no change has been made"
  if (oldValue === null && newValue === '') {
    return false;
  }

  //properties going into propertyinputs may be numbers, but come out formatted as strings.
  //to protect against this, we parse before/after values to strings before comparision
  return String(newValue) !== String(oldValue);
};
const isPropertyUpdateInvalid = propertyUpdate => {
  const propertyUpdateValidationResult = propertyUpdate && propertyUpdate.validationResult;
  if (propertyUpdateValidationResult) {
    return propertyUpdateValidationResult.invalid;
  }
  // there are property updates that come from the propertyinput component that do not have their own validation
  // they are validated by the property input component and must be assumed to be valid
  //
  // There are also `pending` validation results that are not considered invalid but also not considered valid
  // in that case we return `false` to indicate, this property is not explicitly invalid
  return false;
};
const isPropertyUpdateValid = propertyUpdate => {
  const propertyUpdateValidationResult = propertyUpdate && propertyUpdate.validationResult;
  if (propertyUpdateValidationResult) {
    return propertyUpdateValidationResult.saveable;
  }
  // there are property updates that come from the propertyinput component that do not have their own validation
  // they are validated by the property input component and must be assumed to be valid
  return true;
};
const inlineEditingSlice = createSlice({
  name: 'inlineEditing',
  initialState,
  reducers: {
    clearPendingEdits: (state, action) => {
      if (action.payload) {
        const {
          updates,
          objectTypeId
        } = action.payload;
        const byObjectTypeId = state[pendingEdits][objectTypeId];
        if (!byObjectTypeId) {
          return;
        }
        for (const [objectId, update] of Object.entries(updates)) {
          const pendingEditsForObject = byObjectTypeId[objectId];
          if (!update || !pendingEditsForObject) {
            break;
          }
          for (const [propertyName, updatedValue] of Object.entries(update)) {
            const pendingEdit = pendingEditsForObject[propertyName];
            // Only clear edits that are the same as updated value, otherwise you risk
            // clearing an edit that was made after the update was made
            if (!isValueConsideredChanged(pendingEdit === null || pendingEdit === void 0 ? void 0 : pendingEdit.value, updatedValue)) {
              delete pendingEditsForObject[propertyName];
            }
          }
        }
      } else {
        state[pendingEdits] = {};
      }
    },
    addPendingEdit: (state, action) => {
      const {
        objectTypeId,
        objectId,
        updates,
        originalValues
      } = action.payload;
      const byObjectTypeId = state[pendingEdits][objectTypeId] || (state[pendingEdits][objectTypeId] = {});
      const byObjectId = byObjectTypeId[objectId] || (byObjectTypeId[objectId] = {});
      for (const propertyName in updates) {
        if (Object.prototype.hasOwnProperty.call(updates, propertyName)) {
          const newValue = updates[propertyName];
          const originalValue = originalValues[propertyName];
          if (isValueConsideredChanged(originalValue, newValue)) {
            const byPropertyName = byObjectId[propertyName] || (byObjectId[propertyName] = {
              value: newValue,
              validationResult: undefined
            });
            byPropertyName.value = newValue;
          } else {
            //NOTE: if there are ever values stored in this slice that we need to persist when a value is not considered changed, this will need to change
            delete byObjectId[propertyName];
          }
        }
      }
    },
    addPendingEditValidation: (state, action) => {
      const {
        objectTypeId,
        objectId,
        propertyName,
        validation
      } = action.payload;
      const byObjectTypeId = state[pendingEdits][objectTypeId] || (state[pendingEdits][objectTypeId] = {});
      const byObjectId = byObjectTypeId[objectId] || (byObjectTypeId[objectId] = {});
      const byPropertyName = byObjectId[propertyName];
      if (byPropertyName) {
        byObjectId[propertyName] = Object.assign({}, byPropertyName, {
          validationResult: validation
        });
      } else {
        //we are clicking into a cell that is unedited, but validation was fired off because we clicked into it - ignore validation for a property until the property has actually changed
      }
    }
  }
});
export const {
  addPendingEdit,
  clearPendingEdits,
  addPendingEditValidation
} = inlineEditingSlice.actions;
export const inlineEditingReducer = inlineEditingSlice.reducer;
const getSlice = ({
  inlineEditing
}) => inlineEditing;
export const getPendingInlineEdits = createFrozenSelector([getSlice], slice => slice[pendingEdits]);
export const makeGetPendingInlineEditsPerObjectTypeId = ({
  objectTypeId
}) => createFrozenSelector([getPendingInlineEdits], pendingInlineEdits => pendingInlineEdits[objectTypeId]);
export const makeGetPendingInlineEditsPerObject = ({
  objectTypeId,
  objectId
}) => {
  const perObjectTypeIdSelector = makeGetPendingInlineEditsPerObjectTypeId({
    objectTypeId
  });
  return createFrozenSelector([perObjectTypeIdSelector], perObjectTypeId => perObjectTypeId && perObjectTypeId[objectId]);
};
export const makeGetPendingInlineEditsForObjectProperty = ({
  objectTypeId,
  objectId,
  propertyName
}) => {
  const perObjectSelector = makeGetPendingInlineEditsPerObject({
    objectTypeId,
    objectId
  });
  return createFrozenSelector([perObjectSelector], perObject => {
    return perObject && perObject[propertyName];
  });
};
export const makeGetArePendingEditsForSpecificObjectInvalid = ({
  objectTypeId,
  objectId
}) => {
  const perObjectSelector = makeGetPendingInlineEditsPerObject({
    objectTypeId,
    objectId
  });
  return createFrozenSelector([perObjectSelector], perObject => {
    return Boolean(perObject && Object.values(perObject).some(isPropertyUpdateInvalid));
  });
};
export const areAllPendingInlineEditsValidSelector = createFrozenSelector([getPendingInlineEdits], allPendingEdits => {
  //map through each objectTypeId
  return Object.keys(allPendingEdits).every(currentObjectTypeId => {
    const objectPropertyUpdatesValidations = allPendingEdits[currentObjectTypeId];
    if (!objectPropertyUpdatesValidations) {
      return true;
    }
    //map through each objectTypeId+objectId
    return Object.keys(objectPropertyUpdatesValidations).every(currentObjectId => {
      const propertyUpdatesForCurrentObjectId = objectPropertyUpdatesValidations[currentObjectId];
      if (!propertyUpdatesForCurrentObjectId) {
        return true;
      }

      //map through each objectTypeId+objectId+propertyValidationResult
      return Object.values(propertyUpdatesForCurrentObjectId).every(isPropertyUpdateValid);
    }, true);
  }, true);
});

/**
 * will return state.pendingEdits[objectTypeId] but with validations removed
 */
export const makeGetPendingInlineEditsPerObjectTypeIdForUpdateApiCall = ({
  objectTypeId
}) => {
  const perObjectTypeIdSelector = makeGetPendingInlineEditsPerObjectTypeId({
    objectTypeId
  });
  return createFrozenSelector([perObjectTypeIdSelector], pendingInlineEditsForObjectType => {
    if (!pendingInlineEditsForObjectType) {
      return pendingInlineEditsForObjectType;
    }
    return Object.keys(pendingInlineEditsForObjectType).reduce((accumulator, objectId) => {
      const pendingInlineEditsForObjectId = pendingInlineEditsForObjectType[objectId];
      if (!pendingInlineEditsForObjectId) {
        return accumulator;
      }
      const objectPropertyUpdates = accumulator[objectId] = {};
      for (const propertyName in pendingInlineEditsForObjectId) {
        if (Object.prototype.hasOwnProperty.call(pendingInlineEditsForObjectId, propertyName)) {
          const pendingInlineEditForSingleObjectProperty = pendingInlineEditsForObjectId[propertyName];
          if (!pendingInlineEditForSingleObjectProperty) {
            return accumulator;
          }
          objectPropertyUpdates[propertyName] = pendingInlineEditForSingleObjectProperty.value;
        }
      }
      return accumulator;
    }, {});
  });
};