import { DEFAULT, FORECAST } from '../../viewsSearch/types/ViewTypes';

/* Analogous to "is this a view made by hubspot that every portal has"
 * One that is configuered here https://tools.hubteam.com/puma/framework-app-settings/crm_global_default_views/entries
 *
 * The flip flop in terms here is because we show users the phrase "standard" for what our data layers internally calls "default"
 */
export const isHubspotStandardView = ({
  view
}) => {
  return view.type === DEFAULT || view.type === FORECAST;
};