'use es6';

import ReactDOM from 'react-dom';
import EmailConfirmBar from '../views/EmailConfirmBar';
import VerificationEmailResentMessage from '../views/VerificationEmailResentMessage';
import VerificationEmailResendError from '../views/VerificationEmailResendError';
import { EMAIL_CONFIRM_ACTIONS_ID, RESEND_VERIFICATION_EMAIL_BUTTON_ID, HUBSPOT_NAV_V3_ID, HUBSPOT_NAV_V4_ID, HUBSPOT_NAV_NO_BOTTOM_MARGIN_CLASS, HUBSPOT_NAV_BEFORE_ID } from '../constants/DOMSelectors';
const getHubSpotNav = () => document.getElementById(HUBSPOT_NAV_V4_ID) || document.getElementById(HUBSPOT_NAV_V3_ID);
const getEmailContainer = () => document.getElementById(HUBSPOT_NAV_BEFORE_ID);
const createNavContainer = () => {
  const hsNav = getHubSpotNav();
  const container = document.createElement('div');
  container.id = HUBSPOT_NAV_BEFORE_ID;
  if (hsNav.className.indexOf(HUBSPOT_NAV_NO_BOTTOM_MARGIN_CLASS) !== -1) {
    container.className = HUBSPOT_NAV_NO_BOTTOM_MARGIN_CLASS;
  }
  hsNav.parentNode.insertBefore(container, hsNav);
};
class BaseEmailConfirmBarLoader {
  constructor(opts = {}) {
    this.email = '';
    this.userId = null;
    this.isVerified = null;
    this.isVisible = false;
    this.hasResent = false;
    if (this.getRequiredData && typeof this.getRequiredData === 'function') {
      this.getRequiredData().then(this.initialize.bind(this)).catch();
    } else if (opts && 'email' in opts && 'isVerified' in opts) {
      this.initialize(opts);
    }
  }
  initialize({
    email,
    isVerified,
    userId
  }) {
    this.setEmail(email);
    this.setUserId(userId);
    this.setIsVerified(isVerified);
    if (this.email && this.isVerified === false) {
      this.render();
    }
  }
  resendVerificationEmail() {
    // Prevents duplicate requests from clicking the
    // resend link before the request resolves.
    if (this.hasResent) {
      return Promise.resolve();
    }
    this.hasResent = true;
    return this.handleResend(this.userId);
  }
  handleResend() {
    throw new Error('IMPLEMENT handleResend');
  }
  bindEventListeners() {
    const actionsContainer = document.getElementById(EMAIL_CONFIRM_ACTIONS_ID);
    const resendButton = document.getElementById(RESEND_VERIFICATION_EMAIL_BUTTON_ID);
    const setEmailSentMessage = () => {
      if (actionsContainer) {
        actionsContainer.innerHTML = VerificationEmailResentMessage();
      }
    };
    const setEmailSentErrorMessage = () => {
      if (actionsContainer) {
        actionsContainer.innerHTML = VerificationEmailResendError();
      }
    };
    if (resendButton) {
      resendButton.addEventListener('click', () => {
        this.resendVerificationEmail().then(setEmailSentMessage, setEmailSentErrorMessage).catch(setEmailSentErrorMessage);
      });
    }
  }
  setEmail(email) {
    this.email = email;
  }
  setUserId(userId) {
    this.userId = userId;
  }
  setIsVerified(isVerified) {
    this.isVerified = isVerified;
    if (isVerified && this.isVisible) {
      this.remove();
    }
  }
  setIsVisible(isVisible) {
    this.isVisible = isVisible;
  }
  render() {
    if (this.isVisible) return;
    if (!getHubSpotNav()) return;
    if (!getEmailContainer()) {
      createNavContainer();
    }
    if (getEmailContainer()) {
      ReactDOM.render(EmailConfirmBar(this.email), getEmailContainer());
      this.bindEventListeners();
      this.setIsVisible(true);
    }
  }
  remove() {
    if (getEmailContainer() !== null) {
      ReactDOM.unmountComponentAtNode(getEmailContainer());
    }
    this.setIsVisible(false);
  }
}
export default BaseEmailConfirmBarLoader;