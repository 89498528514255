import PortalIdParser from 'PortalIdParser';
import { COMPANY, CONTACT, DEAL } from 'customer-data-objects/constants/ObjectTypes';
const DEAL_WIZARD_URL = `/onboarding-wizard/${PortalIdParser.get()}/track-deals`;
const CONTACT_WIZARD_URL = `/onboarding-wizard/${PortalIdParser.get()}/create-contact`;
const FIND_COMPANY_WIZARD_URL = `/onboarding-wizard/${PortalIdParser.get()}/find-company`;
export const getDealWizardUrl = via => {
  return `${DEAL_WIZARD_URL}?via=${via}`;
};
export const getContactWizardUrl = via => {
  return `${CONTACT_WIZARD_URL}?via=${via}`;
};
export const getCompanyWizardUrl = via => {
  return `${FIND_COMPANY_WIZARD_URL}?via=${via}`;
};
export const getRedirectionUrlForGuidedOnboarding = (objectType, isBoard = false) => {
  switch (objectType) {
    case DEAL:
      return getDealWizardUrl(isBoard ? 'deal-board' : 'deal-list');
    case COMPANY:
      return getCompanyWizardUrl('company-list');
    case CONTACT:
      return getContactWizardUrl('contact-list');
    default:
      return null;
  }
};
export const redirectTo = url => {
  window.location.href = url;
};