export const Level = {
  OFF: 0,
  ERROR: 1,
  DEBUG: 2
};
const reverseLevels = Object.keys(Level).reduce((collector, k) => {
  const level = Level[k];
  collector[level] = k;
  return collector;
}, {});
const LOGGER_KEY = 'crm-message-bus-logger-level';
const LOGGER_TOPICS_KEY = 'crm-message-bus-logger-topics';
function isValidLogLevel(level) {
  return Object.values(Level).includes(level);
}
let currentLevel;
let currentTopics;
function isLoggedTopic(topic) {
  const topics = getLoggedTopics();
  if (topics.length === 0) {
    return true;
  }
  return topics.includes(topic);
}
function getLoggingLevel() {
  if (currentLevel !== undefined) {
    return currentLevel;
  }
  currentLevel = 0;
  try {
    const levelStr = localStorage.getItem(LOGGER_KEY);
    if (levelStr) {
      const parsedLevel = parseInt(levelStr, 10);
      if (isValidLogLevel(parsedLevel)) {
        currentLevel = parsedLevel;
      }
    }
  } catch (_unused) {
    currentLevel = 0;
  }
  return currentLevel;
}
function getLoggedTopics() {
  if (currentTopics !== undefined) {
    return currentTopics;
  }
  currentTopics = [];
  try {
    const topicsStr = localStorage.getItem(LOGGER_TOPICS_KEY);
    if (topicsStr) {
      currentTopics = topicsStr.split(',').map(t => t.trim());
    }
  } catch (_unused2) {
    currentTopics = [];
  }
  return currentTopics;
}
function log(level, ...args) {
  const loggingLevel = getLoggingLevel();
  if (level && loggingLevel && level <= getLoggingLevel()) {
    args.unshift(`[CRM MessageBus ${reverseLevels[level]}]`);
    console.log(...args);
  }
}
function logTopic(level, topic, ...args) {
  const loggingLevel = getLoggingLevel();
  if (level && loggingLevel && level <= getLoggingLevel() && isLoggedTopic(topic)) {
    args.unshift(`[CRM MessageBus ${reverseLevels[level]}]`);
    console.log(...args);
  }
}
function setLevel(level) {
  if (level === undefined || Object.keys(reverseLevels).includes(String(level))) {
    currentLevel = level;
  }
}
export default {
  log,
  logTopic,
  setLevel
};