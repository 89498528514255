import { useEffect, useState } from 'react';
import devLogger from 'react-utils/devLogger';
// eslint-disable-next-line no-restricted-imports
import userInfo from 'hub-http/userInfo';
export const useUserInfo = () => {
  const [userInfoResponse, setUserInfoResponse] = useState();
  useEffect(() => {
    try {
      // ignoreRedirect is important! Without it, any consumers of  settings lib that don't
      // support or shim userInfo can end up redirecting (the default behavior).
      // This has caused past critsits:
      // https://git.hubteam.com/HubSpot/Critsit/issues/3557
      // https://git.hubteam.com/HubSpot/Critsit/issues/1040
      userInfo({
        ignoreRedirect: true
      }).then(response => {
        setUserInfoResponse(response);
      }).catch(() => {
        devLogger.warn({
          message: `pipeline-board-settings-userinfo: API failure fetching userinfo`,
          key: 'pipeline-board-settings-userinfo-api'
        });
      });
    } catch (err) {
      devLogger.warn({
        message: `pipeline-board-settings-userinfo: Failure fetching userinfo`,
        key: 'pipeline-board-settings-userinfo'
      });
    }
  }, []);
  return userInfoResponse;
};