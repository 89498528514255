import { CONTACT, VISIT } from 'customer-data-objects/constants/ObjectTypes';
import { getObjectType, getProperty } from 'customer-data-objects/record/ObjectRecordAccessors';
export const EMPTY_TOSTRING = '-';
const toStringForType = {
  [VISIT]: record => getProperty(record, 'name') || getProperty(record, 'domain') || EMPTY_TOSTRING,
  [CONTACT]: (record, nameFormatter) => {
    const firstName = getProperty(record, 'firstname');
    const lastName = getProperty(record, 'lastname');
    const displayName = nameFormatter({
      firstName,
      lastName
    }) || getProperty(record, 'email');
    return displayName || '-';
  }
};
function toStringByType(objectType, record, nameFormatter) {
  const maybeFormatter = toStringForType[objectType];
  if (!maybeFormatter) {
    throw new Error(`ImmutableModel: toString not defined for objectType "${objectType}"`);
  }
  return maybeFormatter(record, nameFormatter);
}
export function toString(nameFormatter, record) {
  if (!record) return '';
  if (Object.prototype.hasOwnProperty.call(toStringForType, getObjectType(record))) {
    return toStringByType(getObjectType(record), record, nameFormatter);
  }
  if (typeof record.constructor.toString === 'function') {
    return record.constructor.toString(record);
  }
  return toStringByType(getObjectType(record), record, nameFormatter);
}