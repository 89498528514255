import { debug } from './debugUtil';
import { markTaskComplete } from './taskUtil';
import { openTaskCompletionModal } from './banner';
const bindExtraData = (tourCallback, extraData) => {
  if (typeof tourCallback !== 'function') {
    return undefined;
  }
  return param => {
    if (typeof param === 'object') {
      return tourCallback(Object.assign({}, param, extraData));
    } else {
      return tourCallback(extraData);
    }
  };
};
export const buildTourConfig = (tourId, tourData) => {
  const {
    beforeEnter,
    beforeExit,
    beforeStart,
    beforeFinish,
    completeActionKey,
    escapeHatches,
    nextTourAlias
  } = tourData;
  return {
    steps: tourData.steps.map(step => step.id),
    nextTourAlias,
    escapeHatches: escapeHatches || [],
    beforeEnter: bindExtraData(beforeEnter, {
      tourData
    }),
    beforeExit: bindExtraData(beforeExit, {
      tourData
    }),
    beforeFinish: bindExtraData(beforeFinish, {
      tourData
    }),
    beforeStart: bindExtraData(beforeStart, {
      tourData
    }),
    markTaskComplete: options => {
      if (completeActionKey) {
        debug(`Marking task with actionName ${completeActionKey} as complete`);
        markTaskComplete(completeActionKey, options).catch(() => {});
      } else {
        debug(`No taskCompleteActionKey for tour with id ${tourId}`);
      }
    },
    openTaskCompletionModal: () => {
      if (completeActionKey) {
        openTaskCompletionModal(completeActionKey);
      }
    }
  };
};