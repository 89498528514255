'use es6';

import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { TICKET } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { getObjectsByIds, searchObjects, createGetObjectsByIds, createSearchObjects } from 'reference-resolvers/api/ContactSearchAPI';
export const createTicketReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.TICKETS,
  createFetchByIds: opts => createGetObjectsByIds(opts)(TICKET),
  createFetchSearchPage: opts => createSearchObjects(opts)(TICKET),
  fetchByIds: getObjectsByIds(TICKET),
  fetchSearchPage: searchObjects(TICKET)
}, options));
export default createTicketReferenceResolver();