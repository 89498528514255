import { OPEN_MODAL, CLOSE_MODAL, CLOSE_PANEL, OPEN_PANEL } from '../actions/overlayActionTypes';
import { NONE } from '../constants/none';
import { produce } from 'immer';
import { SYNC_ROUTER_VALUES } from '../../rewrite/init/actions/initActionTypes';
import { EXPORT_VIEW_MODAL } from '../constants/modalTypes';
import { OPTIMISTICALLY_UPDATE_CURRENT_VIEW_ID } from '../../views/actions/viewsActionTypes';
const initialState = {
  modalType: NONE,
  modalData: {},
  panelType: NONE,
  panelData: {}
};
export const overlayReducer = produce((draft, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      {
        const {
          modalType,
          modalData
        } = action.payload;
        draft.modalType = modalType;
        draft.modalData = modalData;
        return draft;
      }
    case CLOSE_MODAL:
      {
        draft.modalType = NONE;
        draft.modalData = {};
        return draft;
      }
    case OPEN_PANEL:
      {
        const {
          panelType,
          panelData
        } = action.payload;
        draft.panelType = panelType;
        draft.panelData = panelData;
        return draft;
      }
    case CLOSE_PANEL:
      {
        draft.panelType = NONE;
        draft.panelData = {};
        return draft;
      }
    case OPTIMISTICALLY_UPDATE_CURRENT_VIEW_ID:
    case SYNC_ROUTER_VALUES:
      {
        draft.panelType = NONE;
        draft.panelData = {};

        // When exporting from the "All views" page, we also select the exported view.
        // Without this conditional the modal opens then immediately closes.
        // We could remove this by simply not switching to the view when exporting.
        if (draft.modalType !== EXPORT_VIEW_MODAL) {
          draft.modalType = NONE;
          draft.modalData = {};
        }
        return draft;
      }
    default:
      {
        return draft;
      }
  }
}, initialState);