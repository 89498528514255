import { messageBus } from 'crm-message-bus/MessageBus';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { COMMAND_HANDLER_SOURCE_ID } from '../constants';
export const PING_PONG_HANDLER = {
  topic: TOPIC_NAMES.COMMAND_HANDLER_PING,
  type: 'CALLBACK',
  callback: () => {
    messageBus.publish(TOPIC_NAMES.COMMAND_HANDLER_PONG, {
      data: undefined,
      envelope: {
        sourceId: COMMAND_HANDLER_SOURCE_ID
      }
    });
  }
};