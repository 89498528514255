import { useCallback } from 'react';
import { useLocalMutationsState } from './useLocalMutationsState';
const selector = state => {
  return state.queryMutations;
};
export const useGetLocalMutationsForQuery = () => {
  const queryMutations = useLocalMutationsState(selector);
  return useCallback(({
    queryHash
  }) => {
    return queryMutations[queryHash];
  }, [queryMutations]);
};