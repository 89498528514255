'use es6';

import always from 'transmute/always';
import curry from 'transmute/curry';
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
import { fromJS, List } from 'immutable';
export const formatToReferencesList = curry(({
  getId,
  getLabel,
  getIcon = always(undefined),
  getDescription = always(undefined),
  getDisabled = always(undefined),
  getArchived = always(undefined)
}, items) => {
  return List(items.map(value => new ReferenceRecord({
    id: String(getId(value)),
    label: getLabel(value),
    icon: getIcon(value),
    description: getDescription(value),
    referencedObject: fromJS(value),
    disabled: getDisabled(value),
    archived: getArchived(value)
  })));
});