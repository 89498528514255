import { getExtension } from '../Extensions';
import { DefaultExtension } from '../extensions/DefaultExtension';
export const runBehavior = async (behaviorType, {
  typeDef,
  hasAllGates,
  hasAllScopes,
  pageType,
  viewId
}) => {
  const defaultValue = DefaultExtension[behaviorType]({
    typeDef,
    hasAllGates,
    hasAllScopes,
    pageType,
    viewId
  });
  const extension = await getExtension(typeDef.objectTypeId);
  if (!extension) {
    return defaultValue;
  }
  const behavior = extension[behaviorType];
  if (!behavior) {
    return defaultValue;
  }
  return behavior({
    typeDef,
    hasAllGates,
    hasAllScopes,
    pageType,
    viewId
  });
};