import { generatePath } from 'react-router-dom';
import http from 'hub-http/clients/apiClient';
import Raven from 'raven-js';
export const getTotalRecords = ({
  objectTypeId,
  limit,
  threshold
}) => http.get(generatePath('sales-views/v2/crm-search/limit-check/:objectTypeId', {
  objectTypeId
}), {
  query: {
    limit,
    threshold
  }
}).catch(err => {
  Raven.captureException(err);
  throw err;
});