import { registerQuery, useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
const fetcher = ({
  objectTypeId
}) => getFrameworkDataSchemasClient().then(client => {
  return client.pipelines.get({
    frameworkTypeIdentifier: objectTypeId
  });
});
export const GET_PIPELINES = 'CRM_INDEX_UI_GET_PIPELINES';
const getPipelineQuery = registerQuery({
  fieldName: GET_PIPELINES,
  args: ['objectTypeId'],
  fetcher
});
export const useFetchPipelines = objectTypeId => {
  const {
    loading,
    error,
    data
  } = useQuery(getPipelineQuery, {
    variables: {
      objectTypeId
    },
    skip: !objectTypeId
  });
  return useMemo(() => {
    if (!loading && data && !error) {
      return {
        data: data[GET_PIPELINES],
        loading,
        error
      };
    }
    return {
      data: null,
      loading
    };
  }, [data, error, loading]);
};