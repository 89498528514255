// Commerce Tracker setup
import { setPrimaryTracker } from 'usage-tracker-container';
import { createTracker } from 'usage-tracker';
import events from '../../../events.yaml';

/**
 * Sets up the Primary Tracker for Commerce.
 * Libraries depend on consuming apps to either
 * set or pass in a tracker to be utilized by
 * the Usage Tracker tooling. We're following
 * the Singleton pattern described in the Usage
 * Tracker docs.
 *
 * @see https://product.hubteam.com/docs/usage-tracking-manual/docs/frontend/library-tracking.html
 */
export const setUpCommercePrimaryTracker = () => {
  const commerceTracker = createTracker({
    events
  });
  setPrimaryTracker(commerceTracker, 'commerce-shared-components-lib');
};