import { useEffect, useCallback } from 'react';
/**
 * This implements support for the web visibility API:
 *   https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
 *
 * @example
 * function MyPollingComponent() {
 *   // assumes we have startPolling() and stopPolling() methods defined
 *   useVisibilityChange({
 *     onVisible: startPolling,
 *     onHidden: stopPolling,
 *   });
 *   return <Results />;
 * }
 */
export function useVisibilityChange({
  enabled = true,
  onVisible,
  onHidden
}) {
  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState !== 'visible') {
      onHidden();
    } else {
      onVisible();
    }
  }, [onHidden, onVisible]);

  // We only want to trigger the effect if the user has polling enabled, but
  // eslint doesn't like that so we can disable the rule as a workaround.
  // React doesn't care about the conditional return type so neither should we
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (enabled) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
  }, [enabled, handleVisibilityChange]);
}