import { createPersistedCache } from './cache/createPersistedCache';
import { defaultToCacheKey } from './quickLoad';
import { makeLoadFailed } from './utils/makeLoadFailed';
export function wrapPromise(cacheConfig) {
  const cache = createPersistedCache(cacheConfig);
  return wrapPromiseWithCache({
    fetch: cacheConfig.fetch,
    toCacheKey: cacheConfig.toCacheKey,
    cache
  });
}
export function wrapPromiseWithCache({
  fetch,
  cache,
  toCacheKey = defaultToCacheKey
}) {
  return {
    makeFetchWithOpts: opts => (...args) => {
      var _cache$readThrough;
      return (_cache$readThrough = cache.readThrough({
        cacheKey: toCacheKey(...args),
        fetchValue: () => fetch(...args),
        opts
      })) !== null && _cache$readThrough !== void 0 ? _cache$readThrough : makeLoadFailed();
    },
    syncIntoCache: (value, ...args) => {
      cache.set(toCacheKey(...args), value);
    },
    debug: cache.printDebug,
    clearCache: cache.clear
  };
}