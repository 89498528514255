import memoizeOne from 'react-utils/memoizeOne';
import { getPageType } from '../../quick-fetch/getPageType';
import { parseObjectTypeIdFromPath } from '../../quick-fetch/parseObjectTypeIdFromPath';
import { getAdditionalMetricsDimensions } from './getAdditionalMetricsDimensions';

/**
 * Used when we don't have the objectTypeId or pageType yet (e.g., before
 * we've been rendered by the router). The guesses are the same used for
 * quick fetching.
 */
export const predictAdditionalMetricsDimensions = memoizeOne(() => {
  const objectTypeId = parseObjectTypeIdFromPath(window.location.pathname);
  const pageType = getPageType(window.location.pathname, objectTypeId !== null && objectTypeId !== void 0 ? objectTypeId : '');
  return getAdditionalMetricsDimensions(objectTypeId, pageType);
});