import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
const renderComponentsAdapter = createEntityAdapter({
  selectId: entity => entity.key
});
export const renderComponentsSlice = createSlice({
  name: 'renderComponents',
  initialState: renderComponentsAdapter.getInitialState(),
  reducers: {
    setMultipleRenderComponents: renderComponentsAdapter.setMany,
    removeRenderComponent: renderComponentsAdapter.removeOne
  }
});