import { useMemo } from 'react';
import { useSelectedObjectTypeId } from '../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { useObjectTypeDefinitions } from './useObjectTypeDefinitions';
export const useSelectedObjectTypeDef = () => {
  const objectTypeId = useSelectedObjectTypeId();
  const typeDefs = useObjectTypeDefinitions();
  return typeDefs ? typeDefs.get(objectTypeId) : undefined;
};
export const useSelectedTypeDefSingularForm = () => {
  const selected = useSelectedObjectTypeDef();
  return selected ? selected.singularForm || selected.name : '';
};
export const useSelectedTypeDefPluralForm = () => {
  const selected = useSelectedObjectTypeDef();
  return selected ? selected.pluralForm || selected.name : '';
};
export const useSelectedTypeDefObjectNamesForTranslation = () => {
  const singularForm = useSelectedTypeDefSingularForm();
  const pluralForm = useSelectedTypeDefPluralForm();
  return useMemo(() => {
    return {
      objectName: singularForm,
      singularForm,
      singularFormLowercase: singularForm.toLocaleLowerCase(),
      pluralForm,
      pluralFormLowercase: pluralForm.toLocaleLowerCase()
    };
  }, [singularForm, pluralForm]);
};