'use es6';

import PropTypes from 'prop-types';
import { Record } from 'immutable';
import map from 'transmute/map';
import get from 'transmute/get';
import isFunction from 'transmute/isFunction';
import identity from 'transmute/identity';
import always from 'transmute/always';
import ifElse from 'transmute/ifElse';
import enviro from 'enviro';
const checkPropTypes = (propTypes, props, componentName) => {
  let error;

  // Re-purposing to maintain the contract of throwing for prop type errors
  const getStack = () => {
    error = true;
    // Don't throw here to allow console error to log
    return '';
  };
  Object.keys(props).forEach(key => {
    const propType = propTypes[key];
    if (propType == null) {
      const keys = Object.keys(propTypes).map(k => `\`${k}\``).join(', ');
      throw new Error(`Unexpected prop \`${key}\` supplied to \`${componentName}\`, expected props to be ${keys}`);
    }

    // React v15 compatible
    if (PropTypes.checkPropTypes) {
      PropTypes.checkPropTypes(propTypes, props, key, componentName, getStack);
    }
    if (error) {
      throw new Error(`Invalid prop \`${key}\` supplied to \`${componentName}\``);
    }
  });
};
const TypedRecord = (recordDefinition, name = 'TypedRecord') => {
  const defaults = map(ifElse(isFunction, always(undefined), get('default')), recordDefinition);
  const propTypes = map(ifElse(isFunction, identity, get('type')), recordDefinition);
  const RecordClass = Record(defaults, name);
  if (enviro.deployed() && enviro.isProd()) {
    return RecordClass;
  }
  const check = (record, values = {}) => {
    checkPropTypes(propTypes, Object.assign({}, values, record.toObject()), name);
  };
  function TypedRecordClass(values) {
    if (!(this instanceof TypedRecordClass)) {
      return new TypedRecordClass(values);
    }
    RecordClass.call(this, values);
    check(this, values);
    return this;
  }
  TypedRecordClass.prototype = Object.create(RecordClass.prototype, {
    constructor: {
      value: TypedRecordClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  Object.setPrototypeOf(TypedRecordClass, TypedRecord);
  TypedRecordClass.prototype.set = function (key, value) {
    const result = RecordClass.prototype.set.call(this, key, value);
    check(result, {
      [key]: value
    });
    return result;
  };
  Object.defineProperty(TypedRecordClass, 'name', {
    value: name
  });
  return TypedRecordClass;
};
export default TypedRecord;