// See https://git.hubteam.com/HubSpot/Analytics/blob/master/AnalyticsBase/src/main/java/com/hubspot/analytics/sources/VisitSource.java
// In alphabetical order, mostly used when working with analytics sources as property values

export const DIRECT_TRAFFIC = 'DIRECT_TRAFFIC';
export const EMAIL_MARKETING = 'EMAIL_MARKETING';
export const OFFLINE = 'OFFLINE';
export const ORGANIC_SEARCH = 'ORGANIC_SEARCH';
export const OTHER_CAMPAIGNS = 'OTHER_CAMPAIGNS';
export const PAID_SEARCH = 'PAID_SEARCH';
export const PAID_SOCIAL = 'PAID_SOCIAL';
export const REFERRALS = 'REFERRALS';
export const SOCIAL_MEDIA = 'SOCIAL_MEDIA';