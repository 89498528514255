import { useMemo } from 'react';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import { withCrmObjectBoardNamespace } from '../utils';
import { registerQuery, useQuery } from 'data-fetching-client';
import { useSyncError } from '../error/useSyncError';
const fetcher = () => getFrameworkDataSchemasClient().then(client => {
  return client.frameworkTypes.getObjects();
});
export const GET_OBJECT_DEFINITIONS_QUERY = withCrmObjectBoardNamespace('GET_OBJECT_DEFINITIONS');
const getObjectDefinitionsQuery = registerQuery({
  fieldName: GET_OBJECT_DEFINITIONS_QUERY,
  args: [],
  fetcher
});
export const useQueryObjectDefinitions = () => {
  const {
    loading,
    error,
    data
  } = useQuery(getObjectDefinitionsQuery, {});
  return useMemo(() => {
    if (!loading && data && !error) {
      return {
        data: data[GET_OBJECT_DEFINITIONS_QUERY],
        loading,
        error
      };
    }
    return {
      data: null,
      loading,
      error
    };
  }, [data, error, loading]);
};
export const useLoadObjectTypes = () => {
  const result = useQueryObjectDefinitions();
  useSyncError(result.error);
  return result;
};