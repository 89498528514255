'use es6';

import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import { SEQUENCES } from 'reference-resolvers/constants/CacheKeys';
import { SEQUENCE } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { createFetchCrmObjectsSearchPage, fetchCrmObjectsSearchPage, createFetchCrmObjectsByIds, fetchCrmObjectsByIds } from 'reference-resolvers/api/CrmSearchByUniqueIdApi';
const SEQUENCE_ID_PROPERTY = 'hs_sequence_id';
const SEQUENCE_NAME_PROPERTY = 'hs_sequence_name';
export const createSequencesReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: SEQUENCES,
  createFetchByIds: opts => createFetchCrmObjectsByIds(opts)(SEQUENCE, SEQUENCE_ID_PROPERTY, SEQUENCE_NAME_PROPERTY),
  createFetchSearchPage: opts => createFetchCrmObjectsSearchPage(opts)(SEQUENCE, SEQUENCE_ID_PROPERTY, SEQUENCE_NAME_PROPERTY),
  fetchByIds: fetchCrmObjectsByIds(SEQUENCE, SEQUENCE_ID_PROPERTY, SEQUENCE_NAME_PROPERTY),
  fetchSearchPage: fetchCrmObjectsSearchPage(SEQUENCE, SEQUENCE_ID_PROPERTY, SEQUENCE_NAME_PROPERTY)
}, options));
export default createSequencesReferenceResolver();