import * as ExternalOptionTypes from 'customer-data-objects/property/ExternalOptionTypes';
import * as OptionTypes from 'customer-data-objects/property/OptionTypes';
import { COMPANY, CONTACT, DEAL, ENGAGEMENT, LINE_ITEM, QUOTE, TICKET, VISIT } from 'customer-data-objects/constants/ObjectTypes';
import { CALL_TYPE_ID, CAMPAIGN_TYPE_ID } from '../constants/ObjectTypeIds';
const PropertyNameToReferenceType = {
  [CALL_TYPE_ID]: {
    hs_call_disposition: ExternalOptionTypes.CALL_DISPOSITION,
    hs_created_by: ExternalOptionTypes.USER,
    hs_created_by_user_id: ExternalOptionTypes.USER,
    hs_updated_by_user_id: ExternalOptionTypes.USER,
    hubspot_team_id: ExternalOptionTypes.TEAM
  },
  [CAMPAIGN_TYPE_ID]: {
    hs_owner: ExternalOptionTypes.OWNER
  },
  [COMPANY]: {
    '_inbounddbio.importid_': ExternalOptionTypes.INBOUND_DB_IMPORT,
    'ilsListMemberships.listId': ExternalOptionTypes.INBOUND_DB_LIST,
    hs_analytics_first_touch_converting_campaign: ExternalOptionTypes.CAMPAIGN,
    hs_analytics_last_touch_converting_campaign: ExternalOptionTypes.CAMPAIGN,
    hs_persona: ExternalOptionTypes.PERSONA,
    hubspot_owner_id: ExternalOptionTypes.OWNER,
    hubspot_team_id: ExternalOptionTypes.TEAM,
    hs_all_accessible_team_ids: ExternalOptionTypes.TEAM
  },
  [CONTACT]: {
    associatedcompanyid: ExternalOptionTypes.COMPANY,
    '_inbounddbio.importid_': ExternalOptionTypes.INBOUND_DB_IMPORT,
    'formSubmissions.formId': ExternalOptionTypes.FORM,
    'listMemberships.listId': ExternalOptionTypes.LIST,
    'ilsListMemberships.listId': ExternalOptionTypes.INBOUND_DB_LIST,
    hs_analytics_first_touch_converting_campaign: ExternalOptionTypes.CAMPAIGN,
    hs_analytics_last_touch_converting_campaign: ExternalOptionTypes.CAMPAIGN,
    hs_persona: ExternalOptionTypes.PERSONA,
    hubspot_owner_id: ExternalOptionTypes.OWNER,
    hubspot_team_id: ExternalOptionTypes.TEAM,
    hs_all_accessible_team_ids: ExternalOptionTypes.TEAM,
    salesforcecampaignids: ExternalOptionTypes.SALESFORCE_CAMPAIGN
  },
  [DEAL]: {
    '_inbounddbio.importid_': ExternalOptionTypes.INBOUND_DB_IMPORT,
    'engagement.ownerId': ExternalOptionTypes.OWNER,
    'ilsListMemberships.listId': ExternalOptionTypes.INBOUND_DB_LIST,
    deal_currency_code: ExternalOptionTypes.MULTI_CURRENCY_CURRENCY_CODE,
    dealstage: ExternalOptionTypes.DEAL_STAGE,
    hs_persona: ExternalOptionTypes.PERSONA,
    hubspot_owner_id: ExternalOptionTypes.OWNER,
    hubspot_team_id: ExternalOptionTypes.TEAM,
    hs_all_accessible_team_ids: ExternalOptionTypes.TEAM,
    pipeline: ExternalOptionTypes.DEAL_PIPELINE
  },
  [ENGAGEMENT]: {
    hs_activity_type: ExternalOptionTypes.ACTIVITY_TYPE,
    hs_at_mentioned_owner_ids: ExternalOptionTypes.OWNER,
    hs_created_by: ExternalOptionTypes.USER,
    hs_call_disposition: ExternalOptionTypes.CALL_DISPOSITION,
    hs_meeting_outcome: ExternalOptionTypes.MEETING_OUTCOME,
    hubspot_team_id: ExternalOptionTypes.TEAM,
    hs_all_accessible_team_ids: ExternalOptionTypes.TEAM,
    hubspot_owner_id: ExternalOptionTypes.OWNER,
    hs_attendee_owner_ids: ExternalOptionTypes.OWNER
  },
  [LINE_ITEM]: {
    hs_line_item_currency_code: ExternalOptionTypes.MULTI_CURRENCY_CURRENCY_CODE
  },
  [QUOTE]: {
    hubspot_team_id: ExternalOptionTypes.TEAM,
    hs_all_accessible_team_ids: ExternalOptionTypes.TEAM
  },
  [TICKET]: {
    '_inbounddbio.importid_': ExternalOptionTypes.INBOUND_DB_IMPORT,
    'ilsListMemberships.listId': ExternalOptionTypes.INBOUND_DB_LIST,
    hs_pipeline_stage: ExternalOptionTypes.TICKET_STAGE,
    hs_pipeline: ExternalOptionTypes.TICKET_PIPELINE,
    hubspot_owner_id: ExternalOptionTypes.OWNER,
    hubspot_team_id: ExternalOptionTypes.TEAM,
    hs_all_accessible_team_ids: ExternalOptionTypes.TEAM
  },
  [VISIT]: {
    maxemployees: OptionTypes.MAX_EMPLOYEES,
    maxrevenue: OptionTypes.MAX_REVENUE,
    minemployees: OptionTypes.MIN_EMPLOYEES,
    minrevenue: OptionTypes.MIN_REVENUE
  }
};
export default PropertyNameToReferenceType;