const GenericValidationError = 'GenericValidationError';
const UNKNOWN_ERROR_MESSAGE = 'Unknown error occurred';
const UNKNOWN_ERROR = {
  message: UNKNOWN_ERROR_MESSAGE,
  status: 400
};
const MULTIPLE_ERRORS = {
  message: 'There were multiple errors fetching data',
  status: 400
};
const javascriptErrorFromValidationErrors = validationErrors => {
  if (!validationErrors || !validationErrors.length) {
    return null;
  }
  if (validationErrors.length > 1) {
    // Check if they're all the same. If so, return a more specific message
    // than the generic "multiple errors" error.
    const firstError = validationErrors[0];
    if (validationErrors.some(validationError => validationError.__typename !== firstError.__typename || validationError.message !== firstError.message)) {
      return MULTIPLE_ERRORS;
    }
  }
  const validationError = validationErrors[0];
  if (validationError.__typename === GenericValidationError) {
    return {
      message: validationError.message,
      status: 400
    };
  }
  return UNKNOWN_ERROR;
};

// this is just an arbitrary code that is extremely uncommonly used that we can use to implicitly know "ah, this meant no code"
const fallbackStatusCode = 888;

//handle the network error
const getStatusCode = apolloError => {
  return apolloError && apolloError.networkError && `status` in apolloError.networkError && apolloError.networkError.status !== undefined && apolloError.networkError.status || fallbackStatusCode;
};
export const extractStatusAndMessageFromRawResponse = ({
  rawData,
  rawError
}) => {
  const validationErrors = rawData && rawData.crmObjectsSearch && rawData.crmObjectsSearch.validationErrors;
  const validationError = validationErrors && javascriptErrorFromValidationErrors(validationErrors);
  if (!rawError && !validationError) {
    return null;
  }
  const rawErrorStatus = getStatusCode(rawError);

  //careful - a status can be 0 which is falsy
  const status = validationError ? validationError.status : rawErrorStatus;

  //validationErrors come back as `data` not `error` from the useQuery call
  //in the case of a validationError, rawError will be undefined - this is where we fallback to that message in that scenario
  const message = rawError && rawError.message || validationError && validationError.message || UNKNOWN_ERROR_MESSAGE;
  return {
    status,
    message
  };
};