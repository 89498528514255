'use es6';

import PortalIdParser from 'PortalIdParser';
const _portalId = PortalIdParser.get();
export const DEFAULT_PIPELINE_ID = `defaultPipelineId:${_portalId}`;
export const DEFAULT_TICKET_PIPELINE_ID = `defaultTicketPipelineId:${_portalId}`;
export const POST_IMPORT_VIDEO_VISIBILITY = `postImportVideoVisibility:${_portalId}`;
export const SEGMENTS_FOLDERS_LAST_VIEWED = `segmentsFoldersLastViewed:${_portalId}`;

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}