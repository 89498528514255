'use es6';

import { get, put, post, del } from 'crm_data/api/ImmutableAPI';
import { Map as ImmutableMap } from 'immutable';
const BASE_URL = `pipelines/v2/pipelines/TICKET`;
const uri = (pipelineId = '') => `${BASE_URL}/${pipelineId}`;
const getPipelineStageOptions = pipeline => {
  return pipeline.get('stages').map(stage => {
    return stage.merge({
      value: stage.get('stageId'),
      text: stage.get('label')
    });
  });
};
const parsePipelines = pipelines => {
  const pipelinesMap = pipelines.reduce((acc, pipeline) => {
    const pipelineId = pipeline.get('pipelineId');
    pipeline = pipeline.merge({
      stageOptions: getPipelineStageOptions(pipeline),
      value: pipelineId
    });
    return acc.set(pipelineId, pipeline);
  }, ImmutableMap());
  return pipelinesMap.sortBy(pipeline => pipeline.get('displayOrder'));
};
export function fetch() {
  return get(uri(), {
    includePermissions: true
  }).then(parsePipelines);
}
export function fetchByUUID(pipelineId) {
  return get(uri(pipelineId));
}
export function create(pipeline) {
  return post(uri(), pipeline);
}
export function update(pipeline) {
  return put(uri(pipeline.pipelineId), pipeline);
}
export function remove(pipelineId) {
  return del(uri(pipelineId));
}
export function reorderPipelines(pipelineIds) {
  return put(`${BASE_URL}/order`, pipelineIds);
}