import { DEAL_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useGetHasScope } from './useGetHasScope';
import { useGetHasGate } from './useGetHasGate';
import { useHasFeatureAccess, useObjectTypeSupportsFeature } from 'framework-app-settings-features-lib';
import { useBoardProps } from '../boardProps/useBoardProps';
import { useTagSettings } from '../frameworkAppSettings/useTagSettings';
const SCOPES_BY_OBJECT_TYPE_ID = {
  [DEAL_TYPE_ID]: ['crm-pipelines-deal-tag-read-pre-seat', 'crm-pipelines-deal-tag-read'],
  [TICKET_TYPE_ID]: ['crm-pipelines-ticket-tag-read', 'crm-pipelines-ticket-tag-read-pre-seat']
};
export const useHasTags = () => {
  var _tagSettings$accessSc, _tagSettings$accessGa, _tagSettings$enabled;
  const {
    loading: loadingScopes,
    hasScope
  } = useGetHasScope();
  const {
    loading: loadingGates
  } = useGetHasGate();
  const {
    objectTypeId
  } = useBoardProps();
  const tagSettings = useTagSettings();
  const accessScopes = (tagSettings === null || tagSettings === void 0 || (_tagSettings$accessSc = tagSettings.accessScopes) === null || _tagSettings$accessSc === void 0 ? void 0 : _tagSettings$accessSc.value) || [];
  const accessGates = (tagSettings === null || tagSettings === void 0 || (_tagSettings$accessGa = tagSettings.accessGates) === null || _tagSettings$accessGa === void 0 ? void 0 : _tagSettings$accessGa.value) || [];
  const hasTagsFeature = useObjectTypeSupportsFeature({
    accessScopes,
    accessGates
  });
  const hasTagsAccess = useHasFeatureAccess({
    accessScopes
  });
  const specialScopes = SCOPES_BY_OBJECT_TYPE_ID[objectTypeId];
  //just default false if still loading
  if (loadingScopes || loadingGates || tagSettings === null) {
    return false;
  }

  //if we have special scopes and we don't have one
  //return false
  if (specialScopes && !specialScopes.some(v => hasScope(v))) {
    return false;
  }
  const isTagsEnabled = (tagSettings === null || tagSettings === void 0 || (_tagSettings$enabled = tagSettings.enabled) === null || _tagSettings$enabled === void 0 ? void 0 : _tagSettings$enabled.value) || false;
  return Boolean(isTagsEnabled && hasTagsFeature && hasTagsAccess);
};