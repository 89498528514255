import { useCallback } from 'react';
import enviro from 'enviro';
import PortalIdParser from 'PortalIdParser';
import { DEAL_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useTriggerCSATSurvey } from '../../board/hooks/useTriggerCSATSurvey';
import { useSelectedObjectTypeId } from '../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
const QA_ID = 432; //https://app.hubspotqa.com/feedback-editor/100765651/satisfaction/432/survey
const PROD_ID = 1158; //https://app.hubspot.com/feedback-editor/53/satisfaction/1158/survey

export const useFlywheelDealCSATSurvey = () => {
  const objectTypeId = useSelectedObjectTypeId();
  const portalId = PortalIdParser.get();
  const isPortal53 = enviro.isProd() ? portalId === 53 : portalId === 99535353;
  const trigger = useTriggerCSATSurvey();
  return useCallback(() => {
    if (isPortal53 && objectTypeId === DEAL_TYPE_ID) {
      trigger(QA_ID, PROD_ID);
    }
  }, [isPortal53, objectTypeId, trigger]);
};