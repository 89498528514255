const removeTypename = obj => {
  const newObj = Object.assign({}, obj);
  for (const key in newObj) {
    if (key === '__typename') {
      delete newObj[key];
    } else if (typeof newObj[key] === 'object') {
      newObj[key] = removeTypename(newObj[key]);
    }
  }
  return newObj;
};

/**
 * Will take in a raw object from the response of crmObjectsSearch and do some cleanup
 * - remove the __typename field recursively (to abstract away the fact that it's a graphql object)
 * - convert the properties array to a map (helpful for preventing iterating the entire array when you want to find a property value)
 * - convert the objectId to a string (to better match types of framework-data-table and other surrounding libraries)
 * - convert null properties to undefined (to better match types from customer-data-properties)
 */
export const parseCrmObject = row => {
  var _row$properties;
  const objectWithNullPropertiesAsUndefined = Object.assign({}, removeTypename(row), {
    id: row.objectId.toString(),
    objectId: row.objectId.toString(),
    properties: ((_row$properties = row.properties) === null || _row$properties === void 0 ? void 0 : _row$properties.reduce((acc, propertyObject) => {
      const value = propertyObject.value;
      const propertyName = propertyObject.name;
      acc[propertyName] = {
        name: propertyName,
        value: value !== null && value !== void 0 ? value : undefined
      };
      return acc;
    }, {})) || {}
  });
  return objectWithNullPropertiesAsUndefined;
};