'use es6';

import { OrderedMap, List, fromJS } from 'immutable';
import ReferenceRecord from '../schema/ReferenceRecord';
import setIn from 'transmute/setIn';
import getIn from 'transmute/getIn';
export const getDisplayOrder = obj => {
  if (typeof obj.displayOrder === 'number') {
    return obj.displayOrder;
  } else {
    return -1;
  }
};
export const getIsHiddenByPipelinePermissions = pipeline => getIn(['permission', 'accessLevel'], pipeline) === 'HIDDEN';
const formatPipelineId = pipeline => {
  return String(pipeline.pipelineId);
};
const formatPipelineReference = pipeline => {
  return new ReferenceRecord({
    id: formatPipelineId(pipeline),
    label: pipeline.label,
    referencedObject: fromJS(pipeline),
    disabled: getIsHiddenByPipelinePermissions(pipeline)
  });
};
const sortPipelines = pipelines => pipelines.sort((pipelineA, pipelineB) => {
  const isAHidden = getIsHiddenByPipelinePermissions(pipelineA);
  const isBHidden = getIsHiddenByPipelinePermissions(pipelineB);
  if (isAHidden && !isBHidden) {
    return 1;
  } else if (!isAHidden && isBHidden) {
    return -1;
  }
  return getDisplayOrder(pipelineA) - getDisplayOrder(pipelineB);
});
const formatPipelinesWithPermissions = response => {
  const sortedPipelines = sortPipelines(List(response));

  // We need to override the displayOrder of all the pipelines, otherwise disabled
  // pipelines get sorted by that in the reference input component.
  return OrderedMap(sortedPipelines.map((pipeline, index) => [formatPipelineId(pipeline), setIn(['referencedObject', 'displayOrder'], index, formatPipelineReference(pipeline))]));
};
export default formatPipelinesWithPermissions;