import { CrmSearchFLPError } from './CrmSearchFLPError';
import { CrmSearchQueryStringTooLongError } from './CrmSearchQueryStringTooLongError';
import { CrmSearchUnknownError } from './CrmSearchUnknownError';
import { CrmSearchSortingOnFieldRestrictedError } from './CrmSearchSortingOnFieldRestrictedError';
import { extractStatusAndMessageFromRawResponse } from './extractStatusAndMessageFromRawResponse';
import { BaseCrmSearchError } from './BaseCrmSearchError';
export const createMaybeCrmSearchError = ({
  objectTypeId,
  rawData,
  rawError,
  crmSearchQueryObject
}) => {
  const maybeStatusAndMessage = extractStatusAndMessageFromRawResponse({
    rawData,
    rawError
  });
  if (!maybeStatusAndMessage) {
    return null;
  }
  const {
    message,
    status
  } = maybeStatusAndMessage;
  const error = new BaseCrmSearchError({
    message,
    status,
    objectTypeId,
    metadata: {
      rawError,
      rawData,
      crmSearchQueryObject
    }
  });
  return CrmSearchFLPError.tryFromError(error) || CrmSearchQueryStringTooLongError.tryFromError(error) || CrmSearchSortingOnFieldRestrictedError.tryFromError(error) || new CrmSearchUnknownError(error);
};
export const createMaybeBoardCrmSearchError = ({
  objectTypeId,
  rawData,
  rawError,
  crmSearchQueryObject
}) => {
  const rawDataAsCrmSearch = rawData && rawData.crmObjectsByPipelineStageSearch ? {
    crmObjectsSearch: {
      validationErrors: rawData.crmObjectsByPipelineStageSearch.flatMap(stageResults => stageResults.crmSearchPayload && stageResults.crmSearchPayload.validationErrors ? stageResults.crmSearchPayload.validationErrors : [])
    }
  } : undefined;
  return createMaybeCrmSearchError({
    objectTypeId,
    rawData: rawDataAsCrmSearch,
    rawError,
    crmSearchQueryObject
  });
};
export { BaseCrmSearchError, CrmSearchFLPError, CrmSearchQueryStringTooLongError, CrmSearchUnknownError };