'use es6';

import http from 'hub-http/clients/apiClient';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
import get from 'transmute/get';
import { Map as ImmutableMap, fromJS } from 'immutable';
const BASE_URL = 'automation/v3/workflows';
const formatWorkflows = formatToReferencesList({
  getId: get('id'),
  getLabel: get('name')
});
export const createGetWorkflowsByIds = ({
  httpClient
}) => ids => {
  return httpClient.put(`${BASE_URL}/batch`, {
    data: ids
  }).then(get('workflows')).then(formatWorkflows);
};
export const getWorkflowsByIds = createGetWorkflowsByIds({
  httpClient: http
});
export const createGetWorkflowsSearchPage = ({
  httpClient
}) => props => {
  const {
    count,
    offset,
    query
  } = props.toJS();
  return httpClient.get(`${BASE_URL}/search`, {
    query: {
      limit: count,
      offset: offset || 0,
      q: query
    }
  }).then(response => {
    const hasMore = false;
    const responseCount = response.length;
    const responseOffset = 0;
    const total = response.length;
    const results = formatWorkflows(fromJS(response));
    return ImmutableMap({
      hasMore,
      offset: responseOffset,
      count: responseCount,
      total,
      results
    });
  });
};
export const getWorkflowsSearchPage = createGetWorkflowsSearchPage({
  httpClient: http
});
export const createGetAllWorkflows = ({
  httpClient
}) => () => httpClient.get(`${BASE_URL}/?stats=false&property=name&property=id&property=enabled`).then(results => formatToReferencesList({
  getId: get('id'),
  getLabel: get('name')
}, results.workflows));
export const getAllWorkflows = createGetAllWorkflows({
  httpClient: http
});