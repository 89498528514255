import { createSlice } from '@reduxjs/toolkit';
export const propsSlice = createSlice({
  name: 'props',
  initialState: {
    props: {}
  },
  reducers: {
    setProps: (state, action) => {
      state.props = Object.assign({}, action.payload);
    }
  }
});