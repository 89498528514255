import { PageDataQueryError } from './PageDataQueryError';
import Raven from 'raven-js';
export const trackPageDataQueryError = (error, objectTypeId) => {
  try {
    console.error(error);
    const pageDataQueryError = new PageDataQueryError(error, objectTypeId);
    Raven.captureException(pageDataQueryError, {
      tags: {
        pageDataQueryError: 'true'
      },
      extra: pageDataQueryError.getExtra()
    });
  } catch (ex) {
    console.error(ex);
    Raven.captureException(ex);
  }
};