import { useMemo } from 'react';
import { CONTACT_TYPE_ID, COMPANY_TYPE_ID, ObjectTypeFromIds } from 'customer-data-objects/constants/ObjectTypeIds';
// @ts-expect-error untyped
import { canBETCreate } from 'customer-data-objects/permissions/BETPermissions';
import { useScopes } from '../../auth/hooks/useScopes';
import { useSelectedObjectTypeId } from '../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
export const useCanBETImport = () => {
  const scopes = useScopes();
  const objectTypeId = useSelectedObjectTypeId();
  return useMemo(() => {
    return [CONTACT_TYPE_ID, COMPANY_TYPE_ID].includes(objectTypeId) && canBETCreate(scopes, ObjectTypeFromIds[objectTypeId] || objectTypeId);
  }, [objectTypeId, scopes]);
};