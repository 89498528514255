import { useCallback } from 'react';
import { usePropertyDefinitions } from './usePropertyDefinitions';
export const useGetProperty = () => {
  const data = usePropertyDefinitions();
  return useCallback(propertyName => {
    if (!data) {
      return null;
    }
    if (data.has(propertyName)) {
      return data.get(propertyName).property;
    }
    return null;
  }, [data]);
};