import { RECORD_CARD_FETCH_FAILED, RECORD_CARD_FETCH_STARTED, RECORD_CARD_FETCH_SUCCEEDED } from './recordCardsActionTypes';
import { getRecordCard } from '../api/recordCardsAPI';
export const getRecordCardAction = ({
  objectTypeId,
  pipelineId,
  hasCardCustomizationPerPipeline
}) => dispatch => {
  dispatch({
    type: RECORD_CARD_FETCH_STARTED,
    payload: {
      objectTypeId,
      pipelineId
    }
  });
  return getRecordCard({
    objectTypeId,
    pipelineId: hasCardCustomizationPerPipeline ? pipelineId : null
  }).then(data => {
    dispatch({
      type: RECORD_CARD_FETCH_SUCCEEDED,
      payload: {
        objectTypeId,
        pipelineId,
        data
      }
    });
  }).catch(e => {
    dispatch({
      type: RECORD_CARD_FETCH_FAILED,
      payload: {
        objectTypeId,
        pipelineId
      }
    });
    throw e;
  });
};