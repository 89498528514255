export function rewriteObjectPropertiesAsMap(graphQLObject) {
  const properties = graphQLObject.properties || [];
  const clonedObject = Object.assign({}, graphQLObject, {
    properties: properties.reduce((acc, prop) => {
      // @ts-expect-error TODO fix after client-types migration
      acc[prop.name] = prop;
      return acc;
    }, {})
  });
  return clonedObject;
}