import { useFetchDefaultView } from './useFetchDefaultView';
export const useDefaultViewId = ({
  objectTypeId,
  viewId,
  skip
}) => {
  const {
    data
  } = useFetchDefaultView({
    objectTypeId,
    viewId,
    skip: Boolean(skip)
  });
  const defaultViewId = data ? data['defaultView'].id : undefined;
  return defaultViewId;
};