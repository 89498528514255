// @ts-expect-error untyped
import withGateOverride from 'crm_data/gates/withGateOverride';
import { useHasAllScopes } from '../../auth/hooks/useHasAllScopes';
import { useHasAllGates } from '../../auth/hooks/useHasAllGates';
import { useCrmTagAppSettingFieldValue } from '../../frameworkAppSettings/hooks/useCrmTagAppSettingFieldValue';
export const useHasObjectTags = () => {
  const enabled = useCrmTagAppSettingFieldValue('enabled');
  const gates = useCrmTagAppSettingFieldValue('accessGates');
  const scopes = useCrmTagAppSettingFieldValue('accessScopes');
  const hasAllGates = useHasAllGates();
  const hasAllScopes = useHasAllScopes();
  return Boolean(
  // Whether this object is enabled (this can likely be removed in the
  // future as FAS won't send back disabled entries)
  Boolean(enabled) && (
  // If there are no scopes, the object has no scope requirements. If there
  // are scopes, they must all be present.
  !scopes || hasAllScopes(...scopes)) && (
  // If there are no gates, the object has no gate requirements. If there
  // are gates, they must all be present.
  !gates || gates.every(gate => withGateOverride(gate, hasAllGates(gate)))));
};