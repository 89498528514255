import http from 'hub-http/clients/apiClient';
import quickFetch from 'quick-fetch';
import { getRecordCardKey } from '../../quickFetch';
import { Metrics } from '../../performance/metrics';
const BASE_URL = 'crm-record-cards/v1/pipeline-customization';
export const getRecordCard = ({
  objectTypeId,
  pipelineId
}) => {
  const url = pipelineId ? `${BASE_URL}/${objectTypeId}/pipeline/${pipelineId}` : `${BASE_URL}/${objectTypeId}/`;
  const fallbackRequest = () => http.get(url);
  const quickFetchKey = getRecordCardKey(objectTypeId, pipelineId);
  const earlyRequest = quickFetch.getRequestStateByName(quickFetchKey);
  const request = earlyRequest ? new Promise((resolve, reject) => {
    earlyRequest.whenFinished(response => {
      Metrics.counter('RECORD_CARD_PROPERTIES_FETCHED_FROM_QUICK_FETCH').increment();
      resolve(response);
      quickFetch.removeEarlyRequest(quickFetchKey);
    });
    earlyRequest.onError(() => {
      fallbackRequest().then(resolve, reject).catch(reject);
    });
  }) : fallbackRequest().then(v => {
    Metrics.counter('RECORD_CARD_PROPERTIES_FETCHED_FROM_API').increment();
    return v;
  });
  return request.then(d => d.propertyNames);
};