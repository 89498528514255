import Raven from 'raven-js';
import { getMostRecentlyUsedPageType } from '../../../app/utils/mostRecentlyUsedPageType';
import { parseObjectTypeIdFromPath } from '../../../quick-fetch/parseObjectTypeIdFromPath';
import { BOARD, LIST } from '../../../views/constants/PageType';
import { loadBoardWrapper, lowPriorityLoadBoardWrapper } from '../../board/components/AsyncBoardWrapper';
import { loadTable, lowPriorityLoadTable } from '../../../app/components/AsyncIndexPageTable';

/**
 * This function looks at the current pathname and makes a guess about which pageType is about to be loaded.
 * It also checks the most recently used pageType in localstorage. Using this information, it preloads
 * the code that will most likely be used on that path. This is only ever called in App when the rewrite is enabled.
 *
 * @param path - window.location.pathname. The default parameter is meant to be used in real code,
 * it is only provided for testing purposes.
 */
export const preloadCodeSplits = (path = window.location.pathname) => {
  const pathMatchesList = path.match(`/${LIST}`);
  const pathMatchesBoard = path.match(`/${BOARD}`);
  const objectTypeId = parseObjectTypeIdFromPath(path);
  const recentlyUsedPageType = getMostRecentlyUsedPageType(objectTypeId);
  if (pathMatchesBoard || !pathMatchesList && recentlyUsedPageType === BOARD) {
    loadBoardWrapper().catch(err => Raven.captureException(err));

    // Preload the table with low priority so it's ready if switched to
    lowPriorityLoadTable().catch(err => Raven.captureException(err));
  } else if (pathMatchesList || recentlyUsedPageType !== BOARD) {
    loadTable().catch(err => Raven.captureException(err));

    // Preload the board with low priority so it's ready if switched to
    lowPriorityLoadBoardWrapper().catch(err => Raven.captureException(err));
  }
};