import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { CRM_OBJECT_TABLE_SEARCH_QUERY } from './types';
const IDLE_CALLBACK_FALLBACK_TIMEOUT = 2000;
const executeAtLowerPriority = callback => {
  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(callback);
  } else {
    setTimeout(callback, IDLE_CALLBACK_FALLBACK_TIMEOUT);
  }
};

/**
 * Run `useQuery` with a given fetch policy, switching between 'no-cache' and
 * 'cache-first'. Once 'no-cache' completes, it will switch to 'cache-first'.
 * We have to run two useQuery to take advantage of the cache of the
 * 'cache-first' useQuery (couldn't get it to work with just one).
 */
const useRunQueryWithFetchPolicy = ({
  fetchPolicy,
  skip,
  pollInterval,
  crmSearchQueryObject,
  onSwitchFetchPolicy
}) => {
  const client = useApolloClient();
  const commonQueryOptions = {
    context: {
      noBatch: true
    },
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
    variables: crmSearchQueryObject,
    pollInterval
  };

  // Only one of the following queries is run. The fetchPolicy determines which
  // we run and which gets skipped.
  const skipFastQuery = skip || fetchPolicy === 'cache-first';
  const skipApolloQuery = skip || fetchPolicy === 'no-cache';

  // Only run when fetchPolicy is 'no-cache'
  const {
    loading: fastLoading,
    data: fastRawData,
    error: fastRawError,
    refetch: fastRefetch,
    startPolling: fastStartPolling,
    stopPolling: fastStopPolling,
    networkStatus: fastNetworkStatus
  } = useQuery(CRM_OBJECT_TABLE_SEARCH_QUERY, Object.assign({}, commonQueryOptions, {
    skip: skipFastQuery,
    onCompleted: response => {
      executeAtLowerPriority(() => {
        client.writeQuery({
          query: CRM_OBJECT_TABLE_SEARCH_QUERY,
          data: response,
          variables: crmSearchQueryObject
        });
        onSwitchFetchPolicy();
      });
    }
  }));

  // Only run when fetchPolicy is 'cache-first'
  const {
    loading: apolloLoading,
    data: apolloRawData,
    error: apolloRawError,
    refetch: apolloRefetch,
    startPolling: apolloStartPolling,
    stopPolling: apolloStopPolling,
    networkStatus: apolloNetworkStatus
  } = useQuery(CRM_OBJECT_TABLE_SEARCH_QUERY, Object.assign({}, commonQueryOptions, {
    skip: skipApolloQuery
  }));
  return useMemo(() => fetchPolicy === 'cache-first' ? {
    loading: apolloLoading,
    data: apolloRawData,
    error: apolloRawError,
    refetch: apolloRefetch,
    startPolling: apolloStartPolling,
    stopPolling: apolloStopPolling,
    networkStatus: apolloNetworkStatus
  } : {
    loading: fastLoading,
    data: fastRawData,
    error: fastRawError,
    refetch: fastRefetch,
    startPolling: fastStartPolling,
    stopPolling: fastStopPolling,
    networkStatus: fastNetworkStatus
  }, [fetchPolicy, fastLoading, fastNetworkStatus, fastRawData, fastRawError, fastRefetch, fastStartPolling, fastStopPolling, apolloLoading, apolloNetworkStatus, apolloRawData, apolloRawError, apolloRefetch, apolloStartPolling, apolloStopPolling]);
};
export const useFastCrmObjectsSearch = ({
  crmSearchQueryObject,
  pollInterval,
  skip,
  initialFetchPolicy = 'cache-first'
}) => {
  const [fetchPolicy, setFetchPolicy] = useState(initialFetchPolicy);
  const handleSwitchFetchPolicy = useCallback(() => {
    setFetchPolicy('cache-first');
  }, []);
  return useRunQueryWithFetchPolicy({
    fetchPolicy,
    skip,
    pollInterval,
    crmSearchQueryObject,
    onSwitchFetchPolicy: handleSwitchFetchPolicy
  });
};