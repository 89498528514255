'use es6';

import { Map as ImmutableMap } from 'immutable';
import { defineFactory } from 'general-store';
import dispatcher from 'dispatcher/dispatcher';
import { TICKET_PIPELINES_FETCH_SUCCEEDED, PIPELINE_UPDATED, PIPELINE_DELETED } from 'crm_data/actions/ActionTypes';
import TicketsPipelinesStore from 'crm_data/tickets/TicketsPipelinesStore';
const parseStage = (stage, pipeline) => stage.merge({
  pipelineDisplayOrder: pipeline.get('displayOrder'),
  pipelineId: pipeline.get('pipelineId'),
  pipelineLabel: pipeline.get('label'),
  text: stage.get('label'),
  value: stage.get('stageId')
});
const TicketsPiplinesStagesStore = defineFactory().defineName('TicketsPiplinesStagesStore').defineGetInitialState(() => ImmutableMap()).defineGet((state, ticketPipelineStageId) => {
  if (state.size === 0) {
    TicketsPipelinesStore.get();
  }
  if (ticketPipelineStageId) {
    return state.get(ticketPipelineStageId);
  }
  return state;
}).defineResponseTo(TICKET_PIPELINES_FETCH_SUCCEEDED, (state, pipelines) => {
  pipelines.forEach(pipeline => pipeline.get('stages').forEach(stage => {
    state = state.set(stage.get('stageId'), parseStage(stage, pipeline));
  }));
  return state;
}).defineResponseTo(PIPELINE_UPDATED, (state, pipeline) => {
  const {
    value
  } = pipeline;
  if (!value) {
    return state;
  }
  const stages = value.get('stages').sortBy(stage => stage.get('displayOrder'));
  stages.forEach(stage => {
    state = state.set(stage.get('stageId'), parseStage(stage, value));
  });
  return state;
}).defineResponseTo(PIPELINE_DELETED, (state, pipelineId) => state.filter(stage => stage.get('pipelineId') !== pipelineId)).register(dispatcher);
export default TicketsPiplinesStagesStore;