import enviro from 'enviro';
import { useCallback } from 'react';
export function useTriggerCSATSurvey() {
  return useCallback((qaSurveyId, prodSurveyId) => {
    const surveyId = enviro.isProd() && prodSurveyId ? prodSurveyId : qaSurveyId;
    if (!('onHsFeedbackReady' in window)) {
      // if nothing is loaded yet, we can still set the queue property to an array of
      // feedback client callbacks, and when the script gets injected on the window,
      // it will read the queue and run them.

      // if the property is already on window, calling .push() will just run the
      // callback and load the survey immediately

      // we have to turn off typechecking for these lines, because the
      // "correct" TS way is to declare global with an extended Winow interface,
      // and we don't do that from within modules at HubSpot.

      // JS uses of this queue typically look like so:
      // window.onHsFeedbackReady = window.onHsFeedbackReady || []
      // window.onHsFeedbackReady.push(callback)

      window.onHsFeedbackReady = [];
    }
    window.onHsFeedbackReady.push(feedbackClient => {
      feedbackClient.loadSurvey('CSAT', surveyId);
    });
  }, []);
}