import { getSuperStoreValue } from '../../superstore/getSuperStoreValue';
import { setSuperStoreValue } from '../../superstore/setSuperStoreValue';
const getViewColumnWidthsSuperStoreKey = (objectTypeId, viewId) => `${objectTypeId}:${viewId}:columnWidths`;
export const loadViewColumnWidthsFromSuperStore = ({
  objectTypeId,
  viewId
}) => getSuperStoreValue(getViewColumnWidthsSuperStoreKey(objectTypeId, viewId));
export const setViewColumnWidthsInSuperStore = ({
  objectTypeId,
  viewId,
  value
}) => setSuperStoreValue(getViewColumnWidthsSuperStoreKey(objectTypeId, viewId), value);