export const ActionsDropdown = 'ActionsDropdown';
export const Banner = 'Banner';
export const PrimaryActionButton = 'PrimaryActionButton';
export const SecondaryActionButton = 'SecondaryActionButton';
export const QuickFilterProperties = 'QuickFilterProperties';
export const EmptyState = 'EmptyState';
export const ZeroQueryResultsState = 'ZeroQueryResultsState';
export const TertiaryActionButton = 'TertiaryActionButton';
export const DataWell = 'DataWell';
export const DataWellShowHideButton = 'DataWellShowHideButton';
const BehaviorTypes = {
  ActionsDropdown,
  Banner,
  PrimaryActionButton,
  SecondaryActionButton,
  QuickFilterProperties,
  EmptyState,
  ZeroQueryResultsState,
  TertiaryActionButton,
  DataWell,
  DataWellShowHideButton
};
export default BehaviorTypes;