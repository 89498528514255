import { useMemo } from 'react';
import { getRequiredProperties } from './RequiredProperties';
import { useProcessInputQuerySorts } from './useProcessInputQuerySorts';
import { makeApolloLikeResultType } from '../typeUtils';
import { useProcessInputQueryFilters } from './useProcessInputQueryFilters';
import { useObjectTypeDefinition } from './useObjectTypeDefinitions';
const useAddRequiredPropertiesToInputProperties = ({
  objectTypeId,
  inputProperties
}) => {
  const {
    data,
    loading,
    error
  } = useObjectTypeDefinition({
    objectTypeId
  });
  const requiredProperties = getRequiredProperties(objectTypeId);
  const properties = requiredProperties.concat(inputProperties);
  if (data) {
    const {
      pipelinePropertyName,
      pipelineStagePropertyName,
      primaryDisplayLabelPropertyName
    } = data;
    properties.push(pipelinePropertyName);
    properties.push(pipelineStagePropertyName);
    if (primaryDisplayLabelPropertyName) {
      properties.push(primaryDisplayLabelPropertyName);
    }
  }
  const uniqueProperties = new Set(properties);
  return {
    data: Array.from(uniqueProperties).sort(),
    loading,
    error
  };
};

/**
 * This hook is a util meant to allow consumers of crm search to apply sane business logic
 * to the crm search query object before it is sent to crmsearch
 *
 * Unless you are hitting crm search on your own, you should not be using this hook.
 * Instead please use useSensibleCrmObjectsSearchQuery
 *
 * It applies sane defaults such as:
 * - "Required properties"
 *   - Ex: If you are requesting deal amount, we'll also add deal currency
 * - Sort order
 *   - Ex: You are trying to sort on an enumeration property, you actually want to sort by the displayOrder
 * - Pipeline Permissions
 *   - Applies an exclusion filter to the query based on the pipelines that the user does not have access to.
 */
export const useGenerateCrmObjectsSearchQueryObject = ({
  inputQuery
}) => {
  const {
    data: sorts,
    loading: sortsLoading,
    error: sortsError
  } = useProcessInputQuerySorts({
    inputSorts: inputQuery.sorts,
    objectTypeId: inputQuery.objectTypeId
  });
  const {
    data: filterGroups,
    loading: filterGroupsLoading,
    error: filterGroupsError
  } = useProcessInputQueryFilters({
    inputFilters: inputQuery.filterGroups,
    objectTypeId: inputQuery.objectTypeId
  });
  const {
    data: properties,
    loading: propertiesLoading,
    error: propertiesError
  } = useAddRequiredPropertiesToInputProperties({
    objectTypeId: inputQuery.objectTypeId,
    inputProperties: inputQuery.properties
  });
  const data = useMemo(() => Object.assign({}, inputQuery, {
    properties,
    sorts,
    filterGroups: filterGroups || []
  }), [filterGroups, inputQuery, properties, sorts]);
  const loading = sortsLoading || filterGroupsLoading || propertiesLoading;
  const error = sortsError || filterGroupsError || propertiesError;
  return useMemo(() => makeApolloLikeResultType({
    data,
    loading,
    error
  }), [data, error, loading]);
};