import { useGenerateCrmObjectsSearchQueryObject } from 'crm-object-search-query-utilities/crmObjectSearch';
import { usePropertiesForSearch } from '../usePropertiesForSearch';
import { useProps } from '../../props/useProps';
import { useMemo } from 'react';
import { captureException, createBoardError } from '../../error/utils';
export const useLoadGeneratedQuery = query => {
  const {
    objectTypeId
  } = useProps();
  const properties = usePropertiesForSearch();
  const {
    data,
    loading,
    error
  } = useGenerateCrmObjectsSearchQueryObject({
    inputQuery: Object.assign({}, query, {
      filterGroups: query.filterGroups,
      objectTypeId,
      properties: properties || []
    })
  });
  return useMemo(() => {
    if (!loading && error) {
      const boardError = createBoardError('Failed to generate search query');
      captureException(boardError, error);
      //Generate a query failed, fall back to original query plus pipeline filter
      return {
        loading,
        error: undefined,
        data: Object.assign({}, query, {
          filterGroups: query.filterGroups,
          objectTypeId,
          properties: properties || []
        })
      };
    }
    return {
      data,
      loading,
      error
    };
  }, [data, loading, error, properties, objectTypeId, query]);
};