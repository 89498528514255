import { useMemo } from 'react';
import { useCurrentPipelineId } from './useCurrentPipelineId';
import { usePipelines } from '../context/PipelinesContext';
export const useCurrentPipeline = () => {
  const pipelines = usePipelines();
  const currentPipelineId = useCurrentPipelineId();
  const currentPipeline = useMemo(() => pipelines.find(({
    pipelineId
  }) => pipelineId === currentPipelineId), [currentPipelineId, pipelines]);
  return currentPipeline;
};