import { makeGetArePendingEditsForSpecificObjectInvalid } from './inlineEditingSlice';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
export const useArePendingEditsForSpecificObjectInvalid = ({
  objectTypeId,
  objectId
}) => {
  const selector = useMemo(() => makeGetArePendingEditsForSpecificObjectInvalid({
    objectTypeId,
    objectId
  }), [objectId, objectTypeId]);
  return useSelector(selector);
};