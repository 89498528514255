import { Metrics } from '../../lib/metrics/metrics';
import devLogger from 'react-utils/devLogger';
const SAFETY_TIMEOUT_MS = 2000;
const PRIORITY_MAPPING = {
  medium: ['mark_all_failure', 'mark_all_success', 'mark-table-mounted', 'WRAPPER_MOUNTED', 'mark-BoardLoaded', 'BOARD_MOUNT'],
  low: ['mark_all_failure', 'mark_all_success']
};

/**
 * Allows a loader to be deferred beyond just code-splitting. Consumers can
 * choose to wait for certain classes of performance markers to show before
 * being loaded. Current support is for high, medium, and low priority.
 *
 * low - load after success or failure marker
 * medium - load after aggregate visual mounts
 * high - load as a normal code-split
 *
 * @param loader - The LoadableLoader function to be executed.
 * @param priority - The priority level of the loader (high, medium, low).
 * @param FOR_TESTING - Do Not Use - Flag to bypass jasmine test for unit tests
 *
 * @returns A LoadableLoader that will resolve based on the specified priority.
 *
 * @see https://tools.hubteamqa.com/ui-library/docs/loadable
 */
export const priorityLoader = ({
  loader,
  priority: requestedPriority,
  FOR_TESTING = false
}) => {
  const priority = !FOR_TESTING && 'jasmine' in window ? 'high' : requestedPriority;
  if (priority === 'high') {
    Metrics.counter('priority-loader-loaded', {
      trigger: 'high-priority',
      priority
    }).increment();
    return loader;
  }
  if (!window.PerformanceObserver || !window.performance) {
    Metrics.counter('priority-loader-performance-observer-not-available').increment();
    return loader;
  }
  const markAlreadyFired = PRIORITY_MAPPING[priority].some(mark => window.performance.getEntriesByName(mark, 'mark').length);
  if (markAlreadyFired) {
    Metrics.counter('priority-loader-loaded', {
      trigger: 'already-fired',
      priority
    }).increment();
    return loader;
  }
  return () => {
    return new Promise(resolve => {
      let safetyTimeoutId = undefined;

      // eslint-disable-next-line compat/compat
      const observer = new window.PerformanceObserver(list => {
        const foundMarker = list.getEntries().some(entry => entry.entryType === 'mark' && PRIORITY_MAPPING[priority].includes(entry.name));
        if (foundMarker) {
          resolve({
            trigger: 'performance-marker',
            observer,
            safetyTimeoutId
          });
        }
      });
      observer.observe({
        entryTypes: ['mark']
      });
      safetyTimeoutId = setTimeout(() => {
        devLogger.warn({
          key: 'priority-loaded-safety',
          message: 'Priority loader safety timeout fired'
        });
        resolve({
          trigger: 'safety-timeout',
          observer,
          safetyTimeoutId
        });
      }, SAFETY_TIMEOUT_MS);
    }).then(({
      trigger,
      observer,
      safetyTimeoutId
    }) => {
      Metrics.counter('priority-loader-loaded', {
        priority,
        trigger
      }).increment();
      clearTimeout(safetyTimeoutId);
      observer.disconnect();
      return loader();
    }).catch(err => {
      Metrics.counter('priority-loader-loaded', {
        priority,
        trigger: 'catch'
      }).increment();
      console.error(err);
      return loader();
    });
  };
};
/**
 * A form of priorityLoader to be used in hooks to execute the loader according
 * to the specified priority.
 *
 * @param loader - The function to be executed.
 * @param priority - The priority level of the loader (high, medium, low).
 * @param FOR_TESTING - Do Not Use - Flag to bypass jasmine test for unit tests
 *
 * @returns A Promise<void> that will resolve based on the specified priority.
 *
 * @see priorityLoader
 *
 * @example
 * ```typescript
 * const myFunc = useCallback(() => {
 *   doSomethingLowPriority();
 *   doSomethingElseLowPriority();
 * }, []);
 *
 * useEffect(() => {
 *   priorityLoaderExec({
 *     loader: myFunc,
 *     priority: LOW,
 *   });
 * }, []);
 * ```
 */
export const priorityLoaderExec = ({
  loader,
  priority,
  FOR_TESTING
}) => {
  return priorityLoader({
    priority,
    loader: () => Promise.resolve(loader()),
    FOR_TESTING
  })();
};