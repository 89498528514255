import { getCurrentObjectTypeId } from '../../rewrite/init/selectors/routerStateSelectors';
import { BOARD as LEGACY_BOARD, INDEX as LEGACY_INDEX } from 'customer-data-objects/view/PageTypes';
import { BOARD } from '../constants/PageType';
import { createTypedFrozenSelector } from '../../rewrite/utils/createTypedFrozenSelector';
export const getViewsSlice = state => state.views;
export const getViewsData = createTypedFrozenSelector([getViewsSlice], ({
  data
}) => data);
export const getCachedViewsData = createTypedFrozenSelector([getViewsSlice], ({
  cachedData
}) => cachedData);
export const getCurrentViewId = createTypedFrozenSelector([getViewsSlice], ({
  currentViewId
}) => currentViewId);
export const getCurrentPageType = createTypedFrozenSelector([getViewsSlice], ({
  currentPageType
}) => currentPageType);
/**
 * Maps the current page type to the legacy page type (BOARD or INDEX). INDEX is renamed as 'list' nowadays.
 * @deprecated - Use the modern page type if possible
 */
export const getLegacyPageType = createTypedFrozenSelector([getCurrentPageType], pageType => pageType === BOARD ? LEGACY_BOARD : LEGACY_INDEX);
export const getViewsInState = createTypedFrozenSelector([getViewsData, getCurrentObjectTypeId], (views, objectTypeId) => views[objectTypeId] || {});
export const getCachedViews = createTypedFrozenSelector([getCachedViewsData, getCurrentObjectTypeId], (views, objectTypeId) => views[objectTypeId] || {});
export const getDeletedViewIds = createTypedFrozenSelector([getViewsSlice], ({
  deletedViewIds
}) => deletedViewIds);