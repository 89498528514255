import { useSelectedObjectTypeDef } from '../../../crmObjects/hooks/useSelectedObjectTypeDef';
import { useCurrentView } from '../../../views/hooks/useCurrentView';
import { getSortsFromView } from 'customer-data-views-management/crmSearch/utils/getSortsFromView';
import { usePojoProperties } from '../../properties/hooks/useProperties';
import { useMemo } from 'react';
import memoizeLast from 'transmute/memoizeLast';
const memoizedGetSortsFromView = memoizeLast(getSortsFromView);
export const useQuerySorts = () => {
  const typeDef = useSelectedObjectTypeDef();
  const view = useCurrentView();
  const properties = usePojoProperties();

  // For the way this uses properties, this is safe (and was what it was doing before)
  const propertiesAsProperties = properties;
  return useMemo(() => memoizedGetSortsFromView(view, typeDef, propertiesAsProperties), [typeDef, view, propertiesAsProperties]);
};