import { useEffect } from 'react';
import { SYNC_SELECTION_STATE } from '../constants/actionTypes';
import { createBulkActionsSelectionStateObject } from '../reducers/defaultBulkActionsReducer';
import { useBulkActionsDispatch } from './useBulkActionsDispatch';
import { useBulkActionsState } from './useBulkActionsState';
export const useBulkActionsSyncSelectionState = newState => {
  const dispatch = useBulkActionsDispatch();
  const state = useBulkActionsState();
  useEffect(() => {
    const newValue = createBulkActionsSelectionStateObject(newState);
    const currentStateValue = createBulkActionsSelectionStateObject(state.selection);
    if (JSON.stringify(currentStateValue) !== JSON.stringify(newValue)) {
      dispatch({
        type: SYNC_SELECTION_STATE,
        payload: newState
      });
    }
  }, [newState, state.selection, dispatch]);
};