import { useEffect } from 'react';
import { useFastDispatch } from '../context/FastContext';
import { handlersSlice } from './handlersStore';
export const useHandler = (key, handler) => {
  const dispatch = useFastDispatch();
  useEffect(() => {
    const kvp = {
      key,
      handler
    };
    dispatch(handlersSlice.actions.addHandler(kvp));
    return () => {
      dispatch(handlersSlice.actions.removeHandler(kvp));
    };
  }, [dispatch, handler, key]);
};