import { createTracker } from 'usage-tracker';
import events from '../events.yaml';
export const EVENTS = {
  TOUR_START: 'tourStart',
  TOUR_CLOSE: 'tourClose',
  TOUR_FINISH: 'tourFinish',
  TOUR_VIEW_STEP: 'tourViewStep',
  TOUR_INTERACTION: 'tourInteraction'
};
export const TOUR_INTERACTION_ACTIONS = {
  CLICK_EXTERNAL_STEP_LINK: 'click external step link',
  VIEW_CLOSE_MODAL: 'view close modal',
  VIEW_FINISH_MODAL: 'view finish modal'
};
export const UsageTracker = createTracker({
  events
});
export class TourUsageTracker {
  constructor(appName) {
    this.trackTourClose = (tourId, stepId) => {
      this.track(EVENTS.TOUR_CLOSE, {
        tourId,
        stepId
      });
    };
    this.trackTourFinish = tourId => {
      this.track(EVENTS.TOUR_FINISH, {
        tourId
      });
    };
    this.trackTourStart = tourId => {
      this.track(EVENTS.TOUR_START, {
        tourId
      });
    };
    this.trackTourViewStep = (tourId, stepId) => {
      this.track(EVENTS.TOUR_VIEW_STEP, {
        tourId,
        stepId
      });
    };
    this.trackTourInteraction = (tourId, stepId, action, value) => {
      this.track(EVENTS.TOUR_INTERACTION, {
        tourId,
        stepId,
        action,
        value
      });
    };
    this.trackTourClickExternalStepLink = (tourId, stepId) => {
      this.track(EVENTS.TOUR_INTERACTION, {
        tourId,
        stepId,
        action: TOUR_INTERACTION_ACTIONS.CLICK_EXTERNAL_STEP_LINK
      });
    };
    this.trackTourViewCloseModal = (tourId, stepId, value) => {
      this.track(EVENTS.TOUR_INTERACTION, {
        tourId,
        stepId,
        action: TOUR_INTERACTION_ACTIONS.VIEW_CLOSE_MODAL,
        value
      });
    };
    this.trackTourViewFinishModal = tourId => {
      this.track(EVENTS.TOUR_INTERACTION, {
        tourId,
        action: TOUR_INTERACTION_ACTIONS.VIEW_FINISH_MODAL
      });
    };
    this.appName = appName;
  }
  track(event, properties) {
    UsageTracker.track(event, Object.assign({
      appName: this.appName
    }, properties));
  }
}