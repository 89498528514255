import { produce } from 'immer';
import { APP_INIT } from '../../rewrite/init/actions/initActionTypes';
const initialState = {};
export const authReducer = produce((draft, action) => {
  switch (action.type) {
    case APP_INIT:
      {
        const {
          auth
        } = action.payload;
        return Object.assign({}, auth, {
          scopes: auth.user.scopes
        });
      }
    default:
      {
        return draft;
      }
  }
}, initialState);