import { useAppSettings } from '../../appSettings/hooks/useAppSettings';
import { useObjectTypeDefinitions } from '../../objectTypeDefinitions/hooks/useObjectTypeDefinitions';
import { getFallback } from '../fallbacks/getFallback';
import { get } from '../../utils/arrayUtils';
export const useObjectTypeAppSettings = (objectTypeId, appSettingName) => {
  const {
    data: appSettings,
    loading: appSettingsLoading
  } = useAppSettings([appSettingName]);
  const {
    data: typeDefs,
    loading: typeDefsLoading
  } = useObjectTypeDefinitions();
  if (appSettingsLoading || typeDefsLoading) {
    return {
      data: undefined,
      loading: true
    };
  }
  if (!appSettings || !typeDefs || !typeDefs[objectTypeId]) {
    return {
      data: undefined,
      loading: false
    };
  }
  const typeDef = typeDefs[objectTypeId];
  const appSettingsForObjectType = appSettings[typeDef.fullyQualifiedName];
  let appSetting = appSettingsForObjectType && appSettingsForObjectType[appSettingName];
  if (!appSetting) {
    const fallbackAppSettings = getFallback(objectTypeId);
    appSetting = fallbackAppSettings && get(appSettingName, fallbackAppSettings);
    if (!appSetting) {
      return {
        data: undefined,
        loading: false
      };
    }
  }
  const metadata = get('metadata', appSetting);
  if (!metadata) {
    return {
      data: undefined,
      loading: false
    };
  }
  return {
    data: metadata,
    loading: false
  };
};