/**
 * The following types are accepted by the backend as `referencedObjectType`s.
 *
 * https://git.hubteam.com/HubSpot/Properties/blob/master/PropertiesCommon/src/main/java/com/hubspot/properties/exceptions/PropertyInvalidException.java#L150
 */
export const OWNER = 'OWNER';

/**
 * The following types aren't supported as `referencedObjectType`s by the
 * backend, but we treat the as reference types on the front end because their
 * options are external to the property definition.
 */
export const ACTIVITY_TYPE = 'ACTIVITY_TYPE';
export const ADS_FACEBOOK_AD = 'ADS_FACEBOOK_AD';
export const ADS_FACEBOOK_ADGROUP = 'ADS_FACEBOOK_ADGROUP';
export const ADS_FACEBOOK_CAMPAIGN = 'ADS_FACEBOOK_CAMPAIGN';
export const ADS_GOOGLE_AD = 'ADS_GOOGLE_AD';
export const ADS_GOOGLE_ADGROUP = 'ADS_GOOGLE_ADGROUP';
export const ADS_GOOGLE_CAMPAIGN = 'ADS_GOOGLE_CAMPAIGN';
export const ADS_GOOGLE_KEYWORD = 'ADS_GOOGLE_KEYWORD';
export const ADS_LINKEDIN_AD = 'ADS_LINKEDIN_AD';
export const ADS_LINKEDIN_ADGROUP = 'ADS_LINKEDIN_ADGROUP';
export const ADS_LINKEDIN_CAMPAIGN = 'ADS_LINKEDIN_CAMPAIGN';
export const AUTOMATION_PLATFORM_WORKFLOWS = 'AUTOMATION_PLATFORM_WORKFLOWS';
export const BUSINESS_UNIT = 'BUSINESS_UNIT';
export const CALL_DISPOSITION = 'CALL_DISPOSITION';
export const CAMPAIGN = 'CAMPAIGN';
export const CHANNEL_INSTANCE = 'CHANNEL_INSTANCE';
export const COMPANY = 'COMPANY';
export const CONTACT = 'CONTACT';
export const CRM_OBJECT_TAG = 'CRM_OBJECT_TAG';
export const DEAL_PIPELINE = 'DEAL_PIPELINE';
export const DEAL_STAGE = 'DEAL_STAGE';
export const FORM = 'FORM';
export const GENERIC_CHANNEL = 'GENERIC_CHANNEL';
export const INBOUND_DB_IMPORT = '_inbounddbio.importid_';
export const INBOUND_DB_LIST = 'INBOUND_DB_LIST';
export const LIST = 'LIST';
export const MULTI_CURRENCY_CURRENCY_CODE = 'MULTI_CURRENCY_CURRENCY_CODE';
export const MEETING_OUTCOME = 'MEETING_OUTCOME';
export const OPTIMIZATION_TYPE = 'OPTIMIZATION_TYPE';
export const PERSONA = 'PERSONA';
export const SALESFORCE_CAMPAIGN = 'SALESFORCE_CAMPAIGN';
export const SEQUENCE = 'SEQUENCE';
export const TASK_QUEUE = 'TASK_QUEUE';
export const TEAM = 'TEAM';
export const TICKET_PIPELINE = 'TICKET_PIPELINE';
export const TICKET_STAGE = 'TICKET_STAGE';
export const TRACKED_TERM = 'TRACKED_TERM';
export const USER = 'USER';
export const PERMISSION_SET = 'PERMISSION_SET';
export const SEAT = 'SEAT';