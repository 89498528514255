'use es6';

import { isHubSpotter, isForceOpen, warn } from '../utils';
import translations from '../wootric-translations';
import * as UserAttributes from '../sources/UserAttributes';
const csatTokenQA = 'NPS-3489af5b';
const csatTokenProd = 'NPS-61a7bd63';
export const getConfig = (token, props = {}, surveyKey) => {
  const {
    field_required,
    permission_labels,
    customMessages: overrideMessages,
    complete,
    supported
  } = translations;
  const lang = supported[props.language] ? props.language : 'EN';

  // Wootric doesn't support translating custom messages for other languages:
  // http://docs.wootric.com/javascript/#custom-language-setting
  // So we have to build our own light translation setup here
  let wootricTarget;
  let customMessages = {};
  let translatedConfig = {};
  const surveysWithLegacyFollowUpQuestion = ['ACCOUNTS_AND_BILLING', 'WORKFLOWS', 'INBOX', 'FORECASTING', 'SOCIAL', 'MARKETING_EMAIL', 'FORMS', 'CAMPAIGNS', 'DEAL', 'ADS', 'TASKS', 'SEQUENCES', 'QUOTES', 'ANALYTICS', 'CHAT', 'DATA_SYNC', 'CRM_LISTS', 'CRM_SETTINGS', 'PAYMENTS', 'ADMIN_SETUP', 'ADMIN_GOVERNANCE', 'DATA_QUALITY'];
  if (complete[lang]) {
    const {
      csat_questions,
      followup_question,
      followup_question_nps,
      followup_thank_you,
      placeholder_text,
      wootric_recommend_target
    } = overrideMessages[lang];
    wootricTarget = wootric_recommend_target;
    customMessages = {
      followup_question: surveysWithLegacyFollowUpQuestion.includes(surveyKey) ? followup_question : followup_question_nps,
      followup_thank_you,
      placeholder_text
    };
    if (props['properties']) {
      if (csat_questions && csat_questions[surveyKey]) {
        customMessages.csat_question = csat_questions[surveyKey];
        props['properties']['question'] = customMessages.csat_question;
      }
      props['event_name'] = props['properties']['event'];
    }
  }
  translatedConfig = {
    field_required,
    permission_labels,
    wootric_recommend_target: wootricTarget
  };
  const wootricSettings = Object.assign({}, props, {
    account_token: token,
    ask_permission_to_share_feedback: true,
    // this generates the checkbox (pre-checked)
    modal_footprint: 'normal',
    callbacks: {
      after_show: config => {
        UserAttributes.touch('nav:seen');
        if (config && config.localized_texts && translations.complete[props.language]) {
          try {
            config.localized_texts.send = translations.customMessages[props.language].send;
          } catch (e) {
            warn(`Could not set localized "Send" text for language: ${props.language}; message: ${e.message}`);
          }
        }
      },
      after_decline() {
        UserAttributes.touch('nav:declined');
      },
      after_response() {
        UserAttributes.touch('nav:responded');
      }
    }
  }, translatedConfig);
  return {
    customMessages,
    wootricSettings
  };
};
export const configureCsat = (props, surveyKey) => {
  const token = isHubSpotter(props.email) ? csatTokenQA : csatTokenProd;
  if (isForceOpen()) {
    window.wootric_no_surveyed_cookie = true;
    console.debug('"wootric-nps-open" flag is set.', 'Wootric should be forced open.', 'Disregard the message it gives saying "remove survey immediately".');
  }

  // seconds to delay before opening (default 10)
  window.wootric_time_delay = 1;
  const {
    wootricSettings,
    customMessages
  } = getConfig(token, props, surveyKey);
  window.customMessages = customMessages;
  window.wootricSettings = wootricSettings;
};