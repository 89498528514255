'use es6';

import { CONTACT_TYPE_ID, COMPANY_TYPE_ID, DEAL_TYPE_ID, TICKET_TYPE_ID } from 'reference-resolvers/constants/ObjectTypeIds';
import { formatContactName } from 'reference-resolvers/formatters/formatContacts';
import always from 'transmute/always';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
const PROPERTIES = 'properties';
const VALUE = 'value';
export const defaultCustomObjectGetters = {
  getId: get('objectId'),
  getLabel: always(''),
  getArchived: record => getIn(['state', 'state'], record) === 'DELETED'
};

// crm-search API format
export const createCustomObjectGetter = property => getIn([PROPERTIES, property, VALUE]);
export const getDefaultGettersByObjectTypeId = objectTypeId => {
  switch (objectTypeId) {
    case CONTACT_TYPE_ID:
      return {
        getLabel: formatContactName
      };
    case COMPANY_TYPE_ID:
      return {
        getLabel: getIn(['properties', 'name', 'value'])
      };
    case DEAL_TYPE_ID:
      return {
        getLabel: getIn(['properties', 'dealname', 'value'])
      };
    case TICKET_TYPE_ID:
      return {
        getLabel: getIn(['properties', 'subject', 'value'])
      };
    default:
      return {};
  }
};