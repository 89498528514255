'use es6';

import { List } from 'immutable';
import { formatToReferencesList } from '../lib/formatReferences';
import http from 'hub-http/clients/apiClient';
import get from 'transmute/get';
import indexBy from 'transmute/indexBy';
import formatMultiCurrencyInformation from '../formatters/formatMultiCurrencyInformation';
const formatter = formatToReferencesList({
  getId: get('fromCurrencyCode'),
  getLabel: get('fromCurrencyCode')
});
export const createFetchAllCurrencies = ({
  httpClient
}) => () => httpClient.get('multi-currency/v1/currencies').then(result => List(result).filter(get('active')).filter(get('visible'))).then(response => indexBy(get('id'), formatter(response)));
export const fetchAllCurrencies = createFetchAllCurrencies({
  httpClient: http
});
export const createFetchMultiCurrencyInfo = ({
  httpClient
}) => () => httpClient.get('multi-currency/v1/currencies/information').then(formatMultiCurrencyInformation);
export const fetchMultiCurrencyInfo = createFetchMultiCurrencyInfo({
  httpClient: http
});