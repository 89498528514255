import { useMemo } from 'react';
import { registerQuery, useQuery } from 'data-fetching-client';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import { makeApolloLikeResultType } from '../typeUtils';
const ALL_PIPELINE__QUERY_NAME = 'crmObjectSearchQueryUtilitiesUsePipelines';
const fetcher = ({
  objectTypeId
}) => getFrameworkDataSchemasClient().then(client => {
  return client.pipelines.get({
    frameworkTypeIdentifier: objectTypeId
  }).catch(error => {
    var _error$responseJSON;
    if ((error === null || error === void 0 || (_error$responseJSON = error.responseJSON) === null || _error$responseJSON === void 0 ? void 0 : _error$responseJSON.subCategory) === 'PipelineError.OBJECT_TYPE_DOES_NOT_HAVE_PIPELINES') {
      return [];
    }
    throw error;
  });
});
const AllPipelinesQuery = registerQuery({
  fieldName: ALL_PIPELINE__QUERY_NAME,
  args: ['objectTypeId'],
  fetcher
});
export const usePipelines = ({
  objectTypeId,
  skip = false
}) => {
  const {
    data,
    loading,
    error
  } = useQuery(AllPipelinesQuery, {
    skip,
    variables: {
      objectTypeId
    }
  });
  const maybePipelines = data === null || data === void 0 ? void 0 : data[ALL_PIPELINE__QUERY_NAME];
  return useMemo(() => {
    return makeApolloLikeResultType({
      data: maybePipelines,
      loading,
      error
    });
  }, [maybePipelines, loading, error]);
};