import { useMemo } from 'react';
import { registerQuery, useQuery } from 'data-fetching-client';
import { APP_SETTINGS_TO_FETCH } from '../constants/AppSettings';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
const fieldName = 'crmIndexPageAppSettings';
const fetcher = () => {
  return getFrameworkDataSchemasClient().then(client => client.typeMetadata.get({
    appSettingNames: APP_SETTINGS_TO_FETCH
  }));
};
const getAppSettingQuery = registerQuery({
  fieldName,
  fetcher
});
export const useFetchAppSettings = () => {
  const {
    loading,
    error,
    data
  } = useQuery(getAppSettingQuery);
  return useMemo(() => ({
    data: data && data[fieldName],
    loading,
    error
  }), [data, error, loading]);
};