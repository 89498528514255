import { useSelector } from 'react-redux';
import { getHasAllGates, getGatesSet } from '../selectors/authSelectors';
import { useMemo } from 'react';
export const useHasAllGates = () => useSelector(getHasAllGates);
/**
 * @description This hook returns an array of all gates present for the user.
 * If you need to check if a user has a specific gate, use the `useHasAllGates` hook instead.
 */
export const useGetAllGates = () => {
  const gateSet = useSelector(getGatesSet);
  return useMemo(() => Array.from(gateSet), [gateSet]);
};