import pluck from 'transmute/pluck';
import { unique } from '../../utils/unique';
/** There are two rules we apply to columns:
 *
 * 1. The first column must be the primary display label
 * 2. The remaining columns must be filtered by isVisibleGridProperty
 */
export const applyColumnRules = ({
  columns,
  primaryDisplayLabelPropertyName,
  isVisibleGridColumnName
}) =>
// FIXME: refactor me with Set
unique([primaryDisplayLabelPropertyName,
// @ts-expect-error type error
...pluck('name', columns).filter(name => isVisibleGridColumnName(name))]).map(name => ({
  name
}));