import { AppRenderApplicationStart, AppStart, BeginningOfTime, HTMLEnd, HTMLStart } from '../../marks/standard/performanceMarks';

/**
{
  name: REQUIRED: String - Measurement name,
  start: REQUIRED: PerformanceMark,
  end: REQUIRED: PerformanceMark,
}
 */

export const AppStartToRenderApplication = {
  name: 'AppStartToRenderApplication',
  start: AppStart,
  end: AppRenderApplicationStart
};
export const EvaluateJS = {
  name: 'EvaluateJS',
  start: HTMLEnd,
  end: AppStart
};
export const LoadBaseJS = {
  name: 'LoadBaseJS',
  start: HTMLStart,
  end: HTMLEnd
};
export const LoadHTML = {
  name: 'LoadHTML',
  start: BeginningOfTime,
  end: HTMLStart
};
export const UntilAppStart = {
  name: 'UntilAppStart',
  start: BeginningOfTime,
  end: AppStart
};