// @ts-expect-error Untyped library
import Products from 'merchandise-lib/constants/Product';
const CRM_SUITE_STARTER_PRODUCTS = [Products.SALES_HUB_STARTER, Products.MARKETING_HUB_STARTER, Products.SERVICE_HUB_STARTER, Products.OPERATIONS_HUB_STARTER];

// Loops through a set os products and returns only the ones that are part of CRM Suite Starter
export const getRelevantOwnedProducts = allOwnedProducts => allOwnedProducts.filter(({
  productApiName
}) => CRM_SUITE_STARTER_PRODUCTS.includes(productApiName));
export const getCrmStarterBundle = allOwnedProducts => allOwnedProducts.find(({
  productApiName
}) => productApiName === Products.CRM_SUITE_STARTER_BUNDLE);

// Verify if a set of products have been purchased on the same date
export const hasPurchasedProductsAsBundle = relevantOwnedProducts => {
  let purchaseDate;
  const hasProductWithDifferentPurchaseDate = relevantOwnedProducts.find(product => {
    if (!product.productSubscription || !product.productSubscription.startDate) {
      return false;
    }
    const {
      productSubscription: {
        startDate: [year, month, day]
      }
    } = product;
    // The month used in Date constructor is 0-index based while the API return values that are 1-12
    const currentProductPurchaseDate = new Date(year, month - 1, day);
    if (!purchaseDate) {
      purchaseDate = currentProductPurchaseDate;
    } else {
      return purchaseDate.getTime() !== currentProductPurchaseDate.getTime();
    }
    return false;
  });
  return !hasProductWithDifferentPurchaseDate;
};

// Verify if a given product has been purchased in the last 3 months
export const hasPurchasedProductInTheLast3Months = product => {
  if (!product.productSubscription || !product.productSubscription.startDate) {
    return false;
  }
  const {
    productSubscription: {
      startDate: [year, month, day]
    }
  } = product;

  // The month used in Date constructor is 0-index based while the API return values that are 1-12
  const productPurchaseDate = new Date(year, month - 1, day);
  const threeMonthsAgo = new Date();
  // Change date to 3 months ago
  threeMonthsAgo.setDate(threeMonthsAgo.getDate() - 90);
  return productPurchaseDate > threeMonthsAgo;
};
export const hasPurchasedProductSinceGivenDate = (product, givenSinceDate) => {
  if (!product.productSubscription || !product.productSubscription.startDate) {
    return false;
  }
  const {
    productSubscription: {
      startDate: [year, month, day]
    }
  } = product;
  const productPurchaseDate = new Date(year, month - 1, day);
  return productPurchaseDate > givenSinceDate;
};

// Verifies if CRM Suite Starter has been purchased in the last 3 month
export const hasPurchasedCrmSuiteStarterLast3Months = allOwnedProducts => {
  const relevantOwnedProducts = getRelevantOwnedProducts(allOwnedProducts);

  // If the portal does not have all products from CRM Suite Starter
  if (relevantOwnedProducts.length !== CRM_SUITE_STARTER_PRODUCTS.length) {
    return false;
  }
  const crmSuiteStarterBundle = getCrmStarterBundle(allOwnedProducts);

  // Check if user has purchased a true bundle or all relevant products have been purchased on the same date
  const isBundle = crmSuiteStarterBundle || hasPurchasedProductsAsBundle(relevantOwnedProducts);
  if (!isBundle) {
    return false;
  }

  // Since all products have been purchased on the same date we can only check the first one
  const productForChecking = crmSuiteStarterBundle || relevantOwnedProducts[0];
  return hasPurchasedProductInTheLast3Months(productForChecking);
};