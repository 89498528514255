import { produce } from 'immer';
import { FAILED, PENDING, SUCCEEDED, UNINITIALIZED } from '../../constants/RequestStatus';
import { IRIS_FETCH_STARTED, IRIS_FETCH_SUCCEEDED, IRIS_FETCH_FAILED } from '../actions/irisActionTypes';
const initialState = {
  status: UNINITIALIZED,
  data: {}
};
export const irisReducer = produce((draft, action) => {
  switch (action.type) {
    case IRIS_FETCH_STARTED:
      {
        draft.status = PENDING;
        return draft;
      }
    case IRIS_FETCH_SUCCEEDED:
      {
        draft.data = action.payload.data;
        draft.status = SUCCEEDED;
        return draft;
      }
    case IRIS_FETCH_FAILED:
      {
        draft.status = FAILED;
        return draft;
      }
    default:
      return draft;
  }
}, initialState);