import { registerQuery, useQuery } from 'data-fetching-client';
import { getTotalRecords } from '../api/crmObjectTotals';
// when total is 95% of limit, recheck bypassing the cache
const RECHECK_THRESHOLD = 95;
export const FETCH_TOTAL_COUNT_OF_OBJECTS_BY_OBJECT_TYPE = 'FETCH_TOTAL_COUNT_OF_OBJECTS_BY_OBJECT_TYPE';
const FetchTotalObjectCountByObjectType = registerQuery({
  fieldName: FETCH_TOTAL_COUNT_OF_OBJECTS_BY_OBJECT_TYPE,
  args: ['objectTypeId', 'limit', 'threshold'],
  fetcher: ({
    objectTypeId,
    limit,
    threshold
  }) => getTotalRecords({
    objectTypeId,
    limit,
    threshold
  })
});
export const useFetchTotalObjectCount = ({
  objectTypeId,
  limit,
  skip
}) => {
  return useQuery(FetchTotalObjectCountByObjectType, {
    variables: {
      objectTypeId,
      limit,
      threshold: RECHECK_THRESHOLD
    },
    skip
  });
};