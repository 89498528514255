import { retryerClient } from './retryerClient';
import { rethrowError } from 'UIComponents/core/PromiseHandlers';
export const BASE_URI = '/sync-health/v1';
export function fetchSyncHealthRecords(connectionIds, status, sortProperty, sortDirection, limit, searchQuery) {
  return retryerClient.get(`${BASE_URI}/record/list`, {
    query: {
      connectionIds,
      status,
      sortProperty,
      sortDirection,
      limit,
      searchQuery
    }
  }).then(response => response.results).catch(rethrowError);
}