import { useCallback, useEffect } from 'react';
import { useTableQueryAsync } from '../../table/hooks/useTableQuery';
import { useFlywheelDealCSATSurvey } from '../../../surveys/hooks/flywheelSurvey';
import { priorityLoaderExec } from '../../../loadable/lib/priorityLoader';
import Raven from 'raven-js';
export const useAfterSuccessActions = () => {
  const fetch = useTableQueryAsync();
  const triggerFlywheelDealCSATSurvey = useFlywheelDealCSATSurvey();
  const execute = useCallback(() => {
    triggerFlywheelDealCSATSurvey();
    fetch();
  }, [fetch, triggerFlywheelDealCSATSurvey]);
  useEffect(() => {
    priorityLoaderExec({
      loader: execute,
      priority: 'low'
    }).catch(err => {
      Raven.captureException(err);
    });
  }, [execute]);
};