import { useEffect, useState } from 'react';
import devLogger from 'react-utils/devLogger';
// eslint-disable-next-line no-restricted-imports
import userInfo, { userInfoSync } from 'hub-http/userInfo';
import { withNamespace } from '../../utils/withNamespace';
export const useUserInfo = () => {
  const [userInfoResponse, setUserInfoResponse] = useState(() => {
    // initialize with userInfoSync in case userInfo has already been loaded.
    // this avoids an initial render without gates/scopes, if possible.
    // userInfoSync throws an Error if userInfo has not finished loading yet;
    // in that case fall back to waiting for async userInfo
    try {
      return userInfoSync();
    } catch (__error) {
      devLogger.warn({
        message: withNamespace('userinfosync: Failure reading userinfo sync', {
          underscore: false
        }),
        key: withNamespace('userinfosync', {
          underscore: false
        })
      });
    }
    return undefined;
  });
  useEffect(() => {
    if (userInfoResponse) {
      return;
    }
    try {
      // ignoreRedirect is important! Without it, any consumers of this lib that don't
      // support or shim userInfo can end up redirecting (the default behavior).
      // This has caused past critsits:
      // https://git.hubteam.com/HubSpot/Critsit/issues/3557
      // https://git.hubteam.com/HubSpot/Critsit/issues/1040
      userInfo({
        ignoreRedirect: true
      }).then(response => {
        setUserInfoResponse(response);
      }).catch(() => {
        devLogger.warn({
          message: withNamespace('API failure fetching userinfo', {
            underscore: false
          }),
          key: withNamespace('userinfo-api', {
            underscore: false
          })
        });
      });
    } catch (err) {
      devLogger.warn({
        message: withNamespace('userinfo: Failure fetching userinfo', {
          underscore: false
        }),
        key: withNamespace('userinfo', {
          underscore: false
        })
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return userInfoResponse;
};