'use es6';

import http from 'hub-http/clients/apiClient';
import userInfo from 'hub-http/userInfo';
import BaseEmailConfirmBarLoader from '../lib/BaseEmailConfirmBarLoader';
import { getEmailVerificationEndpoint } from '../constants/EmailVerificationEndpoints';
class HapiJSPrebuilt extends BaseEmailConfirmBarLoader {
  getRequiredData() {
    return userInfo().then(({
      user
    }) => {
      return {
        email: user.email,
        isVerified: user.verified,
        userId: user.user_id
      };
    });
  }
  handleResend(id) {
    return http.post(getEmailVerificationEndpoint(id));
  }
}
export default HapiJSPrebuilt;