'use es6';

import http from 'hub-http/clients/apiClient';
import { Map as ImmutableMap } from 'immutable';
import formatPipelines from 'reference-resolvers/formatters/formatPipelines';
import formatPipelineStages from 'reference-resolvers/formatters/formatPipelineStages';
const formatResponse = response => {
  return ImmutableMap({
    pipelines: formatPipelines(response),
    stages: formatPipelineStages(response)
  });
};
export const createGetAllPipelines = ({
  httpClient
}) => () => httpClient.get('deals/v1/pipelines').then(formatResponse);
export const getAllPipelines = createGetAllPipelines({
  httpClient: http
});