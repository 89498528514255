'use es6';

import http from 'hub-http/clients/apiClient';
import { formatWorkflows } from 'reference-resolvers/formatters/formatWorkflows';
const BASE_URL = 'automationapps/v1/summaries';
const FLOW_SUMMARY_PAGE_LIMIT = 100;
export const createGetWorkflowsByIds = ({
  httpClient
}) => ids => {
  function getSummariesPage(offset = 0) {
    return httpClient.get(BASE_URL, {
      query: {
        flowIds: ids,
        offset,
        limit: FLOW_SUMMARY_PAGE_LIMIT
      }
    }).then(resp => {
      if (resp.hasMore) {
        return getSummariesPage(resp.offset).then(nextSummaries => {
          return resp.results.concat(nextSummaries);
        });
      }
      return resp.results;
    });
  }
  return getSummariesPage().then(formatWorkflows);
};
export const getWorkflowsByIds = createGetWorkflowsByIds({
  httpClient: http
});