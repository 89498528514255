'use es6';

import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
import { Map as ImmutableMap, List, fromJS } from 'immutable';
import formatName from 'I18n/utils/formatName';
const formatUserId = user => {
  return String(user.user_id);
};
const formatLabel = user => {
  const {
    firstName,
    lastName,
    email
  } = user;
  const fullName = formatName({
    firstName,
    lastName
  });
  if (fullName !== null && fullName.length > 0) {
    return fullName;
  }
  return email;
};
const formatUserReference = user => new ReferenceRecord({
  description: user.email,
  id: formatUserId(user),
  label: formatLabel(user),
  referencedObject: fromJS(user)
});
const formatDisabledUserReference = (user, disabled) => new ReferenceRecord({
  description: user.email,
  id: formatUserId(user),
  label: formatLabel(user),
  referencedObject: fromJS(user),
  disabled
});
export const formatUsersSearch = response => {
  return List(response.map(formatUserReference));
};
const normalizeResults = users => users.map(user => {
  return Object.assign({
    id: user.user_id,
    firstName: user.first_name,
    lastName: user.last_name
  }, user);
});
export const formatUsersSearchPaged = (response, allowList) => {
  const {
    hasMore,
    results,
    offset
  } = response;
  const normalized = normalizeResults(results);
  const users = allowList ? List(normalized.map(user => formatDisabledUserReference(user, !allowList.includes(user.id)))) : formatUsersSearch(normalized);
  return ImmutableMap({
    count: users.size,
    hasMore,
    offset: offset || 0,
    results: users
  });
};