const ONBOARDING_TOUR = 'ONBOARDING_TOUR';
const ONBOARDING_TOUR_DEBUG = `${ONBOARDING_TOUR}_DEBUG`;
const LOG_PREFIX = `${ONBOARDING_TOUR} - `;
function isOnboardingTourDebugEnabled() {
  try {
    return localStorage.getItem(ONBOARDING_TOUR_DEBUG) === 'true';
  } catch (error) {
    /* noop */
  }
  return false;
}
export function debug(debugMessage, ...args) {
  if (isOnboardingTourDebugEnabled()) {
    console.debug(LOG_PREFIX, debugMessage, ...args);
  }
}