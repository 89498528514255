import { useCallback } from 'react';
import { useUserInfo } from './useUserInfo';
import memoizeOne from 'react-utils/memoizeOne';
const EMPTY_GATES = new Set();
const memoizedToGatesSet = memoizeOne(gates => new Set(gates));
export const useGetHasGate = () => {
  const userInfo = useUserInfo();
  const userInfoGates = !userInfo ? EMPTY_GATES : memoizedToGatesSet(userInfo.gates);
  return useCallback(gateToCheck => {
    return userInfoGates.has(gateToCheck);
  }, [userInfoGates]);
};