import { useMemo } from 'react';
import { useFetchPortalCurrencies } from './useFetchPortalCurrencies';
export function useIsPortalCurrency(objectTypeId) {
  const {
    portalCurrencyCodes,
    homeCurrencyCode,
    loading
  } = useFetchPortalCurrencies(objectTypeId);
  return useMemo(() => ({
    loading,
    isPortalCurrency: maybePortalCurrency => portalCurrencyCodes.includes(maybePortalCurrency) || maybePortalCurrency === homeCurrencyCode
  }), [homeCurrencyCode, loading, portalCurrencyCodes]);
}