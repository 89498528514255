import { BaseCrmSearchError } from './BaseCrmSearchError';
const QUERY_STRING_TOO_LONG_ERROR_MESSAGE_MARKER = 'Invalid query of length';
const QUERY_STRING_TOO_LONG_REGEX = new RegExp(`${QUERY_STRING_TOO_LONG_ERROR_MESSAGE_MARKER}`);
export class CrmSearchQueryStringTooLongError extends BaseCrmSearchError {
  constructor(error) {
    super(error);
    this.name = 'CrmSearchQueryStringTooLongError';
  }
  static isQueryStringTooLongError(errorMessage) {
    return Boolean(errorMessage.match(QUERY_STRING_TOO_LONG_REGEX));
  }
  static tryFromError(error) {
    if (CrmSearchQueryStringTooLongError.isQueryStringTooLongError(error.message)) {
      return new CrmSearchQueryStringTooLongError(error);
    }
    return null;
  }
}