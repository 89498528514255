'use es6';

import createSimpleCachedReferenceResolver from '../lib/createSimpleCachedReferenceResolver';
import * as CacheKeys from '../constants/CacheKeys';
import { fetchAllCurrencies, createFetchAllCurrencies } from '../api/MultiCurrencyApi';
export const createMultiCurrencyCurrencyCodeReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.MULTI_CURRENCY_CURRENCY_CODES,
  createFetchData: createFetchAllCurrencies,
  fetchData: fetchAllCurrencies
}, options));
export default createMultiCurrencyCurrencyCodeReferenceResolver();