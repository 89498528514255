'use es6';

/**
 * All action "namespaces" for the action types used in crm_data.
 * For examples, the name spaces of "SOMETHING_FETCH_STARTED" is "SOMETHING" and
 * should be declared here.
 *
 * These keys should be in _ABC order_.
 */
export const LEGACY_ACTIVE_OWNERS = 'LEGACY_ACTIVE_OWNERS';
export const LEGACY_OWNERS = 'LEGACY_OWNERS';
export const ACCOUNTING_EXTENSIONS = 'ACCOUNTING_EXTENSIONS';
export const ACCOUNTING_PRODUCTS = 'ACCOUNTING_PRODUCTS';
export const ACCOUNTING_PRODUCTS_SEARCH = 'ACCOUNTING_PRODUCTS_SEARCH';
export const ACCOUNTING_CUSTOMERS = 'ACCOUNTING_CUSTOMERS';
export const ACCOUNTING_INVOICES = 'ACCOUNTING_INVOICES';
export const ACCOUNTING_TERMS = 'ACCOUNTING_TERMS';
export const ACCOUNTS = 'ACCOUNTS';
export const ACTIVITY_TYPES = 'ACTIVITY_TYPES';
export const AGENDA = 'AGENDA';
export const ASSOCIATED_OPEN_OBJECTS = 'ASSOCIATED_OPEN_OBJECTS';
export const ASSOCIATIONS = 'ASSOCIATIONS';
export const ASSOCIATIONS_DEFINITIONS = 'ASSOCIATIONS_DEFINITIONS';
export const ASSOCIATIONS_FIRST_PAGE = 'ASSOCIATIONS_FIRST_PAGE';
export const BET_PORTAL_OBJECT_ASSOCIATIONS = 'BET_PORTAL_OBJECT_ASSOCIATIONS';
export const BET_SERVICE_OBJECT_ASSOCIATIONS = 'BET_SERVICE_OBJECT_ASSOCIATIONS';
export const BIDEN = 'BIDEN';
export const BRANDING = 'BRANDING';
export const CALLEE = 'CALLEE';
export const CALLING_ADMIN_SETTINGS = 'CALLING_ADMIN_SETTINGS';
export const CALL_DISPOSITIONS = 'CALL_DISPOSITIONS';
export const CALL_PROVIDERS = 'CALL_PROVIDERS';
export const CALL_TRANSCRIPTIONS = 'CALL_TRANSCRIPTIONS';
export const CAMPAIGNS = 'CAMPAIGNS';
export const COMMUNICATOR = 'COMMUNICATOR';
export const COMPANIES = 'COMPANIES';
export const COMPANY_CHILDREN = 'COMPANY_CHILDREN';
export const CRM_CONNECTED_ACCOUNTS = 'CRM_CONNECTED_ACCOUNTS';
export const CRM_OBJECTS = 'CRM_OBJECTS';
export const CRM_OBJECT_TYPES = 'CRM_OBJECT_TYPES';
export const CONTACTS = 'CONTACTS';
export const CONTENTS = 'CONTENTS';
export const CONVERSATIONS = 'CONVERSATIONS';
export const CREATOR_PROPERTIES = 'CREATOR_PROPERTIES';
export const CURRENT_OWNER_ID = 'CURRENT_OWNER_ID';
export const DEAL_AUTOMATION = 'DEAL_AUTOMATION';
export const DEAL_AUTOMATION_ACTION = 'DEAL_AUTOMATION_ACTION';
export const DEAL_PIPELINE_SEARCH = 'DEAL_PIPELINE_SEARCH';
export const DEAL_PIPELINES = 'DEAL_PIPELINES';
export const DEAL_STAGE_PROPERTIES = 'DEAL_STAGE_PROPERTIES';
export const DEALS = 'DEALS';
export const DECKS = 'DECKS';
export const DOUBLE_OPT_IN = 'DOUBLE_OPT_IN';
export const EMAIL_CAMPAIGNS = 'EMAIL_CAMPAIGNS';
export const EMAIL_INTEGRATIONS = 'EMAIL_INTEGRATIONS';
export const EMAIL_PROVIDER = 'EMAIL_PROVIDER';
export const EMAIL_PROVIDER_BY_DOMAIN = 'EMAIL_PROVIDER_BY_DOMAIN';
export const EMAIL_RECIPIENT_DELIVERABILTY = 'EMAIL_RECIPIENT_DELIVERABILTY';
export const EMAIL_SUBSCRIPTIONS = 'EMAIL_SUBSCRIPTIONS';
export const EMAIL_TRACKING_BY_TRACKER_KEY = 'EMAIL_TRACKING_BY_TRACKER_KEY';
export const EMAIL_TRACKING_BY_MESSAGE_ID = 'EMAIL_TRACKING_BY_MESSAGE_ID';
export const EMAIL_UNBOUNCE_EVENTS = 'EMAIL_UNBOUNCE_EVENTS';
export const EMAIL_UNBOUNCE = 'EMAIL_UNBOUNCE';
export const EMAIL_UNSUBSCRIBE_LINK = 'EMAIL_UNSUBSCRIBE_LINK';
export const EMAIL_TRACKING_SETTINGS = 'EMAIL_TRACKING_SETTINGS';
export const TIMELINE_COMMENT = 'TIMELINE_COMMENT';
export const ENGAGEMENTS = 'ENGAGEMENTS';
export const ENGAGEMENT_BODY = 'ENGAGEMENT_BODY';
export const ENROLLMENTS = 'ENROLLMENTS';
export const ES = 'ES';
export const FAVORITE_LISTS = 'FAVORITE_LISTS';
export const FAVORITE_VIEWS_USER = 'FAVORITE_VIEWS_USER';
export const FEEDBACK_SUBMISSIONS = 'FEEDBACK_SUBMISSIONS';
export const FIELD_LEVEL_PERMISSIONS = 'FIELD_LEVEL_PERMISSIONS';
export const FORMS = 'FORMS';
export const FOLLOW_PROFILE = 'FOLLOW_PROFILE';
export const GATES = 'GATES';
export const GENERIC_INBOX_SETTINGS = 'GENERIC_INBOX_SETTINGS';
export const GOTOWEBINAR_SETTINGS = 'GOTOWEBINAR_SETTINGS';
export const GRID_VIEW_SELECTION_EDITABLE_COUNT = 'GRID_VIEW_SELECTION_EDITABLE_COUNT';
export const HAS_EMAIL_ENGAGEMENTS = 'HAS_EMAIL_ENGAGEMENTS';
export const IMPORT_NAMES = 'IMPORT_NAMES';
export const INTEGRATION_NAME = 'INTEGRATION_NAME';
export const INSTALLED_APPS = 'INSTALLED_APPS';
export const IP = 'IP';
export const JOIN_ME_USER = 'JOIN_ME_USER';
export const LINE_ITEMS = 'LINE_ITEMS';
export const LINE_ITEMS_BULK = 'LINE_ITEMS_BULK';
export const LINKED_IN_NAVIGATOR = 'LINKED_IN_NAVIGATOR';
export const LIST_REFS = 'LIST_REFS';
export const LIST_USAGE = 'LIST_USAGE';
export const LISTS_HAS_STATIC = 'LISTS_HAS_STATIC';
export const LISTS_SEARCH = 'LISTS_SEARCH';
export const LISTS = 'LISTS';
export const LOCALSETTINGS = 'LOCALSETTINGS';
export const KNOWLEDGEARTICLES = 'KNOWLEDGEARTICLES';
export const MEETINGS_LINKS = 'MEETINGS_LINKS';
export const MEETINGS_USER = 'MEETINGS_USER';
export const MULTI_CURRENCY_SETTINGS = 'MULTI_CURRENCY_SETTINGS';
export const NEVER_LOG = 'NEVER_LOG';
export const NOTIFICATION_PREFERENCES = 'NOTIFICATION_PREFERENCES';
export const O365_ADDIN = 'O365_ADDIN';
export const OBJECT_CREATOR_PROPERTIES = 'OBJECT_CREATOR_PROPERTIES';
export const OBJECT_LISTS_SEARCH = 'OBJECT_LISTS_SEARCH';
export const OBJECT_PREVIEW = 'OBJECT_PREVIEW';
export const OUTLOOK_PLUGIN = 'OUTLOOK_PLUGIN';
export const OWNER_IDS_BY_EMAIL = 'OWNER_IDS_BY_EMAIL';
export const OWNER_IDS_BY_REMOTE_ID = 'OWNER_IDS_BY_REMOTE_ID';
export const OWNERS = 'OWNERS';
export const OWNERS_SEARCH = 'OWNERS_SEARCH';
export const PERSONAS = 'PERSONAS';
export const PINNED_ENGAGEMENT = 'PINNED_ENGAGEMENT';
export const PIPELINE_DETAILS = 'PIPELINE_DETAILS';
export const PIPELINE_STAGES = 'PIPELINE_STAGES';
export const PLAYBOOKS = 'PLAYBOOKS';
export const PORTAL_FULL = 'PORTAL_FULL';
export const PREDICTIVE_SCORE = 'PREDICTIVE_SCORE';
export const PRODUCTS_BY_ID = 'PRODUCTS_BY_ID';
export const PRODUCTS_SEARCH = 'PRODUCTS_SEARCH';
export const PRODUCTS_SEARCH_LOAD_MORE = 'PRODUCTS_SEARCH_LOAD_MORE';
export const PRODUCTS = 'PRODUCTS';
export const PROPERTIES = 'PROPERTIES';
export const PROSPECT_FAVORITES = 'PROSPECT_FAVORITES';
export const QUOTE_BY_ID = 'QUOTE_BY_ID';
export const QUOTES = 'QUOTES';
export const QUOTES_BY_DEAL_ID = 'QUOTES_BY_DEAL_ID';
export const QUOTES_SETTINGS = 'QUOTES_SETTINGS';
export const CONTENT_REGISTERED_CONTACTS = 'CONTENT_REGISTERED_CONTACTS';
export const RELATED_ITEMS_ASSOCIATIONS = 'RELATED_ITEMS_ASSOCIATIONS';
export const RESTRICTED_CONTENTS_BY_LIST = 'RESTRICTED_CONTENTS_BY_LIST';
export const RESTRICTED_CONTENTS_BY_CONTACT = 'RESTRICTED_CONTENTS_BY_CONTACT';
export const SALESFORCE_RATE_LIMIT = 'SALESFORCE_RATE_LIMIT';
export const SALESFORCE_INCLUSION_LIST = 'SALESFORCE_INCLUSION_LIST';
export const SEGMENT_FILTERS = 'SEGMENT_FILTERS';
export const SEGMENTS_CONTACT_MATCH_CHECK = 'SEGMENTS_CONTACT_MATCH_CHECK';
export const SEGMENTS_OBJECT_MATCH_CHECK = 'SEGMENTS_OBJECT_MATCH_CHECK';
export const SEGMENTS_DEPENDENT_WORKFLOWS = 'SEGMENTS_DEPENDENT_WORKFLOWS';
export const SEGMENTS_DEPENDENT_WORKFLOWS_ILS = 'SEGMENTS_DEPENDENT_WORKFLOWS_ILS';
export const SEGMENTS_FOLDERS = 'SEGMENTS_FOLDERS';
export const SEGMENTS_SEARCH = 'SEGMENTS_SEARCH';
export const SEGMENTS = 'SEGMENTS';
export const SETTINGS = 'SETTINGS';
export const SEQUENCE_CONTEXTUAL_AUTOMATION_BY_SEQUENCE_ID = 'SEQUENCE_CONTEXTUAL_AUTOMATION_BY_SEQUENCE_ID';
export const SEQUENCE_ENROLLMENT_LIMIT = 'SEQUENCE_ENROLLMENT_LIMIT';
export const SEQUENCE_ENROLLMENTS = 'SEQUENCE_ENROLLMENTS';
export const SEQUENCE_RESUME_PREVIEWS = 'SEQUENCE_RESUME_PREVIEWS';
export const SFDC_METADATA = 'SFDC_METADATA';
export const SFDC_OBJECT = 'SFDC_OBJECT';
export const SFDC_RATE_LIMIT = 'SFDC_RATE_LIMIT';
export const SIDEBAR_INTEGRATIONS = 'SIDEBAR_INTEGRATIONS';
export const SIDEKICK_SETTINGS = 'SIDEKICK_SETTINGS';
export const SIDEKICK_PORTAL = 'SIDEKICK_PORTAL';
export const SIGNED_FILES = 'SIGNED_FILES';
export const SNIPPETS = 'SNIPPETS';
export const SUBSCRIPTIONS = 'SUBSCRIPTIONS';
export const SUBSCRIPTIONS_BY_BRAND = 'SUBSCRIPTIONS_BY_BRAND';
export const SUBSCRIPTIONS_BY_EMAIL = 'SUBSCRIPTIONS_BY_EMAIL';
export const SUBSCRIPTION_DEFINITIONS = 'SUBSCRIPTION_DEFINITIONS';
export const UPDATED_SUBSCRIPTION_DEFINITIONS = 'UPDATED_SUBSCRIPTION_DEFINITIONS';
export const UPDATED_SUBSCRIPTION_STATUS = 'UPDATED_SUBSCRIPTION_STATUS';
export const UPDATED_SUBSCRIPTION_HISTORY = 'UPDATED_SUBSCRIPTION_HISTORY';
export const BRAND_DOMAINS = 'BRAND_DOMAINS';
export const SURVEY_MONKEY = 'SURVEY_MONKEY';
export const SURVEY_MONKEY_SETTINGS = 'SURVEY_MONKEY_SETTINGS';
export const SURVEY_MONKEY_INSTALLED = 'SURVEY_MONKEY_INSTALLED';
export const TASK_PIPELINE_SEARCH = 'TASK_PIPELINE_SEARCH';
export const TASKS = 'TASKS';
export const TEAMS = 'TEAMS';
export const TEAM_HIERARCHY = 'TEAM_HIERARCHY';
export const TEAM_OWNER = 'TEAM_OWNER';
export const TICKETS = 'TICKETS';
export const TICKET_AUTOMATION = 'TICKET_AUTOMATION';
export const TICKET_PIPELINE_SEARCH = 'TICKET_PIPELINE_SEARCH';
export const TICKET_PIPELINE_CONTENTS = 'TICKET_PIPELINE_CONTENTS';
export const TICKET_PIPELINES = 'TICKET_PIPELINES';
export const TICKET_STAGE_PROPERTIES = 'TICKET_STAGE_PROPERTIES';
export const TIMELINE_FILTERS = 'TIMELINE_FILTERS';
export const TIMELINE_INTEGRATION = 'TIMELINE_INTEGRATION';
export const USERS = 'USERS';
export const USERSETTINGS = 'USERSETTINGS';
export const USER_ATTRIBUTES = 'USER_ATTRIBUTES';
export const VISITS = 'VISITS';
export const WORKFLOWS = 'WORKFLOWS';
export const PAID_HUB = 'PAID_HUB';
export const PAID_USER = 'PAID_USER';
export const PORTAL_TRIAL_STATE = 'PORTAL_TRIAL_STATE';
export const VIDEO_CONFERENCE = 'VIDEO_CONFERENCE';
export const VIDYARD = 'VIDYARD';