'use es6';

import http from 'hub-http/clients/apiClient';
import { formatOwners } from 'reference-resolvers/formatters/formatOwners';
import formatUsers from 'reference-resolvers/formatters/formatUsers';
import { formatUsersV2Map } from 'reference-resolvers/formatters/formatUsersV2';
import { formatUsersSearchPaged } from 'reference-resolvers/formatters/formatUsersSearch';
import { Map as ImmutableMap } from 'immutable';
import get from 'transmute/get';
const formatResponse = response => {
  return ImmutableMap({
    owners: formatOwners(response),
    users: formatUsers(response)
  });
};
export const createGetAllUsers = ({
  httpClient
}) => () => httpClient.get('owners/v2/owners', {
  query: {
    contactsOnly: true
  }
}).then(formatResponse);
export const getAllUsers = createGetAllUsers({
  httpClient: http
});
export const createGetUsersByIds = ({
  httpClient
}) => ids => httpClient.put('users/v2/app/hub-users/bulk', {
  data: ids,
  query: {
    limit: ids.length
  }
}).then(get('userBriefs')).then(formatUsersV2Map);
export const getUsersByIds = createGetUsersByIds({
  httpClient: http
});
export const createFetchUsersSearchPage = ({
  httpClient
}) => props => {
  const {
    count,
    offset,
    query
  } = props.toJS();
  return httpClient.put('users-search/v1/users-search/app/search', {
    data: {
      limit: count,
      offset,
      searchQuery: query
    }
  });
};
export const fetchUsersSearchPage = createFetchUsersSearchPage({
  httpClient: http
});
export const createGetUsersSearchPage = options => {
  const getter = createFetchUsersSearchPage(options);
  return props => getter(props).then(formatUsersSearchPaged);
};
export const getUsersSearchPage = props => {
  return fetchUsersSearchPage(props).then(formatUsersSearchPaged);
};