import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryParams } from '../../../router/useQueryParams';
import { setLastAccessedView, updateUIState } from '../../../crm_ui/grid/utils/gridStateLocalStorage';
export const useSyncRouterValuesToCache = () => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'objectTypeId' does not exist on type '{}... Remove this comment to see the full error message
  const {
    objectTypeId,
    viewId
  } = useParams();
  const {
    query
  } = useQueryParams();
  useEffect(() => {
    setLastAccessedView({
      objectType: objectTypeId,
      viewId
    });
    updateUIState({
      objectType: objectTypeId,
      key: 'query',
      value: query
    });
  }, [objectTypeId, query, viewId]);
};