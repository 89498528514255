'use es6';

import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import { Map as ImmutableMap } from 'immutable';
import curry from 'transmute/curry';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
import { defaultCustomObjectGetters } from 'reference-resolvers/formatters/formatCustomObjects';
const BASE_URI = 'crm-search';
const SEARCH_API = `${BASE_URI}/search`;
const emptyQuery = ImmutableMap();
const defaultSearchOptions = {
  count: 100,
  offset: 0,
  query: '',
  requestOptions: {
    allPropertiesFetchMode: 'latest_version'
  },
  sorts: [{
    property: 'createdate',
    order: 'ASC'
  }]
};
const formatResults = curry((getters, response) => {
  const {
    hasMore,
    offset,
    total: count,
    results
  } = response;
  return ImmutableMap({
    hasMore,
    offset,
    count,
    results: formatToReferencesList(getters, results)
  });
});
const defaultIdsFilterInserter = (searchOptions, idsFilter) => {
  const filters = searchOptions && searchOptions.filterGroups && searchOptions.filterGroups[0] && searchOptions.filterGroups[0].filters || [];
  const filterGroups = [{
    filters: filters.concat(idsFilter)
  }];
  return Object.assign({
    count: idsFilter.values.length
  }, searchOptions, {
    filterGroups
  });
};
const createIdsFilter = (idPropName, ids) => ({
  property: idPropName,
  values: ids,
  operator: 'IN'
});
const createFetchByIdsSearchOptions = (searchOptions, idsFilterInserter, idPropName, ids) => idsFilterInserter(searchOptions, createIdsFilter(idPropName, ids));
const fetch = (httpClient, objectTypeId, {
  searchOptions,
  searchQueryParams,
  formatterOptions: {
    getters
  }
}, query = emptyQuery) => {
  const fullSearchQuery = Object.assign({
    portalId: PortalIdParser.get()
  }, defaultSearchOptions, searchOptions, query.toJS(), {
    objectTypeId
  });
  const referenceGetters = Object.assign({}, defaultCustomObjectGetters, getters);
  return httpClient.post(SEARCH_API, {
    query: searchQueryParams,
    data: fullSearchQuery
  }).then(formatResults(referenceGetters));
};
export const createFetchByIds = ({
  httpClient
}) => curry((objectTypeId, {
  searchOptions,
  searchQueryParams,
  formatterOptions,
  responseFormatter
}, {
  idPropName,
  idsFilterInserter = defaultIdsFilterInserter
}, ids) => {
  const fetchByIdSearchOptions = createFetchByIdsSearchOptions(searchOptions, idsFilterInserter, idPropName, ids);
  return fetch(httpClient, objectTypeId, {
    searchOptions: fetchByIdSearchOptions,
    searchQueryParams,
    formatterOptions
  }).then(response => {
    return responseFormatter ? responseFormatter(response) : response;
  });
});
export const fetchByIds = createFetchByIds({
  httpClient: http
});
export const createFetchSearchPage = ({
  httpClient
}) => curry((objectTypeId, {
  searchOptions,
  searchQueryParams,
  formatterOptions
}, query) => {
  return fetch(httpClient, objectTypeId, {
    searchOptions,
    searchQueryParams,
    formatterOptions
  }, query);
});
export const fetchSearchPage = createFetchSearchPage({
  httpClient: http
});