import { createFrozenSelector } from '../../utils/createFrozenSelector';
const getPaginationSlice = state => state.pagination;
export const getPage = createFrozenSelector([getPaginationSlice], ({
  page
}) => page);
export const getPageSize = createFrozenSelector([getPaginationSlice], ({
  pageSize
}) => pageSize);
export const getBoardPageSize = createFrozenSelector([getPaginationSlice], ({
  boardPageSize
}) => boardPageSize);