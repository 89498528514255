'use es6';

import { List, fromJS } from 'immutable';
import indexBy from 'transmute/indexBy';
import get from 'transmute/get';
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
const formatListId = list => String(list.listId);
const formatListReference = list => new ReferenceRecord({
  id: formatListId(list),
  label: list.name,
  referencedObject: fromJS(list)
});
const formatContactLists = contactLists => {
  const {
    lists
  } = contactLists;
  return indexBy(get('id'), List(lists).map(formatListReference));
};
export default formatContactLists;