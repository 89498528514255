// @ts-expect-error untyped
import { setupCsat } from 'wootric-nps/csat/csat-setup';

// Wootric is our CSAT system for feedback. It's loaded in the global Navigation bar.
// Whether the survey shows or not is controlled by Wootric. (Surveys are only shown
// once, only on certain pages, and only if users have not been recently shown other
// surveys to avoid fatigue.)

export const INDEX_CRM_SURVEY_ID = 'INDEX_CRM';
export const DEALBOARD_SURVEY_ID = 'DEAL';
export const SALES_EXECUTION_DEALS = 'SALES_EXECUTION_DEALS';
export const SALES_EXECUTION_DEAL_PIPELINE = 'SALES_EXECUTION_DEAL_PIPELINE';
export const PAYMENTS_SURVEY_ID = 'PAYMENTS';
export const PRODUCTS_SURVEY_ID = 'PRODUCTS';
export const COMMERCE_SUBSCRIPTIONS_SURVEY_ID = 'COMMERCE_SUBSCRIPTIONS';
export const INVOICES_SURVEY_ID = 'INVOICES';
export const triggerWootricSurvey = surveyKey => {
  try {
    setupCsat(surveyKey);
  } catch (e) {
    console.error(e);
    // Do nothing; wootric CSAT surveys are best-effort
  }
};
export const getIsRunningInSeleniumTest = () => {
  return !!window.navigator.webdriver;
};