import { registerMutation, registerQuery, useMutation, useQuery } from 'data-fetching-client';
import { getUserBoardSettings, setUserBoardSettings } from '../api/userBoardSettingsAPI';
import { useCallback, useMemo } from 'react';
import { useSetPreferredCurrency } from '../../multiCurrency/hooks/useSetPreferredCurrency';
const fetcher = ({
  objectTypeId
}) => getUserBoardSettings(objectTypeId);
const USER_BOARD_SETTING_FIELD = 'CRM_PIPELINES_USER_BOARD_SETTING_QUERY';
const getUserBoardSettingsQuery = registerQuery({
  fieldName: USER_BOARD_SETTING_FIELD,
  args: ['objectTypeId'],
  fetcher
});
const setUserBoardSettingsMutation = registerMutation({
  fieldName: USER_BOARD_SETTING_FIELD,
  fetcher: setUserBoardSettings
});
export const useUserBoardSettings = objectTypeId => {
  const {
    loading,
    error,
    data
  } = useQuery(getUserBoardSettingsQuery, {
    variables: {
      objectTypeId
    }
  });
  return useMemo(() => {
    if (!loading && data && !error) {
      return {
        data: data[USER_BOARD_SETTING_FIELD],
        loading
      };
    }
    return {
      data: null,
      loading
    };
  }, [data, error, loading]);
};
export const useSetUserBoardSettings = objectTypeId => {
  const updatePreferredCurrency = useSetPreferredCurrency();
  const [updateSettingsMutation] = useMutation(setUserBoardSettingsMutation, {
    optimisticResponse(vars) {
      return {
        [USER_BOARD_SETTING_FIELD]: Object.assign({}, vars.data)
      };
    },
    update(cache, {
      data
    }) {
      if (!data || !data[USER_BOARD_SETTING_FIELD]) {
        return;
      }
      const updated = data[USER_BOARD_SETTING_FIELD];
      const query = {
        query: getUserBoardSettingsQuery,
        variables: {
          objectTypeId
        }
      };
      cache.writeQuery(Object.assign({}, query, {
        data: {
          [USER_BOARD_SETTING_FIELD]: Object.assign({}, updated)
        }
      }));
    }
  });
  return useCallback(incoming => {
    return Promise.all([incoming.preferredDisplayCurrencyPreferences.preferredDisplayCurrency !== 'UNINITIALIZED' ? updatePreferredCurrency(incoming.preferredDisplayCurrencyPreferences) : Promise.resolve(), updateSettingsMutation({
      variables: {
        objectTypeId,
        data: incoming
      }
    })]);
  }, [updateSettingsMutation, objectTypeId, updatePreferredCurrency]);
};