'use es6';

import http from 'hub-http/clients/apiClient';
import { getUserInfo } from 'reference-resolvers/api/UserInfoAPI';
import { formatTaskQueuesReferenceList, formatTaskQueuesSearchResponse } from '../formatters/formatTaskQueues';
export const createGetTaskQueues = ({
  httpClient
}) => args => {
  return getUserInfo().then(({
    user: {
      user_id
    }
  }) => httpClient.post('engagements/v1/queues/search-definitions', {
    data: {
      offset: args ? args.get('offset', 0) : 0,
      limit: args ? args.get('limit', 10) : 10,
      mode: 'ALL_VISIBLE',
      query: args ? args.get('query', '') : '',
      userId: user_id
    }
  })).then(formatTaskQueuesSearchResponse);
};
export const getTaskQueues = createGetTaskQueues({
  httpClient: http
});
export const createGetTaskQueuesByIds = ({
  httpClient
}) => queueIdsArray => httpClient.get('engagements/v1/queues/batch', {
  query: {
    queueId: queueIdsArray,
    includeEngagements: false
  }
}).then(formatTaskQueuesReferenceList);
export const getTaskQueuesByIds = createGetTaskQueuesByIds({
  httpClient: http
});