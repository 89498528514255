import { getCanAccessPipeline } from '../utils/getCanAccessPipeline';
import { useMemo } from 'react';
import { usePipelines } from '../context/PipelinesContext';
import { useCurrentPageType } from '../../../views/hooks/useCurrentPageType';
import { BOARD } from '../../../views/constants/PageType';
import { ALL_PIPELINES_VALUE } from '../constants/AllPipelinesValue';
import get from 'transmute/get';
export const useFallbackPipelineId = () => {
  const pipelines = usePipelines();
  const pageType = useCurrentPageType();
  const isBoard = pageType === BOARD;
  const firstPipelineWithAccess = useMemo(() => pipelines.find(pipeline => getCanAccessPipeline(pipeline)), [pipelines]);

  // We must have a pipeline for the board page, so this code arbitrarily picks the
  // first pipeline the we have access to if we're on the board page without one.
  if (isBoard) {
    // result must be null rather than undefined because the result will be
    // stored in localstorage (JSON)
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'PipelineObject | undefined' is n... Remove this comment to see the full error message
    return get('pipelineId', firstPipelineWithAccess) || null;
  }
  return ALL_PIPELINES_VALUE;
};