import { useEffect } from 'react';
import PortalIdParser from 'PortalIdParser';
import { useSelectedObjectTypeId } from '../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { deleteGridState } from '../../crm_ui/grid/utils/gridStateLocalStorage';
import { useCurrentView } from '../../views/hooks/useCurrentView';
const portalId = PortalIdParser.get();

/* A custom hook that will call window.history.pushState with the /restore route every time someone clicks a link
 * This has the effect of "restoring" previous state when clicking the browser back button
 *
 * This enables the following flow:
 * - someone sets a quickfilter
 * - they click a contact name
 * - they click the browser back button
 * - they are taken back to the index page with the unsaved quickfilter still set
 *   - without useRestoreRouteInterject, this quickfilter would be gone
 */
export const useRestoreRouteInterject = args => {
  var _useCurrentView;
  const objectTypeId = useSelectedObjectTypeId();
  const viewId = (_useCurrentView = useCurrentView()) === null || _useCurrentView === void 0 ? void 0 : _useCurrentView.id;
  useEffect(() => {
    const beforeUnloadListener = () => {
      const newUrl = `/contacts/${portalId}/objects/${objectTypeId}/restore`;
      window.history.pushState({}, '', newUrl);
    };
    const eventType = args && args.___testing_only___eventType || 'beforeunload';
    window.addEventListener(eventType, beforeUnloadListener);
    return () => {
      window.removeEventListener(eventType, beforeUnloadListener);
    };
  }, [objectTypeId, args]);

  /* We want refreshing to reset all filters. Without this PerformanceObserver the filters get reset,
  but session storage still has the old state so if you navigate away through links/the browser back/forward
  buttons, you get the state from before refreshing.*/
  useEffect(() => {
    // eslint-disable-next-line compat/compat
    const observer = new PerformanceObserver(list => {
      list.getEntries().forEach(entry => {
        if (entry.type === 'reload') {
          deleteGridState({
            objectType: objectTypeId,
            viewId
          });
        }
      });
    });
    observer.observe({
      type: 'navigation',
      buffered: true
    });
  });
};