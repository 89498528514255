'use es6';

import { fetchSearchPage, createFetchSearchPage } from 'reference-resolvers/api/CrmSearchAPI';
import { getWorkflowsByIds, createGetWorkflowsByIds } from 'reference-resolvers/api/AutomationPlatformWorkflowsAPI';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { AUTOMATION_PLATFORM_FLOW_ID } from 'reference-resolvers/constants/ObjectTypeIds';
import { getId, getLabel } from 'reference-resolvers/formatters/formatAutomationPlatformWorkflows';
import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
const formatterOptions = {
  getters: {
    getId,
    getLabel
  }
};
export const createAutomationPlatformWorkflowReferenceResolver = (options, flowObjectTypeId = null, flowIsEnabled = null) => {
  const filters = [{
    operator: 'EQ',
    property: 'hs_flow_type',
    value: 'workflow'
  }, {
    operator: 'EQ',
    property: 'hs_is_deleted',
    value: 'false'
  }, {
    operator: 'EQ',
    property: 'hs_visible_source_app',
    value: 'true'
  }];
  if (flowIsEnabled !== null) {
    filters.push({
      operator: 'EQ',
      property: 'hs_enabled',
      value: flowIsEnabled
    });
  }
  if (flowObjectTypeId !== null) {
    filters.push({
      operator: 'EQ',
      property: 'hs_object_type_id',
      value: flowObjectTypeId
    });
  }
  const searchOptions = {
    filterGroups: [{
      filters
    }],
    requestOptions: {
      properties: ['hs_flow_id', 'hs_name']
    },
    sorts: [{
      property: 'hs_name',
      order: 'ASC'
    }]
  };
  return createBatchedReferenceResolver(Object.assign({
    cacheKey: CacheKeys.AUTOMATION_PLATFORM_WORKFLOWS,
    createFetchByIds: createGetWorkflowsByIds,
    createFetchSearchPage: opts => createFetchSearchPage(opts)(AUTOMATION_PLATFORM_FLOW_ID, {
      searchOptions,
      searchQueryParams: {},
      formatterOptions
    }),
    fetchByIds: getWorkflowsByIds,
    fetchSearchPage: fetchSearchPage(AUTOMATION_PLATFORM_FLOW_ID, {
      searchOptions,
      searchQueryParams: {},
      formatterOptions
    })
  }, options));
};
export default createAutomationPlatformWorkflowReferenceResolver();