'use es6';

import * as ReferenceObjectTypes from 'reference-resolvers/constants/ReferenceObjectTypes';
import BusinessUnitReferenceResolver from 'reference-resolvers/resolvers/BusinessUnitReferenceResolver';
import CallDispositionReferenceResolver from 'reference-resolvers/resolvers/CallDispositionReferenceResolver';
import CampaignReferenceResolver from 'reference-resolvers/resolvers/CampaignReferenceResolver';
import CompanyReferenceResolver from 'reference-resolvers/resolvers/CompanyReferenceResolver';
import ContactReferenceResolver from 'reference-resolvers/resolvers/ContactReferenceResolver';
import ContactByEmailReferenceResolver from 'reference-resolvers/resolvers/ContactByEmailReferenceResolver';
import EmailCampaignReferenceResolver from 'reference-resolvers/resolvers/EmailCampaignReferenceResolver';
import ImportNameReferenceResolver from 'reference-resolvers/resolvers/ImportNameReferenceResolver';
import IntegrationNameReferenceResolver from 'reference-resolvers/resolvers/IntegrationNameReferenceResolver';
import MultiCurrencyCurrencyCodeResolver from 'reference-resolvers/resolvers/MultiCurrencyCurrencyCodeResolver';
import MultiCurrencyInformationResolver from 'reference-resolvers/resolvers/MultiCurrencyInformationResolver';
import OwnerPagedReferenceResolver from 'reference-resolvers/resolvers/OwnerPagedReferenceResolver';
import PersonaReferenceResolver from 'reference-resolvers/resolvers/PersonaReferenceResolver';
import DealPipelinesReferenceResolver from 'reference-resolvers/resolvers/DealPipelinesReferenceResolver';
import PipelineStageReferenceResolver from 'reference-resolvers/resolvers/PipelineStageReferenceResolver';
import SalesforceCampaignReferenceResolver from 'reference-resolvers/resolvers/SalesforceCampaignReferenceResolver';
import TeamReferenceResolver from 'reference-resolvers/resolvers/TeamReferenceResolver';
import TicketPipelinesReferenceResolver from 'reference-resolvers/resolvers/TicketPipelinesReferenceResolver';
import TicketStagesReferenceResolver from 'reference-resolvers/resolvers/TicketStagesReferenceResolver';
import TaskQueuesReferenceResolver from 'reference-resolvers/resolvers/TaskQueuesReferenceResolver';
import UserReferenceResolver from 'reference-resolvers/resolvers/UserReferenceResolver';
import MarketingReasonReferenceResolver from 'reference-resolvers/resolvers/MarketingReasonReferenceResolver';
import DealPipelinesWithPermissionsResolver from 'reference-resolvers/resolvers/DealPipelinesWithPermissionsResolver';
import DealPipelineStagesWithPermissionsResolver from 'reference-resolvers/resolvers/DealPipelineStagesWithPermissionsResolver';
import TicketPipelinesWithPermissionsResolver from 'reference-resolvers/resolvers/TicketPipelinesWithPermissionsResolver';
import TicketPipelineStagesWithPermissionsResolver from 'reference-resolvers/resolvers/TicketPipelineStagesWithPermissionsResolver';
const RESOLVERS = {
  [ReferenceObjectTypes.BUSINESS_UNIT]: BusinessUnitReferenceResolver,
  // NOTE: no longer used by customer-data-properties, but consumers may still be relying on it being provided
  [ReferenceObjectTypes.CALL_DISPOSITION]: CallDispositionReferenceResolver,
  [ReferenceObjectTypes.CAMPAIGN]: CampaignReferenceResolver,
  [ReferenceObjectTypes.COMPANY]: CompanyReferenceResolver,
  [ReferenceObjectTypes.CONTACT]: ContactReferenceResolver,
  [ReferenceObjectTypes.CONTACT_BY_EMAIL]: ContactByEmailReferenceResolver,
  [ReferenceObjectTypes.DEAL_PIPELINE_STAGE]: PipelineStageReferenceResolver,
  [ReferenceObjectTypes.DEAL_PIPELINE]: DealPipelinesReferenceResolver,
  [ReferenceObjectTypes.EMAIL_CAMPAIGN]: EmailCampaignReferenceResolver,
  [ReferenceObjectTypes.IMPORT_NAME]: ImportNameReferenceResolver,
  [ReferenceObjectTypes.INTEGRATION_NAME]: IntegrationNameReferenceResolver,
  [ReferenceObjectTypes.MULTI_CURRENCY_CURRENCY_CODE]: MultiCurrencyCurrencyCodeResolver,
  [ReferenceObjectTypes.MULTI_CURRENCY_INFORMATION]: MultiCurrencyInformationResolver,
  [ReferenceObjectTypes.OWNER]: OwnerPagedReferenceResolver,
  [ReferenceObjectTypes.PERSONA]: PersonaReferenceResolver,
  // NOTE: no longer used by customer-data-properties, but consumers may still be relying on it being provided
  [ReferenceObjectTypes.MARKETING_REASON]: MarketingReasonReferenceResolver,
  [ReferenceObjectTypes.SALESFORCE_CAMPAIGN]: SalesforceCampaignReferenceResolver,
  [ReferenceObjectTypes.TEAM]: TeamReferenceResolver,
  [ReferenceObjectTypes.TICKET_PIPELINE]: TicketPipelinesReferenceResolver,
  [ReferenceObjectTypes.TICKET_STAGE]: TicketStagesReferenceResolver,
  [ReferenceObjectTypes.TASK_QUEUE]: TaskQueuesReferenceResolver,
  [ReferenceObjectTypes.USER]: UserReferenceResolver
};
export const getPropertyInputResolverCreators = ({
  includePipelinePermissions = false
} = {}) => includePipelinePermissions ? Object.assign({}, RESOLVERS, {
  [ReferenceObjectTypes.DEAL_PIPELINE]: DealPipelinesWithPermissionsResolver,
  [ReferenceObjectTypes.DEAL_PIPELINE_STAGE]: DealPipelineStagesWithPermissionsResolver,
  [ReferenceObjectTypes.TICKET_PIPELINE]: TicketPipelinesWithPermissionsResolver,
  [ReferenceObjectTypes.TICKET_STAGE]: TicketPipelineStagesWithPermissionsResolver
}) : RESOLVERS;