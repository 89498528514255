'use es6';

import { atom, reset, watchUnreferenced } from 'atom';
import identity from 'transmute/identity';
import invariant from 'react-utils/invariant';
import isFunction from 'transmute/isFunction';
import memoize from 'transmute/memoize';
import pipe from 'transmute/pipe';
export function bridgeAtom(createConnection) {
  const bridge = atom();
  const removeConnection = createConnection(nextValue => reset(bridge, nextValue));
  watchUnreferenced(bridge, removeConnection);
  return bridge;
}
export function enforceRemoveFunction(remove) {
  invariant(isFunction(remove), 'expected `getSubscription(...)` to return a function but got `%s`', remove);
  return remove;
}
export const makeKeyedResolver = ({
  idTransform = identity,
  valueTransform = identity
} = {}) => getSubscription => {
  const getAtom = memoize(id => {
    return bridgeAtom(next => {
      const validNext = pipe(valueTransform, next);
      const remove = enforceRemoveFunction(getSubscription(id, validNext));
      return () => {
        remove();
        getAtom.cache.remove(id);
      };
    });
  });
  const result = pipe(idTransform, getAtom);
  result.cache = getAtom.cache;
  return result;
};