import has from 'transmute/has';
import curry from 'transmute/curry';
/**
 * @deprecated Update using plain javascript syntax if possible. This function is not type safe.
 */
export const mutableSetIn = curry((keyPath, value, obj) => {
  keyPath.reduce((nestedObj, key, index) => {
    if (index === keyPath.length - 1) {
      nestedObj[key] = value;
    } else if (!has(key, nestedObj)) {
      nestedObj[key] = {};
    }
    return nestedObj[key];
  }, obj);
});