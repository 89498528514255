import { createTracker } from 'usage-tracker';
import events from '../../events.yaml';
export const usageTracker = createTracker({
  events
});
export const trackSubmit = stageName => {
  usageTracker.track('revenuestagechangesubmit', {
    stageName
  });
};
export const trackConditionalPropertiesPanelSubmit = () => {
  usageTracker.track('Interaction', {
    screen: `customer-data-properties`,
    subscreen: `conditional-properties-panel`,
    action: 'submit'
  });
};
export const trackAutosave = ({
  propertyName,
  objectTypeId,
  type
}) => {
  usageTracker.track('Interaction', {
    screen: `customer-data-properties`,
    action: 'autosave',
    propertyName,
    objectTypeId,
    type
  });
};
export const trackShowHighlySensitiveValueClick = ({
  propertyName,
  objectTypeId
}) => {
  usageTracker.track('Interaction', {
    screen: `customer-data-properties`,
    propertyName,
    objectTypeId,
    action: 'show highly sensitive value'
  });
};
export const trackRejectedReviveDealSubmit = () => {
  usageTracker.track('Interaction', {
    screen: `customer-data-properties`,
    action: 'revive rejected deal'
  });
};
export const logPageView = payload => {
  usageTracker.track('Pageview', Object.assign({}, payload, {
    screen: `customer-data-properties`
  }));
};