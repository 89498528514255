import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["label", "description"];
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getProperties } from '../selectors/propertiesSelectors';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import PropertyRecord from 'customer-data-objects/property/PropertyRecord';
import { Map as ImmutableMap } from 'immutable';
import { getMagicPropertiesForObjectType } from '../utils/getMagicPropertiesForObjectType';
import { getPermissionsForCurrentObjectType } from '../../fieldLevelPermissions/selectors/fieldLevelPermissionsSelectors';
import memoizeLast from 'transmute/memoizeLast';
import { CONTACT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';

// What usePojoProperties returns

export const maybeOverrideOptionsForPersona = (objectTypeId, property) => {
  if (objectTypeId !== CONTACT_TYPE_ID || !property || property.name !== 'hs_persona' || !property.options) {
    return {};
  }
  return {
    options: property.options.map(_ref => {
      let {
          label,
          description
        } = _ref,
        restOption = _objectWithoutPropertiesLoose(_ref, _excluded);
      return Object.assign({}, restOption, {
        label: description,
        description: label
      });
    })
  };
};

// HACK: We need to convert to a Map of PropertyRecords for compatibility with legacy UI code.
export const legacyFormatAdapter = memoizeLast((objectTypeId, magicProperties, properties, permissions) => {
  const propertyNames = properties ? Object.keys(properties) : [];
  const propertiesWithPermissions = {};

  // We are intentionally not using .reduce. Some portals have a lot of
  // properties and performance significantly suffers.
  for (let i = 0; i < propertyNames.length; i++) {
    const propertyName = propertyNames[i];
    propertiesWithPermissions[propertyName] = Object.assign({}, properties[propertyName], permissions[propertyName], maybeOverrideOptionsForPersona(objectTypeId, properties[propertyName]));
  }
  return Object.assign({}, magicProperties, propertiesWithPermissions);
});

/**
 * This is the same as useProperties but instead of returning immutable PropertyRecords, we instead return pojos
 */
export const usePojoProperties = () => {
  const objectTypeId = useSelectedObjectTypeId();
  const magicProperties = useMemo(() => getMagicPropertiesForObjectType(objectTypeId), [objectTypeId]);
  const properties = useSelector(getProperties);
  const permissions = useSelector(getPermissionsForCurrentObjectType);
  return legacyFormatAdapter(objectTypeId, magicProperties, properties, permissions);
};
const memoizedToImmutable = memoizeLast(pojoProperties => {
  return ImmutableMap(pojoProperties).map(PropertyRecord.fromJS);
});

// HACK: We need to convert to a Map of PropertyRecords for compatibility with legacy UI code.
export const useProperties = () => {
  const pojoProperties = usePojoProperties();
  return memoizedToImmutable(pojoProperties);
};