import { CONTACT_TYPE_ID, PRODUCT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useLimit } from 'crm-index-ui/auth/hooks/useLimit';
import { useMemo } from 'react';
import { useSelectedObjectTypeId } from 'crm-index-ui/objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { useSelector } from 'react-redux';
import { getLocallyCreatedObjectIdsForCurrentType, getLocallyDeletedObjectIdsForCurrentType } from 'crm-index-ui/rewrite/localMutations/selectors/localCrmObjectMutationsSelectors';
import { FETCH_TOTAL_COUNT_OF_OBJECTS_BY_OBJECT_TYPE, useFetchTotalObjectCount } from '../../crmSearch/hooks/useFetchTotalObjectCount';
export const PRODUCT_LIMIT_KEY = 'crm-products';
export const CONTACT_LIMIT_KEY = 'crm-contacts';
const LIMIT_KEY_BY_OBJECT_TYPE_ID = {
  [CONTACT_TYPE_ID]: CONTACT_LIMIT_KEY,
  [PRODUCT_TYPE_ID]: PRODUCT_LIMIT_KEY
};

/**
 * This hook does 3 main things:
 * 1. It checks the limit of unique records of the current object type
 * 2. It queries CRM search to find the total number of unique records of the current object type
 * 3. It compares the total against the limit
 *
 * Notes:
 * 1. If the total fetch is loading or fails, the limit is considered as not being reached. This is done
 * to prevent lock states from flashing on load, and to prevent the user from being locked out if the fetch
 * happens to fail. The downside of the user creating a few extra objects over the limit is miniscule.
 * 2. If there is no limit defined for the object type, the limit is considered as not being reached.
 * 3. If the user is not ungated for EnforceObjectLimits, this will always return limitReached: false.
 */
export function useObjectLimitAndTotal() {
  const objectTypeId = useSelectedObjectTypeId();
  const locallyCreatedObjectIds = useSelector(getLocallyCreatedObjectIdsForCurrentType);
  const locallyDeletedObjectIds = useSelector(getLocallyDeletedObjectIdsForCurrentType);
  const limitKey = LIMIT_KEY_BY_OBJECT_TYPE_ID[objectTypeId];
  const limit = useLimit(limitKey);
  const {
    loading,
    error,
    data: apiData
  } = useFetchTotalObjectCount({
    objectTypeId,
    limit: limit || 0,
    skip: !limitKey || limit === undefined
  });
  const total = apiData ? apiData[FETCH_TOTAL_COUNT_OF_OBJECTS_BY_OBJECT_TYPE].total : 0;

  // ensuring that local mutations impact the total
  const totalWithLocalMutations = total + locallyCreatedObjectIds.length - locallyDeletedObjectIds.length;
  const limitReached = (() => {
    if (!limitKey || limit === undefined) {
      return false;
    }
    if (loading || error) {
      return false;
    }
    return totalWithLocalMutations >= limit;
  })();
  return useMemo(() => ({
    loading,
    error,
    limit,
    limitReached,
    total: totalWithLocalMutations
  }), [loading, error, limit, limitReached, totalWithLocalMutations]);
}