'use es6';

import { makeAllResolver } from './strategy/AllStrategy';
import { makeIdResolver } from './strategy/IdStrategy';
import { makeSearchResolver } from './strategy/SearchStrategy';
import emptyFunction from 'react-utils/emptyFunction';
import invariant from 'react-utils/invariant';
import isObject from 'transmute/isObject';
import isString from 'transmute/isString';
export class ReferenceResolver {
  constructor(definition, name) {
    invariant(isObject(definition), 'expected `definition` to be an Object but got `%s`', definition);
    invariant(isString(name), 'expected `name` to be a string but got `%s`', name);
    this._name = name;
    const {
      all,
      byId,
      search,
      refreshCache = emptyFunction
    } = definition;
    this.all = all && makeAllResolver(all);
    this.byId = byId && makeIdResolver(byId);
    this.search = search && makeSearchResolver(search);
    this.refreshCache = refreshCache;
    this.getDefinition = () => definition;
  }
  toString() {
    return `${this._name}<${Object.keys(this.getDefinition()).join(',')}>`;
  }
}
export function isReferenceResolver(thing) {
  return thing instanceof ReferenceResolver;
}
export function makeReferenceResolver(definition, name = 'ReferenceResolver') {
  return new ReferenceResolver(definition, name);
}