import { useEffect, useMemo } from 'react';
import { useFallbackPipelineId } from './useFallbackPipelineId';
import { useViewPipeline } from './useViewPipeline';
import { BOARD } from '../../../views/constants/PageType';
import { useCurrentPageType } from '../../../views/hooks/useCurrentPageType';
import { ALL_PIPELINES_VALUE, ALL_PIPELINES_VALUE_FROM_QUERY_PARAM } from '../constants/AllPipelinesValue';
import { useCurrentPipeline } from './useCurrentPipeline';
import { useCurrentPipelineId } from './useCurrentPipelineId';
import { useDefaultPipeline } from './useDefaultPipeline';
import { useCurrentPipelineIdActions } from './useCurrentPipelineIdActions';
import { getCanAccessPipeline } from '../utils/getCanAccessPipeline';
import get from 'transmute/get';
import { useQueryParams } from '../../../router/useQueryParams';
import { usePipelineById } from './usePipelineById';
import { useNavigate } from '../../navigation/hooks/useNavigate';
import { trackPipelineSwitcherChanged } from '../../../tracking/indexPageTracking';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { useCurrentView } from '../../../views/hooks/useCurrentView';
export const getNextPipelineId = ({
  queryParamPipelineId,
  queryParamPipeline,
  currentPipeline,
  currentPipelineId,
  currentView,
  defaultPipeline,
  fallbackPipelineId,
  isBoard,
  viewPipeline
}) => {
  //the user is on the table and has an explicit ALL_PIPELINES url param set
  if (!isBoard && queryParamPipelineId === ALL_PIPELINES_VALUE_FROM_QUERY_PARAM) {
    return ALL_PIPELINES_VALUE;
  }

  //the user navigated to a url with pipelineId set explicitly
  if (queryParamPipeline && getCanAccessPipeline(queryParamPipeline)) {
    return get('pipelineId', queryParamPipeline);
  }

  //view has pipeline saved in state.pipelineId but view is not of type DEFAULT
  if (viewPipeline && (currentView && currentView.type !== 'DEFAULT' || queryParamPipelineId === ALL_PIPELINES_VALUE_FROM_QUERY_PARAM && isBoard) && getCanAccessPipeline(viewPipeline)) {
    return get('pipelineId', viewPipeline);
  }

  //load from localstorage
  if (defaultPipeline && getCanAccessPipeline(defaultPipeline)) {
    return get('pipelineId', defaultPipeline);
  }

  //load from redux state
  // this condition will only trigger if currentPipeline is a real pipeline
  if (currentPipeline && getCanAccessPipeline(currentPipeline)) {
    return get('pipelineId', currentPipeline);
  }

  // ALL_PIPELINES_VALUE is not a real pipeline and thus does not have data, but
  // it can be the value of currentPipelineId when on the table
  if (!isBoard && currentPipelineId === ALL_PIPELINES_VALUE) {
    return currentPipelineId;
  }
  return fallbackPipelineId;
};
export const useSyncPipelineId = () => {
  const pageType = useCurrentPageType();
  const objectTypeId = useSelectedObjectTypeId();
  const isBoard = pageType === BOARD;
  const {
    pipelineId: queryParamPipelineId
  } = useQueryParams();
  const queryParamPipeline = usePipelineById(queryParamPipelineId);
  const viewPipeline = useViewPipeline();
  const fallbackPipelineId = useFallbackPipelineId();
  const currentPipelineId = useCurrentPipelineId();

  // ALL_PIPELINES_VALUE does not have data, so in the case that currentPipelineId
  // is ALL_PIPELINES_VALUE, currentPipeline will be undefined
  const currentPipeline = useCurrentPipeline();
  const currentView = useCurrentView();
  const defaultPipeline = useDefaultPipeline();
  const nextPipelineId = useMemo(() => getNextPipelineId({
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | string[]' is not assignable to type... Remove this comment to see the full error message
    queryParamPipelineId,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Pipeline | undefined' is not assignabl... Remove this comment to see the full error message
    queryParamPipeline,
    currentPipeline,
    currentPipelineId,
    currentView,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Pipeline | undefined' is not assignabl... Remove this comment to see the full error message
    defaultPipeline,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    fallbackPipelineId,
    isBoard,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Pipeline | undefined' is not assignabl... Remove this comment to see the full error message
    viewPipeline
  }), [queryParamPipelineId, queryParamPipeline, currentPipeline, currentPipelineId, currentView, defaultPipeline, fallbackPipelineId, isBoard, viewPipeline]);
  const currentViewPipelineId = currentView.state.pipelineId || currentPipelineId;
  const doesViewPipelineMismatch = currentViewPipelineId !== nextPipelineId;
  const doesCurrentPipelineIdMismatch = currentPipelineId !== nextPipelineId;
  const shouldSync = !!queryParamPipelineId || doesViewPipelineMismatch || doesCurrentPipelineIdMismatch;
  const {
    changePipeline
  } = useCurrentPipelineIdActions();
  useEffect(() => {
    if (shouldSync) {
      changePipeline(nextPipelineId);
      trackPipelineSwitcherChanged({
        objectTypeId,
        pipelineId: nextPipelineId,
        type: 'automagic',
        pageType
      });
    }
  }, [changePipeline, nextPipelineId, shouldSync, objectTypeId, pageType]);
  const navigate = useNavigate();
  useEffect(() => {
    if (queryParamPipelineId) {
      navigate({
        query: {
          pipelineId: undefined
        }
      });
    }
  }, [navigate, queryParamPipelineId]);
  return !shouldSync;
};