'use es6';

import * as LocalSettings from 'crm_data/settings/LocalSettings';
import { Map as ImmutableMap } from 'immutable';
import { DEFAULT_PIPELINE_ID } from 'crm_data/settings/LocalSettingsKeys';
import { get, put, post, del, send } from 'crm_data/api/ImmutableAPI';
import { POST } from 'crm_data/constants/HTTPVerbs';
import { CONTACTS, CRM_UI } from 'customer-data-objects/property/PropertySourceTypes';
import { propertyLabelTranslator } from 'property-translator/propertyTranslator';
const BASE_URL = 'deals/v1';
const UPDATE_STAGE_URI = `${BASE_URL}/propertyupdates/multi/dealstage`;
const ORDER_API = `${BASE_URL}/pipelines/order`;
const uri = (pipelineId = '') => `${BASE_URL}/pipelines/${pipelineId}`;
const getPipelineStageOptions = pipeline => {
  return pipeline.get('stages').map(stage => stage.merge({
    value: stage.get('stageId'),
    text: propertyLabelTranslator(stage.get('label')) // unfortunately there is no way to distinguish hubspot-defined and custom stages, so translate all
  }));
};
const parsePipelines = pipelines => {
  const pipelinesMap = pipelines.reduce((acc, pipeline) => {
    pipeline = pipeline.merge({
      stageOptions: getPipelineStageOptions(pipeline),
      value: pipeline.get('pipelineId'),
      stages: pipeline.get('stages')
    });
    return acc.set(pipeline.get('pipelineId'), pipeline);
  }, ImmutableMap());

  // Ensure the pipelineId in localSettings still exists
  const defaultPipelineId = LocalSettings.getFrom(localStorage, DEFAULT_PIPELINE_ID);
  if (defaultPipelineId && !pipelinesMap.has(defaultPipelineId)) {
    LocalSettings.deleteFrom(localStorage, DEFAULT_PIPELINE_ID);
  }
  return pipelinesMap.sortBy(pipeline => pipeline.get('displayOrder'));
};
export function fetch() {
  return get(uri()).then(parsePipelines);
}
export function updateDealStages(pipeline) {
  return put(uri(pipeline.pipelineId), pipeline);
}
export function createPipeline(pipeline) {
  return post(uri(), pipeline);
}
export function deletePipeline(pipelineId) {
  return del(uri(pipelineId));
}
export function bulkUpdateDealStages(deltas) {
  // Deltas should look like
  // [{"newValue":"newValue","oldValue":"oldValue"}]
  const options = {
    type: POST,
    headers: {
      'X-Properties-Source': CONTACTS,
      'X-Properties-SourceId': CRM_UI
    }
  };
  return send(options, UPDATE_STAGE_URI, deltas);
}
export function reorderPipelines(pipelines) {
  return put(ORDER_API, pipelines);
}