import { registerQuery, useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
import { withCrmObjectBoardNamespace } from '../utils';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import { useBoardProps } from '../boardProps/useBoardProps';
import { useSyncError } from '../error/useSyncError';
const fetcher = ({
  objectTypeId,
  pipelineId
}) => getFrameworkDataSchemasClient().then(client => {
  return client.pipelines.getPipeline({
    frameworkTypeIdentifier: objectTypeId,
    pipelineId
  }).then(response => {
    return response;
  });
});
export const GET_PIPELINE_QUERY = withCrmObjectBoardNamespace('GET_PIPELINE');
const getPipelineQuery = registerQuery({
  fieldName: GET_PIPELINE_QUERY,
  args: ['objectTypeId', 'pipelineId'],
  fetcher
});
export const useLoadPipeline = () => {
  const {
    objectTypeId,
    pipelineId
  } = useBoardProps();
  const {
    loading,
    error,
    data
  } = useQuery(getPipelineQuery, {
    variables: {
      objectTypeId,
      pipelineId
    },
    skip: !objectTypeId || !pipelineId
  });
  useSyncError(error);
  return useMemo(() => {
    if (!loading && data && !error) {
      return {
        data: data[GET_PIPELINE_QUERY],
        loading
      };
    }
    return {
      data: null,
      loading
    };
  }, [data, error, loading]);
};