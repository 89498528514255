'use es6';

import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { CONTACT } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { createSearchObjects, searchObjects } from 'reference-resolvers/api/ContactSearchAPI';
import { createFetchByIds, fetchByIds } from 'reference-resolvers/api/ContactsV3API';
export const createContactReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.CONTACTS,
  createFetchByIds,
  createFetchSearchPage: opts => createSearchObjects(opts)(CONTACT),
  fetchByIds,
  fetchSearchPage: searchObjects(CONTACT)
}, options));
export default createContactReferenceResolver();