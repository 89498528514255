import memoizeOne from 'react-utils/memoizeOne';
import { CALL_TYPE_ID, COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_REGISTRATION_ID, DEAL_TYPE_ID, INVOICE_TYPE_ID, MARKETING_EVENT_TYPE_ID, MEETING_EVENT_TYPE_ID, TICKET_TYPE_ID, COMMERCE_PAYMENT_TYPE_ID, ORDER_TYPE_ID, CART_TYPE_ID, PRODUCT_TYPE_ID, SUBSCRIPTION_TYPE_ID, PARTNER_CLIENT_TYPE_ID, TASK_TYPE_ID, PARTNER_SERVICE_TYPE_ID, EMAIL_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { priorityLoader } from '../loadable/lib/priorityLoader';
const extensionConfigs = {
  [CONTACT_TYPE_ID]: () => priorityLoader({
    loader: () => import( /* webpackChunkName: "extensions-contact" */'./extensions/Contact').then(mod => mod.Contact),
    priority: 'medium'
  }),
  [COMPANY_TYPE_ID]: () => priorityLoader({
    loader: () => import( /* webpackChunkName: "extensions-company" */'./extensions/Company').then(mod => mod.Company),
    priority: 'medium'
  }),
  [DEAL_REGISTRATION_ID]: () => priorityLoader({
    loader: () => import( /* webpackChunkName: "extensions-deal-registration" */'./extensions/DealRegistration').then(mod => mod.DealRegistration),
    priority: 'medium'
  }),
  [DEAL_TYPE_ID]: () => priorityLoader({
    loader: () => import( /* webpackChunkName: "extensions-deal" */'./extensions/Deal').then(mod => mod.Deal),
    priority: 'medium'
  }),
  [TICKET_TYPE_ID]: () => priorityLoader({
    loader: () => import( /* webpackChunkName: "extensions-ticket" */'./extensions/Ticket').then(mod => mod.Ticket),
    priority: 'medium'
  }),
  [CALL_TYPE_ID]: () => priorityLoader({
    loader: () => import( /* webpackChunkName: "extensions-call" */'./extensions/Call').then(mod => mod.Call),
    priority: 'medium'
  }),
  [MARKETING_EVENT_TYPE_ID]: () => priorityLoader({
    loader: () => import( /* webpackChunkName: "extensions-marketing-event" */'./extensions/MarketingEvent').then(mod => mod.MarketingEvent),
    priority: 'medium'
  }),
  [MEETING_EVENT_TYPE_ID]: () => priorityLoader({
    loader: () => import( /* webpackChunkName: "extensions-meeting-event" */'./extensions/MeetingEvent').then(mod => mod.MeetingEvent),
    priority: 'medium'
  }),
  [INVOICE_TYPE_ID]: () => priorityLoader({
    loader: () => import( /* webpackChunkName: "extensions-invoice" */'./extensions/Invoice').then(mod => mod.Invoice),
    priority: 'medium'
  }),
  [COMMERCE_PAYMENT_TYPE_ID]: () => priorityLoader({
    loader: () => import( /* webpackChunkName: "extensions-commerce-payment" */'./extensions/CommercePayment').then(mod => mod.CommercePayment),
    priority: 'medium'
  }),
  [ORDER_TYPE_ID]: () => priorityLoader({
    loader: () => import( /* webpackChunkName: "extensions-order" */'./extensions/Order').then(mod => mod.Order),
    priority: 'medium'
  }),
  [CART_TYPE_ID]: () => priorityLoader({
    loader: () => import( /* webpackChunkName: "extensions-cart" */'./extensions/Cart').then(mod => mod.Cart),
    priority: 'medium'
  }),
  [PRODUCT_TYPE_ID]: () => priorityLoader({
    loader: () => import( /* webpackChunkName: "extensions-product" */'./extensions/Product').then(mod => mod.Product),
    priority: 'medium'
  }),
  [SUBSCRIPTION_TYPE_ID]: () => priorityLoader({
    loader: () => import( /* webpackChunkName: "extensions-subscription" */'./extensions/Subscription').then(mod => mod.Subscription),
    priority: 'medium'
  }),
  [PARTNER_CLIENT_TYPE_ID]: () => priorityLoader({
    loader: () => import( /* webpackChunkName: "extensions-partner-client" */'./extensions/PartnerClient').then(mod => mod.PartnerClient),
    priority: 'medium'
  }),
  [TASK_TYPE_ID]: () => priorityLoader({
    loader: () => import( /* webpackChunkName: "extensions-task" */'./extensions/Task').then(mod => mod.TaskExtension),
    priority: 'medium'
  }),
  [PARTNER_SERVICE_TYPE_ID]: () => priorityLoader({
    loader: () => import( /* webpackChunkName: "extensions-partner-service" */'./extensions/PartnerService').then(mod => mod.PartnerService),
    priority: 'medium'
  }),
  [EMAIL_TYPE_ID]: () => priorityLoader({
    loader: () => import( /* webpackChunkName: "extensions-email" */'./extensions/Email').then(mod => mod.Email),
    priority: 'medium'
  })
};
export const getExtension = memoizeOne(objectTypeId => {
  const extensionConfig = extensionConfigs[objectTypeId];
  if (!extensionConfig) {
    return Promise.resolve(undefined);
  }
  const extensionLoader = extensionConfig();
  if (!extensionLoader) {
    return Promise.resolve(undefined);
  }
  return extensionLoader();
});