/**
 * Returns true if `value` is "empty".
 * If given null, undefined, isEmptyObject will return true.
 *
 * @param {any} value
 * @return {boolean}
 */
export default function isEmptyObject(value) {
  if (!value) {
    return true;
  }
  return !Object.keys(value).length;
}