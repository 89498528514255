import { useMemo } from 'react';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import { registerQuery, useQuery } from 'data-fetching-client';
const fetcher = () => getFrameworkDataSchemasClient().then(client => {
  return client.frameworkTypes.getObjects();
});
const GET_OBJECT_DEFINITIONS = 'CRM_INDEX_UI_GET_OBJECT_DEFINITIONS';
const getObjectDefinitionsQuery = registerQuery({
  fieldName: GET_OBJECT_DEFINITIONS,
  args: [],
  fetcher
});
export const useFetchObjectTypeDefinitions = () => {
  const {
    loading,
    error,
    data
  } = useQuery(getObjectDefinitionsQuery, {});
  return useMemo(() => {
    if (!loading && data && !error) {
      return {
        data: data[GET_OBJECT_DEFINITIONS],
        loading,
        error
      };
    }
    return {
      data: null,
      loading,
      error
    };
  }, [data, error, loading]);
};