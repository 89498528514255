'use es6';

import { defineAuthStore } from 'crm_data/store/AuthStore';
import dispatcher from 'dispatcher/dispatcher';
import { parse } from 'hub-http/helpers/params';
import memoize from 'transmute/memoize';
const getQueryParams = memoize(searchString => {
  return parse(searchString.substring(1));
});
const isUngatedByUrl = memoize(gate => {
  const {
    ungated
  } = getQueryParams(window.location.search);
  let ungatedStr = '';
  if (Array.isArray(ungated)) {
    ungatedStr = ungated.join();
  } else {
    ungatedStr = ungated;
  }
  return Boolean(ungatedStr && ungatedStr.split(',').includes(gate));
});
const isGatedByUrl = memoize(gate => {
  const {
    gated
  } = getQueryParams(window.location.search);
  let gatedStr = '';
  if (Array.isArray(gated)) {
    gatedStr = gated.join();
  } else {
    gatedStr = gated;
  }
  return Boolean(gatedStr && gatedStr.split(',').includes(gate));
});

/**
 * @deprecated - DO NOT USE
 * This requires consuming apps to call SetupData() in their app. Not all consuming apps are guaranteed to do that if this is used in a library
 * Example of this issue: https://hubspot.slack.com/archives/CHSNBCG79/p1695055609631749
 */
const IsUngatedStore = defineAuthStore({
  getter: (state, gate) => gate && !isGatedByUrl(gate) && (state.includes(gate) || isUngatedByUrl(gate)),
  initialState: [],
  name: 'IsUngatedStore',
  onAuthLoad: auth => auth.gates
}).register(dispatcher);
export default IsUngatedStore;