import { produce } from 'immer';
import { FETCH_PINNED_VIEWS_SUCCEEDED } from '../actions/pinnedViewsActionTypes';
import { mutableSetIn } from '../../objectUtils/mutableSetIn';
import getIn from 'transmute/getIn';
const initialState = {
  optimisticUpdates: {},
  values: {}
};
export const pinnedViewsReducer = produce((draft, action) => {
  switch (action.type) {
    case FETCH_PINNED_VIEWS_SUCCEEDED:
      {
        const {
          objectTypeId,
          ids
        } = action.payload;
        if (getIn(['optimisticUpdates', objectTypeId], draft)) {
          delete draft.optimisticUpdates[objectTypeId];
        }

        // @ts-expect-error ts-migrate(2322) FIXME: Type 'void' is not assignable to type '{ optimisti... Remove this comment to see the full error message
        draft = mutableSetIn(['values', objectTypeId], ids, draft);
        return draft;
      }
    default:
      {
        return draft;
      }
  }
}, initialState);