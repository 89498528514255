/**
 * Enables return linking from a settings page. This adds an `eschref` param to the link
 * which is used by `settings-ui-nav` to return back to the original page after. There must be
 * a corresponding key in `ProductUrlMappings` that matches the eschref to work.
 *
 * The eschref should just be the return link itself, i.e. the intended href of the return link in settings.
 *
 * See: https://product.hubteam.com/docs/settings-design-system/static/deep-linking.html#setup-a-return-link
 * @param {string} link to settings page
 * @param {string} override optional override return link, if not using current path
 */
export const getReturnLink = (link, override) => {
  const eschref = `eschref=${encodeURIComponent(override || window.location.pathname)}`;
  const qsIndex = link.indexOf('?');
  if (qsIndex === -1) {
    return `${link}?${eschref}`;
  }
  return `${link}&${eschref}`;
};