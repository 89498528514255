// To be kept in sync with https://git.hubteam.com/HubSpot/IdentityBase/blob/2ccaec45b4a78521c6ce953ea848c5399494816d/src/main/protobuf/properties.proto#L112
// (link is to commit as of 4/14/2021, check file at latest master for updates)

export const BOOLEAN_CHECKBOX = 'booleancheckbox';
export const CHECKBOX = 'checkbox';
export const DATE = 'date';
export const FILE = 'file';
export const NUMBER = 'number';
export const PHONE_NUMBER = 'phonenumber';
export const RADIO = 'radio';
export const SELECT = 'select';
export const TEXT = 'text';
export const TEXTAREA = 'textarea';
export const EQUATION = 'calculation_equation';
export const ROLLUP = 'calculation_rollup';
export const SCORE = 'calculation_score';
// export const CALCULATION_READ_TIME = 'calculation_read_time';
// export const UNKNOWN = 'unknown';
export const HTML = 'html';
export const propertyFieldTypes = [BOOLEAN_CHECKBOX, CHECKBOX, DATE, FILE, NUMBER, PHONE_NUMBER, RADIO, SELECT, TEXT, TEXTAREA, EQUATION, ROLLUP, SCORE, HTML];