'use es6';

import http from 'hub-http/clients/apiClient';
import formatContactsByEmail from 'reference-resolvers/formatters/formatContactsByEmail';
const BASE_URI = 'contacts/v1/contact';
export const createFetchContactByEmailAddress = ({
  httpClient
}) => emailAddresses => Promise.all(emailAddresses.map(emailAddress => httpClient.get(`${BASE_URI}/email/${encodeURIComponent(emailAddress)}/profile`).then(contact => ({
  emailAddress,
  contact
}), error => ({
  emailAddress,
  error
})))).then(formatContactsByEmail);
export const fetchContactByEmailAddress = createFetchContactByEmailAddress({
  httpClient: http
});