import { useSelector } from 'react-redux';
import { getBoardPageSize, getPage, getPageSize } from '../selectors/paginationSelectors';
export const usePaginationState = () => {
  // TODO: Page in state can be deprecated after moving to crm-object-table
  const page = useSelector(getPage);
  const pageSize = useSelector(getPageSize);
  const boardPageSize = useSelector(getBoardPageSize);
  return {
    page,
    pageSize,
    boardPageSize
  };
};