import { objectEntries } from '../../objectUtils/objectEntries';
import { DenormalizedPropertyFilterQueryParamMapping } from '../constants/QueryParamsThatRequireParsing';
// HACK: Some older links pass a "-" in place of the "." that CrmSearch actually expects
// for these properties. Until we can change all places generating these links, we'll
// have to handle them just like the old code did.
export const normalizeQueryParams = rawParams => objectEntries(DenormalizedPropertyFilterQueryParamMapping).reduce((normalizedParams, [denormalizedKey, normalizedKey]) => {
  if (rawParams[denormalizedKey]) {
    normalizedParams[normalizedKey] = rawParams[denormalizedKey];
    delete normalizedParams[denormalizedKey];
  }
  return normalizedParams;
}, Object.assign({}, rawParams));