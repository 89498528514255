'use es6';

import { fromJS, List } from 'immutable';
import get from 'transmute/get';
import pick from 'transmute/pick';
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
const INTEGRATION_NAME = 'integrationName';
const pickIntegrationName = pick([INTEGRATION_NAME]);
const getIntegrationName = get(INTEGRATION_NAME);
const getAppId = get('appId');
const getError = get('error');
const formatReferencedObject = item => fromJS(getError(item) ? null : pickIntegrationName(item));
const formatLabel = item => getError(item) ? String(getAppId(item)) : getIntegrationName(item);
export default (integrationNameResponses => List(integrationNameResponses.map(item => item ? new ReferenceRecord({
  id: String(getAppId(item)),
  label: formatLabel(item),
  referencedObject: formatReferencedObject(item)
}) : null)));