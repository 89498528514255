'use es6';

import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
import get from 'transmute/get';
import indexBy from 'transmute/indexBy';
import enviro from 'enviro';
const hardCodedResponse = enviro.isProd() ? [{
  label: 'Eventbrite',
  value: '28564'
}, {
  label: 'GoToWebinar',
  value: '35161'
}, {
  label: 'Zoom',
  value: '178192'
}, {
  label: 'Microsoft Teams',
  value: '221635'
}] : [{
  label: 'Eventbrite',
  value: '1159843'
}, {
  label: 'GoToWebinar',
  value: '1158066'
}, {
  label: 'Zoom',
  value: '1162897'
}, {
  label: 'Microsoft Teams',
  value: '1202021'
}];
export const createGetAllMarketingEventAppNames = () => () => Promise.resolve(hardCodedResponse).then(response => indexBy(get('id'), formatToReferencesList({
  getId: get('value'),
  getLabel: get('label')
}, response)));
export const getAllMarketingEventAppNames = createGetAllMarketingEventAppNames();