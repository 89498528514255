import { useCallback, useSyncExternalStore } from 'react';
import { useLocalMutationsStore } from './LocalMutationsProvider';
/**
 * useLocalMutationsState takes in a state selector and then returns the result of the selector upon the stores current state.
 *
 * Example:
 * ```tsx
 *   const storeSelector = (state) => state.mything;
 *   useLocalMutationsState(storeSelector) // will return LocalMutationsStore.mything
 * ```
 */
export const useLocalMutationsState = selector => {
  const store = useLocalMutationsStore();
  const getSnapshot = useCallback(() => selector(store.getSnapshot()), [selector, store]);
  return useSyncExternalStore(store.subscribe, getSnapshot);
};