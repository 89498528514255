import { QUERY_DELETED, OBJECT_DELETED } from '../LocalMutationsStore';
import { produce } from 'immer';
import { MULTI_ENUM_DELIMITER, APPEND_MULTI_ENUM_DELIMITER, REMOVE_MULTI_ENUM_DELIMITER } from '../constants';

// The implementation below attempts to support multiple object-level mutations
// effectively while preserving the previous behavior where object-level mutations
// override query-level mutations if they're for the same property.
// There is a level of depth and nuance to how we handle query and object mutations
// and in what order that may need further refinement. Additionally, we may
// need to add support for lists of query mutations, and/or ordering of query mutations.
// Maybe the ideal user experience is to interleave query-level and object-level
// mutations into one sequential update list depending on the order of user input.
const updateProperties = produce((draft, queryMutation, objectMutations) => {
  const keysUpdatedByObjectMutations = new Set();

  // Apply object mutations first, tracking updated keys
  for (const objectMutation of objectMutations) {
    Object.entries(objectMutation).forEach(([key, val]) => {
      if (draft.properties[key]) {
        var _getNewPropertyValue;
        draft.properties[key].value = (_getNewPropertyValue = getNewPropertyValue(draft.properties[key].value, val)) !== null && _getNewPropertyValue !== void 0 ? _getNewPropertyValue : '';
        keysUpdatedByObjectMutations.add(key);
      }
    });
  }

  // Apply query mutations to keys not updated by object mutations
  Object.entries(queryMutation).forEach(([key, val]) => {
    if (!keysUpdatedByObjectMutations.has(key) && draft.properties[key]) {
      var _getNewPropertyValue2;
      draft.properties[key].value = (_getNewPropertyValue2 = getNewPropertyValue(draft.properties[key].value, val)) !== null && _getNewPropertyValue2 !== void 0 ? _getNewPropertyValue2 : '';
    }
  });
});
export const applyMutationsToObject = ({
  object,
  queryMutations,
  objectMutations = []
}) => {
  const objectPropertyEdits = normalizeObjectMutations(objectMutations);
  if (!queryMutations && objectMutations.length === 0) {
    return object;
  }
  const objectNotDeleted = hasNoDeletion(objectPropertyEdits);
  if (queryMutations === QUERY_DELETED || !objectNotDeleted) {
    return undefined;
  }
  const queryPropertyEdits = queryMutations ? queryMutations.edits : {};
  return updateProperties(object, queryPropertyEdits, objectPropertyEdits);
};
function normalizeObjectMutations(objectMutations) {
  return Array.isArray(objectMutations) ? objectMutations : [objectMutations];
}
function hasNoDeletion(mutations) {
  return !(mutations !== null && mutations !== void 0 && mutations.includes(OBJECT_DELETED));
}

// Multi-enum properties are a set of options split by semicolons.
//
// They can be edited in multiple strategies: "append", "replace", "remove"
// (this append/replace/remove functionality can be found in the bulk edit modal, example property "buying role")
//
// "replace" will discard all values from the previous array (split at semicolon)
// "append" will combine the previous value + new value into a deduped array (split at semicolon)
// "remove" will remove the previous value + new value into a deduped array (split at semicolon)
//
// The backend determines "append", "remove", "replace" by the first character.
// If the first character of the new value is a semicolon, it "appends" the values.
// If the first character of the new value is a '?^', it "removes" the values.
// If the first character of the new value is anything except a semicolon, it "replaces" the values
//
// This function is meant to re-create that functionality on the frontend within our optimistic update code
//
// Exporting getNewPropertyValue for consumption by svh-help-desk, which does not use Ticket objects
// but still needs to optimistically update multi-enum values according to this logic.
export function getNewPropertyValue(currentValue, propertyUpdate) {
  if (propertyUpdate === undefined || propertyUpdate === null) {
    return '';
  }
  propertyUpdate = String(propertyUpdate);

  // If it does not start with a delimiter, we should replace the values
  if (propertyUpdateIsReplace(propertyUpdate)) {
    return propertyUpdate;
  }

  // Otherwise, we either need to append or remove values
  const valueSet = multiEnumStringToValueSet(currentValue);
  updateValueSet(valueSet, propertyUpdate);
  return valueSetToMultiEnumString(valueSet);
}
function propertyUpdateIsReplace(propertyUpdate) {
  return !(propertyUpdate !== null && propertyUpdate !== void 0 && propertyUpdate.startsWith(APPEND_MULTI_ENUM_DELIMITER)) && !(propertyUpdate !== null && propertyUpdate !== void 0 && propertyUpdate.startsWith(REMOVE_MULTI_ENUM_DELIMITER));
}
function multiEnumStringToValueSet(value) {
  var _value$split;
  return new Set((_value$split = value === null || value === void 0 ? void 0 : value.split(MULTI_ENUM_DELIMITER)) !== null && _value$split !== void 0 ? _value$split : []);
}
function updateValueSet(values, propertyUpdate) {
  if (propertyUpdate !== null && propertyUpdate !== void 0 && propertyUpdate.startsWith(APPEND_MULTI_ENUM_DELIMITER)) {
    propertyUpdate
    // Remove the leading append delimiter before parsing out the new values
    .slice(APPEND_MULTI_ENUM_DELIMITER.length).split(MULTI_ENUM_DELIMITER).forEach(value => values.add(value));
  } else if (propertyUpdate !== null && propertyUpdate !== void 0 && propertyUpdate.startsWith(REMOVE_MULTI_ENUM_DELIMITER)) {
    propertyUpdate
    // Remove the leading remove delimiter before parsing out the new values
    .slice(REMOVE_MULTI_ENUM_DELIMITER.length).split(MULTI_ENUM_DELIMITER).forEach(value => values.delete(value));
  }
}
function valueSetToMultiEnumString(valueSet) {
  return Array.from(valueSet).join(MULTI_ENUM_DELIMITER);
}