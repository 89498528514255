'use es6';

import curry from 'transmute/curry';
import get from 'transmute/get';
import http from 'hub-http/clients/apiClient';
import { formatContactName } from 'reference-resolvers/formatters/formatContacts';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
const BASE_URI = 'contacts/v3/contacts';
const BATCH_URI = `${BASE_URI}/batch`;
const BATCH_LIMIT = 100;
const IDENTITY_FETCH_MODE_FIRST = 'first';
const PROP_FETCH_MODE_LATEST_VERSION = 'latest_version';

// Creates batches of ids up to the endpoints batch limit
const batchIds = (arr, size) => Array.from({
  length: Math.ceil(arr.length / size)
}, (__, idx) => arr.slice(idx * size, idx * size + size));
const contactGetters = {
  getId: get('vid'),
  getLabel: formatContactName
};
const toResultsList = curry((ids, results) => ids.map(id => results[id]));
const flatten = results => [].concat(...results);
export const createFetchByIds = ({
  httpClient
}) => ids => Promise.all(batchIds(ids, BATCH_LIMIT).map(batchedIds => httpClient.get(BATCH_URI, {
  query: {
    id: batchedIds,
    allPropertiesFetchMode: PROP_FETCH_MODE_LATEST_VERSION,
    identitiesFetchMode: IDENTITY_FETCH_MODE_FIRST
  }
}).then(toResultsList(batchedIds)))).then(flatten).then(formatToReferencesList(contactGetters));
export const fetchByIds = createFetchByIds({
  httpClient: http
});