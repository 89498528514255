import { get } from '../../utils/arrayUtils';
import { useObjectTypeAppSettings } from './useObjectTypeAppSettings';
export const useObjectTypeFeatureByAppSetting = (objectTypeId, appSettingName, featureName) => {
  const {
    data: metadata,
    loading
  } = useObjectTypeAppSettings(objectTypeId, appSettingName);
  if (loading) {
    return {
      data: undefined,
      loading: true
    };
  }
  const maybeFeature = metadata && get(featureName, metadata);

  // We need this explicit falsy check because TS does not know if the feature object is defined at compile time.
  // Once we put this check in, 'value' becomes a valid keyof maybeFeature since TS understands it's defined
  if (!maybeFeature) {
    return {
      data: undefined,
      loading: false
    };
  }
  return {
    data: get('value', maybeFeature),
    loading: false
  };
};