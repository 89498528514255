'use es6';

import { Set as ImmutableSet, Map as ImmutableMap, List } from 'immutable';
import indexBy from 'transmute/indexBy';
import get from 'transmute/get';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
import pipe from 'transmute/pipe';
import reduce from 'transmute/reduce';
const flattenChildTeams = (acc, team) => {
  acc = acc.push(team);
  if (team.childTeams) {
    acc = reduce(acc, flattenChildTeams, team.childTeams);
  }
  return acc;
};
const formatTeams = teams => ImmutableMap({
  all: pipe(formatToReferencesList({
    getId: get('id'),
    getLabel: get('name'),
    referencedObject: value => ImmutableMap(value).update('userIds', ImmutableSet)
  }), indexBy(get('id')))(teams),
  byId: pipe(reduce(List(), flattenChildTeams), formatToReferencesList({
    getId: get('id'),
    getLabel: get('name'),
    referencedObject: value => ImmutableMap(value).update('userIds', ImmutableSet)
  }), indexBy(get('id')))(teams)
});
export default formatTeams;