import { useFetchPortalCurrencies } from './useFetchPortalCurrencies';
import { useIsPortalCurrency } from './useIsPortalCurrency';
import { useMemo } from 'react';
import { isCurrencyCode } from '../utils/isCurrencyCode';
import { useFetchPreferredCurrency } from './useFetchPreferredCurrency';
import { useHasPreferredDisplayCurrency } from './useHasPreferredDisplayCurrency';
export const usePreferredDisplayCurrency = (objectTypeId, settings) => {
  const preferredDisplayCurrency = settings ? settings.preferredDisplayCurrencyPreferences.preferredDisplayCurrency : undefined;
  const {
    preferredCurrency
  } = useFetchPreferredCurrency(objectTypeId);
  const {
    homeCurrencyCode
  } = useFetchPortalCurrencies(objectTypeId);
  const {
    isPortalCurrency
  } = useIsPortalCurrency(objectTypeId);
  const canAccessPreferredCurrency = useHasPreferredDisplayCurrency(objectTypeId);
  return useMemo(() => {
    if (!canAccessPreferredCurrency) {
      return homeCurrencyCode;
    }
    if (isCurrencyCode(preferredDisplayCurrency)) {
      return preferredDisplayCurrency;
    }
    if (isCurrencyCode(preferredCurrency) && isPortalCurrency(preferredCurrency)) {
      return preferredCurrency;
    }
    return homeCurrencyCode;
  }, [canAccessPreferredCurrency, homeCurrencyCode, isPortalCurrency, preferredCurrency, preferredDisplayCurrency]);
};