import { TOUR_ID, ONBOARDING_TOUR_ID, ONBOARDING_TOUR_SOURCE } from '../constants/URL';
export { ONBOARDING_TOUR_SOURCE } from '../constants/URL';
export const TourSource = {
  UserGuide: 'user-guide',
  GrowthPlan: 'growth-plan',
  GrowthSidePanel: 'growth-side-panel'
};
/**
 * Adds the tour source query parameter to the url
 * @param options - options of tour source util
 * @param options.url - url to add tour source to
 * @param options.tourSource - tour source
 * @param options.skipTourCheck - option to skip tour id check on url and always add tour source if value is true
 * @returns The url with the tour source query parameter added
 */
export const addSourceToTourUrl = ({
  url,
  tourSource,
  skipTourCheck = false
}) => {
  const [pathname, queryString] = url.split('?');
  const searchParams = new URLSearchParams(queryString);
  if (skipTourCheck || searchParams.has(TOUR_ID) || searchParams.has(ONBOARDING_TOUR_ID)) {
    searchParams.set(ONBOARDING_TOUR_SOURCE, tourSource);
    return `${pathname}?${searchParams.toString()}`;
  }
  return url;
};