import { useObjectTypeDefinition } from './useObjectTypeDefinitions';
import { useMemo } from 'react';
import { processInputQuerySorts } from './processInputQuerySorts';
import { usePropertyDefinitions } from './usePropertyDefinitions';
import { makeApolloLikeResultType } from '../typeUtils';
export const useProcessInputQuerySorts = ({
  objectTypeId,
  inputSorts
}) => {
  const {
    data: objectTypeDef,
    loading: objectTypeDefLoading,
    error: objectTypeDefError
  } = useObjectTypeDefinition({
    objectTypeId
  });
  const {
    data: propertyDefinitions,
    loading: propertyDefinitionsLoading,
    error: propertyDefinitionsError
  } = usePropertyDefinitions({
    objectTypeId
  });
  const data = useMemo(() => {
    if (!objectTypeDef || !propertyDefinitions) {
      return undefined;
    }
    return processInputQuerySorts({
      inputSorts,
      properties: propertyDefinitions,
      partialTypeDef: objectTypeDef
    });
  }, [objectTypeDef, propertyDefinitions, inputSorts]);
  const loading = objectTypeDefLoading || propertyDefinitionsLoading;
  const error = objectTypeDefError || propertyDefinitionsError;
  return useMemo(() => makeApolloLikeResultType({
    data,
    loading,
    error
  }), [error, loading, data]);
};