import * as params from 'hub-http/helpers/params';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Custom hook for parsing the query parameters from the URL.
 * It leverages the `useLocation` hook from React Router to access the current URL's search string,
 * then uses `useMemo` to memoize the parsing result of the query parameters to optimize performance.
 * The parsing of query parameters is handled by the `params.parse` method.
 *
 * @returns {Object} An object representing the parsed query parameters. The object's structure
 *                   depends on the query parameters present in the URL. Each key in the returned
 *                   object corresponds to a query parameter name, and each value corresponds to the
 *                   query parameter's value, parsed by `params.parse`.
 *
 * @example
 * ```ts
 * // Assuming the current URL is "http://example.com/?name=John&age=30"
 * const queryParams = useQueryParams();
 * console.log(queryParams); // Output: { name: 'John', age: '30' }
 * ```
 */
export function useQueryParams() {
  const {
    search
  } = useLocation();
  const queryParams = useMemo(() => params.parse(search.substring(1)), [search]);
  return queryParams;
}