import unescapedText from 'I18n/utils/unescapedText';
const getLabel = label => {
  if (!label) {
    return '';
  }
  return ` (${label})`;
};
export const generateAssociationLabel = associationDefinition => {
  const toObjectName = associationDefinition.cardinality === 'ONE_TO_ONE' ? associationDefinition.toObjectTypeDefinition.singularForm : associationDefinition.toObjectTypeDefinition.pluralForm;
  const fromObjectName = associationDefinition.fromObjectTypeDefinition.singularForm;
  const isPrimary = associationDefinition.isPrimary;
  const inverseLabel = associationDefinition.label !== associationDefinition.inverseLabel && !isPrimary ? getLabel(associationDefinition.inverseLabel) : '';
  const label = getLabel(associationDefinition.label);
  return unescapedText('crmIndexAssociationsLib.associationLabel', {
    fromObjectName,
    inverseLabel,
    toObjectName,
    label
  });
};