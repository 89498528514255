'use es6';

import { List, fromJS } from 'immutable';
import indexBy from 'transmute/indexBy';
import get from 'transmute/get';
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
const formatTeamId = team => {
  return String(team.id);
};
const formatTeamReference = team => {
  return new ReferenceRecord({
    id: formatTeamId(team),
    label: team.name,
    referencedObject: fromJS(team)
  });
};
const formatTeams = teams => {
  return indexBy(get('id'), List(teams).map(formatTeamReference));
};
export default formatTeams;