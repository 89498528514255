'use es6';

import { OrderedMap, List, fromJS } from 'immutable';
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
import sortBy from 'transmute/sortBy';
const formatPipelineId = pipeline => {
  return String(pipeline.pipelineId);
};
const formatPipelineReference = pipeline => {
  return new ReferenceRecord({
    id: formatPipelineId(pipeline),
    label: pipeline.label,
    referencedObject: fromJS(pipeline)
  });
};
const getDisplayOrder = pipeline => {
  if (typeof pipeline.displayOrder === 'number') {
    return pipeline.displayOrder;
  } else {
    return -1;
  }
};
const sortByDisplayOrder = sortBy(getDisplayOrder);
const formatPipelines = response => {
  const sortedPipelines = sortByDisplayOrder(List(response));
  return OrderedMap(sortedPipelines.map(pipeline => [formatPipelineId(pipeline), formatPipelineReference(pipeline)]));
};
export default formatPipelines;