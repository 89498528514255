import { userInfoSync } from 'hub-http/userInfo';
const isUngated = (gates, gate) => gates.has(gate);
export function useIsUngated(gate) {
  try {
    const auth = userInfoSync();
    return isUngated(new Set(auth.gates), gate);
  } catch (error) {
    console.error(error);
    return false;
  }
}