import identity from 'transmute/identity';

/**
 * A method for deduping arrays of either primitive or complex values.
 * Element identities are compared by adding them to a Set, which uses
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Equality_comparisons_and_sameness#same-value-zero_equality|same-value-zero equality}.
 * The first ocurrence of an identity will survive, and any subsequent
 * ocurrences are ignored. This **does not** work on immutable structures
 * or objects, only plain JS arrays (the values within can be any type).
 * You can customize the identity of an element by passing the optional
 * second "getIdentity" parameter, which may be useful when deduping arrays of objects.
 *
 * **WARNING**: This does not mutate its input, so it will not work with immer-based code.
 */
export function unique(array, getIdentity = identity) {
  const keys = new window.Set();
  const uniqueArray = [];
  for (const item of array) {
    const key = getIdentity(item);
    if (!keys.has(key)) {
      keys.add(key);
      uniqueArray.push(item);
    }
  }
  return uniqueArray;
}