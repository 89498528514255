import { OPEN_MODAL, CLOSE_MODAL, OPEN_PANEL, CLOSE_PANEL } from './overlayActionTypes';
export const openModalAction = (modalType, modalData = {}) => ({
  type: OPEN_MODAL,
  payload: {
    modalType,
    modalData
  }
});
export const closeModalAction = () => ({
  type: CLOSE_MODAL
});
export const openPanelAction = (panelType, panelData = {}) => ({
  type: OPEN_PANEL,
  payload: {
    panelType,
    panelData
  }
});
export const closePanelAction = () => ({
  type: CLOSE_PANEL
});