'use es6';

import http from 'hub-http/clients/apiClient';
import get from 'transmute/get';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
import { Map as ImmutableMap, fromJS } from 'immutable';
const URL = 'campaigns/v1/campaigns/guids';
const CRM_SEARCH_URL = 'crm-search/search';
const PAGE_SIZE = 30;
const getCrmSearchCampaignOptions = ({
  offset = 0,
  nameSearch = null,
  count = PAGE_SIZE
}) => {
  return {
    count,
    offset,
    objectTypeId: '0-35',
    requestOptions: {
      properties: ['hs_origin_asset_id', 'hs_name']
    },
    query: nameSearch
  };
};
export const createFetchCampaignsById = ({
  httpClient
}) => ids => httpClient.get(URL, {
  query: {
    guid: ids,
    includeDeletes: true
  }
}).then(formatToReferencesList({
  getId: get('guid'),
  getLabel: get('display_name')
}));
export const fetchCampaignsById = createFetchCampaignsById({
  httpClient: http
});
export const createSearchCampaigns = ({
  httpClient
}) => (args = ImmutableMap({
  offset: 0,
  query: '',
  count: PAGE_SIZE
})) => {
  const {
    offset,
    count,
    query: queryString
  } = args.toJS();
  return httpClient.post(CRM_SEARCH_URL, {
    data: getCrmSearchCampaignOptions({
      count,
      offset,
      nameSearch: queryString
    })
  }).then(fromJS).then(response => {
    const results = formatToReferencesList({
      getId: campaign => campaign.getIn(['properties', 'hs_origin_asset_id', 'value']),
      getLabel: campaign => campaign.getIn(['properties', 'hs_name', 'value'])
    })(response.get('results'));
    return ImmutableMap({
      count: results.length,
      offset: response.get('offset'),
      hasMore: response.get('hasMore'),
      results,
      total: response.get('total')
    });
  });
};
export const searchCampaigns = createSearchCampaigns({
  httpClient: http
});