import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useQueryParams } from '../../../router/useQueryParams';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { getCurrentViewId, getCurrentPageType } from '../../../views/selectors/viewsSelectors';
import { stringify } from 'hub-http/helpers/params';
export const useGenerateLocation = () => {
  const params = useQueryParams();
  const currentObjectTypeId = useSelectedObjectTypeId();
  const currentViewId = useSelector(getCurrentViewId);
  const currentPageType = useSelector(getCurrentPageType);
  return useCallback(({
    objectTypeId = currentObjectTypeId,
    viewId = currentViewId,
    pageType = currentPageType,
    query
  }) => {
    const encodedTypeId = encodeURIComponent(objectTypeId);
    const encodedViewId = encodeURIComponent(viewId);
    const encodedPageType = encodeURIComponent(pageType);
    const pageTypePath = pageType ? encodedPageType : '';
    const viewPath = viewId ? `views/${encodedViewId}/${pageTypePath}` : '';
    return {
      pathname: `/objects/${encodedTypeId}/${viewPath}`,
      search: stringify(Object.assign({}, params, query))
    };
  }, [currentObjectTypeId, currentViewId, currentPageType, params]);
};