import { useAppSettings } from '../../appSettings/hooks/useAppSettings';
import { useObjectTypeDefinitions } from '../../objectTypeDefinitions/hooks/useObjectTypeDefinitions';

/**
 * Populates the cache of data-fetching-client with the specified app settings and any dependent data.
 * This allows subsequent calls to `useObjectTypeFeatureByAppSetting` to return results immediately.
 *
 * @param {Array<keyof AppSettingsMetadataTypes>} appSettings - the app settings to pre-fetch
 * @returns {{ loading: boolean }}
 */
export const useWarmupAppSettingsFeaturesCache = appSettings => {
  const {
    loading: appSettingsLoading
  } = useAppSettings(appSettings);
  const {
    loading: typeDefsLoading
  } = useObjectTypeDefinitions();
  return {
    loading: appSettingsLoading || typeDefsLoading
  };
};