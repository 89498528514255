import { Component, version } from 'react';
import Raven from 'raven-js';
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }
  componentDidCatch(error, errorInfo) {
    this.reportError(error, errorInfo);
  }
  reportError(error, errorInfo) {
    const {
      boundaryName
    } = this.props;
    Raven.captureException(error, {
      extra: Object.assign({}, errorInfo, {
        version
      }),
      tags: {
        errorBoundaryName: boundaryName
      }
    });
  }
  render() {
    if (this.state.hasError) {
      return this.props.ErrorComponent;
    }
    return this.props.children;
  }
}
export default ErrorBoundary;