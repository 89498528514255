import { TOPIC_NAMES as MESSAGE_TOPICS } from 'crm-message-bus/types/MessageTopics';
import { useOnCrmMessageTopic } from 'crm-message-bus/useOnCrmMessageTopic';
import { denormalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
import { isLegacyObjectType } from 'customer-data-objects/types/LegacyObjectType';
import { Map as ImmutableMap, Set as ImmutableSet, fromJS } from 'immutable';
import { useCallback } from 'react';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './Ov... Remove this comment to see the full error message
import dispatcher from 'dispatcher/dispatcher';
import devLogger from 'react-utils/devLogger';
import Raven from 'raven-js';
export function useGeneralStoreRelays() {
  const handleAssociationUpdate = useCallback(updateAssociationData => {
    if (updateAssociationData.objectId && updateAssociationData.objectTypeId && updateAssociationData.toObjectTypeId && isLegacyObjectType(denormalizeTypeId(updateAssociationData.toObjectTypeId))) {
      const denormalizedFromObjectTypeId = denormalizeTypeId(updateAssociationData.objectTypeId);
      const denormalizedToObjectTypeId = denormalizeTypeId(updateAssociationData.toObjectTypeId);
      try {
        // update associated open objects store for communicator
        dispatcher.dispatch({
          actionType: 'ASSOCIATED_OPEN_OBJECTS_REFRESH_QUEUED',
          data: fromJS([{
            subjectId: String(updateAssociationData.objectId),
            objectType: denormalizedFromObjectTypeId,
            objectTypeToFetch: denormalizedToObjectTypeId
          }])
        });
        // update associations store for legacy object creator
        dispatcher.dispatch({
          actionType: 'ASSOCIATIONS_REFRESH_QUEUED',
          data: ImmutableSet([ImmutableMap({
            objectId: String(updateAssociationData.objectId),
            objectType: denormalizedFromObjectTypeId,
            associationType: `${String(denormalizedFromObjectTypeId)}_TO_${denormalizedToObjectTypeId}`
          })])
        });
      } catch (e) {
        const errorMessage = e instanceof Error ? e.message : String(e);
        const errorName = e instanceof Error ? e.name : undefined;
        devLogger.warn({
          key: 'useGeneralStoreRelays:handleAssociationUpdate',
          message: `Error relaying CRM message to general store. Actual error: ${errorMessage}`
        });
        Raven.captureException(new Error('Error relaying CRM message to general store'), {
          extra: {
            originalError: {
              message: errorMessage,
              name: errorName
            }
          }
        });
      }
    }
  }, []);
  useOnCrmMessageTopic(MESSAGE_TOPICS.UPDATE_ASSOCIATIONS, handleAssociationUpdate);
}