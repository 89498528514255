export function freeze(object, {
  freezer = Object.freeze,
  isFrozen = Object.isFrozen
} = {}) {
  if (object) {
    Object.values(object).forEach(value => {
      if (value && typeof value === 'object' && !isFrozen(value)) {
        freeze(value, {
          freezer,
          isFrozen
        });
      }
    });
    freezer(object);
  }
  return object;
}