export const AssociationColumnRegex = /^associations\.[0-9]+-[0-9]+$/;

/**
 * Parses the association id out of a view column. An association view column
 * has the format "associations.0-1" where the 0-1 is the association id.
 *
 * @param {*} columnName - The name of a view column
 */
export const parseAssociationIdFromColumnName = columnName => {
  if (!AssociationColumnRegex.test(columnName)) {
    return undefined;
  }
  return columnName.split('.')[1];
};
export const isAssociationColumnName = name => Boolean(name && AssociationColumnRegex.test(name));
export const isAssociationColumn = column => isAssociationColumnName(column && column.name);