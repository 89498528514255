'use es6';

export function getHref() {
  return window.location.href;
}
export function getSearch() {
  return window.location.search;
}
export function getPathname() {
  return window.location.pathname;
}