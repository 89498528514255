import { APP_INIT, SYNC_ROUTER_VALUES, RESTORE_CACHED_VALUES } from './initActionTypes';
export const appInit = auth => ({
  type: APP_INIT,
  payload: {
    auth
  }
});
export const syncRouterValuesAction = ({
  objectTypeId,
  viewId,
  pageType,
  searchTerm
}) => ({
  type: SYNC_ROUTER_VALUES,
  payload: {
    objectTypeId,
    viewId,
    pageType,
    searchTerm
  }
});
export const restoreCachedValuesAction = ({
  objectTypeId,
  viewId,
  pageType,
  searchTerm,
  page,
  views,
  hasData
}) => ({
  type: RESTORE_CACHED_VALUES,
  payload: {
    objectTypeId,
    viewId,
    pageType,
    searchTerm,
    page,
    views,
    hasData
  }
});