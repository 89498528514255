import { registerMutation, registerQuery, useMutation, useQuery } from 'data-fetching-client';
import { Metrics } from '../lib/metrics/metrics';
import { getSuperStoreValue } from '../rewrite/superstore/getSuperStoreValue';
import { setSuperStoreValue } from '../rewrite/superstore/setSuperStoreValue';
const LOCAL_STORAGE_VALUE_IS_HIDDEN = 'IS_HIDDEN';
const LOCAL_STORAGE_VALUE_IS_SHOWN = 'IS_SHOWN';
const generateIsDataWellShownStorageKey = ({
  objectTypeId,
  viewId
}) => {
  return `CRM:Datasets:IsDataWellShown:objectTypeId=${objectTypeId}:viewId=${viewId}`;
};
export const setIsDataWellShownLocalStorage = ({
  objectTypeId,
  viewId,
  isShown
}) => {
  try {
    const storageKey = generateIsDataWellShownStorageKey({
      objectTypeId,
      viewId
    });
    const value = isShown ? LOCAL_STORAGE_VALUE_IS_SHOWN : LOCAL_STORAGE_VALUE_IS_HIDDEN;
    return setSuperStoreValue(storageKey, value); //important that this is returned as its a promise and DFC needs to await it finishing
  } catch (e) {
    Metrics.counter('set-is-data-well-shown-superstore-error').increment();
  }
  return Promise.resolve(isShown);
};
const getIsDataWellShownLocalStorage = ({
  objectTypeId,
  viewId
}) => {
  if (localStorage) {
    const storageKey = generateIsDataWellShownStorageKey({
      objectTypeId,
      viewId
    });
    try {
      return getSuperStoreValue(storageKey).then(rawValue => {
        const isHidden = rawValue === LOCAL_STORAGE_VALUE_IS_HIDDEN;
        return !isHidden;
      });
    } catch (e) {
      Metrics.counter('get-is-data-well-shown-superstore-error').increment();
    }
  }
  return Promise.resolve(true); //if there is an error default to it being shown to fail gracefully - wed rather have then see it and not be able to close it, than not be able to see it
};
const fieldName = 'isDataWellShown';
export const GetIsDataWellShownQuery = registerQuery({
  fieldName,
  args: ['objectTypeId', 'viewId'],
  fetcher: ({
    objectTypeId,
    viewId
  }) => getIsDataWellShownLocalStorage({
    objectTypeId,
    viewId
  })
});
export const SetIsDataWellShownMutation = registerMutation({
  fieldName,
  args: ['objectTypeId', 'viewId'],
  fetcher: ({
    objectTypeId,
    viewId,
    isShown
  }) => setIsDataWellShownLocalStorage({
    objectTypeId,
    viewId,
    isShown
  })
});
export const useGetIsDataWellShown = ({
  objectTypeId,
  viewId
}) => {
  return useQuery(GetIsDataWellShownQuery, {
    variables: {
      objectTypeId,
      viewId
    }
  });
};
export const useSetIsDataWellShown = ({
  objectTypeId,
  viewId,
  isShown
}) => {
  return useMutation(SetIsDataWellShownMutation, {
    variables: {
      objectTypeId,
      viewId,
      isShown
    },
    refetchQueries: [GetIsDataWellShownQuery],
    //From apollo docs: "If true, makes sure all queries included in refetchQueries are completed before the mutation is considered complete."
    awaitRefetchQueries: true
  });
};