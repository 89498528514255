import { FAILED, PENDING, SUCCEEDED, UNINITIALIZED } from '../../constants/RequestStatus';
import { produce } from 'immer';
import { FETCH_DOUBLE_OPT_IN_FAILED, FETCH_DOUBLE_OPT_IN_STARTED, FETCH_DOUBLE_OPT_IN_SUCCEEDED } from '../actions/doubleOptInActionTypes';
const initialState = {
  status: UNINITIALIZED,
  data: null
};
export const doubleOptInReducer = produce((draft, action) => {
  switch (action.type) {
    case FETCH_DOUBLE_OPT_IN_STARTED:
      {
        draft.status = PENDING;
        return draft;
      }
    case FETCH_DOUBLE_OPT_IN_SUCCEEDED:
      {
        const {
          data
        } = action.payload;
        draft.status = SUCCEEDED;
        draft.data = data;
        return draft;
      }
    case FETCH_DOUBLE_OPT_IN_FAILED:
      {
        draft.status = FAILED;
        return draft;
      }
    default:
      {
        return draft;
      }
  }
}, initialState);