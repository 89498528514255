import { CrmIndexSearchQuery, useCrmSearchQuery } from '../../crmSearch/hooks/useCrmSearchQuery';
import { BACKGROUND_REFRESH_INTERVAL_TIME } from '../../crmSearch/constants/cacheTime';
import { useSearchQuery } from '../../searchQuery/hooks/useSearchQuery';
import { useLazyQuery } from '@apollo/client';
export const useTableQuery = () => {
  const query = useSearchQuery();
  return useCrmSearchQuery(query, {
    pollInterval: BACKGROUND_REFRESH_INTERVAL_TIME
  });
};
export const useTableQueryAsync = () => {
  const {
    count,
    filterGroups,
    objectTypeId,
    offset,
    query: searchQuery,
    requestOptions = {
      properties: []
    },
    sorts
  } = useSearchQuery();
  const fetchPolicy = 'cache-and-network';
  const nextFetchPolicy = 'cache-first';
  const [fetch] = useLazyQuery(CrmIndexSearchQuery, {
    fetchPolicy,
    nextFetchPolicy,
    variables: {
      count,
      filterGroups,
      objectTypeId,
      offset,
      properties: requestOptions.properties,
      query: searchQuery,
      sorts
    }
  });
  return fetch;
};