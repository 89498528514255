// See https://git.hubteam.com/HubSpot/InboundDbAssociations/blob/c5e9a5281b2b172f558e80f82371d6280297e9fb/InboundDbAssociationsBase/src/main/java/com/hubspot/inbounddb/associations/base/AssociationCategory.java
export const HUBSPOT_DEFINED = 'HUBSPOT_DEFINED';
export const USER_DEFINED = 'USER_DEFINED';
export const INTEGRATOR_DEFINED = 'INTEGRATOR_DEFINED';
export const AssociationCategoryTypesToIds = {
  HUBSPOT_DEFINED: '0',
  USER_DEFINED: '1',
  INTEGRATOR_DEFINED: '2'
};
export const AssociationCategoryIdsToTypes = {
  0: HUBSPOT_DEFINED,
  1: USER_DEFINED,
  2: INTEGRATOR_DEFINED
};