import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { UNINITIALIZED } from '../../constants/RequestStatus';
import { fetchPropertiesAction } from '../actions/propertiesActions';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { SUCCEEDED } from 'crm_data/actions/ActionSteps';
import { usePropertiesFetchStatus } from './usePropertiesFetchStatus';
import { useHasAllGates } from '../../../auth/hooks/useHasAllGates';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import withGateOverride from 'crm_data/gates/withGateOverride';
export const useFetchAllProperties = ({
  skip = false
} = {}) => {
  const dispatch = useDispatch();
  const objectTypeId = useSelectedObjectTypeId();
  const status = usePropertiesFetchStatus();
  const hasAllGates = useHasAllGates();
  const hasGate = useCallback(gateName => withGateOverride(gateName, hasAllGates(gateName)), [hasAllGates]);
  useEffect(() => {
    if (status === UNINITIALIZED && !skip) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(fetchPropertiesAction(objectTypeId, hasGate));
    }
  }, [dispatch, objectTypeId, status, skip, hasGate]);
  return skip ? SUCCEEDED : status;
};