import Raven from 'raven-js';

/**
 * @deprecated
 * This library is now deprecated in favor of {@link https://git.hubteam.com/HubSpot/growth-onboarding-empty-states/tree/master/growth-onboarding-empty-states}
 * For helpers and utilities the recommendation is to create them inside your project.
 */

const getCompanyEmptyState = (data, loading) => {
  try {
    if (typeof data !== 'object' || data === null) {
      return {
        isEmptyPageLoading: loading,
        isEmptyPage: false
      };
    }
    const count = data === null || data === void 0 ? void 0 : data.total;
    const hasNoCompanies = count === 0;
    const firstEntryProperties = Array.isArray(data.results) && data.results.length > 0 && typeof data.results[0].properties === 'object' && data.results[0].properties !== null ? data.results[0].properties : null;
    const hasHubSpotDomain = (firstEntryProperties === null || firstEntryProperties === void 0 ? void 0 : firstEntryProperties.domain.value) === 'hubspot.com';
    const hasHubSpotName = (firstEntryProperties === null || firstEntryProperties === void 0 ? void 0 : firstEntryProperties.name.value) === 'HubSpot';
    const hasOnlySampleCompany = count === 1 && hasHubSpotDomain && hasHubSpotName;
    return {
      isEmptyPageLoading: loading,
      isEmptyPage: hasNoCompanies || hasOnlySampleCompany
    };
  } catch (error) {
    Raven.captureException(new Error('An unexpected error occurred in getCompanyEmptyState'), {
      extra: {
        error
      }
    });
    return {
      isEmptyPageLoading: loading,
      isEmptyPage: false
    };
  }
};
export { getCompanyEmptyState };