import { produce } from 'immer';
import { mutableSetIn } from '../../objectUtils/mutableSetIn';
import { FETCH_CRM_OBJECTS_SUCCEEDED } from '../actions/crmObjectsActionTypes';
const initialState = {};
export const crmObjectsReducer = produce((draft, action) => {
  switch (action.type) {
    case FETCH_CRM_OBJECTS_SUCCEEDED:
      {
        const {
          objectTypeId,
          objects
        } = action.payload;
        Object.entries(objects).forEach(([objectId, object]) => mutableSetIn([objectTypeId, objectId], object, draft));
        return draft;
      }
    default:
      {
        return draft;
      }
  }
}, initialState);