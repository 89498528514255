import { BaseCrmSearchError } from './BaseCrmSearchError';
const FLP_ERROR_MESSAGE_MARKER = 'User does not have permission to view property';
const FLP_REGEX = new RegExp(`${FLP_ERROR_MESSAGE_MARKER}\\s\\[([^\\]]+)\\]`);
export class CrmSearchFLPError extends BaseCrmSearchError {
  constructor(error, blockedProperties) {
    super(error);
    this.name = 'CrmSearchFLPError';
    this.blockedProperties = blockedProperties;
  }

  /*
   * CrmSearch may return with an error that looks like this:
   * Api[name=crm-search, httpMethod=POST, subPath=/crm-search/search/ids] Failed to process your request. Error code was '403'. Error message was: {"status":"error","message":"User does not have permission to view property [superadminstring]; cannot filter/aggregate upon it","correlationId":"2d597435-77a5-4be1-a24e-7d65e516d330"}
   *
   * This function will parse out if it is indeed an FLP error response, and then if so, return an array of property names that are blocked
   */
  static getBlockedProperties(errorMessage) {
    if (!errorMessage.includes(FLP_ERROR_MESSAGE_MARKER)) {
      return null;
    }
    const match = errorMessage.match(FLP_REGEX);
    if (!match) {
      return null;
    }
    return match[1].split(', ');
  }
  static tryFromError(error) {
    const blockedProperties = CrmSearchFLPError.getBlockedProperties(error.message);
    if (blockedProperties) {
      return new CrmSearchFLPError(error, blockedProperties);
    }
    return null;
  }
}