import { useLimit } from '../../../auth/hooks/useLimit';
import { getPinnedViewLimit } from '../constants/PinnedViewLimit';
export const usePinnedViewLimit = () => {
  const crmIndexPagePinnedViewsLimit = useLimit('crm-index-page-pinned-views-limit'); //https://tools.hubteam.com/launch/limits/1153/parcels

  const pinnedViewLimit = getPinnedViewLimit({
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    crmIndexPagePinnedViewsLimit
  });
  return pinnedViewLimit;
};