import { getQuickLoadRequest, removeQuickLoadRequest } from './quickLoad';
export const withQuickLoad = ({
  namespace,
  entityName,
  cacheKey,
  baseLoad
}) => {
  const quickLoadRequest = getQuickLoadRequest({
    namespace,
    entityName,
    cacheKey
  });
  if (!quickLoadRequest) {
    return baseLoad();
  }
  return quickLoadRequest.then(result => {
    removeQuickLoadRequest({
      namespace,
      entityName,
      cacheKey
    });
    return result;
  }).catch(() => {
    removeQuickLoadRequest({
      namespace,
      entityName,
      cacheKey
    });
    return baseLoad();
  });
};