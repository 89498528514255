import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { useCurrentViewId } from '../../../views/hooks/useCurrentViewId';
import { makeGetColumnWidthsStatus } from '../slice/columnWidthsSlice';
export const useColumnWidthsStatus = () => {
  const objectTypeId = useSelectedObjectTypeId();
  const viewId = useCurrentViewId();
  const getColumnWidthsStatus = useMemo(() => makeGetColumnWidthsStatus(objectTypeId, viewId), [objectTypeId, viewId]);
  const status = useSelector(getColumnWidthsStatus);
  return status;
};