'use es6';

import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
import { fromJS, Map as ImmutableMap } from 'immutable';
const formatIntegrationPropertyReference = (properties, id) => {
  return new ReferenceRecord({
    id,
    label: `${id}-properties`,
    referencedObject: fromJS({
      id,
      properties
    })
  });
};
const formatIntegrationProperties = response => {
  return ImmutableMap(response.eventTypeIdProperties).map(formatIntegrationPropertyReference);
};
export default formatIntegrationProperties;