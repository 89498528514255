import { useMemo } from 'react';
import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchAppSettings } from '../api';
import { withNamespace } from '../../utils/withNamespace';
const APP_SETTING_QUERY_FIELD_NAME = withNamespace('AppSettingQuery');
const AppSettingQuery = registerQuery({
  fieldName: APP_SETTING_QUERY_FIELD_NAME,
  args: ['appSettings'],
  fetcher: fetchAppSettings
});
export const useAppSettings = appSettings => {
  const {
    data,
    loading
  } = useQuery(AppSettingQuery, {
    variables: {
      appSettings
    }
  });
  return useMemo(() => ({
    data: data && data[APP_SETTING_QUERY_FIELD_NAME],
    loading
  }), [data, loading]);
};