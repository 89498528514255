import { useEffect, useMemo, useRef } from 'react';
/**
 * In some cases it may be necessary to mock dismissErrorWithoutHandling
 * in tests.
 */
export function dismissErrorWithoutHandling(error) {
  setTimeout(() => {
    throw error;
  }, 0);
}
function createRethrowAsync() {
  const syncStackError = new Error(`Encountered unexpected rejection in async side effect`);
  return error => {
    dismissErrorWithoutHandling(error);
    dismissErrorWithoutHandling(syncStackError);
  };
}
async function handleEffectResult(effectResult) {
  if (effectResult instanceof Promise) {
    return handleEffectResult(await effectResult);
  } else if (typeof effectResult === 'function') {
    return handleEffectResult(effectResult());
  }
  return undefined;
}
export function useAsyncEffect(effect, deps) {
  const rethrowAsync = useMemo(createRethrowAsync, []);
  const {
    current: asyncEffectState
  } = useRef({
    mostRecentEffect: effect,
    asyncQueue: Promise.resolve(undefined),
    done: false
  });
  asyncEffectState.mostRecentEffect = effect;
  useEffect(() => {
    const newLink = handleEffectResult(asyncEffectState.asyncQueue).catch(rethrowAsync).then(() => {
      // we've reached the end of the queue, so it's time to trigger work
      if (!asyncEffectState.done && asyncEffectState.asyncQueue === newLink) {
        return asyncEffectState.mostRecentEffect();
      } else {
        return undefined;
      }
    }).catch(rethrowAsync);
    asyncEffectState.asyncQueue = newLink;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asyncEffectState, rethrowAsync, ...deps]);
  useEffect(() => () => {
    asyncEffectState.done = true;
    handleEffectResult(asyncEffectState.asyncQueue).catch(rethrowAsync);
  }, [asyncEffectState, rethrowAsync]);
}