'use es6';

import http from 'hub-http/clients/apiClient';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
import get from 'transmute/get';
import { Map as ImmutableMap, fromJS, List } from 'immutable';
const formatWorkflows = formatToReferencesList({
  getId: get('id'),
  getLabel: get('name'),
  getDisabled: value => get('enabled', value) === false || !get('canEnrollFromSalesforce', value)
});
function getSalesforceWorkflows(httpClient) {
  return httpClient.get('automation/v2/workflows?property=id&property=name&property=enabled&property=canEnrollFromSalesforce').then(response => {
    if (!response || !response.workflows) {
      return [];
    }
    return response.workflows;
  });
}
function workflowComparator(a, b) {
  return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
}
function workflowSearchFilter(search) {
  search = search.toUpperCase();
  return workflow => {
    if (!search) {
      return true;
    }
    const name = workflow.name.toUpperCase();
    return name.indexOf(search) > -1;
  };
}
export const createGetSalesforceWorkflowsByIds = ({
  httpClient
}) => ids => {
  return getSalesforceWorkflows(httpClient).then(workflows => {
    if (!ids) {
      return workflows;
    }
    return workflows.filter(workflow => ids.indexOf(workflow.id) > -1).sort(workflowComparator);
  });
};
export const getSalesforceWorkflowsByIds = createGetSalesforceWorkflowsByIds({
  httpClient: http
});
export const createGetSalesforceWorkflowsBySearch = ({
  httpClient
}) => props => {
  const {
    query
  } = props.toJS();
  return getSalesforceWorkflows(httpClient).then(workflows => {
    const formattedWorkflows = formatWorkflows(fromJS(workflows.filter(workflowSearchFilter(query))));
    const groupedWfs = formattedWorkflows.groupBy(_ => _.disabled);
    const hasMore = false;
    const count = workflows.length;
    const offset = 0;
    const total = workflows.length;

    // Show all the enabled workflows at the top of the dropdown, then sort them
    const results = groupedWfs.get(false, List()).sort(workflowComparator).concat(groupedWfs.get(true, List()).sort(workflowComparator));
    return ImmutableMap({
      hasMore,
      offset,
      count,
      total,
      results
    });
  });
};
export const getSalesforceWorkflowsBySearch = createGetSalesforceWorkflowsBySearch({
  httpClient: http
});