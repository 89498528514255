import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getScopesSet } from '../selectors/authSelectors';
export const useHasAllScopes = () => {
  const scopesSet = useSelector(getScopesSet);
  return useCallback(
  // @ts-expect-error FIXME: conflicting signature at call site (scope: string | string[]) and also variadic signature in tests.
  (...scopes) => scopes.every(scope => scopesSet.has(scope)), [scopesSet]);
};

/**
 * This hook returns an array of all scopes present for the user (?). The data is supplied by the redux store, coming from the userInfo API.
 * @see https://product.hubteam.com/docs/appsystems/productcm/scopes.html#content
 *
 * If you need to check if a user has a specific scope, use the `useHasAllScopes` hook instead.
 *
 * @returns {string[]} - Array of all scopes
 */
export const useGetAllScopes = () => {
  const scopesSet = useSelector(getScopesSet);
  return useMemo(() => Array.from(scopesSet), [scopesSet]);
};