export function rewriteObjectPropertiesAsMap(graphQLObject) {
  if (
  // FIXME: Remove redundant checks after type conversions
  !graphQLObject || !graphQLObject.properties || !graphQLObject.properties.length) {
    return graphQLObject;
  }
  const clonedObject = Object.assign({}, graphQLObject, {
    properties: graphQLObject.properties.reduce((acc, prop) => {
      // @ts-expect-error TODO fix after client-types migration
      acc[prop.name] = prop;
      return acc;
    }, {})
  });
  return clonedObject;
}