import { useObjectLimitAndTotal } from '../../crmObjects/hooks/useObjectLimitAndTotal';
import { useSelectedObjectTypeId } from 'crm-index-ui/objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { useUpgradeModal } from 'ui-addon-upgrades/ums/useUpgradeModal';
import { PRODUCT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { FREE, STARTER } from '../constants/tiers';
import { getTierByProductLimit } from '../utils/utils';
export const IMPORT = 'IMPORT';
export const RESTORE = 'RESTORE';
export const CREATE = 'CREATE';
export const CLONE = 'CLONE';
function getLimitUpgradePointName(objectTypeId, buttonName, limit) {
  if (limit === undefined) {
    return null;
  }
  if (objectTypeId === PRODUCT_TYPE_ID) {
    const tier = getTierByProductLimit(limit);
    if (tier === FREE) {
      if (buttonName === IMPORT) {
        return 'product-index-page-import-starter';
      }
      if (buttonName === RESTORE) {
        return 'product-index-page-restore-starter';
      }
      if (buttonName === CREATE) {
        return 'product-index-page-create-product-starter';
      }
      if (buttonName === CLONE) {
        return 'product-index-page-clone-product-starter';
      }
    }
    if (tier === STARTER) {
      if (buttonName === IMPORT) {
        return 'product-index-page-import-professional';
      }
      if (buttonName === RESTORE) {
        return 'product-index-page-restore-professional';
      }
      if (buttonName === CREATE) {
        return 'product-index-page-create-product-professional';
      }
      if (buttonName === CLONE) {
        return 'product-index-page-clone-product-professional';
      }
    }
  }
  return null;
}

/**
 * This hook returns an object containing button props to support opening an upgrade point
 * when the button is clicked. It also returns whether the relevant information is loading or
 * if there was an error fetching it, so components can decide how to respond.
 *
 * Currently, it is implemented such that it will only turn buttons into upgrade points when
 * the current object type's unique record limit has been reached.
 *
 * Examples of where this is used: Import button, Restore records, Create record button.
 */
export function useLimitUpgradeButtonProps(buttonName) {
  const {
    loading,
    error,
    limitReached,
    limit
  } = useObjectLimitAndTotal();
  const objectTypeId = useSelectedObjectTypeId();
  const upgradePointName = getLimitUpgradePointName(objectTypeId, buttonName, limit);

  // the upgrade modal will fail to open if there is not a valid upgrade point key.
  const {
    openUpgradeModal
  } = useUpgradeModal(upgradePointName || '');
  if (loading) {
    return {
      loading,
      error: null,
      buttonProps: {
        'data-test-loading': loading
      }
    };
  }
  if (!limitReached || !upgradePointName) {
    return {};
  }
  return {
    loading,
    error,
    buttonProps: {
      // override default button behavior
      href: undefined,
      onClick: openUpgradeModal
    }
  };
}