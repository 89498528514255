import devLogger from 'react-utils/devLogger';
export const getPerformanceAPI = () => {
  if (window.performance) {
    return window.performance;
  } else {
    devLogger.warn({
      key: 'CRM_FE_PERF:NO_WINDOW_PERFORMANCE',
      message: `window.performance requested but does not exist`
    });
    return undefined;
  }
};