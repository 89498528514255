import { useMemo } from 'react';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import { registerQuery, useQuery } from 'data-fetching-client';
const ALL_PROPERTIES_QUERY_NAME = 'crmObjectTableAllProperties';
const allPropertiesQueryArgsArgument = ['objectTypeId'];
export const AllPropertiesQuery = registerQuery({
  fieldName: ALL_PROPERTIES_QUERY_NAME,
  args: allPropertiesQueryArgsArgument,
  fetcher: args => getFrameworkDataSchemasClient().then(client => client.properties.get({
    frameworkTypeIdentifier: args.objectTypeId,
    query: {
      showHighlySensitiveProperties: true
    }
  })).then(allProperties => {
    if (!allProperties) {
      return undefined;
    } else {
      const properties = allProperties.map(property => property.property);
      const propertiesByName = properties && properties.reduce((propertiesAccumulator, property) => {
        propertiesAccumulator[property.name] = property;
        return propertiesAccumulator;
      }, {});
      return propertiesByName;
    }
  })
});
export const useProperties = (objectTypeId, {
  skip = false
} = {}) => {
  const {
    data,
    loading,
    error
  } = useQuery(AllPropertiesQuery, {
    skip,
    variables: {
      objectTypeId
    }
  });
  const propertiesByName = data && data[ALL_PROPERTIES_QUERY_NAME];
  return useMemo(() => {
    return {
      data: propertiesByName,
      loading,
      error
    };
  }, [propertiesByName, loading, error]);
};