import { DEAL_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useHasAllScopes } from '../../auth/hooks/useHasAllScopes';
import { useSelectedObjectTypeId } from '../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
const SCOPES_BY_OBJECT_TYPE_ID = {
  [DEAL_TYPE_ID]: ['crm-pipelines-deal-tag-read-pre-seat', 'crm-pipelines-deal-tag-read'],
  [TICKET_TYPE_ID]: ['crm-pipelines-ticket-tag-read', 'crm-pipelines-ticket-tag-read-pre-seat']
};
export const useHasTagsPropertyRead = () => {
  const hasAllScopes = useHasAllScopes();
  const objectTypeId = useSelectedObjectTypeId();
  const scopes = SCOPES_BY_OBJECT_TYPE_ID[objectTypeId];
  if (!scopes) {
    return true;
  }
  return scopes.some(scope => hasAllScopes(scope));
};