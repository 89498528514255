'use es6';

import { fromJS } from 'immutable';
import getIn from 'transmute/getIn';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
export const formatTaskQueuesReferenceList = formatToReferencesList({
  getId: getIn(['definition', 'id']),
  getLabel: getIn(['definition', 'name'])
});
export const formatSearchResults = formatToReferencesList({
  getId: queue => queue.id,
  getLabel: queue => queue.name
});
export const formatTaskQueuesSearchResponse = response => {
  return fromJS({
    count: response.total || 0,
    hasMore: response.hasMore || false,
    offset: response.offset || 0,
    results: formatSearchResults(response.results || [])
  });
};