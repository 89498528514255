import { usePrefetch } from 'crm-object-board/prefetch';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { useCurrentPipeline } from '../../pipelines/hooks/useCurrentPipeline';
import { useSearchQuery } from '../../searchQuery/hooks/useSearchQuery';
import { useCurrentPipelineId } from '../../pipelines/hooks/useCurrentPipelineId';
import { useMemo } from 'react';
export const PrefetchBoardSearch = () => {
  const objectTypeId = useSelectedObjectTypeId();
  const pipeline = useCurrentPipeline();
  const pipelineId = useCurrentPipelineId();
  const query = useSearchQuery();
  const searchQuery = useMemo(() => {
    return {
      filterGroups: query.filterGroups,
      sorts: query.sorts,
      query: query.query
    };
  }, [query]);
  usePrefetch({
    pipelineId: pipelineId || '',
    objectTypeId,
    searchQuery,
    stageIds: ((pipeline === null || pipeline === void 0 ? void 0 : pipeline.stages) || []).map(stage => stage.stageId)
  });
  return null;
};