'use es6';

import { defineFactory } from 'general-store';
import invariant from 'react-utils/invariant';
import identity from 'transmute/identity';
import * as ActionTypes from 'crm_data/actions/ActionTypes';
import always from 'transmute/always';
import devLogger from 'react-utils/devLogger';
import Raven from 'raven-js';
import enviro from 'enviro';
export const defineAuthStore = ({
  getter = identity,
  initialState = [],
  name,
  onAuthLoad
}) => {
  invariant(typeof name === 'string' && name !== '', 'Name for auth store must be provided');
  invariant(typeof onAuthLoad === 'function', 'onAuthLoad must be provided and be a function');
  const getInitialState = always({
    isAuthLoaded: false,
    authData: initialState
  });
  return defineFactory().defineName(`Auth-${name}`).defineGetInitialState(getInitialState).defineGet((state, ...args) => {
    const {
      isAuthLoaded,
      authData
    } = state;
    if (!isAuthLoaded) {
      if (enviro.isProd()) {
        Raven.captureMessage('Attempted to access an Auth store without dispatching an AUTH_LOADED action', {
          extra: {
            args,
            name
          },
          level: 'info'
        });
      }
      devLogger.warn({
        message: 'Attempted to access an Auth store without dispatching an AUTH_LOADED action'
      });
    }
    return getter(authData, ...args);
  }).defineResponseTo(ActionTypes.AUTH_LOADED, (_, auth) => ({
    isAuthLoaded: true,
    authData: onAuthLoad(auth)
  }));
};