'use es6';

/* ABC order please */
export const APPROVE = 'APPROVE';
export const CREATE = 'CREATE';
export const DELETE = 'DELETE';
export const FETCH = 'FETCH';
export const REFRESH = 'REFRESH';
export const REORDER = 'REORDER';
export const REQUEST_CHANGES = 'REQUEST_CHANGES';
export const UPDATE = 'UPDATE';