import { RADIO } from 'customer-data-objects/property/PropertyFieldTypes';
import PropertyRecord from 'customer-data-objects/property/PropertyRecord';
import { ENUMERATION } from 'customer-data-objects/property/PropertyTypes';
import { INBOUND_DB_LIST } from '../ExternalOptionTypes';

// this is the new special property you should be using for all new list membership filtering
// ilslistmemberships.listid was just for migrating from contactssearch to crmsearch.
export default PropertyRecord({
  displayOrder: -1,
  externalOptions: true,
  favoritedOrder: -1,
  fieldType: RADIO,
  groupName: 'listmembership',
  hubspotDefined: true,
  label: 'List membership',
  name: 'hs_crm_search.ilsListIds',
  readOnlyDefinition: true,
  readOnlyValue: true,
  referencedObjectType: INBOUND_DB_LIST,
  type: ENUMERATION
});