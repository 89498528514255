'use es6';

import { OrderedMap } from 'immutable';
import ReferenceRecord from '../schema/ReferenceRecord';
import partial from 'transmute/partial';
import formatPipelinesWithPermissions from './formatPipelinesWithPermissions';
import setIn from 'transmute/setIn';
const getDisplayOrder = obj => {
  if (typeof obj.displayOrder === 'number') {
    return obj.displayOrder;
  } else {
    return -1;
  }
};
const formatStageId = stage => {
  return String(stage.get('stageId'));
};
const formatStageReference = (pipelineCount, pipelineRef, stage) => {
  return new ReferenceRecord({
    id: formatStageId(stage),
    label: pipelineCount > 1 ? `${stage.get('label')} (${pipelineRef.label})` : stage.get('label'),
    referencedObject: stage.merge({
      pipeline: pipelineRef.referencedObject
    }),
    // If a pipeline is marked as disabled, it's from pipeline permissions
    // and we need to disable all its stages as well.
    disabled: pipelineRef.disabled
  });
};
const getPipelineRefStages = (pipelineCount, pipelineRef) => {
  const sortedStages = pipelineRef.getIn(['referencedObject', 'stages']).sortBy(getDisplayOrder);
  return OrderedMap(sortedStages.map(stage => [formatStageId(stage), formatStageReference(pipelineCount, pipelineRef, stage)]));
};
const formatPipelineStagesWithPermissions = response => {
  const pipelineRefs = formatPipelinesWithPermissions(response);
  const pipelineCount = pipelineRefs.size;

  // We need to override the displayOrder, otherwise disabled stages get sorted incorrectly.
  // We're using entrySeq here to get access to the index of the stage. .map does not give it to us.
  return pipelineRefs.toSeq().map(partial(getPipelineRefStages, pipelineCount)).flatten(1).mapEntries(([stageId, stageRef], index) => [stageId, setIn(['referencedObject', 'displayOrder'], index, stageRef)]).toOrderedMap();
};
export default formatPipelineStagesWithPermissions;