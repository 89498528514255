import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeGetPendingInlineEditsPerObject } from './inlineEditingSlice';
export const usePendingInlineEditsForSpecificObject = ({
  objectTypeId,
  objectId
}) => {
  const getPendingInlineEditsForObjectAreInvalid = useMemo(() => makeGetPendingInlineEditsPerObject({
    objectTypeId,
    objectId
  }), [objectId, objectTypeId]);
  return useSelector(getPendingInlineEditsForObjectAreInvalid);
};