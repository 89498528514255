import { createSelector } from 'reselect';
import { Set as ImmutableSet, fromJS } from 'immutable';
import { createFrozenSelector } from '../../rewrite/utils/createFrozenSelector';
const getAuthSlice = state => state.auth;
const getAuthSliceAsImmutable = createSelector([getAuthSlice], slice => fromJS(Object.assign({}, slice, {
  gates: slice.gates.reduce((gatesSet, gate) => gatesSet.add(gate), ImmutableSet()),
  scopes: slice.scopes.reduce((scopesSet, scope) => scopesSet.add(scope), ImmutableSet())
})));
export const getAuthAsJS = createFrozenSelector([getAuthSlice], slice => slice);
export const getAuthUser = createSelector([getAuthSliceAsImmutable], slice => slice.get('user'));
export const getCurrentUserId = createFrozenSelector([getAuthUser], user => String(user.get('user_id')));
export const getCurrentUserTeamsAsJS = createFrozenSelector([getAuthUser], user => user.get('teams').toJS());
export const getGatesSet = createSelector([getAuthSliceAsImmutable], slice => slice.get('gates'));
export const getHasAllGates = createFrozenSelector([getGatesSet], gatesSet => (...gates) => gates.every(gate => gatesSet.has(gate)));
export const getGatesInLegacyFormat = createFrozenSelector([getGatesSet], gates => gates.toArray());
export const getScopesSet = createSelector([getAuthSliceAsImmutable], slice => slice.get('scopes'));
export const getScopesInLegacyFormat = createFrozenSelector([getScopesSet], scopes => scopes.reduce((scopesMap, scope) => {
  scopesMap[scope] = true;
  return scopesMap;
}, {}));
export const getPortalSlice = createFrozenSelector([getAuthSliceAsImmutable], authSlice => authSlice.get('portal'));
export const getLimitsMap = createFrozenSelector([getPortalSlice], portalSlice => portalSlice.get('limits'));