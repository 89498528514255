import { useQuery, registerQuery } from 'data-fetching-client';
import { getDefaultView } from '../api/getDefaultView';
import { parseViewFromBEModel } from '../../../views/utils/parseViews';
import { useIsViewCached } from '../../../views/hooks/useIsViewCached';
export const FetchDefaultView = registerQuery({
  fieldName: 'defaultView',
  args: ['objectTypeId', 'viewId'],
  fetcher: args => getDefaultView(args).then(parseViewFromBEModel)
});
export const useFetchDefaultView = ({
  objectTypeId,
  skip,
  viewId
}) => {
  const isViewCached = useIsViewCached(viewId);
  return useQuery(FetchDefaultView, {
    variables: {
      objectTypeId,
      viewId
    },
    skip: skip || isViewCached
  });
};