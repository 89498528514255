'use es6';

import { OrderedMap } from 'immutable';
import dispatcher from 'dispatcher/dispatcher';
import { DEAL_PIPELINES } from '../actions/ActionNamespaces';
import { defineLazyValueStore } from '../store/LazyValueStore';
import { fetch } from './api/DealPipelineAPI';
import { PIPELINE_UPDATED, PIPELINE_DELETED, PIPELINES_UPDATED } from 'crm_data/actions/ActionTypes';
export default defineLazyValueStore({
  fetch,
  namespace: DEAL_PIPELINES
}).defineName('DealPipelineStore').defineResponseTo(PIPELINE_UPDATED, (state, {
  key,
  value
}) => {
  return state.setIn(['value', key], value);
}).defineResponseTo(PIPELINE_DELETED, (state, pipelineId) => {
  return state.deleteIn(['value', pipelineId]);
}).defineResponseTo(PIPELINES_UPDATED, (state, pipelines) => {
  const updatedPipelines = pipelines.reduce((map, pipeline) => {
    return map.set(pipeline.get('pipelineId'), pipeline);
  }, OrderedMap());
  return state.set('value', updatedPipelines);
}).register(dispatcher);