import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FAILED, PENDING, SUCCEEDED, UNINITIALIZED } from '../../constants/RequestStatus';
import { mutableSetIn } from '../../objectUtils/mutableSetIn';
import { loadViewColumnWidthsFromSuperStore, setViewColumnWidthsInSuperStore } from './columnWidthsSuperStore';
import { createFrozenSelector } from '../../utils/createFrozenSelector';
import getIn from 'transmute/getIn';
const initialState = {
  data: {},
  status: {}
};
const getColumnWidthsSlice = state => {
  return state.columnWidths;
};
export const makeGetColumnWidthsStatus = (objectTypeId, viewId) => createFrozenSelector([getColumnWidthsSlice], state => getIn(['status', objectTypeId, viewId], state) || UNINITIALIZED);
export const makeGetColumnWidths = (objectTypeId, viewId) => createFrozenSelector([getColumnWidthsSlice], state => getIn(['data', objectTypeId, viewId], state) || {});
export const loadViewColumnWidths = createAsyncThunk('viewColumnWidths/load', loadViewColumnWidthsFromSuperStore);
export const setViewColumnWidths = createAsyncThunk('viewColumnWidths/set', ({
  objectTypeId,
  viewId,
  columnName,
  width
}, {
  getState
}) => {
  const state = getState();
  const currentValue = makeGetColumnWidths(objectTypeId, viewId)(state);
  const nextValue = Object.assign({}, currentValue, {
    [columnName]: width
  });
  return setViewColumnWidthsInSuperStore({
    objectTypeId,
    viewId,
    value: nextValue
  });
});
const widthsSlice = createSlice({
  name: 'columnWidths',
  initialState,
  reducers: {
    setWidth: (state, {
      payload: {
        objectTypeId,
        viewId,
        columnName,
        width
      }
    }) => {
      mutableSetIn([objectTypeId, viewId, columnName], width, state);
    }
  },
  extraReducers: builder => builder.addCase(loadViewColumnWidths.pending, (state, action) => {
    const {
      objectTypeId,
      viewId
    } = action.meta.arg;
    mutableSetIn(['status', objectTypeId, viewId], PENDING, state);
  }).addCase(loadViewColumnWidths.fulfilled, (state, action) => {
    const {
      objectTypeId,
      viewId
    } = action.meta.arg;
    const value = action.payload || {};
    mutableSetIn(['data', objectTypeId, viewId], value, state);
    mutableSetIn(['status', objectTypeId, viewId], SUCCEEDED, state);
  }).addCase(loadViewColumnWidths.rejected, (state, action) => {
    const {
      objectTypeId,
      viewId
    } = action.meta.arg;
    mutableSetIn(['status', objectTypeId, viewId], FAILED, state);
  }).addCase(setViewColumnWidths.pending, (state, action) => {
    const {
      objectTypeId,
      viewId,
      columnName,
      width
    } = action.meta.arg;
    mutableSetIn(['data', objectTypeId, viewId, columnName], width, state);
    mutableSetIn(['status', objectTypeId, viewId], SUCCEEDED, state);
  })
});
export const columnWidthsReducer = widthsSlice.reducer;
export const {
  setWidth
} = widthsSlice.actions;