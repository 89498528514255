'use es6';

import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import createSimpleCachedReferenceResolver from 'reference-resolvers/lib/createSimpleCachedReferenceResolver';
import { getAllDispositionTypes, createGetAllDispositionTypes } from 'reference-resolvers/api/CallDispositionsAPI';
export const createCallDispositionReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.CALL_DISPOSITIONS,
  createFetchData: createGetAllDispositionTypes,
  fetchData: getAllDispositionTypes,
  selectAllReferences: dispositions => dispositions.filter(disposition => !disposition.archived)
}, options));
export default createCallDispositionReferenceResolver();