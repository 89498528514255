export default {
  create() {
    let _data;
    const container = {
      set(data) {
        _data = data;
        return _data;
      },
      get() {
        return _data;
      }
    };
    return container;
  }
};