'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import ReferenceRecord from '../schema/ReferenceRecord';
const formatMarketingContactReason = response => ImmutableMap(response).reduce((acc, {
  url,
  label
}, key) => acc.push(ReferenceRecord({
  description: url,
  label: label || '',
  id: key
})), List());
export default formatMarketingContactReason;