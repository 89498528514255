/**
 * utility adapted from polyfill at https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries#polyfill
 * @deprecated - This function is deprecated. Use Object.entries instead.
 */
export const objectEntries = function objectEntries(obj) {
  const ownProps = Object.keys(obj);
  let i = ownProps.length;
  const resArray = new Array(i); // preallocate the Array
  while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];
  return resArray;
};