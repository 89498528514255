// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import * as LocalSettings from 'crm_data/settings/LocalSettings';
import devLogger from 'react-utils/devLogger';
import toJS from 'transmute/toJS';
import isString from 'transmute/isString';
import isEmpty from 'transmute/isEmpty';
import isNull from 'transmute/isNull';
import filterNot from 'transmute/filterNot';
import { ObjectTypesToIds } from 'customer-data-objects/constants/ObjectTypeIds';
const emptyResult = {};

// This was duplicated from normalizeTypeId.js in crm-index-ui as part of https://git.hubteam.com/HubSpot/CRM/pull/22295
// If you make changes here, please go make them there as well!
export const normalizeTypeId = objectType => ObjectTypesToIds[objectType] || objectType;
export const safeStringify = value => {
  try {
    return JSON.stringify(value);
  } catch (err) {
    devLogger.warn({
      message: err.message,
      key: `gridStateLocalStorage::safeStringify`
    });
    return '';
  }
};
export const safeParse = value => {
  try {
    return isString(value) || value === null ? JSON.parse(value) : emptyResult;
  } catch (err) {
    devLogger.warn({
      message: err.message,
      key: `gridStateLocalStorage::safeParse`
    });
  }
  return emptyResult;
};
export const deleteGridState = ({
  objectType,
  viewId
}) => {
  const objectTypeId = normalizeTypeId(objectType);
  if (!objectTypeId || !viewId) {
    devLogger.warn({
      key: `gridStateLocalStorage::deleteGridState`,
      message: `deleteGridState requires objectType and viewId`
    });
  } else {
    LocalSettings.deleteFrom(sessionStorage, `${objectTypeId}:${viewId}:gridState`);
  }
};
export const getGridState = ({
  objectType,
  viewId
}) => {
  const objectTypeId = normalizeTypeId(objectType);
  if (!objectTypeId || !viewId) {
    devLogger.warn({
      key: `gridStateLocalStorage::getGridState`,
      message: `getGridState requires objectType and viewId`
    });
  } else {
    const gridState = LocalSettings.getFrom(sessionStorage, `${objectTypeId}:${viewId}:gridState`);
    if (gridState) {
      return safeParse(gridState);
    }
    const legacyGridState = LocalSettings.getFrom(sessionStorage, `${objectType}:${viewId}:gridState`);
    return safeParse(legacyGridState);
  }
  return emptyResult;
};
export const updateGridState = ({
  objectType,
  viewId,
  key,
  value
}) => {
  const objectTypeId = normalizeTypeId(objectType);
  if (!objectTypeId || !viewId) {
    devLogger.warn({
      key: `gridStateLocalStorage::updateGridState`,
      message: `updateGridState requires objectType and viewId`
    });
  } else {
    const oldGridState = getGridState({
      objectType: objectTypeId,
      viewId
    });
    let newValue = toJS(value);
    if (key === 'state') {
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      newValue = Object.assign({}, oldGridState.state, newValue);
    }
    const newGridState = key ? Object.assign({}, oldGridState, {
      [key]: newValue
    }) : oldGridState;
    LocalSettings.setFrom(sessionStorage, `${objectTypeId}:${viewId}:gridState`, safeStringify(newGridState));
  }
};
export const getUIState = objectType => {
  return getGridState({
    objectType,
    viewId: 'lastAccessedView'
  });
};
export const updateUIState = ({
  objectType,
  key,
  value
}) => {
  updateGridState({
    objectType,
    viewId: 'lastAccessedView',
    key,
    value
  });
};
const getLastAccessedView = objectType => {
  return getGridState({
    objectType,
    viewId: 'lastAccessedView'
  });
};
export const setLastAccessedView = ({
  objectType,
  viewId
}) => {
  updateGridState({
    objectType,
    viewId: 'lastAccessedView',
    key: 'viewId',
    value: viewId
  });
};
export const clearLastAccessedView = objectType => {
  deleteGridState({
    objectType,
    viewId: 'lastAccessedView'
  });
};
export const setLastAccessedPage = ({
  objectType,
  value
}) => {
  updateGridState({
    objectType,
    viewId: 'lastAccessedView',
    key: 'lastPage',
    value
  });
};
export const getLastAccessedPage = ({
  objectType,
  viewId
}) => {
  const {
    lastPage
  } = getLastAccessedView(objectType);
  if (lastPage && lastPage.viewId === viewId) {
    return lastPage;
  }
  return undefined;
};
export const deleteLastAccessedPage = objectType => {
  updateGridState({
    objectType,
    viewId: 'lastAccessedView',
    key: 'lastPage',
    value: undefined
  });
};
export const reconcileWithCache = ({
  objectType,
  viewId
}, view) => {
  let cache = getGridState({
    objectType,
    viewId
  });
  if (cache === emptyResult || isEmpty(cache)) {
    return view;
  }
  cache = filterNot(isNull, cache);
  const combinedState = Object.assign({}, view.state, cache.state);
  return Object.assign({}, view, cache, {
    state: combinedState
  });
};