import { useMemo } from 'react';
import { useObjectTypeDefinitions } from './useObjectTypeDefinitions';
import { useHasAllScopes } from '../../auth/hooks/useHasAllScopes';
import { useHasAllGates } from '../../auth/hooks/useHasAllGates';
import { CRM_INDEX_PAGE_APP_SETTING } from '../../frameworkAppSettings/constants/AppSettings';
import { getIsPortalSpecific } from '../methods/getIsPortalSpecific';
import { getAppSettingForTypeDef, getFieldValue } from '../../frameworkAppSettings/utils/getFieldValue';
import { useFrameworkAppSettings } from '../../frameworkAppSettings/context/AppSettingsContext';
/**
 * Return the list of visible type definition, based on a varieties of rules such as scopes, gates, and
 */
export const useAvailableObjectTypes = () => {
  const typeDefs = useObjectTypeDefinitions();
  const hasAllScopes = useHasAllScopes();
  const hasAllGates = useHasAllGates();
  const appSettings = useFrameworkAppSettings();
  return useMemo(() => {
    return new Map(Array.from(typeDefs.entries()).filter(([__, typeDef]) => {
      // Bypass for custom objects, since we can't apply metadata to an entire meta type
      if (getIsPortalSpecific(typeDef)) {
        return hasAllScopes('custom-object-read');
      }
      const metadata = getAppSettingForTypeDef(appSettings, typeDef, CRM_INDEX_PAGE_APP_SETTING);
      const isEnabled = getFieldValue(metadata, 'enabled');
      const enableCases = getFieldValue(metadata, 'enableCases');
      return isEnabled && (!enableCases || !enableCases.length || enableCases.some(({
        scopes,
        gates
      }) => {
        const includedGates = gates && gates.includes || [];
        const excludedGates = gates && gates.excludes || [];
        const includedScopes = scopes && scopes.includes || [];
        const excludedScopes = scopes && scopes.excludes || [];
        return includedGates.every(gate => hasAllGates(gate)) && !excludedGates.some(gate => hasAllGates(gate)) && includedScopes.every(scope => hasAllScopes(scope)) && !excludedScopes.some(scope => hasAllScopes(scope));
      }));
    }));
  }, [appSettings, typeDefs, hasAllScopes, hasAllGates]);
};