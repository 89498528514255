'use es6';

import { List, Map as ImmutableMap, fromJS } from 'immutable';
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
const formatListId = list => String(list.listId);
const formatSegmentReference = listObj => {
  const {
    list
  } = listObj;
  return new ReferenceRecord({
    id: formatListId(list),
    label: list.name,
    referencedObject: fromJS(list)
  });
};
const formatContactSegments = response => {
  const {
    lists,
    offset,
    count,
    total,
    hasMore
  } = response;
  return ImmutableMap({
    hasMore,
    offset,
    count,
    total,
    results: List(lists).map(formatSegmentReference)
  });
};
export default formatContactSegments;