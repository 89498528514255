import { useMemo } from 'react';
import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchObjectTypeDefinitions } from '../api';
import { withNamespace } from '../../utils/withNamespace';
const OBJECT_TYPE_DEFINITION_QUERY_NAME = withNamespace('ObjectTypeDefinitionsQuery');
const ObjectTypeDefinitionsQuery = registerQuery({
  fieldName: OBJECT_TYPE_DEFINITION_QUERY_NAME,
  args: [],
  fetcher: fetchObjectTypeDefinitions
});
export const useObjectTypeDefinitions = () => {
  const {
    data,
    loading
  } = useQuery(ObjectTypeDefinitionsQuery);
  return useMemo(() => ({
    data: data && data[OBJECT_TYPE_DEFINITION_QUERY_NAME],
    loading
  }), [data, loading]);
};