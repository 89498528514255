import { useMemo } from 'react';
import { useObjectTypeSupportsFeature } from './useObjectTypeSupportsFeature';
import { useGetHasScope } from '../../userInfo/hooks/useGetHasScope';
export const useHasFeatureAccess = feature => {
  const objectHasFeature = useObjectTypeSupportsFeature(feature);
  const hasScope = useGetHasScope();
  return useMemo(() => {
    // No feature entry means no access
    if (!feature) {
      return false;
    }

    // If the object doesn't support it, no access
    if (!objectHasFeature) {
      return false;
    }

    // No scopes means no access
    if (!feature.accessScopes || !feature.accessScopes.length) {
      return false;
    }

    // Must have all scopes for access
    return feature.accessScopes.every(accessScope => hasScope(accessScope));
  }, [feature, hasScope, objectHasFeature]);
};