import { useCallback } from 'react';
import { usePojoProperties } from './useProperties';

// Historically the index page used GridProperties https://git.hubteam.com/HubSpot/CRM/blob/master/crm_data/static/js/properties/GridProperties.js#L16
// But over time, GridProperties has been shared between different apps, different libraries, and is a relic of the CRM monolith
//
// We cut ties with GridProperties, and instead are opting for a crm-index-ui specific instance that we can shave down over time.
// Right now there is a single "business logic" use case left, "associatedcompanyid" that we need to account for,
// Every other real property behaves like it should.
//
// Ref https://hubspot.slack.com/archives/C3JF6497E/p1709751900846419
//
// I believe when the index page fully migrates over to SegmentationFilters, we can remove this entirely.
// SegmentationFilters allows for generating association filters (including associated companies) from scratch, so we will not need to leverage this property anymore.
//
// The backend double-writes associatedcompanyid to the association service behind CONTACT_TO_COMPANY https://git.hubteam.com/HubSpot/Contacts/issues/10830#issuecomment-6135472
// https://git.hubteam.com/HubSpot/Contacts/issues/10830

const REAL_PROPERTIES_THAT_ARE_HIDDEN_BUT_SHOULD_BE_SHOWN = ['associatedcompanyid'];
export const useIsVisibleFilterPropertyName = () => {
  const properties = usePojoProperties();
  return useCallback(propertyName => {
    const property = properties[propertyName];
    if (!property) {
      return true; //we do not want to hide the fact that a property is deleted/invalid from the user
    }
    if (REAL_PROPERTIES_THAT_ARE_HIDDEN_BUT_SHOULD_BE_SHOWN.includes(propertyName)) {
      return true;
    }
    return !property.hidden;
  }, [properties]);
};