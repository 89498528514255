'use es6';

import { Map as ImmutableMap } from 'immutable';
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
export default (({
  currencyExchangeRates,
  hasMulticurrencyActive,
  homeCurrency
}) => {
  const defaultRecord = ReferenceRecord({
    id: 'default',
    label: homeCurrency.currencyCode,
    referencedObject: ImmutableMap(Object.assign({}, homeCurrency, {
      hasMulticurrencyActive
    }))
  });
  const exchangeRecords = currencyExchangeRates.reduce((acc, exchangeRate) => {
    return acc.set(exchangeRate.fromCurrencyCode, ReferenceRecord({
      id: exchangeRate.fromCurrencyCode,
      label: exchangeRate.fromCurrencyCode,
      description: `${exchangeRate.conversionMultiplier}`,
      referencedObject: ImmutableMap(exchangeRate)
    }));
  }, ImmutableMap());
  return exchangeRecords.set('default', defaultRecord);
});