/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import { makeReferenceResolver } from 'reference-resolvers/ReferenceResolver';
import ResolverLoading from 'reference-resolvers/schema/ResolverLoading';
import ResolverError from 'reference-resolvers/schema/ResolverError';
import { Set as ImmutableSet, Map as ImmutableMap, Range } from 'immutable';
import debounce from 'transmute/debounce';
import updateIn from 'transmute/updateIn';
import curry from 'transmute/curry';
import hasIn from 'transmute/hasIn';
import isArray from 'transmute/isArray';
import isEmpty from 'transmute/isEmpty';
import always from 'transmute/always';
import indexBy from 'transmute/indexBy';
import identity from 'transmute/identity';
import merge from 'transmute/merge';
import get from 'transmute/get';
import ifThen from 'transmute/ifThen';
import pipe from 'transmute/pipe';
import setIn from 'transmute/setIn';
import filterNot from 'transmute/filterNot';
import { swap, deref, watch, unwatch } from 'atom';
import invariant from 'react-utils/invariant';
const chunk = (collection, size) => Range(0, collection.count(), size).map(start => collection.slice(start, start + size));
const not = curry((test, subject) => !test(subject));
const toIdsSet = (ids = []) => {
  if (!isArray(ids)) {
    ids = [ids];
  }
  return ImmutableSet(ids.map(String));
};
const createCache = atom => {
  swap(atom, ifThen(isEmpty, always(ImmutableMap({
    idQueue: ImmutableSet(),
    objects: ImmutableMap(),
    startDates: ImmutableMap()
  }))));
  return {
    queueId(id) {
      swap(atom, ifThen(not(hasIn(['objects', id])), pipe(updateIn(['idQueue'], queue => queue.add(id)), setIn(['startDates', id], Date.now()))));
    },
    getQueue() {
      const queue = deref(atom).get('idQueue');
      return queue;
    },
    loadingData(ids) {
      const newObjects = indexBy(identity, ids).map(() => ImmutableMap({
        loading: true,
        reference: null
      }));
      swap(atom, state => state.update('objects', merge(newObjects)).update('idQueue', queue => queue.subtract(ids)));
    },
    loadingDataFailed(ids, e) {
      const newObjects = indexBy(identity, ids).map(() => ImmutableMap({
        loading: false,
        error: e,
        reference: null
      }));
      swap(atom, pipe(updateIn(['objects'], merge(newObjects)), updateIn(['startDates'], filterNot((_, id) => ids.contains(id)))));
    },
    loadedData(ids, references) {
      const newObjects = indexBy(get('id'), references).map(reference => ImmutableMap({
        loading: false,
        reference
      }));
      const missing = indexBy(identity, ids.filter(id => !newObjects.has(id))).map(() => ImmutableMap({
        loading: false,
        reference: null
      }));
      swap(atom, pipe(updateIn(['objects'], merge(newObjects.merge(missing))), updateIn(['startDates'], filterNot((_, id) => ids.contains(id)))));
    },
    listen(onUpdate) {
      watch(atom, onUpdate);
      onUpdate(deref(atom));
      return () => {
        unwatch(atom, onUpdate);
      };
    },
    evict(ids) {
      if (ids.isEmpty()) {
        ids = deref(atom).get('objects').keySeq();
      }
      swap(atom, updateIn(['objects'], filterNot((object, id) => !object.get('loading') && ids.contains(id))));
    }
  };
};
const createBatchedReferenceResolver = ({
  cacheKey,
  createFetchSearchPage,
  createFetchByIds,
  fetchSearchPage,
  fetchByIds,
  httpClient,
  debouncePeriod = 500,
  maxBatchSize = 500,
  idIsValid = () => true
}) => {
  invariant(cacheKey && typeof cacheKey === 'string', 'expected `cacheKey` to be a non-empty string');
  if (httpClient) {
    invariant(!!(createFetchSearchPage || createFetchByIds), 'createFetchSearchPage or createFetchByIds factory function not provided for supplied httpClient for batched resolver');
    fetchSearchPage = createFetchSearchPage && createFetchSearchPage({
      httpClient
    });
    fetchByIds = createFetchByIds && createFetchByIds({
      httpClient
    });
  }
  return getCacheAtom => {
    const cache = createCache(getCacheAtom(cacheKey));
    const makeBatchedIdRequest = debounce(debouncePeriod, () => {
      const ids = cache.getQueue();
      if (ids.size > 0) {
        cache.loadingData(ids);
        const chunks = chunk(ids, maxBatchSize);
        chunks.forEach(chunkIds => {
          fetchByIds(chunkIds.toArray()).then(results => cache.loadedData(chunkIds, results), e => {
            cache.loadingDataFailed(chunkIds, e);
          });
        });
      }
    });
    const queueIdFetch = id => {
      cache.queueId(id);
      makeBatchedIdRequest();
    };
    return makeReferenceResolver({
      byId(id, next) {
        return cache.listen(state => {
          if (!idIsValid(id)) {
            return;
          }
          const object = state.getIn(['objects', id]);
          const queued = state.hasIn(['idQueue', id]);
          if (object == null && !queued) {
            queueIdFetch(id);
          } else if (object == null || object && object.get('loading')) {
            const startDate = state.getIn(['startDates', id]);
            next(ResolverLoading({
              startDate
            }));
          } else if (object.get('error')) {
            next(ResolverError({
              reason: object.get('error')
            }));
          } else if (!object.get('loading')) {
            next(object.get('reference'));
          }
        });
      },
      search(query, next) {
        next(ResolverLoading({
          startDate: Date.now()
        }));
        fetchSearchPage(query).then(next).catch(e => next(ResolverError({
          reason: e
        })));
        return () => {};
      },
      refreshCache(ids) {
        cache.evict(toIdsSet(ids));
      }
    }, `BatchedReferenceResolver_${cacheKey}`);
  };
};
export default createBatchedReferenceResolver;