import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import once from 'transmute/once';
import { usePinnedViewIdsWithCurrentView } from '../../rewrite/pinnedViews/hooks/usePinnedViewIdsWithCurrentView';
import { usePinnedViewLimit } from '../../rewrite/pinnedViews/hooks/usePinnedViewLimit';
import { trackIndexPageView, LOG_INDEX_PAGE_VIEW_TYPES, PAGE_VIEW_TO_VIEW_TYPE_MAP } from '../../tracking/indexPageTracking';
import { getPinnedViewIds } from '../../rewrite/pinnedViews/selectors/pinnedViewsSelectors';
const trackIndexPageViewOnce = 'jasmine' in window ? trackIndexPageView : once(trackIndexPageView);

/**
 * @function useTrackIndexPageViewOnLoad
 * @param {PageType} props.pageType
 * @param {Object} props.typeDef
 */
export const useTrackIndexPageViewOnLoad = ({
  pageType,
  typeDef
}) => {
  const viewIds = usePinnedViewIdsWithCurrentView();
  const pinnedViewsLimit = usePinnedViewLimit();
  const numberOfPinnedViews = viewIds.length;
  // Use the selector directly to avoid fallback logic, we want to know if the
  // pinned views are loaded
  const pinnedViewIds = useSelector(getPinnedViewIds);

  /**
   * Pinned views are lazily loaded now. We need to wait for them to load before tracking.
   */
  useEffect(() => {
    if (!pinnedViewIds.length) {
      return;
    }
    trackIndexPageViewOnce({
      viewType: PAGE_VIEW_TO_VIEW_TYPE_MAP[pageType] || LOG_INDEX_PAGE_VIEW_TYPES.LIST_VIEW,
      typeDef,
      pinnedViewsLimit,
      numberOfPinnedViews
    });
  }, [pageType, pinnedViewIds, typeDef, pinnedViewsLimit, numberOfPinnedViews]);
};