import Raven from 'raven-js';
export const getJsonFilters = value => {
  try {
    return JSON.parse(decodeURIComponent(value));
  } catch (err) {
    Raven.captureException(err, {
      extra: {
        value
      }
    });
    return JSON.parse(value);
  }
};