import { useMemo } from 'react';
import { useFetchRawAllAppSettings } from './useFetchRawAllAppSettings';
import { useFetchObjectTypeNamesToIds } from './useFetchObjectTypeNamesToIds';
import { FetchStatuses, isLoading, isRejected, isResolved } from '../../types/FetchStatus';
const getAllAppSettingsByObjectTypeId = (rawAllAppSettings, objectTypeNamesToIds) => {
  return Object.keys(rawAllAppSettings).reduce((appSettings, objectTypeName) => {
    const objectTypeId = objectTypeNamesToIds.get(objectTypeName);
    if (objectTypeId) {
      appSettings.set(objectTypeId, rawAllAppSettings[objectTypeName]);
    }
    return appSettings;
  }, new Map());
};
export const useFetchAllAppSettings = () => {
  const rawAllAppSettingsState = useFetchRawAllAppSettings();
  const objectTypeNamesToIdsState = useFetchObjectTypeNamesToIds();
  return useMemo(() => {
    // Since Framework App Settings (FAS) is the primary data source, if both FAS and Framework schema data resolver (FSDR) fail,
    // we prioritize sending the FAS error forward.
    if (isRejected(rawAllAppSettingsState)) {
      return {
        data: null,
        status: FetchStatuses.REJECTED,
        error: rawAllAppSettingsState.error
      };
    }
    if (isRejected(objectTypeNamesToIdsState)) {
      return {
        data: null,
        status: FetchStatuses.REJECTED,
        error: objectTypeNamesToIdsState.error
      };
    }

    // Combined LOADING state
    if (isLoading(rawAllAppSettingsState) || isLoading(objectTypeNamesToIdsState)) {
      return {
        data: null,
        status: FetchStatuses.LOADING
      };
    }

    // Combined RESOLVED state
    if (isResolved(rawAllAppSettingsState) && isResolved(objectTypeNamesToIdsState)) {
      return {
        data: getAllAppSettingsByObjectTypeId(rawAllAppSettingsState.data, objectTypeNamesToIdsState.data),
        status: FetchStatuses.RESOLVED
      };
    }

    // Combined IDLE state
    return {
      data: null,
      status: FetchStatuses.IDLE
    };
  }, [rawAllAppSettingsState, objectTypeNamesToIdsState]);
};