'use es6';

import http from 'hub-http/clients/apiClient';
import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { getEmailCampaignsByid, createGetEmailCampaignsByid } from 'reference-resolvers/api/EmailCampaignAPI';
import { makeCreateGetEmailCampaignsSearchPage } from 'reference-resolvers/api/EmailCampaignAPI';
export const createEmailCampaignReferenceResolver = (options, idLevel = null) => {
  const createFetchSearchPage = makeCreateGetEmailCampaignsSearchPage(idLevel);
  return createBatchedReferenceResolver(Object.assign({
    cacheKey: CacheKeys.EMAIL_CAMPAIGNS,
    createFetchByIds: createGetEmailCampaignsByid,
    createFetchSearchPage,
    fetchByIds: getEmailCampaignsByid,
    fetchSearchPage: createFetchSearchPage({
      httpClient: http
    }),
    maxBatchSize: 100
  }, options));
};
export default createEmailCampaignReferenceResolver();