import { createSlice } from '@reduxjs/toolkit';
export const errorSlice = createSlice({
  name: 'error',
  initialState: {
    error: null
  },
  reducers: {
    setError: (state, action) => {
      if (!state.error) {
        state.error = action.payload;
      }
    }
  }
});