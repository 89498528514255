/* hs-eslint ignored failing-rules */
/* eslint-disable no-bitwise */

'use es6';

const descriptionKeys = {};
export default (description => {
  if (descriptionKeys[description]) return descriptionKeys[description];
  let hash = 0;
  if (description.length === 0) return hash;
  description.split('').forEach(char => {
    hash = (hash << 5) - hash + char.charCodeAt(0);
    hash = hash & hash;
  });
  const key = `desc_${hash.toString().replace('-', 'n')}`;
  descriptionKeys[description] = key;
  return key;
});