import I18n from 'I18n';

// TODO: at some point, hopefully I18n will export currencySymbols in their
// type definition. Then this can be type CurrencyCode = keyof I18n.currencySymbols
export function isCurrencyCode(maybeCurrencyCode) {
  if (typeof maybeCurrencyCode === 'string') {
    //@ts-expect-error untyped
    return maybeCurrencyCode in I18n.currencySymbols;
  }
  return false;
}