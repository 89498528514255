'use es6';

import { Map as ImmutableMap } from 'immutable';
import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import formatSalesforceCampaigns from 'reference-resolvers/formatters/formatSalesforceCampaigns';
const BATCH_URL = 'sfdc/v1/campaigns/batch';
const campaignSearchFilter = search => campaigns => {
  if (!search) {
    return campaigns;
  }
  return campaigns.filter(campaign => campaign.name.toUpperCase().indexOf(search.toUpperCase()) > -1);
};
export const createGetAllSalesforceCampaigns = ({
  httpClient
}) => () => httpClient.get(`sfdc/v1/campaigns/${PortalIdParser.get()}?detailView=false`).then(formatSalesforceCampaigns);
export const getAllSalesforceCampaigns = createGetAllSalesforceCampaigns({
  httpClient: http
});
export const createGetSalesforceCampaignsById = ({
  httpClient
}) => ids => {
  return httpClient.post(BATCH_URL, {
    data: ids
  }).then(formatSalesforceCampaigns);
};
export const getSalesforceCampaignsById = createGetSalesforceCampaignsById({
  httpClient: http
});
export const createGetSalesforceCampaignsBySearch = options => props => {
  const {
    query
  } = props.toJS();
  const getFn = createGetAllSalesforceCampaigns(options);
  return getFn().then(campaignSearchFilter(query)).then(campaigns => ImmutableMap({
    hasMore: false,
    offset: 0,
    count: campaigns.size,
    total: campaigns.size,
    results: campaigns.toList()
  }));
};
export const getSalesforceCampaignsBySearch = createGetSalesforceCampaignsBySearch({
  httpClient: http
});