import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
export function createShowAlertHandler({
  floatingAlertStoreInstance
}) {
  return {
    topic: TOPIC_NAMES.SHOW_ALERT,
    type: 'CALLBACK',
    callback: data => {
      floatingAlertStoreInstance.addAlert({
        titleText: data.title,
        message: data.message,
        type: data.type
      });
    }
  };
}