import { CRM_TAGS_SETTINGS } from '../constants/AppSettings';
export const getCustomObjectFallbackAppSettings = () => ({
  [CRM_TAGS_SETTINGS]: {
    metadata: {
      accessGates: {
        source: CRM_TAGS_SETTINGS,
        value: [],
        isPortalSpecific: true
      },
      accessScopes: {
        value: ['custom-object-access', 'crm-pipelines-object-label-access'],
        source: CRM_TAGS_SETTINGS,
        isPortalSpecific: true
      },
      propertyName: {
        value: 'hs_tag_ids',
        source: CRM_TAGS_SETTINGS,
        isPortalSpecific: true
      },
      enabled: {
        value: true,
        source: CRM_TAGS_SETTINGS,
        isPortalSpecific: true
      }
    }
  }
});