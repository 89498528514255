import Raven from 'raven-js';
import { QUERY_DELETED } from '../LocalMutationsStore';
import { produce } from 'immer';
export const editQuery = ({
  input: {
    queryHash,
    edits
  },
  currentState
}) => {
  const nextState = produce(currentState, draft => {
    const currentMutation = draft.queryMutations[queryHash];
    if (currentMutation === QUERY_DELETED) {
      const error = new Error('LocalMutationsStore - trying to edit deleted query');
      Raven.captureException(error);
      return;
    }
    if (!currentMutation) {
      draft.queryMutations[queryHash] = {
        edits,
        newObjects: []
      };
      return;
    }
    currentMutation.edits = Object.assign({}, currentMutation.edits, edits);
    return;
  });
  return nextState;
};