import CrmLogger from '../../../loggers/crmLogger';
import { useEffect } from 'react';
import { createKeyboardShortcut } from '../../profile/shortcuts/KeyboardShortcut';
import { createShortcutHandler } from '../../profile/shortcuts/ProfileKeyboardShortcutHandler';
import KeyCodes, { MODIFIER_KEYS } from '../../profile/shortcuts/KeyCodes';
import { keyCombination, anyOf, exactly } from '../../profile/shortcuts/KeyTesters';
export default function PageRefreshUsageLogger({
  objectType
}) {
  const logPageRefresh = function logPageRefresh() {
    CrmLogger.logImmediate('pageRefresh', {
      objectType
    });
  };
  const refreshShortcutCmdR = createKeyboardShortcut(logPageRefresh, keyCombination(anyOf(...MODIFIER_KEYS), exactly(KeyCodes.R_KEY)));
  const refreshShortcutF5 = createKeyboardShortcut(logPageRefresh, exactly(KeyCodes.F5_KEY));
  const shortcutHandler = createShortcutHandler(refreshShortcutCmdR, refreshShortcutF5);
  useEffect(() => shortcutHandler.initializeShortcutListener(false));
  return null;
}