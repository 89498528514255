import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import Raven from 'raven-js';
import { keyBy } from '../utils/arrayUtils';
export const fetchObjectTypeDefinitions = () => {
  return getFrameworkDataSchemasClient().then(client => client.frameworkTypes.getObjects()).then(objectTypes => {
    return keyBy(objectTypes, ({
      objectTypeId
    }) => objectTypeId);
  }).catch(err => {
    Raven.captureException(err);
    return undefined;
  });
};