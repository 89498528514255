import { createSlice } from '@reduxjs/toolkit';
export const columnSlice = createSlice({
  name: 'columnns',
  initialState: {},
  reducers: {
    setIcons: (state, action) => {
      Object.entries(action.payload).forEach(([stageId, icon]) => {
        const stageState = state[stageId] || {};
        stageState.icon = icon;
        state[stageId] = stageState;
      });
    },
    setExpandCollapse: (state, action) => {
      Object.entries(action.payload).forEach(([stageId, collapse]) => {
        const stageState = state[stageId] || {};
        stageState.collapse = collapse;
        state[stageId] = stageState;
      });
    },
    setAggregations: (state, action) => {
      Object.entries(action.payload).forEach(([stageId, agg]) => {
        const stageState = state[stageId] || {};
        stageState.aggregations = agg;
        state[stageId] = stageState;
      });
    }
  }
});