import { useCallback } from 'react';
import { useFastStore } from '../context/FastContext';
export const useTriggerHandler = () => {
  const handlers = useFastStore(state => state.handlers);
  return useCallback(handler => {
    const callbacks = handlers[handler.key];
    if (callbacks) {
      callbacks.forEach(cb => cb(handler.options));
    }
  }, [handlers]);
};