import { SAVE_VIEW_STARTED, SAVE_VIEW_SUCCEEDED, SAVE_VIEW_FAILED, FILTERS_CHANGED, FILTER_GROUPS_CHANGED, QUICK_FILTERS_CHANGED, QUICK_FILTER_PROPERTIES_CHANGED, RESET_VIEW, COLUMNS_CHANGED, SORT_CHANGED, CREATE_VIEW_STARTED, CREATE_VIEW_SUCCEEDED, CREATE_VIEW_FAILED, STORE_VIEWS, REPLACE_VIEWS, OPTIMISTICALLY_UPDATE_CURRENT_VIEW_ID, VIEW_PIPELINE_CHANGED } from './viewsActionTypes';
import { createView, putDefaultView, writeView } from '../api/viewsAPI';
import { isHubspotStandardView } from 'customer-data-views-management/singleView/dataOperators/isHubspotStandardView';

// SAVE VIEW section ------------------------------

/**
 * @throws if API failed
 */
export const saveViewAction = ({
  objectTypeId,
  view
}) => dispatch => {
  dispatch({
    type: SAVE_VIEW_STARTED,
    payload: {
      objectTypeId,
      view
    }
  });
  const action = isHubspotStandardView({
    view
  }) ? putDefaultView : writeView;
  return action({
    objectTypeId,
    view
  }).then(() => {
    dispatch({
      type: SAVE_VIEW_SUCCEEDED,
      payload: {
        objectTypeId,
        view
      }
    });
  }).catch(error => {
    dispatch({
      type: SAVE_VIEW_FAILED,
      payload: {
        objectTypeId,
        view
      }
    });
    throw error;
  });
};

// CREATE VIEW section ------------------------------

/**
 * @throws if API failed
 * @returns created view1
 */
export const createNewViewAction = ({
  objectTypeId,
  view
}) => dispatch => {
  dispatch({
    type: CREATE_VIEW_STARTED,
    payload: {
      objectTypeId
    }
  });
  return createView({
    view
  }).then(createdView => {
    dispatch({
      type: CREATE_VIEW_SUCCEEDED,
      payload: {
        objectTypeId,
        view: createdView
      }
    });
    return createdView;
  }).catch(error => {
    dispatch({
      type: CREATE_VIEW_FAILED,
      payload: {
        objectTypeId
      }
    });
    throw error;
  });
};

// MODIFY VIEW section -- Filter change ------------------------------

export const filtersChangedAction = ({
  objectTypeId,
  viewId,
  filters
}) => ({
  type: FILTERS_CHANGED,
  payload: {
    objectTypeId,
    viewId,
    filters
  }
});
export const filterGroupsChangedAction = ({
  objectTypeId,
  viewId,
  filterGroups
}) => ({
  type: FILTER_GROUPS_CHANGED,
  payload: {
    objectTypeId,
    viewId,
    filterGroups
  }
});
export const quickFiltersChangedAction = ({
  objectTypeId,
  viewId,
  quickFilters
}) => ({
  type: QUICK_FILTERS_CHANGED,
  payload: {
    objectTypeId,
    viewId,
    quickFilters
  }
});
export const quickFilterPropertiesChangedAction = ({
  objectTypeId,
  viewId,
  quickFilterProperties
}) => ({
  type: QUICK_FILTER_PROPERTIES_CHANGED,
  payload: {
    objectTypeId,
    viewId,
    quickFilterProperties
  }
});
export const resetViewAction = ({
  objectTypeId,
  viewId
}) => ({
  type: RESET_VIEW,
  payload: {
    objectTypeId,
    viewId
  }
});
export const columnsChangedAction = ({
  objectTypeId,
  viewId,
  columns,
  state
}) => ({
  type: COLUMNS_CHANGED,
  payload: {
    objectTypeId,
    viewId,
    columns,
    state
  }
});
export const sortChangedAction = ({
  sortKey,
  sortColumnName,
  order,
  objectTypeId,
  viewId
}) => ({
  type: SORT_CHANGED,
  payload: {
    sortKey,
    sortColumnName,
    order,
    objectTypeId,
    viewId
  }
});
export const storeViewsAction = ({
  objectTypeId,
  views
}) => ({
  type: STORE_VIEWS,
  payload: {
    objectTypeId,
    views
  }
});
export const replaceViewsAction = ({
  objectTypeId,
  views
}) => ({
  type: REPLACE_VIEWS,
  payload: {
    objectTypeId,
    views
  }
});
export const optimisticallyUpdateCurrentViewIdAction = ({
  viewId
}) => ({
  type: OPTIMISTICALLY_UPDATE_CURRENT_VIEW_ID,
  payload: {
    viewId
  }
});
export const viewPipelineChangedAction = ({
  pipelineId
}) => ({
  type: VIEW_PIPELINE_CHANGED,
  payload: {
    pipelineId
  }
});