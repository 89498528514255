import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useGenerateLocation } from './useGenerateLocation';
export const useNavigate = () => {
  const history = useHistory();
  const generateLocation = useGenerateLocation();
  return useCallback(({
    objectTypeId,
    viewId,
    pageType,
    query,
    replace = false
  }) => {
    const nextLocation = generateLocation({
      objectTypeId,
      viewId,
      pageType,
      query
    });
    return replace ? history.replace(nextLocation) : history.push(nextLocation);
  }, [history, generateLocation]);
};