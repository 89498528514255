import { useSelector } from 'react-redux';
import isEmpty from 'transmute/isEmpty';
import { getPinnedViewIds } from '../selectors/pinnedViewsSelectors';
import { ALL, MY, UNASSIGNED } from '../../../views/constants/DefaultViews';
export const defaultPinnedViews = [ALL, MY, UNASSIGNED];
export const usePinnedViewIds = () => {
  const pinnedViewsFromState = useSelector(getPinnedViewIds);
  if (!pinnedViewsFromState || isEmpty(pinnedViewsFromState)) {
    return defaultPinnedViews;
  }
  return pinnedViewsFromState;
};