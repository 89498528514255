import { generatePath } from 'react-router-dom';
import PortalIdParser from 'PortalIdParser';
const portalId = PortalIdParser.get();
export const getCurrencySettingsUrl = () => {
  if (!portalId) {
    return undefined;
  }
  return generatePath('/settings/:portalId/account-defaults/multicurrency', {
    portalId
  });
};