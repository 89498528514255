import { SYNC_ROUTER_VALUES } from '../actions/initActionTypes';
import { produce } from 'immer';
const initialState = {
  currentObjectTypeId: null
};
export const routerStateReducer = produce((draft, action) => {
  switch (action.type) {
    case SYNC_ROUTER_VALUES:
      {
        const {
          objectTypeId
        } = action.payload;
        draft.currentObjectTypeId = objectTypeId;
        return draft;
      }
    default:
      {
        return draft;
      }
  }
}, initialState);