import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeGetPendingInlineEditsPerObjectTypeIdForUpdateApiCall } from './inlineEditingSlice';

/** will return pending edits with their validations removed
 */
export const usePendingInlineEditsForUpdateApiCall = ({
  objectTypeId
}) => {
  const selector = useMemo(() => makeGetPendingInlineEditsPerObjectTypeIdForUpdateApiCall({
    objectTypeId
  }), [objectTypeId]);
  return useSelector(selector) || {};
};