const _unique = definitions => {
  const names = {};
  return definitions.filter(definition => {
    if (definition.kind !== 'FragmentDefinition') {
      return true;
    }
    const name = definition.name.value;
    if (names[name]) {
      return false;
    } else {
      names[name] = true;
      return true;
    }
  });
};
import { FAILED, PENDING, SUCCEEDED, UNINITIALIZED } from '../../constants/RequestStatus';
import getIn from 'transmute/getIn';
import once from 'transmute/once';
import { useEffect } from 'react';
import { usePrefetch as usePrefetchTableDeps } from 'crm-object-table/prefetch';
import { useFetchAllProperties } from '../../properties/hooks/useFetchAllProperties';
import { useQuery, gql } from '@apollo/client';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { useFetchRecordCard } from '../../recordCards/hooks/useFetchRecordCard';
import { useCurrentPageType } from '../../../views/hooks/useCurrentPageType';
import { useFetchObjectTypeDefinitions } from '../../../crmObjects/hooks/useFetchObjectTypeDefinitions';
import { BOARD, LIST } from '../../../views/constants/PageType';
import { setupCurrentOwnerContainer } from '../../../setup/setupCurrentOwnerContainer';
import Raven from 'raven-js';
import { getQuickFetchErrorsFromWindow, clearQuickFetchErrorsFromWindow } from '../../../quick-fetch/quickFetchErrorsOnWindow';
import { ASSOCIATION_DEFINITIONS_FRAGMENT } from '../../../associations/context/AssociationDefinitionsContext';
import { OBJECT_PIPELINE_FIELDS_RESULT_FRAGMENT } from '../../objectRequirements/context/ObjectRequirementsContext';
import { useCurrentViewId } from '../../../views/hooks/useCurrentViewId';
import { Metrics } from '../../../lib/metrics/metrics';
import { useCurrentPipelineId } from '../../pipelines/hooks/useCurrentPipelineId';
import { useFetchAppSettings } from '../../../frameworkAppSettings/hooks/useFetchAppSettings';
import { useFetchPipelines } from '../../pipelines/hooks/useFetchPipelines';
import { useIsUngatedForCrmObjectBoard } from '../../../auth/hooks/useIsUngatedForCrmObjectBoard';
import { trackPageDataQueryError } from '../utils/trackPageDataQueryError';
import { useFetchDefaultView } from '../../defaultView/hooks/useFetchDefaultView';
const trackErrors = once(() => {
  // Track any errors we encountered during the quick-fetches, except timeouts as we can't fix those.
  getQuickFetchErrorsFromWindow().forEach(error => {
    const status = getIn(['xhttp', 'status'], error);
    if (status === 0) {
      return;
    }
    Raven.captureException(error, {
      tags: {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type 'string'.
        fromQuickFetch: true
      },
      extra: {
        response: getIn(['xhttp', 'response'], error),
        responseText: getIn(['xhttp', 'responseText'], error),
        responseURL: getIn(['xhttp', 'responseURL'], error),
        status
      }
    });
  });
  clearQuickFetchErrorsFromWindow();
});

// NOTE: This is used to prefetch required data for the page. To avoid drilling the data
// down many levels as props, we pass the data via a set of contexts.
// This is why we use fragments instead of writing the entire query up here - it keeps
// the data we fetch and the data we use in sync.
// Please do not change the name of this query! It will break the graphql quick fetch
// unless you go change it there too. That file lives here:
// https://git.hubteam.com/HubSpot/CRM/blob/master/crm-index-ui/static/js/quick-fetch/graphqlQuickFetch.js
const PageDataQuery = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"PageDataQuery\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"String\"}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"viewer\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"ownerId\"}}]}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"objectPipelineFields\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"ObjectPipelineFieldsResultFragment\"}}]}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"allAssociationTypesFromObjectType\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"objectType\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"AssociationDefinitionsFragment\"}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: _unique([{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "PageDataQuery"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "objectTypeId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "viewer"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "ownerId"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "objectPipelineFields"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "objectTypeId"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "objectTypeId"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ObjectPipelineFieldsResultFragment"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "allAssociationTypesFromObjectType"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "objectType"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "objectTypeId"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "AssociationDefinitionsFragment"
            }
          }]
        }
      }]
    }
  }].concat(OBJECT_PIPELINE_FIELDS_RESULT_FRAGMENT.definitions, ASSOCIATION_DEFINITIONS_FRAGMENT.definitions))
});

// HACK: GlobalCurrentOwnerContainer is used in the legacy object creator and the
// "create tasks" bulk action. Both live in crm_ui and the usage is deeply nested,
// so it's not really possible to make this a prop. If/when those are both refactored
// to a modern style, this setup step can be removed.
const syncOwnerId = data => setupCurrentOwnerContainer(getIn(['viewer', 'ownerId'], data));
export const useFetchAllData = () => {
  const objectTypeId = useSelectedObjectTypeId();
  const {
    data,
    loading: dataQueryLoading,
    error: dataQueryError
  } = useQuery(PageDataQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      objectTypeId
    },
    onCompleted: syncOwnerId
  });
  const propertiesFetchStatus = useFetchAllProperties();
  const {
    data: appSettings,
    loading: appSettingsLoading
  } = useFetchAppSettings();
  const {
    data: objectTypes,
    loading: objectTypesLoading,
    error: objectTypersError
  } = useFetchObjectTypeDefinitions();
  const {
    data: pipelines,
    loading: pipelinesLoading,
    error: pipelinesError
  } = useFetchPipelines(objectTypeId);
  const currentViewId = useCurrentViewId();
  const {
    data: defaultView,
    loading: defaultViewLoading,
    error: defaultViewError
  } = useFetchDefaultView({
    objectTypeId,
    viewId: currentViewId
  });
  const pageType = useCurrentPageType();
  const pipelineId = useCurrentPipelineId();
  const isUngatedForCrmObjectBoard = useIsUngatedForCrmObjectBoard();
  const shouldFetchRecordCard = pageType === BOARD && pipelineId && !isUngatedForCrmObjectBoard;
  const rawRecordCardsFetchStatus = useFetchRecordCard(pipelineId, !shouldFetchRecordCard);
  const recordCardsFetchStatus = shouldFetchRecordCard ? rawRecordCardsFetchStatus : SUCCEEDED;
  usePrefetchTableDeps({
    objectTypeId,
    skip: pageType !== LIST
  });
  const error = dataQueryError || defaultViewError || objectTypersError || pipelinesError || propertiesFetchStatus === FAILED;
  const loading = appSettingsLoading || dataQueryLoading || objectTypesLoading || pipelinesLoading || defaultViewLoading || [propertiesFetchStatus, recordCardsFetchStatus].some(status => [UNINITIALIZED, PENDING].includes(status));
  useEffect(() => {
    if (!loading || error) {
      if (defaultViewError) {
        Metrics.counter('missing-view-error').increment();
      } else {
        trackErrors();
      }
    }
  }, [loading, error, defaultViewError]);
  useEffect(() => {
    if (!dataQueryError) {
      return;
    }
    trackPageDataQueryError(dataQueryError, objectTypeId);
  }, [dataQueryError, objectTypeId]);
  return {
    data,
    appSettings,
    objectTypes,
    pipelines,
    loading,
    error,
    dataQueryError,
    escapeHatch: {
      currentView: defaultView === null || defaultView === void 0 ? void 0 : defaultView.defaultView,
      currentViewError: defaultViewError
    }
  };
};