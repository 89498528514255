import memoize from 'transmute/memoize';
import { isMultienum } from 'customer-data-objects/property/PropertyIdentifier';
import { ENUMERATION } from 'customer-data-objects/property/PropertyTypes';
import OptionSortStrategy from 'customer-data-objects/property/OptionSortStrategy';
import { CONTACT_TYPE_ID, DEAL_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import invariant from 'react-utils/invariant';
const SORT_OVERRIDES = {
  [CONTACT_TYPE_ID]: {
    name: {
      sortKey: ['lastname', 'firstname', 'email']
    }
  },
  [DEAL_TYPE_ID]: {
    dealstage: {
      sortKey: ['pipeline', 'dealstage.displayOrder', 'dealstage.label']
    },
    hs_priority: {
      sortKey: 'hs_priority.displayOrder'
    }
  },
  [TICKET_TYPE_ID]: {
    hs_ticket_priority: {
      sortKey: 'hs_ticket_priority.displayOrder'
    },
    hs_pipeline_stage: {
      sortKey: 'hs_pipeline_stage.displayOrder'
    }
  }
};
const hasExternalOptions = property => property && property.externalOptions;
const enumPropertyHasAlphabeticalStrategy = property => property && property.type === ENUMERATION && property.optionSortStrategy === OptionSortStrategy.ALPHABETICAL;

// See related thread: https://hubspot.slack.com/archives/C02CUKAKQSH/p1700167426148509
// TLDR: If an enum property has an abnormally large amount of options, crm-search struggles to sort by display order resulting in timeout failures
// We can be a little smarter in our logic if the optionSortStrategy on the property is alphabetical and it has a lot of options since the options are already sorted by display order.
const shouldSkipEnumDisplayOrderSorting = property => {
  return enumPropertyHasAlphabeticalStrategy(property);
};

// We want to sort by display order for enumeration properties, which means
// "extending" the property to be property.displayOrder. However, multi-
// enum properties (that use a checkbox) and properties supported by external
// options don't support sorting by displayOrder, so we'll leave those as-is
// and sort by internal value.
const extendPropertyName = (propertyName, property) => {
  if (
  // TODO: Switch this to isEnumeration from PropertyIdentifier once that becomes available.
  // See https://git.hubteam.com/HubSpot/crm-framework-frontend/issues/104
  property.type === ENUMERATION && !isMultienum(property) && !hasExternalOptions(property) && !shouldSkipEnumDisplayOrderSorting(property)) {
    return `${propertyName}.displayOrder`;
  }
  return propertyName;
};
const getOverrideSortKey = (objectTypeId, sortColumnName, overrides) => {
  const objectTypeIdOverride = overrides[objectTypeId];
  if (objectTypeIdOverride) {
    const sortColumnOverride = objectTypeIdOverride[sortColumnName];
    if (sortColumnOverride) {
      return sortColumnOverride.sortKey;
    }
  }
  return undefined;
};
const raw_getSortsFromView = (view, typeDef, properties, overrides = SORT_OVERRIDES) => {
  if (!view || !typeDef || !properties) {
    return [];
  }
  const {
    objectTypeId,
    createDatePropertyName
  } = typeDef;
  invariant(typeof objectTypeId === 'string', `objectTypeId was not string. Value was ${objectTypeId}`);
  const viewState = view.state || {};
  const sortColumnName = viewState.sortColumnName;
  const viewSortKey = viewState.sortKey;

  // This code overrides the sort settings for specific properties
  // on specific object types.
  const overrideSortKey = getOverrideSortKey(objectTypeId, sortColumnName, overrides);
  const sortKey = overrideSortKey || viewSortKey;
  const order = viewState.order === -1 ? 'ASC' : 'DESC';
  const sorts = [];
  if (Array.isArray(sortKey)) {
    sortKey.forEach(propertyName => {
      const property = properties[propertyName];
      if (!property) {
        sorts.push({
          property: propertyName,
          order
        });
      } else {
        sorts.push({
          property: extendPropertyName(propertyName, property),
          order
        });
      }
    });
  } else if (sortKey) {
    const property = properties[sortKey];
    if (!property) {
      sorts.push({
        property: sortKey,
        order
      });
    } else {
      sorts.push({
        property: extendPropertyName(sortKey, property),
        order
      });
    }
  }
  if (!sorts.find(({
    property
  }) => property === createDatePropertyName)) {
    sorts.push({
      property: createDatePropertyName,
      order: 'DESC'
    });
  }
  sorts.push({
    property: 'hs_object_id',
    order: 'DESC'
  });
  return sorts;
};
// Logic pulled from ViewToElasticSearchQuery
// https://git.hubteam.com/HubSpot/CRM/blob/8ce0583ea15e4b4d8363612e52d21896945b21c8/crm_ui/static/js/utils/ViewToElasticSearchQuery.js#L33-L77
export const getSortsFromView = memoize(raw_getSortsFromView);