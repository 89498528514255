import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchPortalCurrencies } from '../api/multicurrencyApi';
import { useMemo } from 'react';
import { useHasBoardSettingsTab } from '../../hooks/useHasBoardSettingsTab';
export const portalCurrenciesFieldName = 'portalCurrencyData';
export function formatCurrencyData({
  currencyExchangeRates,
  homeCurrency,
  hasMulticurrencyActive
}) {
  const exchangeRates = currencyExchangeRates.filter(exchangeRate => exchangeRate.visible).reduce((acc, exchangeRate) => Object.assign({}, acc, {
    [exchangeRate.fromCurrencyCode]: exchangeRate.conversionMultiplier
  }), {});
  return {
    exchangeRates,
    portalCurrencyCodes: Object.keys(exchangeRates),
    homeCurrencyCode: homeCurrency.currencyCode,
    hasMulticurrencyActive
  };
}
export const fetchCurrencies = registerQuery({
  fieldName: portalCurrenciesFieldName,
  fetcher: () => fetchPortalCurrencies().then(formatCurrencyData)
});
export function usePortalCurrenciesFetch(objectTypeId) {
  const hasBoardTab = useHasBoardSettingsTab(objectTypeId);
  return useQuery(fetchCurrencies, {
    skip: !hasBoardTab
  });
}
export function useFetchPortalCurrencies(objectTypeId) {
  const {
    data,
    loading
  } = usePortalCurrenciesFetch(objectTypeId);
  return useMemo(() => {
    if (!data || !data[portalCurrenciesFieldName]) {
      return {
        portalCurrencyCodes: [],
        homeCurrencyCode: undefined,
        loading
      };
    }
    const {
      portalCurrencyCodes,
      homeCurrencyCode
    } = data[portalCurrenciesFieldName];
    return {
      portalCurrencyCodes,
      homeCurrencyCode,
      loading
    };
  }, [data, loading]);
}
export function useHasMulticurrencyActive(objectTypeId) {
  const {
    data
  } = usePortalCurrenciesFetch(objectTypeId);
  return Boolean(data && data[portalCurrenciesFieldName].hasMulticurrencyActive);
}