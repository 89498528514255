import { useEffect, useMemo, useState } from 'react';
import { FetchStatuses } from '../../types/FetchStatus';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
const getObjectTypeNamesToIds = objectTypeDefinitions => {
  return objectTypeDefinitions.reduce((objectTypes, typeDef) => objectTypes.set(typeDef.name, typeDef.objectTypeId), new Map());
};
export const useFetchObjectTypeNamesToIds = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState();
  useEffect(() => {
    getFrameworkDataSchemasClient().then(client => client.frameworkTypes.getObjects()).then(result => setData(result)).catch(err => setError(err)).finally(() => setLoading(false));
  }, []);
  const formattedResult = useMemo(() => {
    if (loading) {
      return {
        status: FetchStatuses.LOADING,
        data: null
      };
    }
    if (error) {
      return {
        status: FetchStatuses.REJECTED,
        data: null,
        error
      };
    }
    if (data) {
      return {
        status: FetchStatuses.RESOLVED,
        data: getObjectTypeNamesToIds(data)
      };
    }
    return {
      status: FetchStatuses.IDLE,
      data: null
    };
  }, [data, loading, error]);
  return formattedResult;
};