'use es6';

import { formatToReferencesList } from '../lib/formatReferences';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import http from 'hub-http/clients/apiClient';
import indexBy from 'transmute/indexBy';
import pipe from 'transmute/pipe';
const FORM_PROPERTIES = ['name', 'guid', 'formType'];
const SUPPORTED_FORM_TYPES = ['HUBSPOT', 'FLOW', 'CAPTURED', 'FACEBOOK_LEAD_AD', 'BLOG_COMMENT', 'MEETING', 'TICKET_FORM', 'DOCUMENT', 'VIDEO_FORM'];
const formatForms = pipe(formatToReferencesList({
  getId: get('guid'),
  getLabel: get('name')
}), indexBy(get('id')));
const getCrmSearchFormOptions = ({
  count = 100,
  offset = 0,
  query = ''
}) => {
  return {
    count,
    offset,
    query,
    objectTypeId: 'FORM',
    requestOptions: {
      properties: ['hs_name', 'hs_form_id', 'hs_form_type']
    },
    filterGroups: [{
      filters: [{
        operator: 'IN',
        property: 'hs_form_type',
        values: SUPPORTED_FORM_TYPES
      }]
    }],
    sorts: [{
      property: 'hs_updated_at',
      order: 'DESC'
    }, {
      property: 'hs_created_at',
      order: 'DESC'
    }]
  };
};
export const createGetAllForms = ({
  httpClient
}) => () => httpClient.get(`forms/v2/forms?`, {
  query: {
    property: FORM_PROPERTIES,
    formTypes: SUPPORTED_FORM_TYPES
  }
}).then(formatForms);
export const getAllForms = createGetAllForms({
  httpClient: http
});
export const createGetFormsByIds = ({
  httpClient
}) => ids => {
  return httpClient.get('/forms/v2/forms/batch', {
    query: {
      guid: ids
    }
  }).then(formatForms);
};
export const getFormsByIds = createGetFormsByIds({
  httpClient: http
});
export const createSearchForms = ({
  httpClient
}) => request => {
  const {
    count,
    offset,
    query
  } = request.toJS();
  const data = getCrmSearchFormOptions({
    count,
    offset,
    query
  });
  return httpClient.post('crm-search/search', {
    data
  }).then(response => {
    const forms = response.results.map(form => {
      return {
        formType: getIn(['properties', 'hs_form_type', 'value'], form),
        guid: getIn(['properties', 'hs_form_id', 'value'], form),
        name: getIn(['properties', 'hs_name', 'value'], form)
      };
    });
    const formattedForms = formatForms(forms);
    return {
      count: response.total,
      offset: response.offset,
      hasMore: response.hasMore,
      results: formattedForms
    };
  });
};
export const searchForms = createSearchForms({
  httpClient: http
});