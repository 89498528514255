import { List, Record, Seq } from 'immutable';
import { FORMATTED } from './NumberDisplayHint';
import { TEXT } from './PropertyFieldTypes';
import { STRING } from './PropertyTypes';
import PropertyOptionRecord from './PropertyOptionRecord';
const PropertyRecord = Record({
  accessLevel: undefined,
  calculated: false,
  canArchive: undefined,
  canRestore: undefined,
  createdAt: null,
  createdUserId: null,
  currencyPropertyName: null,
  dataSensitivity: 'none',
  dateDisplayHint: undefined,
  defaultValue: undefined,
  deleted: false,
  description: '',
  displayMode: 'current_value',
  displayOrder: 0,
  expression: null,
  externalOptions: false,
  externalOptionsReferenceType: null,
  favorited: false,
  favoritedOrder: 0,
  fieldType: TEXT,
  filterName: '',
  formField: true,
  fromUserId: null,
  groupName: null,
  hasUniqueValue: false,
  hidden: false,
  hubspotDefined: false,
  isBidenProperty: false,
  isCustomizedDefault: false,
  isMultiValued: false,
  label: '',
  mutableDefinitionNotDeletable: true,
  name: '',
  numberDisplayHint: FORMATTED,
  objectType: undefined,
  objectTypeId: undefined,
  options: List(),
  optionsAreMutable: null,
  optionSortStrategy: null,
  placeholder: '',
  prospectType: null,
  readOnlyDefinition: false,
  readOnlyValue: false,
  referencedObjectType: null,
  searchableInGlobalSearch: false,
  sensitiveDataCategories: undefined,
  showCurrencySymbol: false,
  sortable: true,
  textDisplayHint: undefined,
  type: STRING,
  updatedAt: null,
  updatedUserId: null
}, 'PropertyRecord');
PropertyRecord.fromJS = json => {
  if (!json || typeof json !== 'object') {
    return json;
  }
  const property = PropertyRecord(json);
  const options = Seq(property.options || List()).map(PropertyOptionRecord.fromJS).toList();
  const normalizedProperty = property.set('options', options);
  return normalizedProperty;
};
export default PropertyRecord;