'use es6';

import http from 'hub-http/clients/apiClient';
import formatContactLists from 'reference-resolvers/formatters/formatContactLists';
import formatContactSegments from 'reference-resolvers/formatters/formatContactSegments';
export const createGetAllContactLists = ({
  httpClient
}) => () => httpClient.get('/contacts/v1/lists/internal/all').then(formatContactLists);
export const getAllContactLists = createGetAllContactLists({
  httpClient: http
});
export const createGetContactListsByIds = ({
  httpClient
}) => ids => httpClient.get('/contacts/v1/lists/batch', {
  query: {
    listId: ids,
    property: ['listId', 'name', 'dynamic']
  }
}).then(formatContactLists);
export const getContactListsByIds = createGetContactListsByIds({
  httpClient: http
});
export const createGetContactListsSearchPage = ({
  httpClient
}) => (props, extraQueryParams) => {
  const {
    count,
    offset,
    query
  } = props.toJS();
  return httpClient.get('/contacts/v1/segment-ui/all', {
    query: Object.assign({}, extraQueryParams, {
      count,
      direction: 'ASC',
      favorites: false,
      includeTotal: true,
      offset: offset || 0,
      q: query,
      sortBy: 'LIST_NAME'
    })
  }).then(formatContactSegments);
};
export const getContactListsSearchPage = createGetContactListsSearchPage({
  httpClient: http
});
export const createGetStaticContactListsSearchPage = options => {
  const getter = createGetContactListsSearchPage(options);
  return props => getter(props, {
    listType: 'STATIC'
  });
};
export const getStaticContactListsSearchPage = props => getContactListsSearchPage(props, {
  listType: 'STATIC'
});