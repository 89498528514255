'use es6';

import http from 'hub-http/clients/apiClient';
import { createGetAllDealPipelinesWithPermissions } from '../api/PipelinesWithPermissionsAPI';
import { createPipelineStageReferenceResolver } from './PipelineStageReferenceResolver';
const getAllDealPipelines = createGetAllDealPipelinesWithPermissions({
  httpClient: http
});
export const createDealPipelineStagesWithPermissionsReferenceResolver = options => createPipelineStageReferenceResolver(Object.assign({
  createFetchData: createGetAllDealPipelinesWithPermissions,
  fetchData: getAllDealPipelines
}, options));
export default createDealPipelineStagesWithPermissionsReferenceResolver();