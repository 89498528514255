import { useState, useEffect } from 'react';
import { captureNetworkError } from 'growth-onboarding-reliability/utils/raven';
import { useIsStarterPortal } from 'onboarding-scopes';
import { getCustomerInventory } from '../api/customerInventory';
import { hasPurchasedAnyStarterProductSinceGivenDate } from '../utils/starterHubs';
export const useHasPurchasedStarterSinceGivenDate = (givenDate, __useOnboardingScopes) => {
  const [hasPurchased, setHasPurchased] = useState(false);
  const isStarterPortal = useIsStarterPortal();
  useEffect(() => {
    // This reduces Inventory API requests by only making such requests
    // on Portals we know have made a Starter purchase
    if (isStarterPortal) {
      getCustomerInventory().then(({
        products
      }) => {
        if (hasPurchasedAnyStarterProductSinceGivenDate(products, givenDate)) {
          setHasPurchased(true);
        }
      }).catch(captureNetworkError);
    }
  }, [isStarterPortal, givenDate]);
  return hasPurchased;
};