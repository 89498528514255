import { useEffect, useState, useTransition } from 'react';
import Raven from 'raven-js';
import { useSelectedObjectTypeDef } from '../../crmObjects/hooks/useSelectedObjectTypeDef';
import { useHasAllGates } from '../../auth/hooks/useHasAllGates';
import { useHasAllScopes } from '../../auth/hooks/useHasAllScopes';
import { useCurrentViewId } from '../../views/hooks/useCurrentViewId';
import { useLegacyPageType } from '../../views/hooks/useLegacyPageType';
import { runBehavior } from '../utils/runBehavior';
export const useBehavior = behaviorType => {
  const [__, startTransition] = useTransition();
  const typeDef = useSelectedObjectTypeDef();
  const hasAllScopes = useHasAllScopes();
  const hasAllGates = useHasAllGates();
  const pageType = useLegacyPageType();
  const viewId = useCurrentViewId();
  const [behaviorResult, setBehaviorResult] = useState();
  useEffect(() => {
    startTransition(() => {
      runBehavior(behaviorType, {
        typeDef: typeDef,
        hasAllScopes,
        hasAllGates,
        pageType,
        viewId
      })
      // The behavior result can sometimes be a component, which setState will run, but we don't want it to
      .then(r => setBehaviorResult(() => r)).catch(err => {
        console.error(err);
        Raven.captureException(new Error(`Could not run behavior ${behaviorType} for ${typeDef === null || typeDef === void 0 ? void 0 : typeDef.objectTypeId}`));
      });
    });
  }, [behaviorType, hasAllGates, hasAllScopes, typeDef, pageType, viewId, startTransition]);
  return behaviorResult;
};