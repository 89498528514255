import { fetchPropertyMetadataEntries } from './frameworkBuilderReadApi';
import { makePropertyMetadataEntriesFetchKey } from 'framework-data-schema-quick-fetch';
import { Metrics } from '../metrics';
import { normalizeCustomIdentifier } from '../utils/normalizeCustomIdentifier';
import { wrapPromise } from 'persist-promise/wrapPromise';
import { isEarlyCacheReturnEnabled } from '../earlyCacheReturn';
const propertyMetadataPersistedPromise = wrapPromise({
  namespace: 'FDSR',
  entityName: 'propertyMetadata',
  deepFreeze: true,
  toCacheKey: makePropertyMetadataEntriesFetchKey,
  fetch: fetchPropertyMetadataEntries,
  metricsConfig: {
    enablePatchDiffing: true,
    convertKeyToMetricsDimension: key => {
      const match = key.match(makePropertyMetadataEntriesFetchKey({
        portalId: '.*',
        frameworkTypeIdentifier: '(.*)',
        appSettingName: '(.*)'
      }));
      if (!match || !match[1] || !match[2]) {
        return null;
      }
      const [__, identifier, appSettingName] = match;
      return `${normalizeCustomIdentifier(identifier)}-${appSettingName}`;
    }
  }
});
export const makePropertyMetadataClient = ({
  httpClient,
  persistedPromise = propertyMetadataPersistedPromise
}) => {
  const client = {
    /**
     * Prints debug info to the console.
     */
    debug: () => {
      persistedPromise.debug();
    },
    /**
     * Clears internal cache state.
     *
     * @returns A promise which resolves when state is clear.
     */
    clearCache: async () => {
      await Promise.all([persistedPromise.clearCache()]);
    },
    /**
     * Gets all property metadata entries for the specified app setting under the specified framework type.
     *
     * @param options.appSettingName The app setting to fetch entries for.
     * @param options.frameworkTypeIdentifier The framework type to fetch entries for under the app setting.
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to the property metadata entries, or null if the data could not be found.
     */
    get: ({
      frameworkTypeIdentifier,
      appSettingName,
      __isComposed = false
    }) => {
      if (!__isComposed) {
        Metrics.counter('propertyMetadata.get').increment();
      }
      return persistedPromise.makeFetchWithOpts({
        allowEagerCacheReturn: isEarlyCacheReturnEnabled()
      })({
        frameworkTypeIdentifier,
        appSettingName,
        httpClient
      });
    }
  };
  return Promise.resolve(client);
};