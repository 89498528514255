import { getFallback } from '../fallbacks/getFallback';
export function getFieldValue(metadata, fieldName) {
  const field = metadata && metadata[fieldName];
  return field ? field.value : null;
}
export function getAppSettingForTypeDef(batchResults, typeDef, appSettingName) {
  const allAppSettings = batchResults && batchResults[typeDef.fullyQualifiedName];
  let appSetting = allAppSettings && allAppSettings[appSettingName];
  if (!appSetting) {
    // no appSetting found for this object type
    // let's try and get a fallback value
    const fallbackSettings = getFallback(typeDef.objectTypeId);
    appSetting = fallbackSettings === null || fallbackSettings === void 0 ? void 0 : fallbackSettings[appSettingName];
  }
  return appSetting && appSetting.metadata || null;
}