'use es6';

import { defaultCustomObjectGetters } from 'reference-resolvers/formatters/formatCustomObjects';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
import http from 'hub-http/clients/apiClient';
import map from 'transmute/map';
import memoize from 'transmute/memoize';
const BASE_URI = 'inbounddb-objects/v1/crm-objects';
const toBatchFetchUri = memoize(objectTypeId => `${BASE_URI}/${encodeURIComponent(objectTypeId)}/batch`);

// Merged Contacts, Companies, Tickets and Deals aren’t stored in CrmObjects yet. The inbounddb-objects endpoint
// transforms these objects to a CrmObject type and does not yet translate the merge audits. Therefore, merged
// objects ids in the response may not match the requested id, so this updates the objectIds to what is expected.
const setIdInObject = (object, id) => {
  if (typeof object.objectId === 'number') {
    id = Number(id);
  }
  if (object.objectId !== id) {
    object.objectId = id;
  }
  return object;
};
const normalizeMergedObjects = map(setIdInObject);
export const createFetchByIds = ({
  httpClient
}) => (objectTypeId, {
  fetchQueryParams,
  formatterOptions: {
    getters
  } = {}
} = {}) => {
  const referenceGetters = Object.assign({}, defaultCustomObjectGetters, getters);
  const url = toBatchFetchUri(objectTypeId);
  return ids => httpClient.get(url, {
    query: Object.assign({}, fetchQueryParams, {
      includeAllValues: true,
      id: ids
    })
  }).then(normalizeMergedObjects).then(Object.values).then(formatToReferencesList(referenceGetters));
};
export const fetchByIds = createFetchByIds({
  httpClient: http
});