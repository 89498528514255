/**
 * This hash is used to take in a CrmObjectsSearchQueryObject and generate a unique hash used for LocalMutationsStore
 *
 * It ignores parts of the query such as sort, count+offset, properties as these do not affect which objects match
 * the given query when bulk editing/deleting/mutating all objects in a query
 */
export const generateLocalMutationsQueryHash = ({
  queryObject
}) => {
  const {
    filterGroups,
    objectTypeId,
    query
  } = queryObject;
  return `LocalMutationsCrmSearchQueryHash:${objectTypeId}-${query}-${JSON.stringify(filterGroups)}`;
};