export const BOARD_TAB_ID = 'BOARD_TAB_ID';
export const CARDS_TAB_ID = 'CARDS_TAB_ID';
export const STYLES = {
  COZY: 'COZY',
  COMFORTABLE: 'COMFORTABLE',
  DEFAULT: 'COMFORTABLE'
};
export const BOTTOM_PANEL = {
  WITH_PANEL: 'WITH_PANEL',
  WITHOUT_PANEL: 'WITHOUT_PANEL',
  DEFAULT: 'WITH_PANEL'
};
export const INACTIVE_LIMIT_UNITS = {
  DAYS: 'days',
  WEEKS: 'weeks'
};
export const DEFAULT_CARD_PREFERENCES = {
  cozyCardPreferences: {
    isCompact: false,
    isBottomPanelHidden: false,
    areTagsHidden: false
  },
  inactiveCardPreferences: {
    isTurnedOff: false,
    unit: 'days',
    value: 14
  },
  nextLastActivityPreferences: {
    isTurnedOff: false
  },
  preferredDisplayCurrencyPreferences: {
    preferredDisplayCurrency: 'UNINITIALIZED'
  },
  currencyFormatPreferences: {
    isAbbreviated: true
  }
};