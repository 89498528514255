/* hs-eslint ignored failing-rules */
/* eslint-disable object-shorthand */

'use es6';

import { Dispatcher } from './vendor/flux';
const VIEW_ACTION = 'VIEW_ACTION';
const dispatcher = new Dispatcher();
dispatcher.handleViewAction = function handleViewAction(action) {
  this.dispatch({
    source: VIEW_ACTION,
    action: action,
    // ensures that `handleViewAction` actions are compatible with GeneralStore
    actionType: action.actionType || ''
  });
};
export default dispatcher;