import Raven from 'raven-js';
export const getJsonColumns = value => {
  if (!value) {
    return null;
  }
  try {
    return JSON.parse(decodeURIComponent(value));
  } catch (err) {
    Raven.captureException(new Error('Failed to parse grid column query param JSON'), {
      extra: {
        err,
        value
      }
    });
    return null;
  }
};