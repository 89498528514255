'use es6';

import http from 'hub-http/clients/apiClient';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
import get from 'transmute/get';
import formatIntegrationProperties from 'reference-resolvers/formatters/formatIntegrationProperties';
import formatIntegrationNames from 'reference-resolvers/formatters/formatIntegrationNames';
import promiseClient from 'hub-http/adapters/promiseClient';
import * as core from 'hub-http/middlewares/core';
import * as hubapi from 'hub-http/middlewares/hubapi';
import * as debug from 'hub-http/middlewares/debug';
import * as externalAuth from 'hub-http/middlewares/externalAuth';
import { createStack } from 'hub-http';
const BASE_URL = '/integrators/v1';
const EVENT_TYPE_BASE_URL = `${BASE_URL}/portal-installs/event-types`;
const APP_BASE_URL = `${BASE_URL}/applications`;
export const createGetIntegrations = ({
  httpClient
}) => () => httpClient.get(EVENT_TYPE_BASE_URL).then(formatToReferencesList({
  getId: get('id'),
  getLabel: get('name')
}));
export const getIntegrations = createGetIntegrations({
  httpClient: http
});
export const createGetIntegrationPropertiesById = ({
  httpClient
}) => ids => httpClient.post(`${EVENT_TYPE_BASE_URL}/properties`, {
  data: {
    ids
  }
}).then(response => formatIntegrationProperties(response));
export const getIntegrationPropertiesById = createGetIntegrationPropertiesById({
  httpClient: http
});

// same as hubapiStack but without core.jsonResponse
export const integrationsHttpClient = promiseClient(createStack(core.services, hubapi.defaults, debug.allowTimeoutOverride, core.jsonBody, core.httpsOnly, hubapi.hubapi, externalAuth.cookieAuthentication, core.withQuery, debug.rewriteUrl, hubapi.timeoutInQuery, core.reportOptionsError, hubapi.logoutOnUnauthorized, hubapi.retryOnError, core.validateStatus));
export const createGetIntegrationNameByAppId = ({
  httpClient
}) => appIds => Promise.all(appIds.map(appId => {
  if (appId) {
    return httpClient.get(`${APP_BASE_URL}/${appId}/name`).then(integrationName => ({
      appId,
      integrationName
    }), error => ({
      appId,
      error
    }));
  }
  return null;
})).then(formatIntegrationNames);
export const getIntegrationNameByAppId = createGetIntegrationNameByAppId({
  httpClient: integrationsHttpClient
});