import { getRelevantOwnedProducts, hasPurchasedProductSinceGivenDate } from './crmSuiteStarter';
export const hasPurchasedAnyStarterProductSinceGivenDate = (allOwnedProducts, givenSinceDate) => {
  let hasPurchasedSinceDate = false;
  const relevantOwnedProducts = getRelevantOwnedProducts(allOwnedProducts);
  relevantOwnedProducts.forEach(product => {
    if (hasPurchasedProductSinceGivenDate(product, givenSinceDate)) {
      hasPurchasedSinceDate = true;
    }
  });
  return hasPurchasedSinceDate;
};