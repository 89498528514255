import { produce } from 'immer';
import { FAILED, PENDING, SUCCEEDED } from '../../constants/RequestStatus';
import { mutableSetIn } from '../../objectUtils/mutableSetIn';
import { RECORD_CARD_FETCH_FAILED, RECORD_CARD_FETCH_STARTED, RECORD_CARD_FETCH_SUCCEEDED } from '../actions/recordCardsActionTypes';
const initialState = {
  status: {},
  data: {}
};
export const recordCardsReducer = produce((draft, action) => {
  switch (action.type) {
    case RECORD_CARD_FETCH_STARTED:
      {
        const {
          pipelineId,
          objectTypeId
        } = action.payload;
        mutableSetIn(['status', objectTypeId, pipelineId], PENDING, draft);
        return draft;
      }
    case RECORD_CARD_FETCH_SUCCEEDED:
      {
        const {
          pipelineId,
          objectTypeId,
          data
        } = action.payload;
        mutableSetIn(['status', objectTypeId, pipelineId], SUCCEEDED, draft);
        mutableSetIn(['data', objectTypeId, pipelineId], data, draft);
        return draft;
      }
    case RECORD_CARD_FETCH_FAILED:
      {
        const {
          pipelineId,
          objectTypeId
        } = action.payload;
        mutableSetIn(['status', objectTypeId, pipelineId], FAILED, draft);
        return draft;
      }
    default:
      {
        return draft;
      }
  }
}, initialState);