'use es6';

import getIn from 'transmute/getIn';
import { fromJS, List, Map as ImmutableMap } from 'immutable';
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
const formatInboundDbList = list => {
  return new ReferenceRecord({
    id: getIn(['properties', 'hs_list_id', 'value'], list),
    label: getIn(['properties', 'hs_list_name', 'value'], list),
    referencedObject: fromJS(list)
  });
};
const formatInboundDbListSegments = response => {
  const {
    count,
    hasMore,
    offset,
    results,
    total
  } = response;
  return ImmutableMap({
    count,
    hasMore,
    offset,
    results: List(results).map(formatInboundDbList),
    total
  });
};
export default formatInboundDbListSegments;