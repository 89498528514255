import { createSlice } from '@reduxjs/toolkit';
export const bulkActionsSlice = createSlice({
  name: 'bulkActions',
  initialState: {
    allIds: []
  },
  reducers: {
    setAllIds: (state, action) => {
      state.allIds = action.payload;
    }
  }
});