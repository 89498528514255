import { Metrics } from '../../../lib/metrics/metrics';

// string constant from 'crm_data/filters/FilterPlaceholderResolver'
// a Placeholder, or an actual filter value

const maybeReplacePlaceholderFilterValue = (placeholders, value) => {
  const maybePlaceholderValue = placeholders[value];
  if (maybePlaceholderValue) {
    Metrics.counter('placeholder-filter-value', {
      placeholder: maybePlaceholderValue.toString()
    }).increment();
  }
  return maybePlaceholderValue || value;
};

/**
 * Will replace __hs__NOW with the current time stamp and log to metrics if it does any replacing
 */
export const setPlaceholdersOnFilter = (filter, placeholders) => {
  // if the filter value is a placeholder key, replace it with the provided actual value
  // otherwise, it's already an actual value, and should be left alone

  if ('value' in filter) {
    return Object.assign({}, filter, {
      value: maybeReplacePlaceholderFilterValue(placeholders, filter.value)
    });
  } else if ('values' in filter) {
    return Object.assign({}, filter, {
      values: filter.values.map(value => maybeReplacePlaceholderFilterValue(placeholders, value))
    });
  } else {
    return filter;
  }
};