import { useMemo } from 'react';
import { registerQuery, useQuery } from 'data-fetching-client';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import { withCrmObjectBoardNamespace } from '../../utils';
import { useBoardProps } from '../../boardProps/useBoardProps';
import { useSyncError } from '../../error/useSyncError';
const fetcher = ({
  objectTypeId
}) => getFrameworkDataSchemasClient().then(client => client.properties.get({
  frameworkTypeIdentifier: objectTypeId,
  query: {
    showHighlySensitiveProperties: true
  }
})).then(properties => {
  let data = null;
  if (properties) {
    data = new Map(properties.map(property => [property.property.name, property]));
  }
  return data;
});
export const GET_PROPERTIES_QUERY = withCrmObjectBoardNamespace('GET_PROPERTIES');
const getPropertiesQuery = registerQuery({
  fieldName: GET_PROPERTIES_QUERY,
  args: ['objectTypeId'],
  fetcher
});
export const useLoadPropertyDefinitions = () => {
  const {
    objectTypeId
  } = useBoardProps();
  const {
    loading,
    error,
    data
  } = useQuery(getPropertiesQuery, {
    variables: {
      objectTypeId
    },
    skip: !objectTypeId
  });
  useSyncError(error);
  return useMemo(() => {
    if (!loading && data && !error) {
      return {
        data: data[GET_PROPERTIES_QUERY],
        loading
      };
    }
    return {
      data: null,
      loading
    };
  }, [data, error, loading]);
};