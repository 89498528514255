import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["id"];
import http from 'hub-http/clients/apiClient';
import { generatePath } from 'react-router-dom';
import { makeQuickFetchedRequest } from '../../utils/makeQuickFetchedRequest';
import { parseViewFromBEModel, convertViewToBEModel } from '../utils/parseViews';
import { VIEWS_NAMESPACE_NONE } from 'customer-data-views-management/viewsSearch/constants';
const V4_BASE_URL = '/sales/v4/views';
const quickFetchedView = makeQuickFetchedRequest({
  getRequestName: ({
    viewId
  }) => `view-${viewId}`,
  request: ({
    objectTypeId,
    viewId
  }) => http.get(generatePath(`${V4_BASE_URL}/:objectTypeId/:viewId`, {
    objectTypeId,
    viewId
  }))
});
export const fetchViewById = ({
  objectTypeId,
  viewId
}) => quickFetchedView({
  objectTypeId,
  viewId
}).then(parseViewFromBEModel);
export const writeView = ({
  view
}) => http.put(generatePath(`${V4_BASE_URL}/:viewId`, {
  viewId: view.id
}), {
  data: convertViewToBEModel(view)
}).then(parseViewFromBEModel);
export const createView = ({
  view
}) => {
  const _convertViewToBEModel = convertViewToBEModel(view),
    viewWithoutId = _objectWithoutPropertiesLoose(_convertViewToBEModel, _excluded);
  return http.post(V4_BASE_URL, {
    data: viewWithoutId
  }).then(parseViewFromBEModel);
};
export const getDoesViewExist = ({
  objectTypeId,
  name
}) => http.get(generatePath(`${V4_BASE_URL}/is-name-used/:objectTypeId/:name`, {
  objectTypeId,
  name
}));
export const putDefaultView = ({
  objectTypeId,
  view
}) => {
  const {
    id,
    columns,
    state
  } = convertViewToBEModel(view);
  // FIXME - Generate Sales v3 client type for PUT default-view endpoint and use it here
  return http.put(`/sales/v3/views/default-view/${objectTypeId}/${id}?namespace=${VIEWS_NAMESPACE_NONE}`, {
    data: {
      id,
      columns,
      state,
      objectTypeId
    }
  });
};