import { useSelectedObjectTypeDef } from '../../crmObjects/hooks/useSelectedObjectTypeDef';
import { useIsVisibleGridColumnName } from './useIsVisibleGridColumnName';
import { applyColumnRules } from '../utils/applyColumnRules';
import memoizeOne from 'react-utils/memoizeOne';
import enviro from 'enviro';
import emptyFunction from 'react-utils/emptyFunction';
import { useEffect } from 'react';
import once from 'transmute/once';
const lazyClone = obj => JSON.parse(JSON.stringify(obj));
const isDeployed = enviro.deployed();
const logMissingDisplayNameInDev = isDeployed ? emptyFunction : once(() => console.error('Object type is missing primaryDisplayLabelPropertyName; falling back on "hs_object_id"'));
const generateView = memoizeOne((view, primaryDisplayLabelPropertyName, isVisibleGridColumnName) => {
  const cloneOfView = lazyClone(view);
  cloneOfView.columns = applyColumnRules({
    columns: cloneOfView.columns,
    primaryDisplayLabelPropertyName,
    isVisibleGridColumnName
  });
  return cloneOfView;
});

/*
 * This hook will take a view object (regardless of where it comes from, cachedViews, views with draft state on top of it, etc
 * and will `applyColumnRules` to it, which will remove columns the user does not have access to or are invalid (such as associations that no longer exist, or properties that no longer exist)
 * */
export const useGenerateView = view => {
  const typeDef = useSelectedObjectTypeDef();
  const isVisibleGridColumnName = useIsVisibleGridColumnName();
  useEffect(() => {
    if (typeDef && !typeDef.primaryDisplayLabelPropertyName) {
      logMissingDisplayNameInDev();
    }
  }, [typeDef]);
  if (!view || !typeDef) {
    return null;
  }
  return generateView(view, typeDef.primaryDisplayLabelPropertyName || 'hs_object_id', isVisibleGridColumnName);
};