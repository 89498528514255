import Raven from 'raven-js';
import { CRM_SEARCH_QUERY } from '../data/useCrmSearch';
import { CRM_OBJECT_BOARD_NAMESPACE } from 'crm-object-board-core/utils';
const last10Errors = [];
const hasSeenErrorBefore = error => {
  if (window['jasmine']) {
    return false;
  }
  //need to check error messages
  //apollo error messages should be the same as the "network error" message found in the link
  return last10Errors.find(e => e.message === error.message) !== undefined;
};
const addErrorToCache = error => {
  last10Errors.push(error);
  if (last10Errors.length > 10) {
    last10Errors.shift();
  }
};
export class BoardError extends Error {
  constructor(message) {
    super(`[CrmObjectBoardError]: ${message}`);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, BoardError.prototype);
  }
}
export const shouldTrackApolloError = operationName => {
  /**
   * We don't want to track errors for CRM_SEARCH_QUERY because it's errors
   * are parsed separately by `crm-search-query-utilities`
   * Otherwise if it starts with our namespace we want to track it
   */
  return operationName.startsWith(CRM_OBJECT_BOARD_NAMESPACE) && operationName !== CRM_SEARCH_QUERY;
};
export const createBoardError = (message, e) => {
  const error = new BoardError(message);
  error.cause = e;
  return error;
};
export const captureException = (error, e, options) => {
  error.cause = e;
  //dont capture the same error twice
  if (hasSeenErrorBefore(error)) {
    return;
  }
  //dont capture the same root error twice
  if (e instanceof Error && hasSeenErrorBefore(e)) {
    return;
  }
  addErrorToCache(error);
  addErrorToCache(e);
  Raven.captureException(error, Object.assign({}, options, {
    extra: e instanceof Error ? Object.assign({
      message: e.message,
      name: e.name
    }, (options === null || options === void 0 ? void 0 : options.extra) || {}) : options === null || options === void 0 ? void 0 : options.extra
  }));
};
export const catchBoardError = (message, get) => {
  const error = createBoardError(message);
  return get.catch(e => {
    captureException(error, e);
    throw e;
  });
};