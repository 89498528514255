'use es6';

import { COMPANY, CONTACT, DEAL, EDITABLE_DEAL, ENGAGEMENT, LINE_ITEM, PRODUCT_FOLDER, PRODUCT, QUOTE, REVENUE_DEAL_MERGE, TASK, TICKET } from 'reference-resolvers/constants/ReferenceObjectTypes';
import http from 'hub-http/clients/apiClient';
import { Map as ImmutableMap, List } from 'immutable';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
import compose from 'transmute/compose';
import curry from 'transmute/curry';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import hasIn from 'transmute/hasIn';
import updateIn from 'transmute/updateIn';
import { formatContactName } from 'reference-resolvers/formatters/formatContacts';
import { formatCompanyName } from 'reference-resolvers/formatters/formatCompanies';
const SEARCH_API = 'contacts/search/v1/search/';
const addSortBy = curry((property, searchQuery) => {
  searchQuery = searchQuery.toJS();
  if (searchQuery.sorts == null || searchQuery.sorts.length === 0) {
    return Object.assign({}, searchQuery, {
      sorts: [{
        property,
        order: 'ASC'
      }]
    });
  }
  return searchQuery;
});
const withIdField = curry((idField, ids) => {
  return {
    count: ids.length,
    filterGroups: [{
      filters: [{
        property: idField,
        operator: 'IN',
        values: ids
      }]
    }]
  };
});
const formatResults = curry((resultsKey, getters, response) => {
  const {
    offset,
    total: count,
    [resultsKey]: results
  } = response;
  const hasMore = Object.prototype.hasOwnProperty.call(response, 'has-more') ? response['has-more'] : response.hasMore;
  return ImmutableMap({
    hasMore,
    offset,
    count,
    results: formatToReferencesList(getters, results)
  });
});
const formatResultsWithFilter = curry((resultsKey, getters, filters, response) => {
  const {
    [resultsKey]: results
  } = response;
  const filteredResults = results.filter(result => filters(result) === 'true');
  return formatResults(resultsKey, getters, Object.assign({}, response, {
    [resultsKey]: filteredResults
  }));
});
const findNamedProperty = curry((propertyName, properties) => {
  return properties.find(p => get('name', p) === propertyName);
});
export const OBJECT_SEARCH_CONFIGS = {
  [CONTACT]: {
    url: `${SEARCH_API}contacts`,
    adapter: {
      mapIds: withIdField('vid'),
      mapQuery: searchQuery => searchQuery.update('sorts', (sorts = List()) => sorts.push({
        property: 'createdate',
        order: 'DESC'
      }).push({
        property: 'vid',
        order: 'DESC'
      })).toJS(),
      mapResponse: formatResults('contacts', {
        getId: get('vid'),
        getLabel: formatContactName
      })
    }
  },
  [COMPANY]: {
    url: `${SEARCH_API}companies`,
    adapter: {
      mapIds: withIdField('companyId'),
      mapQuery: addSortBy('createdate'),
      mapResponse: formatResults('companies', {
        getDescription: getIn(['properties', 'domain', 'value']),
        getId: get('company-id'),
        getLabel: formatCompanyName
      })
    }
  },
  [DEAL]: {
    url: `${SEARCH_API}deals`,
    adapter: {
      mapIds: withIdField('dealId'),
      mapQuery: addSortBy('createdate'),
      mapResponse: formatResults('deals', {
        getId: get('dealId'),
        getLabel: getIn(['properties', 'dealname', 'value'])
      })
    }
  },
  [EDITABLE_DEAL]: {
    // Returns only deals that the current user can edit
    url: `${SEARCH_API}deals?requestAction=EDIT`,
    adapter: {
      mapIds: withIdField('dealId'),
      mapQuery: addSortBy('createdate'),
      mapResponse: formatResults('deals', {
        getId: get('dealId'),
        getLabel: getIn(['properties', 'dealname', 'value'])
      })
    }
  },
  [ENGAGEMENT]: {
    url: `${SEARCH_API}engagements`,
    adapter: {
      mapIds: withIdField('engagement.id'),
      mapQuery: addSortBy('engagement.createdAt'),
      mapResponse: formatResults('engagements', {
        getId: getIn(['engagement', 'id']),
        getLabel: res => {
          const {
            metadata: {
              text,
              body,
              subject,
              title
            },
            engagement: {
              type
            }
          } = res;
          let label = text || body;
          if (['EMAIL', 'INCOMING_EMAIL', 'TASK'].includes(type)) {
            label = subject;
          } else if (type === 'MEETING' && title) {
            label = title;
          }
          const normalizedLabel = label ? String(label).replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ').replace(/&#x27;/g, "'").replace(/&#8230;/, '…').replace(/&quot;/, '"') : '';
          return normalizedLabel.length > 200 ? `${normalizedLabel.slice(0, 200)}…` : normalizedLabel;
        }
      })
    }
  },
  [TASK]: {
    url: `${SEARCH_API}engagements`,
    adapter: {
      mapIds: withIdField('engagement.id'),
      mapQuery: query => {
        const taskFilter = new ImmutableMap({
          operator: 'EQ',
          property: 'engagement.type',
          value: 'TASK'
        });
        const onlyTasksFilter = updateIn(['filterGroups'], (groups = new List()) => {
          return updateIn([0, 'filters'], (filters = new List()) => {
            return filters.concat([taskFilter]);
          }, groups);
        });
        return addSortBy('engagement.createdAt', onlyTasksFilter(query));
      },
      mapResponse: formatResults('engagements', {
        getId: getIn(['engagement', 'id']),
        getLabel: res => {
          return getIn(['metadata', 'subject'], res) || getIn(['metadata', 'body'], res);
        },
        getDescription: res => {
          // if the label is "using" the 'subject', use the body
          return hasIn(['metadata', 'subject'], res) ? getIn(['metadata', 'body'], res) : undefined;
        }
      })
    }
  },
  [TICKET]: {
    url: `${SEARCH_API}services/tickets?includeAllValues=true&allPropertiesFetchMode=latest_version`,
    adapter: {
      mapIds: withIdField('hs_ticket_id'),
      mapQuery: addSortBy('createdate'),
      mapResponse: formatResults('results', {
        getId: get('objectId'),
        getLabel: getIn(['properties', 'subject', 'value'])
      })
    }
  },
  [PRODUCT]: {
    url: `${SEARCH_API}products?includeAllValues=true`,
    adapter: {
      mapIds: withIdField('objectId'),
      mapQuery: addSortBy('createdate'),
      mapResponse: formatResults('results', {
        getId: get('objectId'),
        // the products API does not validate that name is set
        getLabel: result => getIn(['properties', 'name', 'value'], result) || ''
      })
    }
  },
  [PRODUCT_FOLDER]: {
    url: 'folders/v1/search/products?includeAllValues=true',
    adapter: {
      mapIds: withIdField('folderId'),
      mapQuery: query => {
        const FOLDER_TYPE = 2;
        const folderFilter = new ImmutableMap({
          operator: 'EQ',
          property: 'type',
          value: FOLDER_TYPE
        });
        const addProductFoldersFilter = updateIn(['filterGroups'], (groups = new List()) => {
          return updateIn([0, 'filters'], (filters = new List()) => {
            return filters.concat([folderFilter]);
          }, groups);
        });
        return addSortBy('createdate', addProductFoldersFilter(query));
      },
      mapResponse: formatResults('results', {
        getId: get('folderId'),
        getLabel: get('name')
      })
    }
  },
  [LINE_ITEM]: {
    url: `${SEARCH_API}lineitems?includeAllValues=true`,
    adapter: {
      mapIds: withIdField('objectId'),
      mapQuery: addSortBy('createdate'),
      mapResponse: formatResults('results', {
        getId: get('objectId'),
        getLabel: getIn(['properties', 'name', 'value'])
      })
    }
  },
  [QUOTE]: {
    url: `${SEARCH_API}quotes?includeAllValues=true`,
    adapter: {
      mapIds: withIdField('quoteId'),
      mapQuery: addSortBy('createdate'),
      mapResponse: formatResults('results', {
        getId: get('quoteId'),
        getLabel: compose(get('value'), findNamedProperty('hs_title'), get('properties'))
      })
    }
  },
  [REVENUE_DEAL_MERGE]: {
    url: `${SEARCH_API}deals`,
    adapter: {
      mapIds: withIdField('dealId'),
      mapQuery: addSortBy('createdate'),
      mapResponse: formatResultsWithFilter('deals', {
        getId: get('dealId'),
        getLabel: getIn(['properties', 'dealname', 'value'])
      }, getIn(['properties', 'is_open', 'value']))
    }
  }
};
export const createGetObjectsByIds = ({
  httpClient
}) => curry((objectType, ids) => {
  const {
    url,
    adapter
  } = OBJECT_SEARCH_CONFIGS[objectType];
  const query = adapter.mapIds(ids);
  return httpClient.post(url, {
    data: query
  }).then(adapter.mapResponse).then(get('results'));
});
export const getObjectsByIds = createGetObjectsByIds({
  httpClient: http
});
export const createSearchObjects = ({
  httpClient
}) => curry((objectType, originalQuery) => {
  const {
    url,
    adapter
  } = OBJECT_SEARCH_CONFIGS[objectType];
  const query = adapter.mapQuery(originalQuery);
  return httpClient.post(url, {
    data: query
  }).then(adapter.mapResponse);
});
export const searchObjects = createSearchObjects({
  httpClient: http
});