'use es6';

import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { fetchCampaignsById, createFetchCampaignsById, createSearchCampaigns, searchCampaigns } from 'reference-resolvers/api/CampaignsAPI';
export const createCampaignReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.CAMPAIGNS,
  createFetchByIds: createFetchCampaignsById,
  fetchByIds: fetchCampaignsById,
  createFetchSearchPage: createSearchCampaigns,
  fetchSearchPage: searchCampaigns
}, options));
export default createCampaignReferenceResolver();