import { createSlice } from '@reduxjs/toolkit';
export const renderOptionsSlice = createSlice({
  name: 'renderOptions',
  initialState: {},
  reducers: {
    setGetIsCardShaded: (state, action) => {
      state.getIsCardShaded = action.payload;
    },
    setGetDragPermissionsMessageForCard: (state, action) => {
      state.getDragPermissionsMessageForCard = action.payload;
    }
  }
});