import { createSlice } from '@reduxjs/toolkit';
// @ts-expect-error This is not yet typed
import { getFrom } from 'crm_data/settings/LocalSettings';
import { DEFAULT_BOARD_PAGE_SIZE, DEFAULT_TABLE_PAGE_SIZE } from '../constants/DefaultPageSizes';
import { RESTORE_CACHED_VALUES, SYNC_ROUTER_VALUES } from '../../init/actions/initActionTypes';
import { SYNC_SEARCH_TERM } from '../../search/actions/searchActionTypes';
import { FILTERS_CHANGED, OPTIMISTICALLY_UPDATE_CURRENT_VIEW_ID, SORT_CHANGED, FILTER_GROUPS_CHANGED, QUICK_FILTERS_CHANGED } from '../../../views/actions/viewsActionTypes';
import { pipelineChanged } from '../../pipelines/slice/currentPipelineIdSlice';
const isStateResetAction = ({
  type
}) => [OPTIMISTICALLY_UPDATE_CURRENT_VIEW_ID, SYNC_ROUTER_VALUES, SYNC_SEARCH_TERM, FILTERS_CHANGED, SORT_CHANGED, FILTER_GROUPS_CHANGED, QUICK_FILTERS_CHANGED, pipelineChanged.toString()].includes(type);
const getStashedPageSize = () => {
  try {
    return getFrom(localStorage, 'gridPageSize');
  } catch (e) {
    return null;
  }
};
const initialState = {
  page: 0,
  pageSize: getStashedPageSize() || DEFAULT_TABLE_PAGE_SIZE,
  boardPageSize: DEFAULT_BOARD_PAGE_SIZE
};
const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    changePage: (state, {
      payload: page
    }) => {
      state.page = page;
    },
    changePageSize: (state, {
      payload: pageSize
    }) => {
      state.page = 0;
      state.pageSize = pageSize;
    },
    changeBoardPageSize: (state, {
      payload: pageSize
    }) => {
      state.boardPageSize = pageSize;
    }
  },
  extraReducers: builder => {
    builder.addCase(RESTORE_CACHED_VALUES, (state, {
      payload: {
        page,
        hasData
      }
    }) => {
      if (hasData) {
        state.page = page;
      }
    }).addMatcher(isStateResetAction, state => {
      state.page = 0;
      state.boardPageSize = DEFAULT_BOARD_PAGE_SIZE;
    });
  }
});
export const {
  changePage,
  changePageSize,
  changeBoardPageSize
} = paginationSlice.actions;
export const paginationReducer = paginationSlice.reducer;