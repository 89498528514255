import curry from 'transmute/curry';
import { objectEntries } from './objectEntries';
export const mutableDeleteIn = curry((keyPath, object) => {
  let nestedObj = object;
  for (const [stringIndex, key] of objectEntries(keyPath)) {
    if (!Object.prototype.hasOwnProperty.call(nestedObj, key)) {
      break;
    }

    // HACK: objectEntries uses Object.keys() internally, which returns
    // strings when called for arrays. The converion is guaranteed to be safe
    // because the values are generated from array indexes. When Object.entries()
    // is polyfilled, we can use that and remove the conversion here
    if (Number(stringIndex) === keyPath.length - 1) {
      delete nestedObj[key];
      break;
    }
    nestedObj = nestedObj[key];
  }
});