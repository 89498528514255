import { createSlice } from '@reduxjs/toolkit';
export const reconciliationSlice = createSlice({
  name: 'reconciliation',
  initialState: {
    columnsUpdated: new Set()
  },
  reducers: {
    setColumnsUpdated: (state, action) => {
      state.columnsUpdated = action.payload;
    }
  }
});