import { CONTACT_TYPE_ID, DEAL_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import OptionSortStrategy from 'customer-data-objects/property/OptionSortStrategy';
import { isMultienum } from 'customer-data-objects/property/PropertyIdentifier';
import { ENUMERATION } from 'customer-data-objects/property/PropertyTypes';
const SORT_OVERRIDES = {
  [CONTACT_TYPE_ID]: {
    ['name']: {
      sortKeys: ['lastname', 'firstname', 'email']
    }
  },
  [DEAL_TYPE_ID]: {
    ['dealstage']: {
      sortKeys: ['pipeline', 'dealstage.displayOrder', 'dealstage.label']
    },
    ['hs_priority']: {
      sortKeys: ['hs_priority.displayOrder']
    }
  },
  [TICKET_TYPE_ID]: {
    ['hs_ticket_priority']: {
      sortKeys: ['hs_ticket_priority.displayOrder']
    },
    ['hs_pipeline_stage']: {
      sortKeys: ['hs_pipeline_stage.displayOrder']
    }
  }
};
const hasExternalOptions = property => property && property.externalOptions;
const enumPropertyHasAlphabeticalStrategy = property => property && property.type === ENUMERATION && property.optionSortStrategy === OptionSortStrategy.ALPHABETICAL;

// See related thread: https://hubspot.slack.com/archives/C02CUKAKQSH/p1700167426148509
// TLDR: If an enum property has an abnormally large amount of options, crm-search struggles to sort by display order resulting in timeout failures
// We can be a little smarter in our logic if the optionSortStrategy on the property is alphabetical and it has a lot of options since the options are already sorted by display order.
const shouldSkipEnumDisplayOrderSorting = property => {
  return enumPropertyHasAlphabeticalStrategy(property);
};

// We want to sort by display order for enumeration properties, which means
// "extending" the property to be property.displayOrder. However, multi-
// enum properties (that use a checkbox) and properties supported by external
// options don't support sorting by displayOrder, so we'll leave those as-is
// and sort by internal value.
const extendPropertyName = (propertyName, property) => {
  if (!property) {
    return propertyName;
  }
  if (
  // TODO: Switch this to isEnumeration from PropertyIdentifier once that becomes available.
  // See https://git.hubteam.com/HubSpot/crm-framework-frontend/issues/104
  property.type === ENUMERATION && !isMultienum(property) && !hasExternalOptions(property) && !shouldSkipEnumDisplayOrderSorting(property)) {
    return `${propertyName}.displayOrder`;
  }
  return propertyName;
};
const getOverrideSortKeys = (objectTypeId, sortColumnName) => {
  const objectTypeIdOverride = SORT_OVERRIDES[objectTypeId];
  if (objectTypeIdOverride) {
    const sortColumnOverride = objectTypeIdOverride[sortColumnName];
    if (sortColumnOverride) {
      return sortColumnOverride.sortKeys;
    }
  }
  return undefined;
};
export const processInputQuerySorts = ({
  inputSorts,
  partialTypeDef,
  properties
}) => {
  const {
    objectTypeId,
    createDatePropertyName
  } = partialTypeDef;
  const resultSorts = (inputSorts || []).flatMap(inputSort => {
    const rawSortProperty = inputSort.property;
    const order = inputSort.order || 'DESC';
    const overrideSortKeys = getOverrideSortKeys(objectTypeId, rawSortProperty);
    const sortProperties = overrideSortKeys || [rawSortProperty];
    return sortProperties.map(property => ({
      property,
      order
    }));
  }).map(sortObject => {
    const maybePropertyDefinition = properties[sortObject.property];
    return {
      property: extendPropertyName(sortObject.property, maybePropertyDefinition),
      order: sortObject.order
    };
  });
  if (!resultSorts.find(({
    property
  }) => property === createDatePropertyName)) {
    resultSorts.push({
      property: createDatePropertyName,
      order: 'DESC'
    });
  }
  resultSorts.push({
    property: 'hs_object_id',
    order: 'DESC'
  });
  return resultSorts;
};