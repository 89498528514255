'use es6';

export const TASK_LAST_TOUCH = 'TASK_LAST_TOUCH';
export const TASK_LAST_ENGAGEMENT = 'TASK_LAST_ENGAGEMENT';
export const ACTIVITY_TYPE = 'ACTIVITY_TYPE';
export const AD_NETWORK_GOOGLE = 'GOOGLE';
export const AD_NETWORK_FACEBOOK = 'FACEBOOK';
export const AD_NETWORK_LINKEDIN = 'LINKEDIN';
export const AD_OBJECT_AD_ACCOUNT = 'AD_ACCOUNT';
export const AD_OBJECT_AD_CAMPAIGN = 'AD_CAMPAIGN';
export const AD_OBJECT_AD_GROUP = 'AD_GROUP';
export const AD_OBJECT_AD = 'AD';
export const AD_OBJECT_KEYWORD = 'KEYWORD';
export const ADS_FACEBOOK_AD = 'ADS_FACEBOOK_AD';
export const ADS_FACEBOOK_ADGROUP = 'ADS_FACEBOOK_ADGROUP';
export const ADS_FACEBOOK_CAMPAIGN = 'ADS_FACEBOOK_CAMPAIGN';
export const ADS_GOOGLE_AD = 'ADS_GOOGLE_AD';
export const ADS_GOOGLE_ADGROUP = 'ADS_GOOGLE_ADGROUP';
export const ADS_GOOGLE_CAMPAIGN = 'ADS_GOOGLE_CAMPAIGN';
export const ADS_GOOGLE_KEYWORD = 'ADS_GOOGLE_KEYWORD';
export const ADS_LINKEDIN_AD = 'ADS_LINKEDIN_AD';
export const ADS_LINKEDIN_ADGROUP = 'ADS_LINKEDIN_ADGROUP';
export const ADS_LINKEDIN_CAMPAIGN = 'ADS_LINKEDIN_CAMPAIGN';
export const ADS_PROPERTY = 'ADS_PROPERTY';
export const ALL_OBJECT_PROPERTIES = 'ALL_OBJECT_PROPERTIES';
export const ALL_WORKFLOW = 'ALL_WORKFLOW';
export const ANALYTICS_VIEW = 'ANALYTICS_VIEW';
export const APP_USER = 'APP_USER';
export const ASSIGNABLE_OWNER = 'ASSIGNABLE_OWNER';
export const ASSIGNABLE_USER = 'ASSIGNABLE_USER';
export const AUTOMATED_EMAIL = 'AUTOMATED_EMAIL';
export const AUTOMATION_PLATFORM_WORKFLOWS = 'AUTOMATION_PLATFORM_WORKFLOWS';
export const BLOG_AUTHOR = 'BLOG_AUTHOR';
export const BLOG_POST = 'BLOG_POST';
export const BLOG_TAG = 'BLOG_TAG';
export const BLOGS = 'BLOGS';
export const BUSINESS_UNIT = 'BUSINESS_UNIT';
export const BUSINESS_UNIT_DROPDOWN_OPTION = 'BUSINESS_UNIT_DROPDOWN_OPTION';
export const CALL_DISPOSITION = 'CALL_DISPOSITION';
export const CAMPAIGN = 'CAMPAIGN';
export const CHATFLOW = 'CHATFLOW';
export const CHATFLOW_BY_UNIQUE_ID = 'CHATFLOW_BY_UNIQUE_ID';
export const COMPANIES_SAVED_FILTERS = 'COMPANIES_SAVED_FILTERS';
export const COMMENT = 'COMMENT';
export const COMPANY = 'COMPANY';
export const COMPANY_PROPERTY = 'COMPANY_PROPERTY';
export const CONTACT = 'CONTACT';
export const CONTACT_BY_EMAIL = 'CONTACT_BY_EMAIL';
export const CONTACT_LIST = 'CONTACT_LIST';
export const CONTACT_PROPERTY = 'CONTACT_PROPERTY';
export const CONTACT_STATIC_LIST = 'CONTACT_STATIC_LIST';
export const CONTACTS_SAVED_FILTERS = 'CONTACTS_SAVED_FILTERS';
export const CONTENT = 'CONTENT';
export const CONVERSATION_INBOX = 'CONVERSATION_INBOX';
export const COS_INDEX_CONNECTIONS_PARENTS = 'COS_INDEX_CONNECTIONS_PARENTS';
export const CTA = 'CTA';
export const CTA_GUID = 'CTA_GUID';
export const CTA_TO_PLACEMENT = 'CTA_TO_PLACEMENT';
export const CUSTOM_OBJECT = 'CUSTOM_OBJECT';
export const CUSTOM_OBJECT_TYPE = 'CUSTOM_OBJECT_TYPE';
export const DEAL = 'DEAL';
export const DEAL_PIPELINE = 'DEAL_PIPELINE';
export const DEAL_PIPELINE_STAGE = 'DEAL_PIPELINE_STAGE';
export const DEAL_PROPERTY = 'DEAL_PROPERTY';
export const DEALS_SAVED_FILTERS = 'DEALS_SAVED_FILTERS';
export const ECOMMERCE_APP = 'ECOMMERCE_APP';
export const ECOMMERCE_STORE = 'ECOMMERCE_STORE';
export const EXTERNAL_ACCOUNT = 'EXTERNAL_ACCOUNT';
export const EDITABLE_DEAL = 'EDITABLE_DEAL';
export const EMAIL = 'EMAIL';
export const EMAIL_CAMPAIGN = 'EMAIL_CAMPAIGN';
export const EMAIL_CAMPAIGN_URL = 'EMAIL_CAMPAIGN_URL';
export const EMAIL_SUBSCRIPTION = 'EMAIL_SUBSCRIPTION';
export const SMS_SUBSCRIPTION = 'SMS_SUBSCRIPTION';
export const WHATSAPP_SUBSCRIPTION = 'WHATSAPP_SUBSCRIPTION';
export const EMAIL_VALIDATION = 'EMAIL_VALIDATION';
export const EMAIL_SUBSCRIPTION_GROUPS = 'EMAIL_SUBSCRIPTION_GROUPS';
export const ENGAGEMENT = 'ENGAGEMENT';
export const ENGAGEMENT_PROPERTY = 'ENGAGEMENT_PROPERTY';
export const EVENT = 'EVENT';
export const FEEDBACK = 'FEEDBACK';
export const FEEDBACK_FORM = 'FEEDBACK_FORM';
export const FORM = 'FORM';
export const HIERARCHICAL_TEAM = 'HIERARCHICAL_TEAM';
export const HIERARCHICAL_TEAM_BY_USER = 'HIERARCHICAL_TEAM_BY_USER';
export const IMPORT_NAME = 'IMPORT_NAME';
export const INBOUND_DB_COMPANY_IMPORT = 'INBOUND_DB_COMPANY_IMPORT';
export const INBOUND_DB_COMPANY_LIST = 'INBOUND_DB_COMPANY_LIST';
export const INBOUND_DB_CONTACT_IMPORT = 'INBOUND_DB_CONTACT_IMPORT';
export const INBOUND_DB_CONTACT_LIST = 'INBOUND_DB_CONTACT_LIST';
export const INBOUND_DB_DEAL_IMPORT = 'INBOUND_DB_DEAL_IMPORT';
export const INBOUND_DB_DEAL_LIST = 'INBOUND_DB_DEAL_LIST';
export const INBOUND_DB_IMPORT = 'INBOUND_DB_IMPORT';
export const INBOUND_DB_LISTS = 'INBOUND_DB_LISTS';
export const INBOUND_DB_TASK_IMPORT = 'INBOUND_DB_TASK_IMPORT';
export const INBOUND_DB_TICKET_LIST = 'INBOUND_DB_TICKET_LIST';
export const INTEGRATION = 'INTEGRATION';
export const INTEGRATION_NAME = 'INTEGRATION_NAME';
export const INTEGRATION_PROPERTY = 'INTEGRATION_PROPERTY';
export const INVOICE_PROPERTY = 'INVOICE_PROPERTY';
export const KNOWLEDGE_ARTICLES = 'KNOWLEDGE_ARTICLES';
export const LANDING_PAGE = 'LANDING_PAGE';
export const LEAD_FLOW = 'LEAD_FLOW';
export const LINE_ITEM = 'LINE_ITEM';
export const LINE_ITEM_PROPERTY = 'LINE_ITEM_PROPERTY';
export const MEETING_OUTCOME = 'MEETING_OUTCOME';
export const MARKETING_EVENT = 'MARKETING_EVENT';
export const MARKETING_REASON = 'MARKETING_REASON';
export const MARKETING_EVENT_APP_NAME = 'MARKETING_EVENT_APP_NAME';
export const MEDIA_BRIDGE_MEDIA_OBJECT = 'MEDIA_BRIDGE_MEDIA_OBJECT';
export const MULTI_CURRENCY_CURRENCY_CODE = 'MULTI_CURRENCY_CURRENCY_CODE';
export const MULTI_CURRENCY_INFORMATION = 'MULTI_CURRENCY_INFORMATION';
export const OBJECT_COORDINATES = 'OBJECT_COORDINATES';
export const OBJECT_PROPERTIES = 'OBJECT_PROPERTIES';
export const OWNER = 'OWNER';
export const PERSONA = 'PERSONA';
export const PRODUCT = 'PRODUCT';
export const PRODUCT_FOLDER = 'PRODUCT_FOLDER';
export const PRODUCT_PROPERTY = 'PRODUCT_PROPERTY';
export const PROPERTY_GROUP = 'PROPERTY_GROUP';
export const QUOTE = 'QUOTE';
export const QUOTE_PROPERTY = 'QUOTE_PROPERTY';
export const QUOTE_TEMPLATE = 'QUOTE_TEMPLATE';
export const REVENUE_DEAL_MERGE = 'REVENUE_DEAL_MERGE';
export const SALESFORCE_CAMPAIGN = 'SALESFORCE_CAMPAIGN';
export const SEQUENCE = 'SEQUENCE';
export const SEQUENCE_WITH_PERMISSIONS = 'SEQUENCE_WITH_PERMISSIONS';
export const SEQUENCE_STEP_ORDER = 'SEQUENCE_STEP_ORDER';
export const SHARED_TASK_QUEUE = 'SHARED_TASK_QUEUE';
export const SITE_CONTENT = 'SITE_CONTENT';
export const SITE_PAGE = 'SITE_PAGE';
export const SLACK_CHANNEL = 'SLACK_CHANNEL';
export const SLACK_USER = 'SLACK_USER';
export const SOCIAL_CHANNEL = 'SOCIAL_CHANNEL';
export const SUBDOMAIN = 'SUBDOMAIN';
export const SUBDOMAIN_ID = 'SUBDOMAIN_ID';
export const SUBMISSION_TAG = 'SUBMISSION_TAG';
export const SUBSCRIPTION = 'SUBSCRIPTION';
export const SURVEY = 'SURVEY';
export const SURVEY_QUESTION = 'SURVEY_QUESTION';
export const SURVEY_SETTING = 'SURVEY_SETTING';
export const TASK = 'TASK';
export const TASK_QUEUE = 'TASK_QUEUE';
export const TEAM = 'TEAM';
export const TEMPLATE = 'TEMPLATE';
export const TICKET = 'TICKET';
export const TICKET_PIPELINE = 'TICKET_PIPELINE';
export const TICKET_PROPERTY = 'TICKET_PROPERTY';
export const TICKET_STAGE = 'TICKET_STAGE';
export const USER = 'USER';
export const WEBINAR = 'WEBINAR';
export const WEBINAR_SETTING = 'WEBINAR_SETTING';
export const WEB_INTERACTIVE = 'WEB_INTERACTIVE';
export const WORKFLOW = 'WORKFLOW';
export const WORKFLOW_FOLDER = 'WORKFLOW_FOLDER';
export const WORKFLOW_SALESFORCE = 'WORKFLOW_SALESFORCE';

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}