'use es6';

import formatName from 'I18n/utils/formatName';
import filter from 'transmute/filter';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import hasIn from 'transmute/hasIn';
import ifElse from 'transmute/ifElse';
import map from 'transmute/map';
import pipe from 'transmute/pipe';
const hasIdentity = hasIn(['identityProfiles', 0, 'identity']); // if v3 contact API format
const getIdentity = getIn(['identityProfiles', 0, 'identity']); // v3 identity format
const getIdentities = getIn(['identity-profiles', 0, 'identities']); // v1 identity format
const getIdentityProfile = ifElse(hasIdentity, getIdentity, getIdentities);
const getEmailIdentities = filter(identity => get('type', identity) === 'EMAIL');
const getEmailValues = map(get('value'));
const formatEmailValues = emailValues => emailValues.join(', ');
export const formatContactName = contact => {
  const firstName = getIn(['properties', 'firstname', 'value'], contact) || '';
  const lastName = getIn(['properties', 'lastname', 'value'], contact) || '';
  const email = getIn(['properties', 'email', 'value'], contact);
  const fullName = formatName({
    firstName,
    lastName
  });
  if (fullName != null && fullName.length > 0) {
    return fullName;
  } else if (email) {
    return email;
  } else {
    return ''; // anonymous visitors (contacts with `isContact: false`) have no name or email address
  }
};
export const formatContactDescription = pipe(getIdentityProfile, getEmailIdentities, getEmailValues, formatEmailValues);