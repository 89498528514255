import { HUBSPOT_DEFINED, INTEGRATOR_DEFINED, USER_DEFINED } from 'customer-data-objects/associations/AssociationCategoryTypes';
const getMetaType = associationCategory => {
  if (associationCategory === HUBSPOT_DEFINED) {
    return '0';
  }
  if (associationCategory === USER_DEFINED) {
    return '1';
  }
  if (associationCategory === INTEGRATOR_DEFINED) {
    return '2';
  }
  //this assumes that this means it is INTEGRATION_DEFINED, this is not a safe assumption
  //ideally we would log a raven here, this repo does not have raven as a dep, this should be a good follow up
  // until we can add raven tracking, this is falling back to the original behavior pre-refactor
  return '1';
};
export const associationDefinitionToId = associationDefinition => {
  const metaType = getMetaType(associationDefinition.associationCategory);
  return `${metaType}-${associationDefinition.associationTypeId}`;
};