import { usePrefetch as usePrefetchSearchDeps } from 'crm-object-search-query-utilities/prefetch';
import { useProperties } from '../properties/useProperties';
export const usePrefetch = ({
  objectTypeId,
  skip = false
}) => {
  usePrefetchSearchDeps({
    objectTypeId,
    skip
  });
  useProperties(objectTypeId, {
    skip
  });
};