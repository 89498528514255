'use es6';

/**
 * All the actionTypes used by flux actionCreators, services, and stores in crm_data.
 *
 * These keys should be declared in _ABC order_.
 */
export const ACCOUNTING_EXTENSIONS_FETCH_QUEUED = 'ACCOUNTING_EXTENSIONS_FETCH_QUEUED';
export const ACCOUNTING_EXTENSIONS_FETCH_STARTED = 'ACCOUNTING_EXTENSIONS_FETCH_STARTED';
export const ACCOUNTING_EXTENSIONS_FETCH_FAILED = 'ACCOUNTING_EXTENSIONS_FETCH_FAILED';
export const ACCOUNTING_EXTENSIONS_FETCH_SUCCEEDED = 'ACCOUNTING_EXTENSIONS_FETCH_SUCCEEDED';
export const ACCOUNTING_EXTENSIONS_FETCH_SETTLED = 'ACCOUNTING_EXTENSIONS_FETCH_SETTLED';
export const ACCOUNTING_INVOICE_REFRESH_QUEUED = 'ACCOUNTING_INVOICE_REFRESH_QUEUED';
export const ACCOUNTING_NEW_CUSTOMER_UPDATE = 'ACCOUNTING_NEW_CUSTOMER_UPDATE';
export const ACTIVITY_TYPES_UPDATED = 'ACTIVITY_TYPES_UPDATED';
export const ASSOCIATIONS_REFRESH_QUEUED = 'ASSOCIATIONS_REFRESH_QUEUED';
export const AUTH_LOADED = 'AUTH_LOADED';
export const AUTHENTICATED_ZOOM_FILE_FETCH_SUCCEEDED = 'AUTHENTICATED_ZOOM_FILE_FETCH_SUCCEEDED';
export const AUTHENTICATED_ZOOM_FILE_FETCH_FAILED = 'AUTHENTICATED_ZOOM_FILE_FETCH_FAILED';
export const CONVERSATIONS_STORE_RESET_ON_RETURN_TO_TIMELINE = 'CONVERSATIONS_STORE_RESET_ON_RETURN_TO_TIMELINE';
export const BET_PORTAL_OBJECT_ASSOCIATIONS_ADD = 'BET_PORTAL_OBJECT_ASSOCIATIONS_ADD';
export const BET_PORTAL_OBJECT_ASSOCIATIONS_FETCH = 'BET_PORTAL_OBJECT_ASSOCIATIONS_FETCH';
export const BET_PORTAL_OBJECT_ASSOCIATIONS_REMOVE = 'BET_PORTAL_OBJECT_ASSOCIATIONS_REMOVE';
export const BET_SERVICE_OBJECT_ASSOCIATIONS_ADD = 'BET_SERVICE_OBJECT_ASSOCIATIONS_ADD';
export const BET_SERVICE_OBJECT_ASSOCIATIONS_FETCH = 'BET_SERVICE_OBJECT_ASSOCIATIONS_FETCH';
export const BET_SERVICE_OBJECT_ASSOCIATIONS_REMOVE = 'BET_SERVICE_OBJECT_ASSOCIATIONS_REMOVE';
export const CALL_PROVIDERS_FETCH_QUEUED = 'CALL_PROVIDERS_FETCH_QUEUED';
export const CALL_PROVIDERS_FETCH_STARTED = 'CALL_PROVIDERS_FETCH_STARTED';
export const CALL_PROVIDERS_FETCH_FAILED = 'CALL_PROVIDERS_FETCH_FAILED';
export const CALL_PROVIDERS_FETCH_SUCCEEDED = 'CALL_PROVIDERS_FETCH_SUCCEEDED';
export const CALL_PROVIDERS_FETCH_SETTLED = 'CALL_PROVIDERS_FETCH_SETTLED';
export const COMPANIES_FETCH_QUEUED = 'COMPANIES_FETCH_QUEUED';
export const COMPANIES_FETCH_STARTED = 'COMPANIES_FETCH_STARTED';
export const COMPANIES_FETCH_FAILED = 'COMPANIES_FETCH_FAILED';
export const COMPANIES_FETCH_SUCCEEDED = 'COMPANIES_FETCH_SUCCEEDED';
export const COMPANIES_FETCH_SETTLED = 'COMPANIES_FETCH_SETTLED';
export const COMPANIES_UPDATE_QUEUED = 'COMPANIES_UPDATE_QUEUED';
export const COMPANIES_UPDATE_STARTED = 'COMPANIES_UPDATE_STARTED';
export const COMPANIES_UPDATE_FAILED = 'COMPANIES_UPDATE_FAILED';
export const COMPANIES_UPDATE_SUCCEEDED = 'COMPANIES_UPDATE_SUCCEEDED';
export const COMPANIES_UPDATE_SETTLED = 'COMPANIES_UPDATE_SETTLED';
export const COMPANIES_REFRESH_QUEUED = 'COMPANIES_REFRESH_QUEUED';
export const COMPANIES_SEARCH_LOADING = 'COMPANIES_SEARCH_LOADING';
export const COMPANIES_SEARCH_SYNCED = 'COMPANIES_SEARCH_SYNCED';
export const COMPANIES_UPDATED = 'COMPANIES_UPDATED';
export const COMPANIES_VIEW_SYNCED = 'COMPANIES_VIEW_SYNCED';
export const COMPANY_CREATED = 'COMPANY_CREATED';
export const COMPANY_DOMAIN_FOUND = 'COMPANY_DOMAIN_FOUND';
export const COMPANY_DOMAIN_NOT_FOUND = 'COMPANY_DOMAIN_NOT_FOUND';
export const COMPANY_DOMAIN_REMOVED = 'COMPANY_DOMAIN_REMOVED';
export const COMPANY_PARENT_ADD_CHILD = 'COMPANY_PARENT_ADD_CHILD';
export const COMPANY_PARENT_REMOVE_CHILD = 'COMPANY_PARENT_REMOVE_CHILD';
export const CONTACTS_EMAIL_STORE_FETCH_SUCCEEDED = 'CONTACTS_EMAIL_STORE_FETCH_SUCCEEDED';
export const CONTACTS_FETCH_QUEUED = 'CONTACTS_FETCH_QUEUED';
export const CONTACTS_FETCH_STARTED = 'CONTACTS_FETCH_STARTED';
export const CONTACTS_FETCH_FAILED = 'CONTACTS_FETCH_FAILED';
export const CONTACTS_FETCH_SUCCEEDED = 'CONTACTS_FETCH_SUCCEEDED';
export const CONTACTS_FETCH_SETTLED = 'CONTACTS_FETCH_SETTLED';
export const CONTACTS_UPDATE_QUEUED = 'CONTACTS_UPDATE_QUEUED';
export const CONTACTS_UPDATE_STARTED = 'CONTACTS_UPDATE_STARTED';
export const CONTACTS_UPDATE_FAILED = 'CONTACTS_UPDATE_FAILED';
export const CONTACTS_UPDATE_SUCCEEDED = 'CONTACTS_UPDATE_SUCCEEDED';
export const CONTACTS_UPDATE_SETTLED = 'CONTACTS_UPDATE_SETTLED';
export const CONTACT_CREATED = 'CONTACT_CREATED';
export const CONTACT_EMAIL_REMOVED = 'CONTACT_EMAIL_REMOVED';
export const CONTACTS_REFRESH_QUEUED = 'CONTACTS_REFRESH_QUEUED';
export const CONTACTS_REMOVED_FROM_LIST = 'CONTACTS_REMOVED_FROM_LIST';
export const CONTACT_SECONDARY_EMAIL_ADDED = 'CONTACT_SECONDARY_EMAIL_ADDED';
export const CONTACT_SECONDARY_EMAIL_PROMOTED = 'CONTACT_SECONDARY_EMAIL_PROMOTED';
export const CONTACT_SECONDARY_EMAIL_REMOVED = 'CONTACT_SECONDARY_EMAIL_REMOVED';
export const CONTACT_SECONDARY_EMAIL_UPDATED = 'CONTACT_SECONDARY_EMAIL_UPDATED';
export const CONTACT_SET = 'CONTACT_SET';
export const CONTACTS_UPDATED = 'CONTACTS_UPDATED';
export const CONVERSATION_FETCH_HISTORY_SUCCESS = 'CONVERSATION_FETCH_HISTORY_SUCCESS';
export const CRM_OBJECTS_UPDATED = 'CRM_OBJECTS_UPDATED';
export const CRM_OBJECTS_REFRESH_QUEUED = 'CRM_OBJECTS_REFRESH_QUEUED';
export const CRM_OBJECT_TYPES_FETCH_QUEUED = 'CRM_OBJECT_TYPES_FETCH_QUEUED';
export const CRM_OBJECT_TYPES_FETCH_STARTED = 'CRM_OBJECT_TYPES_FETCH_STARTED';
export const CRM_OBJECT_TYPES_FETCH_FAILED = 'CRM_OBJECT_TYPES_FETCH_FAILED';
export const CRM_OBJECT_TYPES_FETCH_SUCCEEDED = 'CRM_OBJECT_TYPES_FETCH_SUCCEEDED';
export const CRM_OBJECT_TYPES_FETCH_SETTLED = 'CRM_OBJECT_TYPES_FETCH_SETTLED';
export const CRM_OBJECTS_UPDATE_STARTED = 'CRM_OBJECTS_UPDATE_STARTED';
export const CRM_OBJECTS_UPDATE_SUCCEEDED = 'CRM_OBJECTS_UPDATE_SUCCEEDED';
export const CRM_OBJECTS_UPDATE_FAILED = 'CRM_OBJECTS_UPDATE_FAILED';
export const CREATOR_PROPERTIES_UPDATE_QUEUED = 'CREATOR_PROPERTIES_UPDATE_QUEUED';
export const CREATOR_PROPERTIES_UPDATE_STARTED = 'CREATOR_PROPERTIES_UPDATE_STARTED';
export const CREATOR_PROPERTIES_UPDATE_FAILED = 'CREATOR_PROPERTIES_UPDATE_FAILED';
export const CREATOR_PROPERTIES_UPDATE_SUCCEEDED = 'CREATOR_PROPERTIES_UPDATE_SUCCEEDED';
export const CREATOR_PROPERTIES_UPDATE_SETTLED = 'CREATOR_PROPERTIES_UPDATE_SETTLED';
export const CURRENT_OWNER_ID_FETCH_QUEUED = 'CURRENT_OWNER_ID_FETCH_QUEUED';
export const CURRENT_OWNER_ID_FETCH_STARTED = 'CURRENT_OWNER_ID_FETCH_STARTED';
export const CURRENT_OWNER_ID_FETCH_FAILED = 'CURRENT_OWNER_ID_FETCH_FAILED';
export const CURRENT_OWNER_ID_FETCH_SUCCEEDED = 'CURRENT_OWNER_ID_FETCH_SUCCEEDED';
export const CURRENT_OWNER_ID_FETCH_SETTLED = 'CURRENT_OWNER_ID_FETCH_SETTLED';
export const DEAL_CREATED = 'DEAL_CREATED';
export const DEAL_PIPELINES_FETCH_QUEUED = 'DEAL_PIPELINES_FETCH_QUEUED';
export const DEAL_PIPELINES_FETCH_STARTED = 'DEAL_PIPELINES_FETCH_STARTED';
export const DEAL_PIPELINES_FETCH_FAILED = 'DEAL_PIPELINES_FETCH_FAILED';
export const DEAL_PIPELINES_FETCH_SUCCEEDED = 'DEAL_PIPELINES_FETCH_SUCCEEDED';
export const DEAL_PIPELINES_FETCH_SETTLED = 'DEAL_PIPELINES_FETCH_SETTLED';
export const DEAL_PIPELINE_SEARCH_RESULT_EXPIRED = 'DEAL_PIPELINE_SEARCH_RESULT_EXPIRED';
export const DEALS_FETCH_QUEUED = 'DEALS_FETCH_QUEUED';
export const DEALS_FETCH_STARTED = 'DEALS_FETCH_STARTED';
export const DEALS_FETCH_FAILED = 'DEALS_FETCH_FAILED';
export const DEALS_FETCH_SUCCEEDED = 'DEALS_FETCH_SUCCEEDED';
export const DEALS_FETCH_SETTLED = 'DEALS_FETCH_SETTLED';
export const DEALS_UPDATE_QUEUED = 'DEALS_UPDATE_QUEUED';
export const DEALS_UPDATE_STARTED = 'DEALS_UPDATE_STARTED';
export const DEALS_UPDATE_FAILED = 'DEALS_UPDATE_FAILED';
export const DEALS_UPDATE_SUCCEEDED = 'DEALS_UPDATE_SUCCEEDED';
export const DEALS_UPDATE_SETTLED = 'DEALS_UPDATE_SETTLED';
export const DEALS_REFRESH_QUEUED = 'DEALS_REFRESH_QUEUED';
export const DEALS_UPDATED = 'DEALS_UPDATED';
export const DEAL_STAGE_PROPERTIES_UPDATE_QUEUED = 'DEAL_STAGE_PROPERTIES_UPDATE_QUEUED';
export const DEAL_STAGE_PROPERTIES_UPDATE_STARTED = 'DEAL_STAGE_PROPERTIES_UPDATE_STARTED';
export const DEAL_STAGE_PROPERTIES_UPDATE_FAILED = 'DEAL_STAGE_PROPERTIES_UPDATE_FAILED';
export const DEAL_STAGE_PROPERTIES_UPDATE_SUCCEEDED = 'DEAL_STAGE_PROPERTIES_UPDATE_SUCCEEDED';
export const DEAL_STAGE_PROPERTIES_UPDATE_SETTLED = 'DEAL_STAGE_PROPERTIES_UPDATE_SETTLED';
export const DEAL_AUTOMATION_CREATE_QUEUED = 'DEAL_AUTOMATION_CREATE_QUEUED';
export const DEAL_AUTOMATION_CREATE_STARTED = 'DEAL_AUTOMATION_CREATE_STARTED';
export const DEAL_AUTOMATION_CREATE_FAILED = 'DEAL_AUTOMATION_CREATE_FAILED';
export const DEAL_AUTOMATION_CREATE_SUCCEEDED = 'DEAL_AUTOMATION_CREATE_SUCCEEDED';
export const DEAL_AUTOMATION_CREATE_SETTLED = 'DEAL_AUTOMATION_CREATE_SETTLED';
export const DEAL_AUTOMATION_DELETE_QUEUED = 'DEAL_AUTOMATION_DELETE_QUEUED';
export const DEAL_AUTOMATION_DELETE_STARTED = 'DEAL_AUTOMATION_DELETE_STARTED';
export const DEAL_AUTOMATION_DELETE_FAILED = 'DEAL_AUTOMATION_DELETE_FAILED';
export const DEAL_AUTOMATION_DELETE_SUCCEEDED = 'DEAL_AUTOMATION_DELETE_SUCCEEDED';
export const DEAL_AUTOMATION_DELETE_SETTLED = 'DEAL_AUTOMATION_DELETE_SETTLED';
export const DEFAULT_VIEW_UPDATED = 'DEFAULT_VIEW_UPDATED';
export const EMAIL_RECIPIENT_DELIVERABILTY_FETCH_QUEUED = 'EMAIL_RECIPIENT_DELIVERABILTY_FETCH_QUEUED';
export const EMAIL_RECIPIENT_DELIVERABILTY_FETCH_STARTED = 'EMAIL_RECIPIENT_DELIVERABILTY_FETCH_STARTED';
export const EMAIL_RECIPIENT_DELIVERABILTY_FETCH_FAILED = 'EMAIL_RECIPIENT_DELIVERABILTY_FETCH_FAILED';
export const EMAIL_RECIPIENT_DELIVERABILTY_FETCH_SUCCEEDED = 'EMAIL_RECIPIENT_DELIVERABILTY_FETCH_SUCCEEDED';
export const EMAIL_RECIPIENT_DELIVERABILTY_FETCH_SETTLED = 'EMAIL_RECIPIENT_DELIVERABILTY_FETCH_SETTLED';
export const EMAIL_TRACKING_EVENT_DELETED = 'EMAIL_TRACKING_EVENT_DELETED';
export const ENGAGEMENTS_FETCH_QUEUED = 'ENGAGEMENTS_FETCH_QUEUED';
export const ENGAGEMENTS_FETCH_STARTED = 'ENGAGEMENTS_FETCH_STARTED';
export const ENGAGEMENTS_FETCH_FAILED = 'ENGAGEMENTS_FETCH_FAILED';
export const ENGAGEMENTS_FETCH_SUCCEEDED = 'ENGAGEMENTS_FETCH_SUCCEEDED';
export const ENGAGEMENTS_FETCH_SETTLED = 'ENGAGEMENTS_FETCH_SETTLED';
export const ENGAGEMENTS_UPDATED = 'ENGAGEMENTS_UPDATED';
export const ENGAGEMENTS_ATTACHMENTS_UPDATED = 'ENGAGEMENTS_ATTACHMENTS_UPDATED';
export const FETCH_UNKNOWN_RESPONDERS_SUCCESS = 'FETCH_UNKNOWN_RESPONDERS_SUCCESS';
export const FOLLOW_PROFILE_TOGGLE_FOLLOW = 'FOLLOW_PROFILE_TOGGLE_FOLLOW';
export const PIPELINE_UPDATED = 'PIPELINE_UPDATED';
export const PIPELINE_DELETED = 'PIPELINE_DELETED';
export const PIPELINES_UPDATED = 'PIPELINES_UPDATED';
export const EMAIL_ALIAS_SIGNATURE_UPDATED = 'EMAIL_ALIAS_SIGNATURE_UPDATED';
export const EMAIL_LAWFUL_BASIS_ADDED = 'EMAIL_LAWFUL_BASIS_ADDED';
export const EMAIL_LAWFUL_BASIS_COMPLETE = 'EMAIL_LAWFUL_BASIS_COMPLETE';
export const LEGACY_ACTIVE_OWNERS_FETCH_QUEUED = 'LEGACY_ACTIVE_OWNERS_FETCH_QUEUED';
export const LEGACY_ACTIVE_OWNERS_FETCH_STARTED = 'LEGACY_ACTIVE_OWNERS_FETCH_STARTED';
export const LEGACY_ACTIVE_OWNERS_FETCH_FAILED = 'LEGACY_ACTIVE_OWNERS_FETCH_FAILED';
export const LEGACY_ACTIVE_OWNERS_FETCH_SUCCEEDED = 'LEGACY_ACTIVE_OWNERS_FETCH_SUCCEEDED';
export const LEGACY_ACTIVE_OWNERS_FETCH_SETTLED = 'LEGACY_ACTIVE_OWNERS_FETCH_SETTLED';
export const LEGACY_OWNERS_FETCH_QUEUED = 'LEGACY_OWNERS_FETCH_QUEUED';
export const LEGACY_OWNERS_FETCH_STARTED = 'LEGACY_OWNERS_FETCH_STARTED';
export const LEGACY_OWNERS_FETCH_FAILED = 'LEGACY_OWNERS_FETCH_FAILED';
export const LEGACY_OWNERS_FETCH_SUCCEEDED = 'LEGACY_OWNERS_FETCH_SUCCEEDED';
export const LEGACY_OWNERS_FETCH_SETTLED = 'LEGACY_OWNERS_FETCH_SETTLED';
export const LEGACY_OWNERS_UPDATED = 'LEGACY_OWNERS_UPDATED';
export const OWNER_IDS_BY_EMAIL_FETCH_QUEUED = 'OWNER_IDS_BY_EMAIL_FETCH_QUEUED';
export const OWNER_IDS_BY_EMAIL_FETCH_STARTED = 'OWNER_IDS_BY_EMAIL_FETCH_STARTED';
export const OWNER_IDS_BY_EMAIL_FETCH_FAILED = 'OWNER_IDS_BY_EMAIL_FETCH_FAILED';
export const OWNER_IDS_BY_EMAIL_FETCH_SUCCEEDED = 'OWNER_IDS_BY_EMAIL_FETCH_SUCCEEDED';
export const OWNER_IDS_BY_EMAIL_FETCH_SETTLED = 'OWNER_IDS_BY_EMAIL_FETCH_SETTLED';
export const OWNER_IDS_BY_REMOTE_ID_FETCH_QUEUED = 'OWNER_IDS_BY_REMOTE_ID_FETCH_QUEUED';
export const OWNER_IDS_BY_REMOTE_ID_FETCH_STARTED = 'OWNER_IDS_BY_REMOTE_ID_FETCH_STARTED';
export const OWNER_IDS_BY_REMOTE_ID_FETCH_FAILED = 'OWNER_IDS_BY_REMOTE_ID_FETCH_FAILED';
export const OWNER_IDS_BY_REMOTE_ID_FETCH_SUCCEEDED = 'OWNER_IDS_BY_REMOTE_ID_FETCH_SUCCEEDED';
export const OWNER_IDS_BY_REMOTE_ID_FETCH_SETTLED = 'OWNER_IDS_BY_REMOTE_ID_FETCH_SETTLED';
export const OWNERS_FETCH_QUEUED = 'OWNERS_FETCH_QUEUED';
export const OWNERS_FETCH_STARTED = 'OWNERS_FETCH_STARTED';
export const OWNERS_FETCH_FAILED = 'OWNERS_FETCH_FAILED';
export const OWNERS_FETCH_SUCCEEDED = 'OWNERS_FETCH_SUCCEEDED';
export const OWNERS_FETCH_SETTLED = 'OWNERS_FETCH_SETTLED';
export const OWNERS_UPDATE_QUEUED = 'OWNERS_UPDATE_QUEUED';
export const OWNERS_UPDATE_STARTED = 'OWNERS_UPDATE_STARTED';
export const OWNERS_UPDATE_FAILED = 'OWNERS_UPDATE_FAILED';
export const OWNERS_UPDATE_SUCCEEDED = 'OWNERS_UPDATE_SUCCEEDED';
export const OWNERS_UPDATE_SETTLED = 'OWNERS_UPDATE_SETTLED';
export const OWNERS_SEARCH_FETCH_QUEUED = 'OWNERS_SEARCH_FETCH_QUEUED';
export const OWNERS_SEARCH_FETCH_STARTED = 'OWNERS_SEARCH_FETCH_STARTED';
export const OWNERS_SEARCH_FETCH_FAILED = 'OWNERS_SEARCH_FETCH_FAILED';
export const OWNERS_SEARCH_FETCH_SUCCEEDED = 'OWNERS_SEARCH_FETCH_SUCCEEDED';
export const OWNERS_SEARCH_FETCH_SETTLED = 'OWNERS_SEARCH_FETCH_SETTLED';
export const IMPORT_NAMES_FETCH_QUEUED = 'IMPORT_NAMES_FETCH_QUEUED';
export const IMPORT_NAMES_FETCH_STARTED = 'IMPORT_NAMES_FETCH_STARTED';
export const IMPORT_NAMES_FETCH_FAILED = 'IMPORT_NAMES_FETCH_FAILED';
export const IMPORT_NAMES_FETCH_SUCCEEDED = 'IMPORT_NAMES_FETCH_SUCCEEDED';
export const IMPORT_NAMES_FETCH_SETTLED = 'IMPORT_NAMES_FETCH_SETTLED';
export const ENGAGEMENT_ASSOCIATIONS_DELETE = 'ENGAGEMENT_ASSOCIATIONS_DELETE';
export const ENGAGEMENT_ASSOCIATIONS_MERGE = 'ENGAGEMENT_ASSOCIATIONS_MERGE';
export const ENGAGEMENT_ASSOCIATIONS_MERGE_AND_UPDATE = 'ENGAGEMENT_ASSOCIATIONS_MERGE_AND_UPDATE';
export const ENGAGEMENTS_ASSOCIATIONS_OVERWRITE = 'ENGAGEMENTS_ASSOCIATIONS_OVERWRITE';
export const ENGAGEMENT_ASSOCIATIONS_REFRESH = 'ENGAGEMENT_ASSOCIATIONS_REFRESH';
export const ENGAGEMENT_ASSOCIATIONS_UPDATE = 'ENGAGEMENT_ASSOCIATIONS_UPDATE';
export const ES_DATA_UPDATED = 'ES_DATA_UPDATED';
export const ES_RESULT_MOVED = 'ES_RESULT_MOVED';
export const ES_VIEW_UPDATED = 'ES_VIEW_UPDATED';
export const ES_FETCH_QUEUED = 'ES_FETCH_QUEUED';
export const ES_FETCH_STARTED = 'ES_FETCH_STARTED';
export const ES_FETCH_FAILED = 'ES_FETCH_FAILED';
export const ES_FETCH_SUCCEEDED = 'ES_FETCH_SUCCEEDED';
export const ES_FETCH_SETTLED = 'ES_FETCH_SETTLED';
export const ES_CLEAR_KEY_DATA = 'ES_CLEAR_KEY_DATA';
export const MEETING_DETAILS_UPDATED = 'MEETING_DETAILS_UPDATED';
export const LIST_CREATE_SUCCEEDED = 'LIST_CREATE_SUCCEEDED';
export const LIST_USAGE_FETCH_QUEUED = 'LIST_USAGE_FETCH_QUEUED';
export const LIST_USAGE_FETCH_STARTED = 'LIST_USAGE_FETCH_STARTED';
export const LIST_USAGE_FETCH_FAILED = 'LIST_USAGE_FETCH_FAILED';
export const LIST_USAGE_FETCH_SUCCEEDED = 'LIST_USAGE_FETCH_SUCCEEDED';
export const LIST_USAGE_FETCH_SETTLED = 'LIST_USAGE_FETCH_SETTLED';
export const LIST_PREVIEW_CANCEL = 'LIST_PREVIEW_CANCEL';
export const LIST_PREVIEW_CREATE = 'LIST_PREVIEW_CREATE';
export const LIST_PREVIEW_UPDATE = 'LIST_PREVIEW_UPDATE';
export const LIST_SEARCH_CACHE_UPDATED = 'LIST_SEARCH_CACHE_UPDATED';
export const LIST_UPDATE_STARTED = 'LIST_UPDATE_STARTED';
export const LIST_UPDATE_SUCCEEDED = 'LIST_UPDATE_SUCCEEDED';
export const LIST_UPDATE_FAILED = 'LIST_UPDATE_FAILED';
export const LIST_VALIDATE_SUCCEEDED = 'LIST_VALIDATE_SUCCEEDED';
export const LIST_VALIDATE_FAILED = 'LIST_VALIDATE_FAILED';
export const LISTS_FETCH_SUCCEEDED = 'LISTS_FETCH_SUCCEEDED';
export const MAXIMIZE_LINKED_IN_NAVIGATOR = 'MAXIMIZE_LINKED_IN_NAVIGATOR';
export const MINIMIZE_LINKED_IN_NAVIGATOR = 'MINIMIZE_LINKED_IN_NAVIGATOR';
export const HIDE_LINKED_IN_NAVIGATOR = 'HIDE_LINKED_IN_NAVIGATOR';
export const SHOW_LINKED_IN_NAVIGATOR = 'SHOW_LINKED_IN_NAVIGATOR';
export const MEETINGS_LINKS_FETCH_QUEUED = 'MEETINGS_LINKS_FETCH_QUEUED';
export const MEETINGS_LINKS_FETCH_STARTED = 'MEETINGS_LINKS_FETCH_STARTED';
export const MEETINGS_LINKS_FETCH_FAILED = 'MEETINGS_LINKS_FETCH_FAILED';
export const MEETINGS_LINKS_FETCH_SUCCEEDED = 'MEETINGS_LINKS_FETCH_SUCCEEDED';
export const MEETINGS_LINKS_FETCH_SETTLED = 'MEETINGS_LINKS_FETCH_SETTLED';
export const MEETINGS_USER_FETCH_QUEUED = 'MEETINGS_USER_FETCH_QUEUED';
export const MEETINGS_USER_FETCH_STARTED = 'MEETINGS_USER_FETCH_STARTED';
export const MEETINGS_USER_FETCH_FAILED = 'MEETINGS_USER_FETCH_FAILED';
export const MEETINGS_USER_FETCH_SUCCEEDED = 'MEETINGS_USER_FETCH_SUCCEEDED';
export const MEETINGS_USER_FETCH_SETTLED = 'MEETINGS_USER_FETCH_SETTLED';
export const MEETINGS_USER_UNAVAILABLE = 'MEETINGS_USER_UNAVAILABLE';
export const OBJECT_ASSOCIATION_SUCCEEDED = 'OBJECT_ASSOCIATION_SUCCEEDED';
export const OBJECT_DISASSOCIATION_SUCCEEDED = 'OBJECT_DISASSOCIATION_SUCCEEDED';
export const SALESFORCE_RATE_LIMIT = 'SALESFORCE_RATE_LIMIT';
export const SALESFORCE_RATE_LIMIT_UPDATE_QUEUED = 'SALESFORCE_RATE_LIMIT_UPDATE_QUEUED';
export const SALESFORCE_RATE_LIMIT_UPDATE_STARTED = 'SALESFORCE_RATE_LIMIT_UPDATE_STARTED';
export const SALESFORCE_RATE_LIMIT_UPDATE_FAILED = 'SALESFORCE_RATE_LIMIT_UPDATE_FAILED';
export const SALESFORCE_RATE_LIMIT_UPDATE_SUCCEEDED = 'SALESFORCE_RATE_LIMIT_UPDATE_SUCCEEDED';
export const SALESFORCE_RATE_LIMIT_UPDATE_SETTLED = 'SALESFORCE_RATE_LIMIT_UPDATE_SETTLED';
export const SALESFORCE_INCLUSION_LIST = 'SALESFORCE_INCLUSION_LIST';
export const SALESFORCE_INCLUSION_LIST_UPDATE_QUEUED = 'SALESFORCE_INCLUSION_LIST_UPDATE_QUEUED';
export const SALESFORCE_INCLUSION_LIST_UPDATE_STARTED = 'SALESFORCE_INCLUSION_LIST_UPDATE_STARTED';
export const SALESFORCE_INCLUSION_LIST_UPDATE_FAILED = 'SALESFORCE_INCLUSION_LIST_UPDATE_FAILED';
export const SALESFORCE_INCLUSION_LIST_UPDATE_SUCCEEDED = 'SALESFORCE_INCLUSION_LIST_UPDATE_SUCCEEDED';
export const SALESFORCE_INCLUSION_LIST_UPDATE_SETTLED = 'SALESFORCE_INCLUSION_LIST_UPDATE_SETTLED';
export const SALESFORCE_INCLUSION_LIST_DELETE_QUEUED = 'SALESFORCE_INCLUSION_LIST_DELETE_QUEUED';
export const SALESFORCE_INCLUSION_LIST_DELETE_STARTED = 'SALESFORCE_INCLUSION_LIST_DELETE_STARTED';
export const SALESFORCE_INCLUSION_LIST_DELETE_FAILED = 'SALESFORCE_INCLUSION_LIST_DELETE_FAILED';
export const SALESFORCE_INCLUSION_LIST_DELETE_SUCCEEDED = 'SALESFORCE_INCLUSION_LIST_DELETE_SUCCEEDED';
export const SALESFORCE_INCLUSION_LIST_DELETE_SETTLED = 'SALESFORCE_INCLUSION_LIST_DELETE_SETTLED';
export const SEGMENTS_DUPLICATE_FAILED = 'SEGMENTS_DUPLICATE_FAILED';
export const SEGMENTS_DUPLICATE_STARTED = 'SEGMENTS_DUPLICATE_STARTED';
export const SEGMENTS_DUPLICATE_SUCCEEDED = 'SEGMENTS_DUPLICATE_SUCCEEDED';
export const SEGMENTS_FETCH_FAILED = 'SEGMENTS_FETCH_FAILED';
export const SEGMENTS_FETCH_STARTED = 'SEGMENTS_FETCH_STARTED';
export const SEGMENTS_FETCH_SUCCEEDED = 'SEGMENTS_FETCH_SUCCEEDED';
export const SEGMENTS_MOVE_FAILED = 'SEGMENTS_MOVE_FAILED';
export const SEGMENTS_MOVE_STARTED = 'SEGMENTS_MOVE_STARTED';
export const SEGMENTS_MOVE_SUCCEEDED = 'SEGMENTS_MOVE_SUCCEEDED';
export const SEGMENTS_BULK_TEAM_UPDATE_FAILED = 'SEGMENTS_BULK_TEAM_UPDATE_FAILED';
export const SEGMENTS_BULK_TEAM_UPDATE_STARTED = 'SEGMENTS_BULK_TEAM_UPDATE_STARTED';
export const SEGMENTS_BULK_TEAM_UPDATE_SUCCEEDED = 'SEGMENTS_BULK_TEAM_UPDATE_SUCCEEDED';
export const SEGMENTS_DELETE_QUEUED = 'SEGMENTS_DELETE_QUEUED';
export const SEGMENTS_DELETE_STARTED = 'SEGMENTS_DELETE_STARTED';
export const SEGMENTS_DELETE_FAILED = 'SEGMENTS_DELETE_FAILED';
export const SEGMENTS_DELETE_SUCCEEDED = 'SEGMENTS_DELETE_SUCCEEDED';
export const SEGMENTS_DELETE_SETTLED = 'SEGMENTS_DELETE_SETTLED';
export const SEGMENTS_CONTACT_MATCH_CHECK_FETCH_QUEUED = 'SEGMENTS_CONTACT_MATCH_CHECK_FETCH_QUEUED';
export const SEGMENTS_CONTACT_MATCH_CHECK_FETCH_STARTED = 'SEGMENTS_CONTACT_MATCH_CHECK_FETCH_STARTED';
export const SEGMENTS_CONTACT_MATCH_CHECK_FETCH_FAILED = 'SEGMENTS_CONTACT_MATCH_CHECK_FETCH_FAILED';
export const SEGMENTS_CONTACT_MATCH_CHECK_FETCH_SUCCEEDED = 'SEGMENTS_CONTACT_MATCH_CHECK_FETCH_SUCCEEDED';
export const SEGMENTS_CONTACT_MATCH_CHECK_FETCH_SETTLED = 'SEGMENTS_CONTACT_MATCH_CHECK_FETCH_SETTLED';
export const SEGMENTS_OBJECT_MATCH_CHECK_FETCH_QUEUED = 'SEGMENTS_OBJECT_MATCH_CHECK_FETCH_QUEUED';
export const SEGMENTS_OBJECT_MATCH_CHECK_FETCH_STARTED = 'SEGMENTS_OBJECT_MATCH_CHECK_FETCH_STARTED';
export const SEGMENTS_OBJECT_MATCH_CHECK_FETCH_FAILED = 'SEGMENTS_OBJECT_MATCH_CHECK_FETCH_FAILED';
export const SEGMENTS_OBJECT_MATCH_CHECK_FETCH_SUCCEEDED = 'SEGMENTS_OBJECT_MATCH_CHECK_FETCH_SUCCEEDED';
export const SEGMENTS_OBJECT_MATCH_CHECK_FETCH_SETTLED = 'SEGMENTS_OBJECT_MATCH_CHECK_FETCH_SETTLED';
export const SEGMENTS_FOLDERS_FETCH_QUEUED = 'SEGMENTS_FOLDERS_FETCH_QUEUED';
export const SEGMENTS_FOLDERS_FETCH_STARTED = 'SEGMENTS_FOLDERS_FETCH_STARTED';
export const SEGMENTS_FOLDERS_FETCH_FAILED = 'SEGMENTS_FOLDERS_FETCH_FAILED';
export const SEGMENTS_FOLDERS_FETCH_SUCCEEDED = 'SEGMENTS_FOLDERS_FETCH_SUCCEEDED';
export const SEGMENTS_FOLDERS_FETCH_SETTLED = 'SEGMENTS_FOLDERS_FETCH_SETTLED';
export const SEGMENTS_FOLDERS_CREATE_QUEUED = 'SEGMENTS_FOLDERS_CREATE_QUEUED';
export const SEGMENTS_FOLDERS_CREATE_STARTED = 'SEGMENTS_FOLDERS_CREATE_STARTED';
export const SEGMENTS_FOLDERS_CREATE_FAILED = 'SEGMENTS_FOLDERS_CREATE_FAILED';
export const SEGMENTS_FOLDERS_CREATE_SUCCEEDED = 'SEGMENTS_FOLDERS_CREATE_SUCCEEDED';
export const SEGMENTS_FOLDERS_CREATE_SETTLED = 'SEGMENTS_FOLDERS_CREATE_SETTLED';
export const SEGMENTS_FOLDERS_DELETE_QUEUED = 'SEGMENTS_FOLDERS_DELETE_QUEUED';
export const SEGMENTS_FOLDERS_DELETE_STARTED = 'SEGMENTS_FOLDERS_DELETE_STARTED';
export const SEGMENTS_FOLDERS_DELETE_FAILED = 'SEGMENTS_FOLDERS_DELETE_FAILED';
export const SEGMENTS_FOLDERS_DELETE_SUCCEEDED = 'SEGMENTS_FOLDERS_DELETE_SUCCEEDED';
export const SEGMENTS_FOLDERS_DELETE_SETTLED = 'SEGMENTS_FOLDERS_DELETE_SETTLED';
export const SEGMENTS_FOLDERS_UPDATE_QUEUED = 'SEGMENTS_FOLDERS_UPDATE_QUEUED';
export const SEGMENTS_FOLDERS_UPDATE_STARTED = 'SEGMENTS_FOLDERS_UPDATE_STARTED';
export const SEGMENTS_FOLDERS_UPDATE_FAILED = 'SEGMENTS_FOLDERS_UPDATE_FAILED';
export const SEGMENTS_FOLDERS_UPDATE_SUCCEEDED = 'SEGMENTS_FOLDERS_UPDATE_SUCCEEDED';
export const SEGMENTS_FOLDERS_UPDATE_SETTLED = 'SEGMENTS_FOLDERS_UPDATE_SETTLED';
export const SEGMENTS_SEARCH_FETCH_QUEUED = 'SEGMENTS_SEARCH_FETCH_QUEUED';
export const SEGMENTS_SEARCH_FETCH_STARTED = 'SEGMENTS_SEARCH_FETCH_STARTED';
export const SEGMENTS_SEARCH_FETCH_FAILED = 'SEGMENTS_SEARCH_FETCH_FAILED';
export const SEGMENTS_SEARCH_FETCH_SUCCEEDED = 'SEGMENTS_SEARCH_FETCH_SUCCEEDED';
export const SEGMENTS_SEARCH_FETCH_SETTLED = 'SEGMENTS_SEARCH_FETCH_SETTLED';
export const TASKS_UPDATED = 'TASKS_UPDATED';
export const TASKS_FETCH_FAILED = 'TASKS_FETCH_FAILED';
export const TASKS_AND_ENGAGEMENTS_UPDATED = 'TASKS_AND_ENGAGEMENTS_UPDATED';
export const TICKET_AUTOMATION_CREATE_QUEUED = 'TICKET_AUTOMATION_CREATE_QUEUED';
export const TICKET_AUTOMATION_CREATE_STARTED = 'TICKET_AUTOMATION_CREATE_STARTED';
export const TICKET_AUTOMATION_CREATE_FAILED = 'TICKET_AUTOMATION_CREATE_FAILED';
export const TICKET_AUTOMATION_CREATE_SUCCEEDED = 'TICKET_AUTOMATION_CREATE_SUCCEEDED';
export const TICKET_AUTOMATION_CREATE_SETTLED = 'TICKET_AUTOMATION_CREATE_SETTLED';
export const TICKETS_CREATE_QUEUED = 'TICKETS_CREATE_QUEUED';
export const TICKETS_CREATE_STARTED = 'TICKETS_CREATE_STARTED';
export const TICKETS_CREATE_FAILED = 'TICKETS_CREATE_FAILED';
export const TICKETS_CREATE_SUCCEEDED = 'TICKETS_CREATE_SUCCEEDED';
export const TICKETS_CREATE_SETTLED = 'TICKETS_CREATE_SETTLED';
export const TICKETS_UPDATE_QUEUED = 'TICKETS_UPDATE_QUEUED';
export const TICKETS_UPDATE_STARTED = 'TICKETS_UPDATE_STARTED';
export const TICKETS_UPDATE_FAILED = 'TICKETS_UPDATE_FAILED';
export const TICKETS_UPDATE_SUCCEEDED = 'TICKETS_UPDATE_SUCCEEDED';
export const TICKETS_UPDATE_SETTLED = 'TICKETS_UPDATE_SETTLED';
export const TICKETS_REFRESH_QUEUED = 'TICKETS_REFRESH_QUEUED';
export const TICKETS_UPDATED = 'TICKETS_UPDATED';
export const TICKET_PIPELINES_FETCH_QUEUED = 'TICKET_PIPELINES_FETCH_QUEUED';
export const TICKET_PIPELINES_FETCH_STARTED = 'TICKET_PIPELINES_FETCH_STARTED';
export const TICKET_PIPELINES_FETCH_FAILED = 'TICKET_PIPELINES_FETCH_FAILED';
export const TICKET_PIPELINES_FETCH_SUCCEEDED = 'TICKET_PIPELINES_FETCH_SUCCEEDED';
export const TICKET_PIPELINES_FETCH_SETTLED = 'TICKET_PIPELINES_FETCH_SETTLED';
export const TICKET_PIPELINES_UPDATE_QUEUED = 'TICKET_PIPELINES_UPDATE_QUEUED';
export const TICKET_PIPELINES_UPDATE_STARTED = 'TICKET_PIPELINES_UPDATE_STARTED';
export const TICKET_PIPELINES_UPDATE_FAILED = 'TICKET_PIPELINES_UPDATE_FAILED';
export const TICKET_PIPELINES_UPDATE_SUCCEEDED = 'TICKET_PIPELINES_UPDATE_SUCCEEDED';
export const TICKET_PIPELINES_UPDATE_SETTLED = 'TICKET_PIPELINES_UPDATE_SETTLED';
export const TICKET_PIPELINES_CREATE_QUEUED = 'TICKET_PIPELINES_CREATE_QUEUED';
export const TICKET_PIPELINES_CREATE_STARTED = 'TICKET_PIPELINES_CREATE_STARTED';
export const TICKET_PIPELINES_CREATE_FAILED = 'TICKET_PIPELINES_CREATE_FAILED';
export const TICKET_PIPELINES_CREATE_SUCCEEDED = 'TICKET_PIPELINES_CREATE_SUCCEEDED';
export const TICKET_PIPELINES_CREATE_SETTLED = 'TICKET_PIPELINES_CREATE_SETTLED';
export const TICKET_PIPELINES_DELETE_QUEUED = 'TICKET_PIPELINES_DELETE_QUEUED';
export const TICKET_PIPELINES_DELETE_STARTED = 'TICKET_PIPELINES_DELETE_STARTED';
export const TICKET_PIPELINES_DELETE_FAILED = 'TICKET_PIPELINES_DELETE_FAILED';
export const TICKET_PIPELINES_DELETE_SUCCEEDED = 'TICKET_PIPELINES_DELETE_SUCCEEDED';
export const TICKET_PIPELINES_DELETE_SETTLED = 'TICKET_PIPELINES_DELETE_SETTLED';
export const TICKET_PIPELINES_REORDER_QUEUED = 'TICKET_PIPELINES_REORDER_QUEUED';
export const TICKET_PIPELINES_REORDER_STARTED = 'TICKET_PIPELINES_REORDER_STARTED';
export const TICKET_PIPELINES_REORDER_FAILED = 'TICKET_PIPELINES_REORDER_FAILED';
export const TICKET_PIPELINES_REORDER_SUCCEEDED = 'TICKET_PIPELINES_REORDER_SUCCEEDED';
export const TICKET_PIPELINES_REORDER_SETTLED = 'TICKET_PIPELINES_REORDER_SETTLED';
export const TICKET_STAGE_PROPERTIES_UPDATE_QUEUED = 'TICKET_STAGE_PROPERTIES_UPDATE_QUEUED';
export const TICKET_STAGE_PROPERTIES_UPDATE_STARTED = 'TICKET_STAGE_PROPERTIES_UPDATE_STARTED';
export const TICKET_STAGE_PROPERTIES_UPDATE_FAILED = 'TICKET_STAGE_PROPERTIES_UPDATE_FAILED';
export const TICKET_STAGE_PROPERTIES_UPDATE_SUCCEEDED = 'TICKET_STAGE_PROPERTIES_UPDATE_SUCCEEDED';
export const TICKET_STAGE_PROPERTIES_UPDATE_SETTLED = 'TICKET_STAGE_PROPERTIES_UPDATE_SETTLED';
export const TIMELINE_COMMENT_CREATED = 'TIMELINE_COMMENT_CREATED';
export const TIMELINE_COMMENT_EDITED = 'TIMELINE_COMMENT_EDITED';
export const TIMELINE_COMMENT_DELETED = 'TIMELINE_COMMENT_DELETED';
export const TIMELINE_COMMENT_FETCHED_MORE = 'TIMELINE_COMMENT_FETCHED_MORE';
export const TIMELINE_QUICK_FETCH_STORE_SUCCESS = 'TIMELINE_QUICK_FETCH_STORE_SUCCESS';
export const FAVORITE_LISTS_FETCH_QUEUED = 'FAVORITE_LISTS_FETCH_QUEUED';
export const FAVORITE_LISTS_FETCH_STARTED = 'FAVORITE_LISTS_FETCH_STARTED';
export const FAVORITE_LISTS_FETCH_FAILED = 'FAVORITE_LISTS_FETCH_FAILED';
export const FAVORITE_LISTS_FETCH_SUCCEEDED = 'FAVORITE_LISTS_FETCH_SUCCEEDED';
export const FAVORITE_LISTS_FETCH_SETTLED = 'FAVORITE_LISTS_FETCH_SETTLED';
export const FAVORITE_LISTS_UPDATE_QUEUED = 'FAVORITE_LISTS_UPDATE_QUEUED';
export const FAVORITE_LISTS_UPDATE_STARTED = 'FAVORITE_LISTS_UPDATE_STARTED';
export const FAVORITE_LISTS_UPDATE_FAILED = 'FAVORITE_LISTS_UPDATE_FAILED';
export const FAVORITE_LISTS_UPDATE_SUCCEEDED = 'FAVORITE_LISTS_UPDATE_SUCCEEDED';
export const FAVORITE_LISTS_UPDATE_SETTLED = 'FAVORITE_LISTS_UPDATE_SETTLED';
export const LIST_REFS_FETCH_QUEUED = 'LIST_REFS_FETCH_QUEUED';
export const LIST_REFS_FETCH_STARTED = 'LIST_REFS_FETCH_STARTED';
export const LIST_REFS_FETCH_FAILED = 'LIST_REFS_FETCH_FAILED';
export const LIST_REFS_FETCH_SUCCEEDED = 'LIST_REFS_FETCH_SUCCEEDED';
export const LIST_REFS_FETCH_SETTLED = 'LIST_REFS_FETCH_SETTLED';
export const OUTLOOK_PLUGIN_FETCH_QUEUED = 'OUTLOOK_PLUGIN_FETCH_QUEUED';
export const OUTLOOK_PLUGIN_FETCH_STARTED = 'OUTLOOK_PLUGIN_FETCH_STARTED';
export const OUTLOOK_PLUGIN_FETCH_FAILED = 'OUTLOOK_PLUGIN_FETCH_FAILED';
export const OUTLOOK_PLUGIN_FETCH_SUCCEEDED = 'OUTLOOK_PLUGIN_FETCH_SUCCEEDED';
export const OUTLOOK_PLUGIN_FETCH_SETTLED = 'OUTLOOK_PLUGIN_FETCH_SETTLED';
export const PROPERTIES_CREATE_QUEUED = 'PROPERTIES_CREATE_QUEUED';
export const PROPERTIES_CREATE_STARTED = 'PROPERTIES_CREATE_STARTED';
export const PROPERTIES_CREATE_FAILED = 'PROPERTIES_CREATE_FAILED';
export const PROPERTIES_CREATE_SUCCEEDED = 'PROPERTIES_CREATE_SUCCEEDED';
export const PROPERTIES_CREATE_SETTLED = 'PROPERTIES_CREATE_SETTLED';
export const PROPERTIES_FETCH_QUEUED = 'PROPERTIES_FETCH_QUEUED';
export const PROPERTIES_FETCH_STARTED = 'PROPERTIES_FETCH_STARTED';
export const PROPERTIES_FETCH_FAILED = 'PROPERTIES_FETCH_FAILED';
export const PROPERTIES_FETCH_SUCCEEDED = 'PROPERTIES_FETCH_SUCCEEDED';
export const PROPERTIES_FETCH_SETTLED = 'PROPERTIES_FETCH_SETTLED';
export const QUEUES_CACHE_UPDATED = 'QUEUES_CACHE_UPDATED';
export const QUEUES_FETCH_STARTED = 'QUEUES_FETCH_STARTED';
export const QUEUES_FETCH_SETTLED = 'QUEUES_FETCH_SETTLED';
export const QUEUES_MERGE_QUEUES = 'QUEUES_MERGE_QUEUES';
export const QUEUES_QUEUE_DELETED = 'QUEUES_QUEUE_DELETED';
export const QUEUES_SET_QUEUE = 'QUEUES_SET_QUEUE';
export const ADD_ENGAGEMENTS_TO_QUEUES = 'ADD_ENGAGEMENTS_TO_QUEUES';
export const ADD_USERS_TO_QUEUE = 'ADD_USERS_TO_QUEUE';
export const REMOVE_USERS_FROM_QUEUE = 'REMOVE_USERS_FROM_QUEUE';
export const TASK_PLAYLIST_GET_STORED_QUERY = 'TASK_PLAYLIST_GET_STORED_QUERY';
export const TASK_PLAYLIST_GET_TASKS = 'TASK_PLAYLIST_GET_TASKS';
export const TASK_PLAYLIST_GET_MORE_TASKS = 'TASK_PLAYLIST_GET_MORE_TASKS';
export const TASK_PLAYLIST_SET_SEARCH_RESULTS = 'TASK_PLAYLIST_SET_SEARCH_RESULTS';
export const TASK_PLAYLIST_ERROR = 'TASK_PLAYLIST_ERROR';
export const REVERT_SETTING = 'REVERT_SETTING';
export const SET_SETTING = 'SET_SETTING';
export const SET_SETTING_SUCCEEDED = 'SET_SETTING_SUCCEEDED';
export const DELETE_SETTING = 'DELETE_SETTING';
export const ACCOUNTS_FETCH_QUEUED = 'ACCOUNTS_FETCH_QUEUED';
export const ACCOUNTS_FETCH_STARTED = 'ACCOUNTS_FETCH_STARTED';
export const ACCOUNTS_FETCH_FAILED = 'ACCOUNTS_FETCH_FAILED';
export const ACCOUNTS_FETCH_SUCCEEDED = 'ACCOUNTS_FETCH_SUCCEEDED';
export const ACCOUNTS_FETCH_SETTLED = 'ACCOUNTS_FETCH_SETTLED';
export const SIDEKICK_PORTAL_FETCH_QUEUED = 'SIDEKICK_PORTAL_FETCH_QUEUED';
export const SIDEKICK_PORTAL_FETCH_STARTED = 'SIDEKICK_PORTAL_FETCH_STARTED';
export const SIDEKICK_PORTAL_FETCH_FAILED = 'SIDEKICK_PORTAL_FETCH_FAILED';
export const SIDEKICK_PORTAL_FETCH_SUCCEEDED = 'SIDEKICK_PORTAL_FETCH_SUCCEEDED';
export const SIDEKICK_PORTAL_FETCH_SETTLED = 'SIDEKICK_PORTAL_FETCH_SETTLED';
export const SIDEKICK_PORTAL_UPDATE_QUEUED = 'SIDEKICK_PORTAL_UPDATE_QUEUED';
export const SIDEKICK_PORTAL_UPDATE_STARTED = 'SIDEKICK_PORTAL_UPDATE_STARTED';
export const SIDEKICK_PORTAL_UPDATE_FAILED = 'SIDEKICK_PORTAL_UPDATE_FAILED';
export const SIDEKICK_PORTAL_UPDATE_SUCCEEDED = 'SIDEKICK_PORTAL_UPDATE_SUCCEEDED';
export const SIDEKICK_PORTAL_UPDATE_SETTLED = 'SIDEKICK_PORTAL_UPDATE_SETTLED';
export const USERSETTINGS_FETCH_FAILED = 'USERSETTINGS_FETCH_FAILED';
export const USER_SETTINGS_SAVE_FAILED = 'USER_SETTINGS_SAVE_FAILED';
export const USER_SETTINGS_SAVED = 'USER_SETTINGS_SAVED';
export const VIEW_DELETED = 'VIEW_DELETED';
export const VISITS_REFRESH_QUEUED = 'VISITS_REFRESH_QUEUED';
export const VISITS_REFRESH_STARTED = 'VISITS_REFRESH_STARTED';
export const VISITS_REFRESH_FAILED = 'VISITS_REFRESH_FAILED';
export const VISITS_REFRESH_SUCCEEDED = 'VISITS_REFRESH_SUCCEEDED';
export const VISITS_REFRESH_SETTLED = 'VISITS_REFRESH_SETTLED';
export const VISITS_UPDATED = 'VISITS_UPDATED';
export const ADD_ENGAGEMENT = 'ADD_ENGAGEMENT';
export const ADD_LINE_ITEMS = 'ADD_LINE_ITEMS';
export const ADD_SAMPLE_TIMELINE_ITEMS = 'ADD_SAMPLE_TIMELINE_ITEMS';
export const CHANGE_FILTER = 'CHANGE_FILTER';
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';
export const TIMELINE_CLEAR_FILTERS = 'TIMELINE_CLEAR_FILTERS';
export const TIMELINE_CHANGE_USER_AND_TEAM_FILTER = 'TIMELINE_CHANGE_USER_AND_TEAM_FILTER';
export const TIMELINE_CHANGE_FILTERS_IN_STORE = 'TIMELINE_CHANGE_FILTERS_IN_STORE';
export const CLEAR_FAVORITE_EVENT_OVERRIDE = 'CLEAR_FAVORITE_EVENT_OVERRIDE';
export const CLICK_TIMELINE_ITEM = 'CLICK_TIMELINE_ITEM';
export const DELETE_RECORDING = 'DELETE_RECORDING';
export const ADD_ENGAGEMENT_WITH_ATTACHMENT = 'ADD_ENGAGEMENT_WITH_ATTACHMENT';
export const DELETE_TIMELINE_ENGAGEMENT = 'DELETE_TIMELINE_ENGAGEMENT';
export const ENABLE_FAVORITE_EVENT = 'ENABLE_FAVORITE_EVENT';
export const FETCHING_INITIAL_TIMELINE_EVENTS_STARTED = 'FETCHING_INITIAL_TIMELINE_EVENTS_STARTED';
export const FETCH_NEXT_TIMELINE_EVENTS_STARTED = 'FETCH_NEXT_TIMELINE_EVENTS_STARTED';
export const FETCH_TIMELINE_ITEMS_ERROR = 'FETCH_TIMELINE_ITEMS_ERROR';
export const FETCH_TIMELINE_ITEMS_FOR_VFP_STARTED = 'FETCH_TIMELINE_ITEMS_FOR_VFP_STARTED';
export const FETCH_TIMELINE_ITEMS_FOR_VFP_SUCCESS = 'FETCH_TIMELINE_ITEMS_FOR_VFP_SUCCESS';
export const FETCH_TIMELINE_ITEMS_SUCCESS = 'FETCH_TIMELINE_ITEMS_SUCCESS';
export const HOVER_ITEM = 'HOVER_ITEM';
export const NAVIGATE = 'NAVIGATE';
export const POLLING_FETCH_TIMELINE_ITEMS_ERROR = 'POLLING_FETCH_TIMELINE_ITEMS_ERROR';
export const POLLING_FETCH_TIMELINE_ITEMS_STARTED = 'POLLING_FETCH_TIMELINE_ITEMS_STARTED';
export const POLLING_FETCH_TIMELINE_ITEMS_SUCCESS = 'POLLING_FETCH_TIMELINE_ITEMS_SUCCESS';
export const REMOVE_LINE_ITEM = 'REMOVE_LINE_ITEM';
export const RESET_LINE_ITEM_EDITOR_STORE = 'RESET_LINE_ITEM_EDITOR_STORE';
export const SET_FAVORITE_EVENT_OVERRIDE = 'SET_FAVORITE_EVENT_OVERRIDE';
export const UPDATE_LINE_ITEM = 'UPDATE_LINE_ITEM';
export const UPDATE_LINE_ITEM_PROPERTY = 'UPDATE_LINE_ITEM_PROPERTY';
export const REORDER_LINE_ITEM = 'REORDER_LINE_ITEM';
export const SET_LINE_ITEM_ORDER = 'SET_LINE_ITEM_ORDER';
export const STAGE_LINE_ITEMS_FOR_EDIT = 'STAGE_LINE_ITEMS_FOR_EDIT';
export const SUBSCRIPTION_RELATIONSHIP_UPDATE_SUCCEEDED = 'SUBSCRIPTION_RELATIONSHIP_UPDATE_SUCCEEDED';
export const UPDATED_SUBSCRIPTION_HISTORY_UPDATED = 'UPDATED_SUBSCRIPTION_HISTORY_UPDATED';
export const UPDATED_SUBSCRIPTION_STATUS_REFRESH_QUEUED = 'UPDATED_SUBSCRIPTION_STATUS_REFRESH_QUEUED';
export const UPDATED_SUBSCRIPTION_HISTORY_REFRESH_QUEUED = 'UPDATED_SUBSCRIPTION_HISTORY_REFRESH_QUEUED';
export const SUBSCRIPTION_RELATIONSHIP_UPDATES_SUCCEEDED = 'SUBSCRIPTION_RELATIONSHIP_UPDATES_SUCCEEDED';
export const SUBSCRIPTION_RELATIONSHIP_FETCH_SUCCEEDED = 'SUBSCRIPTION_RELATIONSHIP_FETCH_SUCCEEDED';
export const CLOSE_FULLSCREEN_COMMUNICATOR = 'CLOSE_FULLSCREEN_COMMUNICATOR';
export const FILE_ATTACHMENT_SELECTED = 'FILE_ATTACHMENT_SELECTED';
export const HIDE_COMMUNICATOR = 'HIDE_COMMUNICATOR';
export const IMAGE_ATTACHMENT_SELECTED = 'IMAGE_ATTACHMENT_SELECTED';
export const MAXIMIZE_COMMUNICATOR = 'MAXIMIZE_COMMUNICATOR';
export const MINIMIZE_COMMUNICATOR = 'MINIMIZE_COMMUNICATOR';
export const OPEN_FULLSCREEN_COMMUNICATOR = 'OPEN_FULLSCREEN_COMMUNICATOR';
export const SET_COMMUNICATOR_INITIAL_CONTENT = 'SET_COMMUNICATOR_INITIAL_CONTENT';
export const SET_COMMUNICATOR_TRIGGER_LOCATION = 'SET_COMMUNICATOR_TRIGGER_LOCATION';
export const SET_COMMUNICATOR_LAUNCH_CONTEXT = 'SET_COMMUNICATOR_LAUNCH_CONTEXT';
export const SHOW_COMMUNICATOR = 'SHOW_COMMUNICATOR';
export const SHOW_COMMUNICATOR_WITH_PERFORMANCE_TRACKING = 'SHOW_COMMUNICATOR_WITH_PERFORMANCE_TRACKING';
export const CLEAR_COMMUNICATOR_PERFORMANCE_TRACKING = 'CLEAR_COMMUNICATOR_PERFORMANCE_TRACKING';
export const CLOSE_COMMUNICATOR_ON_SUBJECT_CHANGE = 'CLOSE_COMMUNICATOR_ON_SUBJECT_CHANGE';
export const SET_CALLEE = 'SET_CALLEE';
export const CLEAR_CALLEE = 'CLEAR_CALLEE';
export const SET_CALL_PROVIDER = 'SET_CALL_PROVIDER';
export const SET_SHOULD_START_CALL_ON_LOAD = 'SET_SHOULD_START_CALL_ON_LOAD';
export const FAVORITE_VIEWS_USER_UPDATE_QUEUED = 'FAVORITE_VIEWS_USER_UPDATE_QUEUED';
export const FAVORITE_VIEWS_USER_UPDATE_STARTED = 'FAVORITE_VIEWS_USER_UPDATE_STARTED';
export const FAVORITE_VIEWS_USER_UPDATE_FAILED = 'FAVORITE_VIEWS_USER_UPDATE_FAILED';
export const FAVORITE_VIEWS_USER_UPDATE_SUCCEEDED = 'FAVORITE_VIEWS_USER_UPDATE_SUCCEEDED';
export const FAVORITE_VIEWS_USER_UPDATE_SETTLED = 'FAVORITE_VIEWS_USER_UPDATE_SETTLED';
export const LINE_ITEMS_REFRESH_QUEUED = 'LINE_ITEMS_REFRESH_QUEUED';
export const LINE_ITEMS_CREATE_QUEUED = 'LINE_ITEMS_CREATE_QUEUED';
export const LINE_ITEMS_CREATE_STARTED = 'LINE_ITEMS_CREATE_STARTED';
export const LINE_ITEMS_CREATE_FAILED = 'LINE_ITEMS_CREATE_FAILED';
export const LINE_ITEMS_CREATE_SUCCEEDED = 'LINE_ITEMS_CREATE_SUCCEEDED';
export const LINE_ITEMS_CREATE_SETTLED = 'LINE_ITEMS_CREATE_SETTLED';
export const LINE_ITEMS_FETCH_QUEUED = 'LINE_ITEMS_FETCH_QUEUED';
export const LINE_ITEMS_FETCH_STARTED = 'LINE_ITEMS_FETCH_STARTED';
export const LINE_ITEMS_FETCH_FAILED = 'LINE_ITEMS_FETCH_FAILED';
export const LINE_ITEMS_FETCH_SUCCEEDED = 'LINE_ITEMS_FETCH_SUCCEEDED';
export const LINE_ITEMS_FETCH_SETTLED = 'LINE_ITEMS_FETCH_SETTLED';
export const LINE_ITEMS_UPDATE_QUEUED = 'LINE_ITEMS_UPDATE_QUEUED';
export const LINE_ITEMS_UPDATE_STARTED = 'LINE_ITEMS_UPDATE_STARTED';
export const LINE_ITEMS_UPDATE_FAILED = 'LINE_ITEMS_UPDATE_FAILED';
export const LINE_ITEMS_UPDATE_SUCCEEDED = 'LINE_ITEMS_UPDATE_SUCCEEDED';
export const LINE_ITEMS_UPDATE_SETTLED = 'LINE_ITEMS_UPDATE_SETTLED';
export const LINE_ITEMS_DELETE_QUEUED = 'LINE_ITEMS_DELETE_QUEUED';
export const LINE_ITEMS_DELETE_STARTED = 'LINE_ITEMS_DELETE_STARTED';
export const LINE_ITEMS_DELETE_FAILED = 'LINE_ITEMS_DELETE_FAILED';
export const LINE_ITEMS_DELETE_SUCCEEDED = 'LINE_ITEMS_DELETE_SUCCEEDED';
export const LINE_ITEMS_DELETE_SETTLED = 'LINE_ITEMS_DELETE_SETTLED';
export const LINE_ITEMS_BULK_UPDATE_QUEUED = 'LINE_ITEMS_BULK_UPDATE_QUEUED';
export const LINE_ITEMS_BULK_UPDATE_STARTED = 'LINE_ITEMS_BULK_UPDATE_STARTED';
export const LINE_ITEMS_BULK_UPDATE_FAILED = 'LINE_ITEMS_BULK_UPDATE_FAILED';
export const LINE_ITEMS_BULK_UPDATE_SUCCEEDED = 'LINE_ITEMS_BULK_UPDATE_SUCCEEDED';
export const LINE_ITEMS_BULK_UPDATE_SETTLED = 'LINE_ITEMS_BULK_UPDATE_SETTLED';
export const PRODUCTS_SEARCH_FETCH_QUEUED = 'PRODUCTS_SEARCH_FETCH_QUEUED';
export const PRODUCTS_SEARCH_FETCH_STARTED = 'PRODUCTS_SEARCH_FETCH_STARTED';
export const PRODUCTS_SEARCH_FETCH_FAILED = 'PRODUCTS_SEARCH_FETCH_FAILED';
export const PRODUCTS_SEARCH_FETCH_SUCCEEDED = 'PRODUCTS_SEARCH_FETCH_SUCCEEDED';
export const PRODUCTS_SEARCH_FETCH_SETTLED = 'PRODUCTS_SEARCH_FETCH_SETTLED';
export const PRODUCTS_SEARCH_LOAD_MORE_FETCH_QUEUED = 'PRODUCTS_SEARCH_LOAD_MORE_FETCH_QUEUED';
export const PRODUCTS_SEARCH_LOAD_MORE_FETCH_STARTED = 'PRODUCTS_SEARCH_LOAD_MORE_FETCH_STARTED';
export const PRODUCTS_SEARCH_LOAD_MORE_FETCH_FAILED = 'PRODUCTS_SEARCH_LOAD_MORE_FETCH_FAILED';
export const PRODUCTS_SEARCH_LOAD_MORE_FETCH_SUCCEEDED = 'PRODUCTS_SEARCH_LOAD_MORE_FETCH_SUCCEEDED';
export const PRODUCTS_SEARCH_LOAD_MORE_FETCH_SETTLED = 'PRODUCTS_SEARCH_LOAD_MORE_FETCH_SETTLED';
export const QUOTES_CREATE_SUCCEEDED = 'QUOTES_CREATE_SUCCEEDED';
export const QUOTE_BY_ID_FETCH_SUCCEEDED = 'QUOTE_BY_ID_FETCH_SUCCEEDED';
export const QUOTES_RECALL_SUCCEEDED = 'QUOTES_RECALL_SUCCEEDED';
export const QUOTES_APPROVE_SUCCEEDED = 'QUOTES_APPROVE_SUCCEEDED';
export const QUOTES_REQUEST_CHANGES_SUCCEEDED = 'QUOTES_REQUEST_CHANGES_SUCCEEDED';
export const QUOTES_UPDATE_SUCCEEDED = 'QUOTES_UPDATE_SUCCEEDED';
export const CALL_TRANSCRIPTIONS_FETCH_QUEUED = 'CALL_TRANSCRIPTIONS_FETCH_QUEUED';
export const CALL_TRANSCRIPTIONS_FETCH_STARTED = 'CALL_TRANSCRIPTIONS_FETCH_STARTED';
export const CALL_TRANSCRIPTIONS_FETCH_FAILED = 'CALL_TRANSCRIPTIONS_FETCH_FAILED';
export const CALL_TRANSCRIPTIONS_FETCH_SUCCEEDED = 'CALL_TRANSCRIPTIONS_FETCH_SUCCEEDED';
export const CALL_TRANSCRIPTIONS_FETCH_SETTLED = 'CALL_TRANSCRIPTIONS_FETCH_SETTLED';
export const CONTENTS_FETCH_QUEUED = 'CONTENTS_FETCH_QUEUED';
export const CONTENTS_FETCH_STARTED = 'CONTENTS_FETCH_STARTED';
export const CONTENTS_FETCH_FAILED = 'CONTENTS_FETCH_FAILED';
export const CONTENTS_FETCH_SUCCEEDED = 'CONTENTS_FETCH_SUCCEEDED';
export const CONTENTS_FETCH_SETTLED = 'CONTENTS_FETCH_SETTLED';
export const OPEN_PLAYBOOK = 'OPEN_PLAYBOOK';
export const CLOSE_PLAYBOOK = 'CLOSE_PLAYBOOK';
export const UPDATE_OPTIN_STATUS = 'UPDATE_OPTIN_STATUS';
export const PIPELINE_STAGES_FETCH_SUCCEEDED = 'PIPELINE_STAGES_FETCH_SUCCEEDED';
export const AUTHENTICATED_INTEGRATIONS_PLATFORM_FILE_FETCH_SUCCEEDED = 'AUTHENTICATED_INTEGRATIONS_PLATFORM_FILE_FETCH_SUCCEEDED';
export const AUTHENTICATED_INTEGRATIONS_PLATFORM_FILE_FETCH_FAILED = 'AUTHENTICATED_INTEGRATIONS_PLATFORM_FILE_FETCH_FAILED';