import { perfSentryApi } from 'crm-fe-perf/perfSentryApi';

// @ts-expect-error This is legacy and is not typed
import { bender } from 'legacy-hubspot-bender-context';
const BaseContainerFactory = {
  create(name) {
    if (!name) {
      throw new Error('Container name must be supplied');
    }
    let _data = null;
    let container = {
      set(data) {
        _data = data;
        return _data;
      },
      get() {
        return _data;
      }
    };
    return {
      name,
      getContainer(loggingOptions) {
        if (loggingOptions) {
          let optionalMessage = '';
          let extra;
          if (typeof loggingOptions === 'object') {
            extra = loggingOptions.extra;
            optionalMessage = loggingOptions.message ? ` ${loggingOptions.message}` : '';
          }
          const deployable = bender.project;
          const loggingMessage = `[CONTAINERS] [${deployable}] ${this.name}.getContainer() accessed.${optionalMessage}`;
          const tags = {
            deployable
          };
          perfSentryApi.send(loggingMessage, extra, {
            tags
          });
        }
        return container;
      },
      setContainer(newContainer) {
        if (typeof newContainer.get !== 'function') {
          throw new Error('container must contain a get function');
        }
        if (typeof newContainer.set !== 'function') {
          throw new Error('container must contain a set function');
        }
        container = newContainer;
      }
    };
  }
};
export default BaseContainerFactory;