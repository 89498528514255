import { useSearchQuery } from '../../searchQuery/hooks/useSearchQuery';
import { useCrmSearchQuery } from '../../crmSearch/hooks/useCrmSearchQuery';
import { useMemo } from 'react';
export function useCachedTableRecordCount() {
  const query = useSearchQuery();
  const {
    data
  } = useCrmSearchQuery(query, {
    fetchPolicy: 'cache-only',
    nextFetchPolicy: 'cache-only'
  });
  const total = useMemo(() => {
    if (data && 'total' in data) {
      return data.total;
    }
    return 0;
  }, [data]);
  return total;
}