import { useMemo } from 'react';
import { useSelectedObjectTypeDef } from '../../../crmObjects/hooks/useSelectedObjectTypeDef';
import { usePipelines } from '../context/PipelinesContext';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { HIDDEN } from 'crm_data/pipelinePermissions/pipelinePermissionsConstants';
import getIn from 'transmute/getIn';
import update from 'transmute/update';
/**
 * @description Applies an exclusion filter to the query based on the pipelines that the user does not have access to.
 * FIXME: Should be part of the process of extracting the filters in the first place
 */
export const useDecorateQueryWithPipelinePermissions = originalQuery => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'pipelinePropertyName' does not exist on ... Remove this comment to see the full error message
  const {
    pipelinePropertyName,
    hasPipelines
  } = useSelectedObjectTypeDef();
  const pipelines = usePipelines();
  const hiddenPipelineFilters = useMemo(() => {
    if (!hasPipelines) {
      return [];
    }
    const hiddenPipelineIds = pipelines.filter(pipeline => getIn(['permission', 'accessLevel'], pipeline) === HIDDEN).map(({
      pipelineId
    }) => pipelineId);
    if (hiddenPipelineIds.length > 0) {
      return [{
        operator: 'NOT_IN',
        property: pipelinePropertyName,
        values: hiddenPipelineIds
      }];
    }
    return [];
  }, [hasPipelines, pipelinePropertyName, pipelines]);
  return useMemo(() => {
    if (!hasPipelines) {
      return originalQuery;
    }
    const nextQuery = update('filterGroups', filterGroups => {
      return filterGroups.map(({
        filters
      }) => {
        return {
          filters: [...filters, ...hiddenPipelineFilters]
        };
      });
    }, originalQuery);
    return nextQuery;
  }, [hasPipelines, hiddenPipelineFilters, originalQuery]);
};