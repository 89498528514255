'use es6';

import memoize from 'transmute/memoize';
import http from 'hub-http/clients/apiClient';
import get from 'transmute/get';
import { createCustomObjectGetter } from 'reference-resolvers/formatters/formatCustomObjects';
import { createFetchSearchPage, createFetchByIds } from './CrmSearchAPI';
const createSearchOptions = memoize(nameProperty => ({
  requestOptions: {
    includeAllValues: true
  },
  filterGroups: [{
    filters: []
  }],
  sorts: [{
    property: nameProperty,
    order: 'ASC'
  }]
}));
const crmObjectFormatterOptions = memoize((uniqueIdProperty, nameProperty) => ({
  getters: {
    getId: createCustomObjectGetter(uniqueIdProperty),
    getLabel: createCustomObjectGetter(nameProperty)
  }
}));
export const createFetchCrmObjectsByIds = options => (objectType, uniqueIdProperty, nameProperty) => {
  const fetchByIds = createFetchByIds(options)(objectType, {
    searchOptions: createSearchOptions(nameProperty),
    formatterOptions: crmObjectFormatterOptions(uniqueIdProperty, nameProperty)
  });
  return ids => fetchByIds({
    idPropName: uniqueIdProperty
  }, ids).then(get('results'));
};
export const fetchCrmObjectsByIds = createFetchCrmObjectsByIds({
  httpClient: http
});
export const createFetchCrmObjectsSearchPage = options => (objectType, uniqueIdProperty, nameProperty) => createFetchSearchPage(options)(objectType, {
  searchOptions: createSearchOptions(nameProperty),
  formatterOptions: crmObjectFormatterOptions(uniqueIdProperty, nameProperty)
});
export const fetchCrmObjectsSearchPage = createFetchCrmObjectsSearchPage({
  httpClient: http
});