import { useCallback, useEffect, useRef, useState } from 'react';
export function useDelayedState(initialValue) {
  const [state, setState] = useState(initialValue);
  const timeoutRef = useRef(null);
  const clearPending = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);
  const setStateWithDelay = useCallback((setStateAction, delay) => {
    clearPending();
    if (!delay) {
      setState(setStateAction);
      return;
    }
    timeoutRef.current = setTimeout(() => {
      setState(setStateAction);
      timeoutRef.current = null;
    }, delay);
  }, [clearPending]);
  useEffect(() => () => clearPending(), [clearPending]);
  return [state, setStateWithDelay];
}