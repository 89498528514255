import { useOnCrmMessageTopic } from 'crm-message-bus/useOnCrmMessageTopic';
import { useCallback } from 'react';
export default function CallbackRegistration({
  handler
}) {
  const onMessage = useCallback(data => {
    handler.callback(data);
  }, [handler]);
  useOnCrmMessageTopic(handler.topic, onMessage);
  return null;
}