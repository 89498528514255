'use es6';

import { formatOwnerLabel } from 'reference-resolvers/formatters/formatOwners';
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
import indexBy from 'transmute/indexBy';
import get from 'transmute/get';
import flattenN from 'transmute/flattenN';
import { List, fromJS } from 'immutable';
const formatUserId = user => {
  return String(user.remoteId);
};
const formatUserReference = (owner, remote) => new ReferenceRecord({
  id: formatUserId(remote),
  label: formatOwnerLabel(owner),
  referencedObject: fromJS(remote)
});
const formatUsers = response => {
  const remotes = flattenN(1, List(response.map(owner => List(owner.remoteList.map(remote => formatUserReference(owner, remote))))));
  return indexBy(get('id'), remotes);
};
export default formatUsers;