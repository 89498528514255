'use es6';

import PortalIdParser from 'PortalIdParser';
import { COMPANY, CONTACT, DEAL, QUOTE, TICKET, VISIT } from 'customer-data-objects/constants/ObjectTypes';
import { CALL_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
/** TODO: RELIABILITY_CLEANUP: https://git.hubteam.com/HubSpot/CRM-Issues/issues/5708
 *
 * crm-legacy-global-containersis deprecated.
 * Please use the application-specific container likely in /containers or pass in this data instead.
 */
// eslint-disable-next-line no-restricted-imports
import { parse, stringify } from 'hub-http/helpers/params';
import once from 'transmute/once';
import { ObjectTypesToIds, isObjectTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
import { getPathname, getSearch } from './windowLocationGetters';
import { normalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
const getRootPath = () => `/contacts/${PortalIdParser.get()}`;
const getRootPathFromWindow = () => {
  const hubspotBasePathnameRegex = /^\/[\w-]+\/\d+/;
  const parts = getPathname().match(hubspotBasePathnameRegex);
  if (parts) {
    //manual override for crm-events iframe
    if (parts[0].includes('crm-events')) {
      return parts[0].replace('crm-events', 'contacts');
    }
    //manual override for crm-record-extension-pane iframe
    if (parts[0].includes('crm-record-extension-pane-ui')) {
      return parts[0].replace('crm-record-extension-pane-ui', 'contacts');
    }
    return parts[0];
  }
  return null;
};
const baseUrl = (includeBaseUrl = true) => {
  if (!includeBaseUrl) {
    return '';
  }
  return getRootPathFromWindow() || getRootPath();
};
const baseSettingsUrl = once(() => `/sales-products-settings/${PortalIdParser.get()}`);
const links = {
  toQueryString(query) {
    if (query) {
      return `?${stringify(query)}`;
    }
    return '';
  },
  createNavigateLink(url) {
    if (url.indexOf(baseUrl()) === 0) {
      return url.substr(baseUrl().length);
    }
    return url;
  },
  createSettingsNavigateLink(url) {
    if (url.indexOf(baseSettingsUrl()) === 0) {
      return url.substr(baseSettingsUrl().length);
    }
    return url;
  },
  // Note - you may want to use NavigationStore instead
  getQueryParams() {
    return parse(getSearch().substring(1));
  },
  getRootPath,
  getRootPathFromWindow,
  fromObjectTypeAndId(objectType, subjectId, includeBaseUrl) {
    switch (objectType) {
      case CALL_TYPE_ID:
        return links.call(subjectId, includeBaseUrl);
      case COMPANY:
        return links.company(subjectId, includeBaseUrl);
      case CONTACT:
        return links.contact(subjectId, null, includeBaseUrl);
      case DEAL:
        return links.deal(subjectId, includeBaseUrl);
      case QUOTE:
        return links.quote(subjectId, includeBaseUrl);
      case TICKET:
        return links.ticket(subjectId, includeBaseUrl);
      case VISIT:
        return null;
      default:
        return `${baseUrl(includeBaseUrl)}/record/${normalizeTypeId(objectType)}/${subjectId}/`;
    }
  },
  visualForceFromObjectTypeAndId(objectType, subjectId) {
    switch (objectType) {
      case COMPANY:
        return `/companies/${PortalIdParser.get()}/company/${subjectId}/`;
      case CONTACT:
        return `/contacts/${PortalIdParser.get()}/contact/${subjectId}/`;
      case DEAL:
        return `/contacts/${PortalIdParser.get()}/deal/${subjectId}/`;
      case TICKET:
        return `/contacts/${PortalIdParser.get()}/ticket/${subjectId}/`;
      default:
        return null;
    }
  },
  timelineEngagement(objectType, subjectId, engagementId, includeBaseUrl = true) {
    const query = {
      engagement: engagementId
    };
    const url = links.fromObjectTypeAndId(objectType, subjectId, includeBaseUrl);
    return `${url}${links.toQueryString(query)}`;
  },
  restoreIndexState(objectType, includeBaseUrl) {
    const objectTypeId = ObjectTypesToIds[objectType] || objectType;
    if (!objectTypeId) {
      return null;
    }
    return `${baseUrl(includeBaseUrl)}/objects/${encodeURIComponent(objectTypeId)}/restore`;
  },
  indexFromObjectType(objectType, includeBaseUrl) {
    if (isObjectTypeId(objectType)) {
      return links.indexFromObjectTypeId({
        objectTypeId: objectType,
        includeBaseUrl
      });
    }
    switch (objectType) {
      case COMPANY:
        return `${baseUrl(includeBaseUrl)}/companies/`;
      case CONTACT:
        return `${baseUrl(includeBaseUrl)}/contacts/`;
      case DEAL:
        return `${baseUrl(includeBaseUrl)}/deals/`;
      case TICKET:
        return `${baseUrl(includeBaseUrl)}/tickets/`;
      case VISIT:
        return `${baseUrl(includeBaseUrl)}/visits/`;
      default:
        return null;
    }
  },
  indexFromObjectTypeId({
    objectTypeId,
    viewId,
    pageType,
    includeBaseUrl = false
  }) {
    const base = baseUrl(includeBaseUrl);
    const objectTypeIdPath = encodeURIComponent(objectTypeId);
    const viewIdPath = viewId ? `/views/${encodeURIComponent(viewId)}` : '';
    const pageTypePath = pageType ? `/${encodeURIComponent(pageType)}` : '';
    return `${base}/objects/${objectTypeIdPath}${viewIdPath}${pageTypePath}`;
  },
  call(callId) {
    return `/calls/${PortalIdParser.get()}/review/${callId}`;
  },
  contact(contactId, query, includeBaseUrl) {
    const queryString = this.toQueryString(query);
    return `${baseUrl(includeBaseUrl)}/contact/${contactId}/${queryString}`;
  },
  contactEmail(email) {
    return `${baseUrl()}/contact/email/${encodeURIComponent(email)}`;
  },
  company(companyId, includeBaseUrl) {
    return `${baseUrl(includeBaseUrl)}/company/${companyId}/`;
  },
  deal(dealId, includeBaseUrl) {
    return `${baseUrl(includeBaseUrl)}/deal/${dealId}/`;
  },
  duplicatesCenter(objectType) {
    switch (objectType) {
      case COMPANY:
        return `/duplicates/${PortalIdParser.get()}/companies`;
      case CONTACT:
        return `/duplicates/${PortalIdParser.get()}/contacts`;
      default:
        return null;
    }
  },
  quote(quoteId, includeBaseUrl) {
    return `${baseUrl(includeBaseUrl)}/quote/${quoteId}/`;
  },
  ticket(ticketId, includeBaseUrl) {
    return `${baseUrl(includeBaseUrl)}/ticket/${ticketId}/`;
  },
  ticketPipeline(pipelineId, addAutomation) {
    return this.settings('tickets', {
      subsection: pipelineId,
      postfix: addAutomation && 'automation'
    });
  },
  dealPipeline(pipelineId, addAutomation) {
    return this.settings('deals', {
      subsection: pipelineId,
      postfix: addAutomation && 'automation'
    });
  },
  interactionType(objectType, objectId, interactionType, includeBaseUrl = true, query = {}) {
    const url = links.fromObjectTypeAndId(objectType, objectId, includeBaseUrl);
    if (interactionType) {
      query.interaction = interactionType;
    } else {
      // overide interactionType from props
      query.interaction = null;
    }
    return `${url}${links.toQueryString(query)}`;
  },
  callSettings() {
    const portalId = PortalIdParser.get();
    return `/integrations/${portalId}/talk`;
  },
  contacts(viewId, options = {}) {
    const {
      query
    } = options;
    let url = `${baseUrl()}/contacts/`;
    if (viewId) {
      url += `view/${viewId}/`;
    }
    if (query != null) {
      url += links.toQueryString(query);
    }
    return url;
  },
  portalSpecificObjects(objectTypeId, options = {}) {
    const {
      query
    } = options;
    let url = `${baseUrl()}/objects/${objectTypeId.replace('-', '/')}`;
    if (query != null) {
      url += links.toQueryString(query);
    }
    return url;
  },
  allProperties(objectType, subjectId) {
    const objectTypeId = normalizeTypeId(objectType);
    return `${getRootPath()}/record/${objectTypeId}/${subjectId}/properties`;
  },
  listMembership(contactId) {
    return `${baseUrl()}/contact/${contactId}/list-memberships`;
  },
  marketingEmail(campaignId) {
    return `/email/${PortalIdParser.get()}/campaign/${campaignId}`;
  },
  workflows(contactId) {
    return `${baseUrl()}/contact/${contactId}/workflow-memberships`;
  },
  workflow(workflowId) {
    return `/workflows/${PortalIdParser.get()}/flow/${workflowId}`;
  },
  flow(flowId) {
    return `/workflows/${PortalIdParser.get()}/platform/flow/${flowId}`;
  },
  workflowAutomationPlatform(workflowId, subjectId) {
    return `/workflows/${PortalIdParser.get()}/platform/flow/${workflowId}/history?objectId=${subjectId}`;
  },
  workflowsPage() {
    return `/workflows/${PortalIdParser.get()}/`;
  },
  cta(guid) {
    return `/cta/${PortalIdParser.get()}/${guid}`;
  },
  allPropertiesHistory(objectType, subjectId) {
    const objectTypeId = normalizeTypeId(objectType);
    return `${getRootPath()}/record/${objectTypeId}/${subjectId}/history`;
  },
  propertySettings(objectType, property = '') {
    const objectTypeId = ObjectTypesToIds[objectType] || objectType;
    return links.propertySettingsRedesign(objectTypeId, property);
  },
  propertySettingsRedesign(objectTypeId, property = '') {
    const propertyString = property ? `&property=${encodeURIComponent(property)}&action=edit` : '';
    return `/property-settings/${PortalIdParser.get()}/properties?type=${encodeURIComponent(objectTypeId)}${propertyString}`;
  },
  recyclingBin(objectType) {
    return `/recycling-bin/${PortalIdParser.get()}/restore/${objectType.toLowerCase()}`;
  },
  companies(viewId, options = {}) {
    const {
      query
    } = options;
    let url = `${baseUrl()}/companies/`;
    if (viewId) {
      url += `view/${viewId}/`;
    }
    if (query != null) {
      url += links.toQueryString(query);
    }
    return url;
  },
  dealsBase() {
    return `${baseUrl()}/deals/`;
  },
  deals(viewId, options = {}) {
    const {
      query,
      includeBaseUrl
    } = options;
    let url = `${baseUrl(includeBaseUrl)}/deals/list/`;
    if (viewId) {
      url += `view/${viewId}/`;
    }
    if (query != null) {
      url += links.toQueryString(query);
    }
    return url;
  },
  dealLineItems(dealId) {
    return `/line-items/${PortalIdParser.get()}/deal/${dealId}`;
  },
  dealsBoard(viewId, options = {}) {
    const {
      query,
      includeBaseUrl
    } = options;
    let url = `${baseUrl(includeBaseUrl)}/deals/board/`;
    if (viewId) {
      url += `view/${viewId}/`;
    }
    if (query != null) {
      url += links.toQueryString(query);
    }
    return url;
  },
  tickets(viewId, options = {}) {
    const {
      query,
      includeBaseUrl
    } = options;
    let url = `${baseUrl(includeBaseUrl)}/tickets/list/`;
    if (viewId) {
      url += `view/${viewId}/`;
    }
    if (query != null) {
      url += links.toQueryString(query);
    }
    return url;
  },
  subscriptions() {
    return `/settings/${PortalIdParser.get()}/marketing/email/subscriptions`;
  },
  ticketsBase() {
    return `${baseUrl()}/tickets/`;
  },
  ticketsBoard(viewId, options = {}) {
    const {
      query,
      includeBaseUrl
    } = options;
    let url = `${baseUrl(includeBaseUrl)}/tickets/board/`;
    if (viewId) {
      url += `view/${viewId}/`;
    }
    if (query != null) {
      url += links.toQueryString(query);
    }
    return url;
  },
  team(extra = '') {
    return `/settings/${PortalIdParser.get()}/users${extra}`;
  },
  templates() {
    return `/templates/${PortalIdParser.get()}`;
  },
  templateReports() {
    return `/templates/${PortalIdParser.get()}/reports`;
  },
  reportsDasboard() {
    return `/reports-dashboard/${PortalIdParser.get()}/`;
  },
  listReport(listId) {
    return `/report-builder/${PortalIdParser.get()}/contacts-list/${listId}`;
  },
  sales_content(deckId) {
    let url = `/presentations/${PortalIdParser.get()}/`;
    if (deckId) {
      url += deckId;
    }
    return url;
  },
  specificSequence(sequenceId) {
    return `/sequences/${PortalIdParser.get()}/sequence/${sequenceId}`;
  },
  sequences() {
    return `/sequences/${PortalIdParser.get()}`;
  },
  signature() {
    return `/settings/${PortalIdParser.get()}/signature`;
  },
  meetings() {
    return `/meetings/${PortalIdParser.get()}`;
  },
  messages() {
    return `/live-messages/${PortalIdParser.get()}`;
  },
  viewChatinMessages(threadId) {
    return `/live-messages/${PortalIdParser.get()}/chat/${threadId}`;
  },
  viewConversationInMessages(threadId) {
    return `/live-messages/${PortalIdParser.get()}/chat/${threadId}`;
  },
  prospects(viewId) {
    let url = `${baseUrl()}/prospects/`;
    if (viewId) {
      url += `view/${viewId}/`;
    }
    return url;
  },
  visits(viewId) {
    let url = `${baseUrl()}/visits/`;
    if (viewId) {
      url += `view/${viewId}/`;
    }
    return url;
  },
  prospectsWithNewsEvents(viewId) {
    let url = `${baseUrl()}/news/`;
    if (viewId) {
      url += `view/${viewId}/`;
    }
    return url;
  },
  dashboard(ownerIds) {
    let url = `${baseUrl()}/dashboard/`;
    if (ownerIds) {
      if (typeof ownerIds === 'string') {
        url += `${ownerIds}/`;
      } else {
        url += `?ownerId=${ownerIds.join('&ownerId=')}`;
      }
    }
    return url;
  },
  import() {
    return `/import/${PortalIdParser.get()}`;
  },
  accelerator(source) {
    if (source) {
      return `https://www.hubspot.com/products/sales/sales-teams?pql-source=${source}`;
    }
    return 'https://www.hubspot.com/products/sales/sales-teams';
  },
  sidekickStream() {
    return `/sales-stream/${PortalIdParser.get()}`;
  },
  settings(section = '', options = {}) {
    const {
      subsection,
      query,
      postfix
    } = options;
    let url = baseSettingsUrl();
    if (section) {
      url += `/${encodeURIComponent(section)}`;
      if (subsection) {
        url += `/${encodeURIComponent(subsection)}`;
      }
    } else {
      url += '/';
    }
    if (postfix) {
      url += `/${encodeURIComponent(postfix)}`;
    }
    if (query) {
      url += this.toQueryString(query);
    }
    return url;
  },
  inboxConnectSettingsRedirect(provider) {
    return links.settings('email', {
      query: {
        inboxconnected: true,
        provider
      }
    });
  },
  inboxConnectContactRedirect(contactId) {
    return `${baseUrl()}/contact/${contactId}/?interaction=email`;
  },
  productSettings() {
    return `/settings/${PortalIdParser.get()}/products`;
  },
  notificationSettings() {
    return `/sales-products-settings/${PortalIdParser.get()}/notifications`;
  },
  accountAndBilling() {
    return `/account-and-billing/${PortalIdParser.get()}/`;
  },
  salesBillingManage() {
    return links.accountAndBilling();
  },
  partnerCapacityManager() {
    return `/partner-capacity/${PortalIdParser.get()}/domains/`;
  },
  salesAnalytics() {
    return `/reports-list/${PortalIdParser.get()}/templates/sales`;
  },
  sequencesCrmCannotSend() {
    return 'https://knowledge.hubspot.com/articles/kcs_article/sequences/why-cant-i-send-a-sequence-from-the-crm';
  },
  gmailHubSpotSalesOnboarding(email) {
    const ref = 'INAPP_CRM';
    return `https://mail.google.com/?hubspot_sales_onboarding=${ref}&author=${email}`;
  },
  outlookHubSpotSalesOnboarding(email) {
    const ref = 'INAPP_CRM';
    return `https://outlook.office365.com/?hubspot_sales_onboarding=${ref}&author=${email}`;
  },
  outlookAddinDownload() {
    return 'https://app.getsidekick.com/outlook/download';
  },
  salesNavigator(objectType, subjectId) {
    if (objectType === CONTACT) {
      return `${baseUrl()}/contact/${subjectId}/sales-navigator`;
    }
    return `${baseUrl()}/company/${subjectId}/sales-navigator`;
  },
  file(fileId) {
    return `/file-preview/${PortalIdParser.get()}/file/${fileId}/`;
  },
  productsLibrary() {
    return `/settings/${PortalIdParser.get()}/sales/products`;
  },
  productsAndAddons(source) {
    return `/browse/${PortalIdParser.get()}?upgradeSource=${source}`;
  },
  lists(id, includeBaseUrl = true) {
    let url = `${includeBaseUrl ? baseUrl() : ''}/lists`;
    if (id) {
      url += `/${id}`;
    }
    return url;
  },
  listDetails(id) {
    return `${baseUrl()}/lists?id=${id}`;
  },
  listCleanup() {
    return `${baseUrl()}/lists/cleanup`;
  },
  cesFeedback(surveyId, submissionId) {
    return `/feedback/${PortalIdParser.get()}/support/${surveyId}/submissions/all/${submissionId}`;
  },
  csatFeedback(surveyId, submissionId) {
    return `/feedback/${PortalIdParser.get()}/satisfaction/${surveyId}/submissions/all/${submissionId}`;
  },
  customFeedback(surveyId, submissionId) {
    return `/feedback/${PortalIdParser.get()}/custom/${surveyId}/submissions/all/${submissionId}`;
  },
  mainFeedback() {
    return `/feedback/${PortalIdParser.get()}`;
  },
  knowledgeFeedback(articleId) {
    if (articleId) {
      return `/knowledge/${PortalIdParser.get()}/insights/article/${articleId}/performance`;
    }
    return `/knowledge/${PortalIdParser.get()}`;
  },
  npsFeedback(surveyId, submissionId) {
    return `/feedback/${PortalIdParser.get()}/loyalty/${surveyId}/submissions/all/${submissionId}`;
  },
  oldLists(listId) {
    let url = `/lists/${PortalIdParser.get()}`;
    if (listId) {
      url += `/list/${listId}`;
    }
    return url;
  },
  listPerformance(listId) {
    return `/list-reports/${PortalIdParser.get()}/${listId}`;
  },
  stripeIntegration() {
    return `/ecosystem/${PortalIdParser.get()}/marketplace/apps/_detail/stripe`;
  },
  analyticsTools() {
    return `/analytics/${PortalIdParser.get()}/tools`;
  },
  formEditor(formId, redirectUrl) {
    return `/forms/${PortalIdParser.get()}/editor/${formId}/edit/form/?redirectUrl=${redirectUrl}&isExternalEditor=ticket`;
  },
  supportFormSettings() {
    return `/sales-products-settings/${PortalIdParser.get()}/supportForm`;
  },
  forecast() {
    return `/forecasting/${PortalIdParser.get()}`;
  }
};
export default links;