import { CRM_OBJECT_PIPELINE_SETTINGS } from '../../appSettings/crmObjectPipelines/types';
import { TEST_FAS } from '../../appSettings/AppSettings';
import { CRM_TAGS_SETTINGS } from '../../appSettings/crmTags/types';
import { gate } from 'hub-http/gates';
export const getCustomObjectFallbackAppSettings = () => ({
  [CRM_OBJECT_PIPELINE_SETTINGS]: {
    metadata: {
      requiredFields: {
        value: {
          accessScopes: ['crm-required-fields'],
          writeScopes: ['crm-set-default-properties']
        },
        source: CRM_OBJECT_PIPELINE_SETTINGS,
        isPortalSpecific: true
      },
      cardCustomization: {
        isPortalSpecific: true,
        source: CRM_OBJECT_PIPELINE_SETTINGS,
        value: {
          accessScopes: ['crm-board-card-customization-access'],
          writeScopes: ['crm-board-card-customization-write'],
          perPipelineAccessScopes: ['crm-board-card-customization-per-pipeline']
        }
      },
      pipelinePermissions: {
        isPortalSpecific: true,
        source: CRM_OBJECT_PIPELINE_SETTINGS,
        value: {
          accessScopes: ['pipeline-level-permissions-access'],
          writeScopes: ['pipeline-level-permissions-write']
        }
      },
      governance: {
        isPortalSpecific: true,
        source: CRM_OBJECT_PIPELINE_SETTINGS,
        value: {
          accessGates: ['CRM:Pipelines:Governance:CustomObjects'],
          pqlKey: 'crm-pipelines-governance-settings',
          writeScopes: ['crm-pipelines-governance-write'],
          accessScopes: ['crm-pipelines-governance-read']
        }
      },
      automation: {
        isPortalSpecific: true,
        source: CRM_OBJECT_PIPELINE_SETTINGS,
        value: {
          accessGates: [gate('CRM:Pipelines:CobjectAutomation')],
          accessScopes: ['custom-object-access'],
          isNewFeature: true
        }
      }
    }
  },
  [CRM_TAGS_SETTINGS]: {
    metadata: {
      accessGates: {
        source: CRM_TAGS_SETTINGS,
        value: [],
        isPortalSpecific: true
      },
      accessScopes: {
        value: ['custom-object-access', 'crm-pipelines-object-label-access'],
        source: CRM_TAGS_SETTINGS,
        isPortalSpecific: true
      },
      propertyName: {
        value: 'hs_tag_ids',
        source: CRM_TAGS_SETTINGS,
        isPortalSpecific: true
      },
      enabled: {
        value: true,
        source: CRM_TAGS_SETTINGS,
        isPortalSpecific: true
      }
    }
  },
  [TEST_FAS]: {
    metadata: {
      testFeature: {
        source: '',
        isPortalSpecific: false,
        value: {
          accessGates: ['Test:FAS:Gate'],
          accessScopes: ['Test:Cobject:Scope:One', 'Test:Cobject:Scope:Two'],
          writeScopes: ['Test:Write:Cobject:Scope']
        }
      }
    }
  }
});