import { PROPERTIES_FETCH_SUCCEEDED } from '../../properties/actions/propertiesActionTypes';
import { produce } from 'immer';
import { mutableSetIn } from '../../objectUtils/mutableSetIn';
const mergePermissionsIntoDraft = ({
  objectTypeId,
  properties,
  draft
}) => properties.filter(({
  permission
}) => permission).forEach(({
  permission,
  property
}) => mutableSetIn([objectTypeId, property.name], permission, draft));
const initialState = {};
export const fieldLevelPermissionsReducer = produce((draft, action) => {
  switch (action.type) {
    case PROPERTIES_FETCH_SUCCEEDED:
      {
        const {
          properties,
          objectTypeId
        } = action.payload;
        mergePermissionsIntoDraft({
          objectTypeId,
          properties,
          draft
        });
        return draft;
      }
    default:
      {
        return draft;
      }
  }
}, initialState);