import { addIndividualObjectMutation } from './actions/addIndividualObjectMutation';
import { deleteQuery } from './actions/deleteQuery';
import { addObjectToQuery } from './actions/addObjectToQuery';
import { editQuery } from './actions/editQuery';
export const OBJECT_DELETED = 'OBJECT_DELETED';
export const QUERY_DELETED = 'QUERY_DELETED';
/**
 * This should not be used other than in tests, or to reset the store
 *
 * This is explicitly a getter, rather than a static object. If it were a static object, and if there were
 * multiple store instances, they would have the same references for individualObjects and queryMutations leading to
 * states being written to multiple stores at once
 *
 * By putting this in a getter, we esnure new objects are being made for each call
 */
export const getLocalMutationsStoreDefaultState = () => ({
  individualObjects: {},
  queryMutations: {}
});
export const createLocalMutationsStore = () => {
  const subscribers = new Set();
  let state = getLocalMutationsStoreDefaultState();
  return {
    notify: () => {
      subscribers.forEach(sub => sub());
    },
    getSnapshot() {
      return state;
    },
    subscribe(subscriber) {
      subscribers.add(subscriber);
      return () => {
        subscribers.delete(subscriber);
      };
    },
    addMultipleObjectMutations(input) {
      state = input.objectIds.reduce((stateObj, currentObjectId) => {
        return addIndividualObjectMutation({
          currentState: stateObj,
          input: {
            objectTypeId: input.objectTypeId,
            objectId: currentObjectId,
            mutation: input.mutation
          }
        });
      }, state);
      this.notify();
    },
    addIndividualObjectMutation(input) {
      state = addIndividualObjectMutation({
        currentState: state,
        input
      });
      this.notify();
    },
    deleteQuery({
      queryHash
    }) {
      state = deleteQuery({
        queryHash,
        currentState: state
      });
      this.notify();
    },
    addObjectToQuery({
      queryHash,
      objectId
    }) {
      state = addObjectToQuery({
        input: {
          queryHash,
          objectId
        },
        currentState: state
      });
      this.notify();
    },
    addQueryMutation({
      queryHash,
      mutation
    }) {
      if (mutation === QUERY_DELETED) {
        state = deleteQuery({
          queryHash,
          currentState: state
        });
      } else {
        state = editQuery({
          input: {
            queryHash,
            edits: mutation
          },
          currentState: state
        });
      }
      this.notify();
    }
  };
};