'use es6';

import http from 'hub-http/clients/apiClient';
import { Map as ImmutableMap } from 'immutable';
import has from 'transmute/has';
import omit from 'transmute/omit';
import { OBJECT_LIST_TYPE_ID } from 'reference-resolvers/constants/ObjectTypeIds';
import formatInboundDbLists from 'reference-resolvers/formatters/formatInboundDbLists';
import formatInboundDbListSegments from 'reference-resolvers/formatters/formatInboundDbListSegments';
export const createGetAllInboundDbLists = ({
  httpClient
}) => objectType => () => httpClient.get(`/inbounddb-lists/v1/lists/type/${objectType}`).then(formatInboundDbLists);
export const getAllInboundDbLists = createGetAllInboundDbLists({
  httpClient: http
});
export const createGetInboundDbListsByIds = ({
  httpClient
}) => ids => httpClient.post('/inbounddb-lists/v1/lists/getBatch', {
  data: ids
}).then(formatInboundDbLists);
export const getInboundDbListsByIds = createGetInboundDbListsByIds({
  httpClient: http
});
const emptyQuery = ImmutableMap();
const defaultSearchOptions = {
  count: 20,
  offset: 0,
  sorts: [{
    property: 'hs_list_name',
    order: 'ASC'
  }]
};
const isUnique = (value, index, array) => array.indexOf(value) === index;
const transformToCrmSearchQuery = searchQuery => {
  // The destructured properties and searchQueryPropertiesToProcess should match
  const {
    properties,
    listFilterProperties
  } = searchQuery;
  const searchQueryPropertiesToProcess = ['properties', 'listFilterProperties'];
  const requiredProperties = ['hs_list_id', 'hs_list_name'];
  const crmSearchQuery = Object.assign({}, defaultSearchOptions, omit(searchQueryPropertiesToProcess, searchQuery), {
    // Allow some flexibility
    objectTypeId: OBJECT_LIST_TYPE_ID,
    requestOptions: {
      properties: Array.isArray(properties) ? requiredProperties.concat(properties).filter(isUnique) : requiredProperties
    }
  });
  const allowableFilterKeys = ['objectTypeId', 'processingTypes', 'includeDeletes'];
  const hasAllowableFilters = typeof listFilterProperties === 'object' && allowableFilterKeys.some(filterKey => has(filterKey, listFilterProperties));
  const filters = [{
    property: 'hs_is_public',
    operator: 'EQ',
    value: true
  }];
  if (hasAllowableFilters) {
    const {
      objectTypeId,
      processingTypes,
      includeDeletes = true
    } = listFilterProperties;
    if (objectTypeId) {
      filters.push({
        property: 'hs_object_type_id',
        operator: 'EQ',
        value: objectTypeId
      });
    }
    if (Array.isArray(processingTypes)) {
      filters.push({
        property: 'hs_processing_type',
        operator: 'IN',
        values: processingTypes
      });
    }
    if (!includeDeletes) {
      filters.push({
        property: 'hs_deleted_at',
        operator: 'NOT_HAS_PROPERTY'
      });
    }
  }
  crmSearchQuery.filterGroups = [{
    filters
  }];
  return crmSearchQuery;
};
export const createGetInboundDbListsSearchPage = ({
  httpClient
}) => (query = emptyQuery) => httpClient.post('/crm-search/search', {
  data: transformToCrmSearchQuery(query.toJS())
}).then(response => formatInboundDbListSegments(Object.assign({}, response, {
  count: query.get('count')
})));
export const getInboundDbListsSearchPage = createGetInboundDbListsSearchPage({
  httpClient: http
});