import isEmptyObject from '../utils/isEmptyObject';

/**
 * Converts to the format the BE typically uses
 *
 * @param propertyUpdates An object whose keys are property names and values are the value of that property
 * @returns An array of objects with name and value properties created from each entry in the input object
 */
export const mapPropertyUpdatesToBackendType = propertyUpdates => Object.entries(propertyUpdates).reduce((acc, [name, value]) => {
  acc.push({
    name,
    value
  });
  return acc;
}, []);

/**
 * Converts to the format the FE typically uses
 *
 *  @param propertyValues An array of objects with name and value keys for each property
 *  @returns An object whose keys are property names with values that correspond to the value of that property name
 */

export const mapPropertyValuesFromBackendType = propertyValues => Object.fromEntries(propertyValues.map(({
  name,
  value
}) => [name, value]));

/**
 *
 * @returns An object whose keys are property names and values are the new value of that property
 */

export const mapPendingEditsToObjectUpdates = pendingEdits => Object.entries(pendingEdits).reduce((objectUpdates, [objectId, propertyUpdates]) => {
  if (!propertyUpdates || isEmptyObject(propertyUpdates)) {
    return objectUpdates;
  }
  return [/* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
  ...objectUpdates, {
    objectId,
    propertyValues: mapPropertyUpdatesToBackendType(propertyUpdates)
  }];
}, []);