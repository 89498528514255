import { useCachedViews } from './useCachedViews';
import { useGenerateView } from './useGenerateView';

// Use this hook instead of useCachedViews()[viewId] when
// - you want the view as it was originally fetched from the backend
//   - as opposed to useCurrentView or useViewById that includes draft changes
// - but also have column rules applied
//   - such as hiding property columns that are for a property that does not exist
//   - or an association column that is not valid
export const useGeneratedCachedViewById = viewId => {
  const cachedViews = useCachedViews();
  // FIXME: view can be null
  const cachedView = cachedViews[viewId];
  return useGenerateView(cachedView);
};