import { EQUAL, NOT_KNOWN, IN, IN_RANGE_ROLLING, TIME_UNIT_TO_DATE } from 'customer-data-filters/converters/contactSearch/FilterContactSearchOperatorTypes';
import * as OperatorTypes from 'customer-data-filters/converters/contactSearch/FilterContactSearchOperatorTypes';
import get from 'transmute/get';
export const checkIfBoolOperatorSupported = operatorType => operatorType === EQUAL || operatorType === NOT_KNOWN;
export const checkIfDatetimeOperatorSupported = (operatorType, rollingOffset) => {
  if (rollingOffset) {
    return false;
  }
  return operatorType === IN_RANGE_ROLLING || operatorType === TIME_UNIT_TO_DATE;
};
export const checkIfEnumOperatorSupported = operatorType => operatorType === IN || operatorType === NOT_KNOWN;
const defaultGetDisplayTextFn = (symbol, filter) => `${symbol} ${get('value', filter)}`;
const twoValueGetDisplayTextFn = (symbol, filter) => {
  const value1 = get('value', filter);
  const value2 = get('highValue', filter);
  return value2 > value1 ? `${value1} ${symbol} ${value2}` : `${value2} ${symbol} ${value1}`;
};
const betweenOption = {
  getDisplayText: twoValueGetDisplayTextFn,
  langToken: 'indexPage.quickFilters.number.operatorHelp.between',
  symbol: '―',
  value: OperatorTypes.IN_RANGE
};
const equalOption = {
  getDisplayText: defaultGetDisplayTextFn,
  langToken: 'indexPage.quickFilters.number.operatorHelp.equal',
  symbol: '=',
  value: OperatorTypes.EQUAL
};
const notEqualOption = {
  getDisplayText: defaultGetDisplayTextFn,
  langToken: 'indexPage.quickFilters.number.operatorHelp.notEqual',
  symbol: '≠',
  value: OperatorTypes.NOT_EQUAL
};
const lessOption = {
  getDisplayText: defaultGetDisplayTextFn,
  langToken: 'indexPage.quickFilters.number.operatorHelp.less',
  symbol: '<',
  value: OperatorTypes.LESS
};
const lessOrEqualOption = {
  getDisplayText: defaultGetDisplayTextFn,
  langToken: 'indexPage.quickFilters.number.operatorHelp.lessOrEqual',
  symbol: '≤',
  value: OperatorTypes.LESS_OR_EQUAL
};
const greaterOption = {
  getDisplayText: defaultGetDisplayTextFn,
  langToken: 'indexPage.quickFilters.number.operatorHelp.greater',
  symbol: '>',
  value: OperatorTypes.GREATER
};
const greaterOrEqualOption = {
  getDisplayText: defaultGetDisplayTextFn,
  langToken: 'indexPage.quickFilters.number.operatorHelp.greaterOrEqual',
  symbol: '≥',
  value: OperatorTypes.GREATER_OR_EQUAL
};
const operatorNameToOption = {
  [OperatorTypes.IN_RANGE]: betweenOption,
  [OperatorTypes.EQUAL]: equalOption,
  [OperatorTypes.NOT_EQUAL]: notEqualOption,
  [OperatorTypes.LESS]: lessOption,
  [OperatorTypes.LESS_OR_EQUAL]: lessOrEqualOption,
  [OperatorTypes.GREATER]: greaterOption,
  [OperatorTypes.GREATER_OR_EQUAL]: greaterOrEqualOption
};
export const checkIfNumberDurationOperatorSupported = operatorType => Object.prototype.hasOwnProperty.call(operatorNameToOption, operatorType);
export const checkIfStringOperatorSupported = operatorType => operatorType === EQUAL;