import memoizeOne from 'react-utils/memoizeOne';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { usePaginationState } from '../../pagination/hooks/usePaginationState';
import { useSearchTerm } from '../../search/hooks/useSearchTerm';
import { useQueryFilterGroups } from './useQueryFilterGroups';
import { useQueryProperties } from './useQueryProperties';
import { useQuerySorts } from './useQuerySorts';
import { useDecorateQueryWithPipelinePermissions } from '../../pipelines/hooks/useDecorateQueryWithPipelinePermissions';
import { BOARD } from '../../../views/constants/PageType';
import { useCurrentPageType } from '../../../views/hooks/useCurrentPageType';
export const generateSearchQuery = memoizeOne((objectTypeId, sorts, properties, searchTerm, page, pageSize, filterGroups) => ({
  objectTypeId,
  sorts,
  requestOptions: {
    properties
  },
  query: searchTerm,
  offset: page * pageSize,
  count: pageSize,
  filterGroups
}));
export const useRawSearchQuery = () => {
  const objectTypeId = useSelectedObjectTypeId();
  const sorts = useQuerySorts();
  const properties = useQueryProperties();
  const searchTerm = useSearchTerm();
  const {
    page: tablePage,
    pageSize: tablePageSize,
    boardPageSize
  } = usePaginationState();
  const filterGroups = useQueryFilterGroups();
  const pageType = useCurrentPageType();
  const page = pageType === BOARD ? 0 : tablePage;
  const pageSize = pageType === BOARD ? boardPageSize : tablePageSize;
  return generateSearchQuery(objectTypeId, sorts, properties, searchTerm, page, pageSize, filterGroups);
};
export const useSearchQuery = () => {
  const query = useRawSearchQuery();
  return useDecorateQueryWithPipelinePermissions(query);
};