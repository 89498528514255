import { useMemo } from 'react';
import { makeApolloLikeResultType } from '../typeUtils';
import { useCrmObjectsSearch } from './useCrmObjectsSearch';
import { useGenerateCrmObjectsSearchQueryObject } from './useGenerateCrmObjectsSearchQueryObject';
import { QUERY_DELETED, applyMutationsToObject, useGetLocalMutationsForQuery, useGetLocalMutationsForSingleObject } from '../localMutations';
import { generateLocalMutationsQueryHash } from '../localMutations/utils/generateLocalMutationsQueryHash';
import Raven from 'raven-js';
import { useDecryptedValues } from './useDecryptedValues';
export const BACKGROUND_REFRESH_INTERVAL_TIME = 2 * 60 * 1000; // 2 minutes
const defaultOptions = {
  pollInterval: BACKGROUND_REFRESH_INTERVAL_TIME,
  skip: false
};

/**
 * Do you want to use the crm `crmObjectsSearch` query? Do you want sane business logic applied like:
 * - pipeline permissions
 * - sorting nuances of displayOrder, labels, per-object-type sort defaults, default objectId tie breakers
 * - requesting additional properties that you'll generally want in all use cases such as contact firstname + lastname
 * - error type parsing (FLP Errors, Query String too long error, Unknown Error)
 *   - We also have error state components for these too! Check out CrmSearchErrorEmptyState
 * - LocalMutationsProvider integration (aka - customer-data-bulk-actions-container can automatically mutate the results of this hook on submit)
 * - Highly Sensitive Property integration (connects to any existing decrypted values cache so we can overlay those on your query result)
 *
 * If the answer to these is yes - the this is the hook for you!
 *
 * ```tsx
 *      const MyComponent = () => {
 *        const { data, loading, error } = useSensibleCrmObjectsSearchQuery({
 *          queryObject: {
 *            objectTypeId: '0-1',
 *            filterGroups: [], // use this if you want to filter down the results
 *            properties: ['email'], // use this array if you know you want to get back certain property values
 *          },
 *        });
 *
 *        if (error) {
 *          return <CrmSearchErrorEmptyState error={error}/>
 *        }
 *
 *        if (loading) {
 *          return 'loading...';
 *        }
 *
 *        return <>
 *          {data?.processedQueryObject && <MyBulkActionsOrMyExport queryObject={processedQueryObject}/>}
 *          {data?.results.map((object) => {
 *            return (
 *              <>
 *                <h1>
 *                  {object.id} : {object.properties['email']?.value}
 *                </h1>
 *                {Object.entries(object.properties).map(([propertyName, property]) => {
 *                  return (
 *                    <span key={propertyName}>
 *                      {propertyName}: {property.value}
 *                    </span>
 *                  );
 *                })}
 *              </>
 *            );
 *          })}
 *        </>
 *      };
 * ```
 *
 * Warnings:
 *  1. If you are using this hook to show results in a table/board/gallery/list, while also integrating with other systems
 *  that fetch results from crm search on their own, it is important to consider the following:
 *    - This hook will take in "queryObject", and do "processing" to that queryObject before sending it to the server
 *    - This processing step can/will change your query, including but not limited to adding filters and sorts
 *    - This can be dangerous if you pass your raw "queryObject" to other systems, such as bulk actions or export
 *    - Its possible/likely that the results you see in your table/board/gallery/list will be different than what you bulk edit or export
 *    - To mitigate this, you can use "processedQueryObject" that is returned from this hook. It will be the exact query that this hook uses to get data
 *    - By using "processedQueryObject" you guarentee that the results you see in your table/board/gallery/list will be the same as what you bulk edit or export
 *
 * @param {Object} [options] - Configuration options for the search.
 * @param {string} [options.searchQueryVariablesLocalCacheKey] - A unique key used for caching the processed search query variables locally in IndexedDB.
 * @param {string} [options.pollInterval] - Interval in milliseconds at which the search query should be re-executed.
 * @param {string} [options.skip] - A boolean flag to skip the execution of the query, useful for conditional fetching based on certain criteria.
 */
export const useSensibleCrmObjectsSearchQuery = ({
  queryObject,
  options = defaultOptions
}) => {
  const objectTypeId = queryObject.objectTypeId;
  const {
    data: processedQueryObject,
    loading: processedQueryObjectLoading,
    error: processedQueryObjectError
  } = useGenerateCrmObjectsSearchQueryObject({
    inputQuery: queryObject
  });
  const crmSearchQueryObject = useMemo(() => processedQueryObject || {
    properties: [],
    objectTypeId,
    filterGroups: []
  }, [objectTypeId, processedQueryObject]);
  const localMutationsHash = useMemo(() => processedQueryObject && generateLocalMutationsQueryHash({
    queryObject: processedQueryObject
  }), [processedQueryObject]);
  const {
    data,
    loading,
    error,
    networkStatus
  } = useCrmObjectsSearch({
    crmSearchQueryObject,
    experimental_useNoCacheOnLoad: options.experimental_useNoCacheOnLoad,
    searchQueryVariablesLocalCacheKey: options.searchQueryVariablesLocalCacheKey,
    pollInterval: options.pollInterval,
    skip: options.skip || !processedQueryObject,
    skipPollAttempt: options.skipPollAttempt
  });
  const getLocalMutationsForQuery = useGetLocalMutationsForQuery();
  const getLocalMutationsForSingleObject = useGetLocalMutationsForSingleObject({
    objectTypeId
  });
  const decryptedValues = useDecryptedValues({
    objectTypeId
  });
  const dataWithLocalMutationsApplied = useMemo(() => {
    if (!localMutationsHash) {
      return undefined;
    }
    const queryMutation = getLocalMutationsForQuery({
      queryHash: localMutationsHash
    });
    if (queryMutation === QUERY_DELETED) {
      return {
        results: [],
        total: 0,
        offset: 0,
        processedQueryObject
      };
    }

    //TODO clean up this type
    const results = [];
    data === null || data === void 0 || data.results.forEach(object => {
      const {
        objectId
      } = object;
      const withDecryptedValues = applyMutationsToObject({
        object,
        objectMutations: decryptedValues[objectId]
      }); // Safe to assert because decrypted values will never be a "DELETE" mutation

      const localMutations = getLocalMutationsForSingleObject({
        objectId
      });
      const withLocalMutations = applyMutationsToObject({
        object: withDecryptedValues,
        queryMutations: queryMutation,
        objectMutations: localMutations
      });
      if (withLocalMutations) {
        const objectWithMutations = Object.assign({}, object, {
          properties: withLocalMutations.properties
        });
        results.push(objectWithMutations);
      }
    });
    if (queryMutation !== null && queryMutation !== void 0 && queryMutation.newObjects) {
      const errorMessage = 'There were new objects via localmutations that were ignored. This can be implemented, but has been deprioritized.';
      const ignoredMutationError = new Error(errorMessage);
      Raven.captureException(ignoredMutationError);
      console.error(errorMessage);

      //psuedo code of what this could look like
      //map over all queryMutation.newObjects, which are object ids
      //for each object id, query the object with a graphql query
      //  - this will then populate the apollo cache with this object
      //  - then we can read the object from the cache
      //  - then we can "add" this object to the beginning of this results array
    }
    return Object.assign({}, data, {
      results,
      processedQueryObject
    });
  }, [data, decryptedValues, getLocalMutationsForQuery, getLocalMutationsForSingleObject, localMutationsHash, processedQueryObject]);
  return useMemo(() => makeApolloLikeResultType({
    data: dataWithLocalMutationsApplied,
    loading: loading || processedQueryObjectLoading,
    error: error || processedQueryObjectError,
    networkStatus
  }), [dataWithLocalMutationsApplied, error, loading, processedQueryObjectError, processedQueryObjectLoading, networkStatus]);
};