'use es6';

import http from 'hub-http/clients/apiClient';
import formatTeams from 'reference-resolvers/formatters/formatTeams';
import formatHierarchicalTeams from 'reference-resolvers/formatters/formatHierarchicalTeams';
export const createGetAllTeams = ({
  httpClient
}) => () => httpClient.get('app-users/v1/teams').then(formatTeams);
export const getAllTeams = createGetAllTeams({
  httpClient: http
});
export const createGetAllHierarchicalTeams = ({
  httpClient
}) => () => httpClient.get('app-users/v1/teams?includeHierarchy=true').then(formatHierarchicalTeams);
export const getAllHierarchicalTeams = createGetAllHierarchicalTeams({
  httpClient: http
});
export const createGetHierarchicalTeamsByUser = ({
  httpClient
}) => () => httpClient.get('app-users/v1/teams/user/all?includeHierarchy=true').then(formatHierarchicalTeams);
export const getHierarchicalTeamsByUser = createGetHierarchicalTeamsByUser({
  httpClient: http
});