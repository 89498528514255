import { useGetHasGate } from '../../userInfo/hooks/useGetHasGate';
// Whether the object supports the feature
export const useObjectTypeSupportsFeature = feature => {
  const hasGate = useGetHasGate();

  // No feature entry means no support
  if (!feature) {
    return false;
  }

  // No scopes means no support
  if (!feature.accessScopes || !feature.accessScopes.length) {
    return false;
  }

  // If there are gates, they must be satisfied. Otherwise, the feature is
  // considered generally available
  if (feature.accessGates && feature.accessGates.length && !feature.accessGates.every(accessGate => hasGate(accessGate))) {
    return false;
  }
  return true;
};