import { CONVERSATION_SESSION_TYPE_ID, SALES_TASK_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { associationDefinitionToId } from './associationDefinitionToId';
export const TASK_TEMPLATE_TYPE_ID = '0-43';

/*************************
 * Object type blocklists
 *
 * Objects covered here should stay in sync with those in the below file
 * https://git.hubteam.com/HubSpot/customer-data-filters/blob/master/customer-data-filters-ui/static/js/utils/associatedRecordsUtils.ts
 ************************/
const OBJECT_TYPE_BLOCKLIST = new Set([TASK_TEMPLATE_TYPE_ID, CONVERSATION_SESSION_TYPE_ID, SALES_TASK_TYPE_ID]);
const isObjectTypeSupported = objectTypeId => {
  return !OBJECT_TYPE_BLOCKLIST.has(objectTypeId);
};
const isFromSupported = associationDefinition => {
  return isObjectTypeSupported(associationDefinition.fromObjectTypeId);
};
const isToSupported = associationDefinition => {
  return isObjectTypeSupported(associationDefinition.toObjectTypeId);
};

/*************************
 * Specific association between two objects blocklists
 *
 * Associations covered here should stay in sync with those in the below file
 * https://git.hubteam.com/HubSpot/customer-data-filters/blob/master/customer-data-filters-ui/static/js/utils/associatedRecordsUtils.ts
 ************************/
const CONTACT_TO_CONVERSATION_ASSOCIATION_TYPE_ID = '0-62';
const TICKET_TO_CONVERSATION_ASSOCIATION_TYPE_ID = '0-32';
const DEAL_TO_CONVERSATION_ASSOCIATION_TYPE_ID = '0-58';
const COMPANY_TO_CONVERSATION_ASSOCIATION_TYPE_ID = '0-60';
const PAYMENT_TO_DISCOUNT_ASSOCIATION_TYPE_ID = '0-428';
const ASSOCIATION_ID_BLOCKLIST = new Set([CONTACT_TO_CONVERSATION_ASSOCIATION_TYPE_ID, TICKET_TO_CONVERSATION_ASSOCIATION_TYPE_ID, DEAL_TO_CONVERSATION_ASSOCIATION_TYPE_ID, COMPANY_TO_CONVERSATION_ASSOCIATION_TYPE_ID, PAYMENT_TO_DISCOUNT_ASSOCIATION_TYPE_ID]);
const isSpecificAssociationBlocklisted = associationDefinition => {
  const associationTypeId = associationDefinitionToId(associationDefinition);
  return ASSOCIATION_ID_BLOCKLIST.has(associationTypeId);
};
export const isSupportedAssociation = (associationDefinition, ___) => {
  // NOTE: If in the future we need to gate a change here, it might look something like this
  // if (
  //   typeof args === 'object' &&
  //   args.hasGate('MyGate')
  // ) {...}

  return !isSpecificAssociationBlocklisted(associationDefinition) && isFromSupported(associationDefinition) && isToSupported(associationDefinition);
};