'use es6';

import http from 'hub-http/clients/apiClient';
import formatCallDispositions from 'reference-resolvers/formatters/formatCallDispositions';
export const createGetAllDispositionTypes = ({
  httpClient
}) => () => httpClient.get('twilio/v1/custom-dispositions', {
  query: {
    includeDeleted: true
  }
}).then(formatCallDispositions);
export const getAllDispositionTypes = createGetAllDispositionTypes({
  httpClient: http
});