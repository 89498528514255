import http from 'hub-http/clients/apiClient';
import { DELETE, UPDATE } from '../constants/UnifiedBatchMutationTypes';
import { generatePath } from 'react-router-dom';
export const BASE_URL = 'inbounddb-objects/v2/batch-mutation/dynamic-search';
export const SINGULAR_URL = 'inbounddb-objects/v1/crm-objects';
const hasFilters = filterGroups => Boolean(filterGroups.find(group => group.filters.length > 0));
export const deleteCrmObjectsByIds = ({
  objectTypeId,
  objectIds
}) => http.post(BASE_URL, {
  data: {
    type: DELETE,
    objectTypeId,
    objectIdList: objectIds
  },
  headers: {
    'x-properties-source': 'CRM_UI'
  }
});
export const deleteCrmObjectsByFilters = ({
  objectTypeId,
  filterGroups,
  query
}) => {
  return http.post(BASE_URL, {
    data: {
      applyToAll: !query && !hasFilters(filterGroups),
      type: DELETE,
      objectTypeId,
      crmSearchRequestProxyEgg: {
        filterGroups,
        objectTypeId,
        query
      },
      headers: {
        'x-properties-source': 'CRM_UI'
      }
    }
  });
};
export const writeMixedCrmObjectPropertiesByObjectIds = ({
  objectTypeId,
  objectUpdates
}) => http.put(generatePath(`${SINGULAR_URL}/:objectTypeId`, {
  objectTypeId
}), {
  data: objectUpdates,
  headers: {
    'x-properties-source': 'CRM_UI'
  }
});
export const writeCrmObjectPropertiesByIds = ({
  objectTypeId,
  objectIds,
  propertyValues
}) => {
  if (objectIds.length === 1) {
    return http.put(generatePath(`${SINGULAR_URL}/:objectTypeId`, {
      objectTypeId
    }), {
      data: [{
        objectId: objectIds[0],
        propertyValues
      }],
      headers: {
        'x-properties-source': 'CRM_UI'
      }
    });
  }
  return http.post(BASE_URL, {
    data: {
      type: UPDATE,
      objectTypeId,
      properties: propertyValues,
      objectIdList: objectIds
    },
    headers: {
      'x-properties-source': 'CRM_UI'
    }
  });
};
export const writeCrmObjectPropertiesByFilters = ({
  objectTypeId,
  propertyValues,
  filterGroups,
  query
}) => {
  return http.post(BASE_URL, {
    data: {
      applyToAll: !query && !hasFilters(filterGroups),
      type: UPDATE,
      objectTypeId,
      properties: propertyValues,
      crmSearchRequestProxyEgg: {
        filterGroups,
        objectTypeId,
        query
      },
      headers: {
        'x-properties-source': 'CRM_UI'
      }
    }
  });
};