import { useCallback } from 'react';
import { useLocalMutationsState } from './useLocalMutationsState';
export const useGetLocalMutationsForSingleObject = ({
  objectTypeId
}) => {
  const selector = useCallback(state => {
    return state.individualObjects[objectTypeId];
  }, [objectTypeId]);
  const objectTypeMutations = useLocalMutationsState(selector);
  return useCallback(({
    objectId
  }) => {
    return objectTypeMutations ? objectTypeMutations[objectId] : undefined;
  }, [objectTypeMutations]);
};