import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["resolverOptions"];
import * as CacheKeys from '../constants/CacheKeys';
// @ts-expect-error untyped module
import { createCustomKey } from 'reference-resolvers/lib/CustomKey';
import { createFetchImportNamesByObjectType, fetchImportNamesByObjectType
// @ts-expect-error untyped module
} from '../api/ImportsAPI';
// @ts-expect-error untyped module
import createSimpleCachedReferenceResolver from '../lib/createSimpleCachedReferenceResolver';
export const createInboundDbImportReferenceResolver = (objectTypeId, options = {}) => {
  const {
    resolverOptions
  } = options;
  return createSimpleCachedReferenceResolver(Object.assign({
    cacheKey: createCustomKey(CacheKeys.INBOUND_DB_IMPORT, objectTypeId),
    createFetchData: opts => {
      const getFn = createFetchImportNamesByObjectType(opts);
      return () => getFn(objectTypeId);
    },
    fetchData: () => fetchImportNamesByObjectType(objectTypeId)
  }, resolverOptions));
};
export default ((objectTypeId, options) => {
  const opts = _objectWithoutPropertiesLoose(options, _excluded);
  return createInboundDbImportReferenceResolver(objectTypeId, opts);
});