import { runWorker } from './runWorker';
import { trackDataChanges } from './trackDataChanges';
export const calculateMetrics = async ({
  fetchPromise,
  cacheLoadPromise,
  namespace,
  entityName,
  cacheKey,
  segment,
  resolvePortalAndUserId,
  metricsConfig: {
    enablePatchDiffing = false,
    normalizeForPatchDiffing = input => input,
    additionalProcessing
  } = {}
}) => {
  const [fetchResult, cacheLoadResult] = await Promise.allSettled([fetchPromise, cacheLoadPromise]);

  // Don't track anything if the network failed
  if (fetchResult.status === 'rejected') {
    return;
  }
  const {
    totalSizeBytes,
    patchSizeBytes,
    patchSizePct,
    extraProcessingResult
  } = await runWorker({
    namespace,
    entityName,
    cacheKey,
    current: fetchResult.value,
    previous: cacheLoadResult.status === 'fulfilled' ? cacheLoadResult.value.data : null,
    enablePatchDiffing,
    normalizeForPatchDiffing,
    extraProcessing: additionalProcessing === null || additionalProcessing === void 0 ? void 0 : additionalProcessing.process
  });

  // If additional processing has been requested, pass the output
  // of the processing to the handler.
  if (additionalProcessing && extraProcessingResult) {
    try {
      additionalProcessing.handleResult({
        segment,
        extraProcessingResult
      });
    } catch (e) {
      // Do nothing. If the requested additional processing errors out, we'd still like
      // to track the standardaized metrics below.
    }
  }

  // This is the standardized "did this data change since the prior request" metric
  // It relies on indexeddb reads/writes, so it is async.
  return trackDataChanges({
    isCacheMiss: cacheLoadResult.status === 'rejected',
    namespace,
    entityName,
    cacheKey,
    segment,
    totalSizeBytes,
    patchSizeBytes,
    patchSizePct,
    resolvePortalAndUserId
  });
};