import setIn from 'transmute/setIn';
import { SYNC_SELECTION_STATE, CACHE_INDIVIDUAL_OBJECTS_PERMISSIONS, CACHE_CRM_SEARCH_QUERY_PERMISSIONS, CACHE_CRM_SEARCH_QUERY_TOTAL_NUMBER_OF_OBJECTS, SYNC_WARMUP_CACHE_FOR, SET_LOADING, SET_ERROR, OPEN_MODAL, CLOSE_MODAL } from '../constants/actionTypes';
import { SELECTION_METHOD_BY_IDS } from '../constants/selectionMethods';
/**
 * This function is meant to help with two things:
 * 1. typescripts inept handling of optional properties and union types when destructuring an object that is typed as a union type
 *      `selectionCrmSearchQuery` is optional when the selection method is by id since you dont need the query if you're only doing individual selection
 *      however typescript needs some help trying to coerce the restructured object to BulkActionsSelectionStateType with the `if` statement you see below - functionally it does nothing, but it helps with type inference.
 * 2. Keeping the order of the properties within the object consistent to allow for the use of JSON.stringify when comparing equality
 *
 */
export const createBulkActionsSelectionStateObject = selection => {
  if (selection.selectionMethod === SELECTION_METHOD_BY_IDS) {
    return {
      selectionMethod: selection.selectionMethod,
      selectionIds: selection.selectionIds.map(String),
      selectionCrmSearchQuery: selection.selectionCrmSearchQuery,
      selectionObjectTypeId: selection.selectionObjectTypeId
    };
  } else {
    return {
      selectionMethod: selection.selectionMethod,
      selectionIds: (selection.selectionIds || []).map(String),
      selectionCrmSearchQuery: selection.selectionCrmSearchQuery,
      selectionObjectTypeId: selection.selectionObjectTypeId
    };
  }
};
export const makeSelectionStateHash = selection => `${selection.selectionObjectTypeId}-${selection.selectionMethod}-${selection.selectionMethod === SELECTION_METHOD_BY_IDS ? JSON.stringify(selection.selectionIds) : JSON.stringify(selection.selectionCrmSearchQuery)}`;
export const DefaultBulkActionsReducer = (state, action) => {
  switch (action.type) {
    case SYNC_SELECTION_STATE:
      {
        const newState = Object.assign({}, state, {
          selection: createBulkActionsSelectionStateObject(action.payload)
        });
        return newState;
      }
    case SYNC_WARMUP_CACHE_FOR:
      {
        return setIn(['warmupCacheFor'], action.payload, state);
      }
    case OPEN_MODAL:
      {
        const newState = Object.assign({}, state, {
          currentModal: action.payload
        });
        return newState;
      }
    case CLOSE_MODAL:
      {
        const newState = Object.assign({}, state, {
          currentModal: undefined
        });
        return newState;
      }
    case SET_LOADING:
      {
        const loadingKey = action.payload.loadingKey;
        const value = action.payload.value;
        return setIn(['loading', loadingKey], value, state);
      }
    case SET_ERROR:
      {
        const errorKey = action.payload.errorKey;
        const value = action.payload.value;
        return setIn(['error', errorKey], value, state);
      }
    case CACHE_INDIVIDUAL_OBJECTS_PERMISSIONS:
      {
        let newState = state;
        action.payload.forEach(({
          objectTypeId,
          objectId,
          permissions
        }) => {
          newState = setIn(['cache', 'byObjectTypeId', objectTypeId, 'byObjectId', objectId, 'permissions'], permissions, newState);
        });
        return newState;
      }
    case CACHE_CRM_SEARCH_QUERY_PERMISSIONS:
      {
        let newState = state;
        action.payload.forEach(({
          query,
          permissions
        }) => {
          newState = setIn(['cache', 'byCrmSearchQueryObject', JSON.stringify(query), 'permissions'], permissions, newState);
        });
        return newState;
      }
    case CACHE_CRM_SEARCH_QUERY_TOTAL_NUMBER_OF_OBJECTS:
      {
        let newState = state;
        action.payload.forEach(({
          query,
          totalNumberOfObjectsInQuery
        }) => {
          newState = setIn(['cache', 'byCrmSearchQueryObject', JSON.stringify(query), 'totalNumberOfObjectsInQuery'], totalNumberOfObjectsInQuery, newState);
        });
        return newState;
      }
    default:
      {
        return state;
      }
  }
};