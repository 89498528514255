import { COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID, TICKET_TYPE_ID, CALL_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import memoize from 'transmute/memoize';
import toJS from 'transmute/toJS';
import AssociatedCompanyProperty from 'customer-data-objects/property/special/AssociatedCompanyProperty';
import AssociatedContactProperty from 'customer-data-objects/property/special/AssociatedContactProperty';
import InboundDbImportProperty from 'customer-data-objects/property/special/InboundDbImportProperty';
import InboundDbListMembershipProperty from 'customer-data-objects/property/special/InboundDbListMembershipProperty';
import FormSubmissionsProperty from 'customer-data-objects/property/special/FormSubmissionsProperty';
import DealStageProbabilityProperty from 'customer-data-objects/property/special/DealStageProbabilityProperty';
import ILSListMembershipProperty from 'customer-data-objects/property/special/ILSListMembershipProperty';
import isPortalSpecificObjectType from 'customer-data-objects/crmObject/isPortalSpecificObjectType';
import { isAppObjectTypeId } from 'customer-data-objects/crmObject/isAppObjectTypeId';

// These groupnames MUST match the internal names of the groups on each object type
// for these magic properties to appear in the correct place.
const groupNames = {
  [CONTACT_TYPE_ID]: 'contactinformation',
  [COMPANY_TYPE_ID]: 'companyinformation',
  [DEAL_TYPE_ID]: 'dealinformation',
  [TICKET_TYPE_ID]: 'ticketinformation',
  [CALL_TYPE_ID]: 'callinformation'
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'property' implicitly has an 'any' type.
export const getMagicPropertyAsJS = (property, objectTypeId) => Object.assign({}, toJS(property), {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  groupName: groupNames[objectTypeId]
});

// HACK: These are psuedo-properties (magic) that are accepted by crmsearch but are not real properties.
// They are used to filter by things like list membership and associated objects, but cannot be used
// as table columns. This is enforced by GridProperties.
export const getMagicPropertiesForObjectType = memoize(objectTypeId => {
  const associatedCompanyProperty = getMagicPropertyAsJS(AssociatedCompanyProperty, objectTypeId);
  const associatedContactProperty = getMagicPropertyAsJS(AssociatedContactProperty, objectTypeId);
  const inbounddbImportProperty = getMagicPropertyAsJS(InboundDbImportProperty, objectTypeId);
  const inbounddbListMembershipProperty = getMagicPropertyAsJS(InboundDbListMembershipProperty, objectTypeId);

  //hs_crm_search.ilsListIds
  const ilsListMembershipProperty = getMagicPropertyAsJS(ILSListMembershipProperty, objectTypeId);
  if (isPortalSpecificObjectType(objectTypeId) || isAppObjectTypeId(objectTypeId)) {
    return {
      [inbounddbImportProperty.name]: inbounddbImportProperty,
      [ilsListMembershipProperty.name]: ilsListMembershipProperty
    };
  }
  switch (objectTypeId) {
    case CONTACT_TYPE_ID:
      {
        const formSubmissionsProperty = getMagicPropertyAsJS(FormSubmissionsProperty, objectTypeId);
        return {
          [inbounddbImportProperty.name]: inbounddbImportProperty,
          [formSubmissionsProperty.name]: formSubmissionsProperty,
          [ilsListMembershipProperty.name]: ilsListMembershipProperty
        };
      }
    case COMPANY_TYPE_ID:
      {
        return {
          [inbounddbImportProperty.name]: inbounddbImportProperty,
          [ilsListMembershipProperty.name]: ilsListMembershipProperty
        };
      }
    case DEAL_TYPE_ID:
      {
        const dealStageProbabilityProperty = getMagicPropertyAsJS(DealStageProbabilityProperty, objectTypeId);
        return {
          [associatedCompanyProperty.name]: associatedCompanyProperty,
          [associatedContactProperty.name]: associatedContactProperty,
          [inbounddbImportProperty.name]: inbounddbImportProperty,
          [dealStageProbabilityProperty.name]: dealStageProbabilityProperty,
          [ilsListMembershipProperty.name]: ilsListMembershipProperty
        };
      }
    case TICKET_TYPE_ID:
      {
        return {
          [associatedCompanyProperty.name]: associatedCompanyProperty,
          [associatedContactProperty.name]: associatedContactProperty,
          [inbounddbImportProperty.name]: inbounddbImportProperty,
          [inbounddbListMembershipProperty.name]: inbounddbListMembershipProperty
        };
      }
    case CALL_TYPE_ID:
      {
        return {
          [inbounddbImportProperty.name]: inbounddbImportProperty
        };
      }
    default:
      {
        return {};
      }
  }
});