import { BaseCrmSearchError } from './BaseCrmSearchError';
const SORTING_ON_FIELD_RESTRICTED_REGEX = /Portal \S+ has been blocked from sorting on \S+ property because this field is too large/;
export class CrmSearchSortingOnFieldRestrictedError extends BaseCrmSearchError {
  constructor(error) {
    super(error);
    this.name = 'CrmSearchSortingOnFieldRestrictedError';
  }
  static isSortingOnFieldRestrictedError(errorMessage) {
    return Boolean(errorMessage.match(SORTING_ON_FIELD_RESTRICTED_REGEX));
  }
  static tryFromError(error) {
    if (CrmSearchSortingOnFieldRestrictedError.isSortingOnFieldRestrictedError(error.message)) {
      return new CrmSearchSortingOnFieldRestrictedError(error);
    }
    return null;
  }
}