import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["default"];
//@ts-expect-error untyped file
import { PLACEHOLDER_NOW } from 'crm_data/filters/FilterPlaceholderResolver';
import { useCurrentViewFilters } from '../../../views/hooks/useCurrentViewFilters';
import { setPlaceholdersOnFilter } from '../utils/setPlaceholdersOnFilter';
import I18n from 'I18n';
import { ALL_PIPELINES_VALUE } from '../../pipelines/constants/AllPipelinesValue';
import { useCurrentPipelineId } from '../../pipelines/hooks/useCurrentPipelineId';
import { useSelectedObjectTypeDef } from '../../../crmObjects/hooks/useSelectedObjectTypeDef';
import memoizeOne from 'react-utils/memoizeOne';
/**
 * @description Processes the filters for a query, replacing placeholders with values, and adding a pipeline filter if necessary.
 */
const processFilters = (filters, pipelineId, pipelinePropertyName) => {
  const now = I18n.moment.userTz().valueOf();
  const filtersWithoutPlaceholders = filters.map(filter => setPlaceholdersOnFilter(filter, {
    [PLACEHOLDER_NOW]: now
  }));

  // HACK: If filters were cloned from a default view in the legacy verison of the app,
  // they may contain a "default" field. This field blows up graphql as it is not
  // expected on the filter object, so we have to make sure to filter them out.
  // Long-term we should have the BE backfill all custom views to remove the field.
  // The field is set in the defaults here: https://git.hubteam.com/HubSpot/crm_universal/blob/eef242d34c4c005e8a8f81431dbc51728233bd62/static/js/view/ViewDefaults.js#L394
  // And also as part of ViewsStore here: https://git.hubteam.com/HubSpot/CRM/blob/bb3b45d4279bcd65f05f47ee9b028c6bdd388da0/crm_ui/static/js/flux/views/ViewsStore.js#L53-L55
  const sanitizedFilters = filtersWithoutPlaceholders.map(filterObject => {
    const filterObjectWithoutDefaultProp = _objectWithoutPropertiesLoose(filterObject, _excluded);
    return filterObjectWithoutDefaultProp;
  });

  // This block of code appends a filter for the current pipeline, if one exists. This
  // is where the value in the pipeline dropdown at the top of the page ends up, and filters
  // the entire query to only objects in the specified pipeline.
  if (pipelineId && pipelineId !== ALL_PIPELINES_VALUE) {
    return sanitizedFilters.concat({
      operator: 'EQ',
      value: pipelineId,
      property: pipelinePropertyName
    });
  }
  return sanitizedFilters;
};
export const generateQueryFilterGroups = memoizeOne((viewFilters, quickFilters, pipelineId, pipelinePropertyName) => {
  //if viewFilters is an empty array, we still care about quickFilters and have to create a single filterGroup for it
  //without this, quickFilters will be ignored entirely
  const normalizedFilterGroups = viewFilters.length > 0 ? viewFilters : [{
    filters: []
  }];
  return normalizedFilterGroups.map(({
    filters
  }) => ({
    filters: processFilters(filters, pipelineId, pipelinePropertyName
    // we concat the array of quickFilters to each array of filters within each filterGroup
    // to "AND" all quickFilters with all the regular filters in each "ORed" filter group
    // the quickFilters need to be "ANDed" to *each* filter group because of the distributivity property of Boolean algebra
    // X AND (A OR B OR C) === (X AND A) OR (X AND B) OR (X AND C)
    ).concat(quickFilters)
  }));
});
export const useQueryFilterGroups = () => {
  const {
    filters,
    quickFilters
  } = useCurrentViewFilters();
  const pipelineId = useCurrentPipelineId();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'pipelinePropertyName' does not exist on ... Remove this comment to see the full error message
  const {
    pipelinePropertyName
  } = useSelectedObjectTypeDef();
  return generateQueryFilterGroups(filters, quickFilters, pipelineId, pipelinePropertyName);
};