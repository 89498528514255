export const EQUAL = 'EQ';
export const GREATER = 'GT';
export const GREATER_OR_EQUAL = 'GTE';
export const GREATER_THAN_X_DAYS = 'GT_X_DAYS';
export const IN = 'IN';
export const IN_RANGE = 'BETWEEN';
export const IN_RANGE_ROLLING = 'ROLLING_DATE_RANGE';
export const KNOWN = 'HAS_PROPERTY';
export const LESS = 'LT';
export const LESS_OR_EQUAL = 'LTE';
export const LESS_THAN_X_DAYS = 'LT_X_DAYS';
export const NEAR = 'DISTANCE_LT';
export const NOT_EQUAL = 'NEQ';
export const NOT_IN = 'NOT_IN';
export const NOT_KNOWN = 'NOT_HAS_PROPERTY';
export const TIME_UNIT_TO_DATE = 'TIME_UNIT_TO_DATE';