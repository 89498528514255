import { registerQuery, useQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
import { useMemo } from 'react';
import { makeApolloLikeResultType } from '../typeUtils';
const HYDRATE_CRM_SEARCH_QUERY_NAME = 'crm_object_search_query_utilities_hydrate_filter_groups';
const HydrateFilterGroupsQuery = registerQuery({
  fieldName: HYDRATE_CRM_SEARCH_QUERY_NAME,
  args: ['filterGroups'],
  fetcher: ({
    filterGroups
  }) => http.post(`sales-views/v2/crm-search/hydrate-filter-groups`, {
    data: filterGroups
  }).then(response => ({
    filterGroups: response
  }))
});

/**
 * This hook is used to hydrate the filterGroups of a CrmObjectsSearchQueryObject.
 *
 * "What is filter hydration?":
 *  - Filter hydration is the process of replacing placeholders in filters with actual values.
 *  - For example, if you have a filter that contains the placeholder `__hs__ME`, this hook will replace that placeholder with the actual id of the current user.
 *
 * You should use this when:
 *   - you are directly passing a search query object to a backend service that does not hydrate filter groups automatically
 *   - you are using a FE library that does not hydrate filter groups automatically
 * Some examples of when you should use this hook:
 *   - You are giving CrmExportDialog a search query object
 *
 *
 *   Example:
 *   ```tsx
 *  const { data, loading, error } = useHydrateCrmObjectsSearchQueryObject(searchQuery);
 *   ```
 */
export const useHydrateCrmObjectsSearchQueryObject = crmSearchQuery => {
  const skip = !crmSearchQuery;
  const {
    data,
    loading,
    error
  } = useQuery(HydrateFilterGroupsQuery, {
    variables: {
      filterGroups: crmSearchQuery && crmSearchQuery.filterGroups || []
    },
    skip
  });
  return useMemo(() => {
    const returnData = data && crmSearchQuery && Object.assign({}, crmSearchQuery, {
      filterGroups: data[HYDRATE_CRM_SEARCH_QUERY_NAME].filterGroups
    });
    return makeApolloLikeResultType({
      data: returnData,
      loading,
      error
    });
  }, [data, loading, error, crmSearchQuery]);
};