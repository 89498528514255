export const CREATED_CRM_OBJECT = 'CREATED_CRM_OBJECT';
export const FETCH_CRM_OBJECTS_STARTED = 'FETCH_CRM_OBJECTS_STARTED';
export const FETCH_CRM_OBJECTS_SUCCEEDED = 'FETCH_CRM_OBJECTS_SUCCEEDED';
export const FETCH_CRM_OBJECTS_FAILED = 'FETCH_CRM_OBJECTS_FAILED';
export const DELETE_CRM_OBJECTS_STARTED = 'DELETE_CRM_OBJECTS_STARTED';
export const DELETE_CRM_OBJECTS_SUCCEEDED = 'DELETE_CRM_OBJECTS_SUCCEEDED';
export const DELETE_CRM_OBJECTS_FAILED = 'DELETE_CRM_OBJECTS_FAILED';
export const BATCH_DELETE_CRM_OBJECTS_STARTED = 'BATCH_DELETE_CRM_OBJECTS_STARTED';
export const BATCH_DELETE_CRM_OBJECTS_SUCCEEDED = 'BATCH_DELETE_CRM_OBJECTS_SUCCEEDED';
export const BATCH_DELETE_CRM_OBJECTS_FAILED = 'BATCH_DELETE_CRM_OBJECTS_FAILED';
export const UPDATE_CRM_OBJECTS_STARTED = 'UPDATE_CRM_OBJECTS_STARTED';
export const UPDATE_CRM_OBJECTS_SUCCEEDED = 'UPDATE_CRM_OBJECTS_SUCCEEDED';
export const UPDATE_CRM_OBJECTS_FAILED = 'UPDATE_CRM_OBJECTS_FAILED';
export const BATCH_UPDATE_CRM_OBJECTS_STARTED = 'BATCH_UPDATE_CRM_OBJECTS_STARTED';
export const BATCH_UPDATE_CRM_OBJECTS_SUCCEEDED = 'BATCH_UPDATE_CRM_OBJECTS_SUCCEEDED';
export const BATCH_UPDATE_CRM_OBJECTS_FAILED = 'BATCH_UPDATE_CRM_OBJECTS_FAILED';