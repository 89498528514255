import { usePropertyDefinitions } from './usePropertyDefinitions';
export const useProperty = propertyName => {
  const data = usePropertyDefinitions();
  if (!data) {
    return null;
  }
  if (data.has(propertyName)) {
    return data.get(propertyName).property;
  }
  return null;
};