'use es6';

import http from 'hub-http/clients/apiClient';
import formatMarketingContactReason from 'reference-resolvers/formatters/formatMarketingContactReason';
export const createGetMarketingReasonById = ({
  httpClient
}) => ids => httpClient.post('marketable-contacts/v1/marketable-labels/vids', {
  data: ids
}).then(formatMarketingContactReason);
export const getMarketingReasonById = createGetMarketingReasonById({
  httpClient: http
});