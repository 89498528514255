import { FiltersQueryParam, SpecificPropertyFilterQueryParams } from '../constants/QueryParamsThatRequireParsing';
import { getJsonFilters } from './getJsonFilters';
export const getFilterForSpecificPropertyParam = (property, value) => property === 'formSubmissions.formId' ? {
  operator: 'IN',
  property,
  values: [value]
} : {
  operator: 'EQ',
  property,
  value
};
export const parseQueryParamFilters = params => {
  const filtersFromFiltersParam = getJsonFilters(params[FiltersQueryParam] || '[]');
  const propertiesFromFiltersParam = new Set(filtersFromFiltersParam.map(({
    property
  }) => property).filter(Boolean));
  const filtersFromSpecificPropertyParams = SpecificPropertyFilterQueryParams.reduce((filtersFromParams, specificPropertyName) => {
    const value = params[specificPropertyName];
    if (!propertiesFromFiltersParam.has(specificPropertyName) && value) {
      filtersFromParams.push(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ operator: string; property: an... Remove this comment to see the full error message
      getFilterForSpecificPropertyParam(specificPropertyName, value));
    }
    return filtersFromParams;
  }, []);
  return [...filtersFromFiltersParam, ...filtersFromSpecificPropertyParams];
};