import getIn from 'transmute/getIn';
import curry from 'transmute/curry';
import { mutableSetIn } from './mutableSetIn';

/**
 * @deprecated Update using plain javascript syntax if possible. This function is not type safe.
 */
export const mutableUpdateIn = curry((keyPath, updater, obj) => {
  const currentValue = getIn(keyPath, obj);
  mutableSetIn(keyPath, updater(currentValue), obj);
});